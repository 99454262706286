import React from 'react';
import { Button as MuiButton, ButtonProps, styled } from '@mui/material';

interface StatusButtonProps extends ButtonProps {
  status?: 'loading' | 'success';
}

const ButtonStyled = styled(MuiButton)<StatusButtonProps>`
  padding: 0 10px 0 0;
  color: ${({ status, theme }) => {
    switch (status) {
      case 'loading':
        return theme.color.primary.$100;
      case 'success':
        return '#78DC00';
      default:
        return '#fff';
    }
  }};
  padding-top: 4;
  border: ${({ status, theme }) => {
    switch (status) {
      case 'loading':
        return `1px solid ${theme.color.primary.$100}`;
      case 'success':
        return '1px solid #78DC00';
      default:
        return 'transparent';
    }
  }};

  padding-bottom: 4;
  box-shadow: none;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'loading':
        return 'transparent';
      case 'success':
        return 'transparent';
      default:
        return theme.color.primary.$100;
    }
  }};
  &:hover,
  &:active {
    color: '#fff';
    background-color: ${({ status, theme }) => {
      switch (status) {
        case 'loading':
          return 'transparent';
        case 'success':
          return 'transparent';
        default:
          return theme.color.primary.$80;
      }
    }};
  }
  &.Mui-disabled {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.secondary.$60};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  }
  & .MuiButton-startIcon {
    margin: 0;
    ${({ status }) => {
      switch (status) {
        case 'loading':
          return 'animation: spin 0.7s linear infinite';
        default:
      }
    }};
    @keyframes spin {
      100% {
        transform: rotate(-360deg);
      }
    }
  }
`;

const ConfirmButton: React.FC<StatusButtonProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <ButtonStyled {...otherProps} disableRipple>
      {children}
    </ButtonStyled>
  );
};

export default ConfirmButton;
