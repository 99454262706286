import { isSameDay } from 'date-fns';

import { PlantWillAssign, PlantWithOrg } from '../../../types';
import { Plant } from '../../../types/Resource';

export const checkIsFormCraneEdited = (
  plantSelected: Plant,
  plantWillAssign: PlantWillAssign,
) => {
  const checkFieldValue = (field: keyof Plant) => {
    const selectedValue = plantSelected[field];
    const willAssignValue = plantWillAssign[field];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue !== willAssignValue) return true;
    }
    return false;
  };

  const checkFieldDate = (field: keyof Plant) => {
    const selectedValue = plantSelected[
      field
    ] as PlantWithOrg['permitExpiryDate'];
    const willAssignValue = plantWillAssign[
      field
    ] as PlantWillAssign['permitExpiryDate'];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (!isSameDay(new Date(selectedValue), new Date(willAssignValue))) {
        return true;
      }
    }
    return false;
  };

  const checkFieldCoordinates = (field: keyof Plant) => {
    const selectedValue = plantSelected[field] as Plant['location'];
    const willAssignValue = plantWillAssign[field] as Plant['location'];

    if (selectedValue == null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue.alt === null) {
        if (willAssignValue?.alt) return true;
      } else {
        if (selectedValue.alt !== willAssignValue?.alt) return true;
      }

      if (selectedValue.lat !== willAssignValue?.lat) return true;
      if (selectedValue.lon !== willAssignValue?.lon) return true;
    }
  };

  if (checkFieldValue('orgId')) return true;
  if (checkFieldValue('name')) return true;
  if (checkFieldValue('model')) return true;
  if (checkFieldValue('serialNumber')) return true;
  if (checkFieldValue('permitNumber')) return true;
  if (checkFieldValue('permitIssuer')) return true;
  if (checkFieldValue('safetyLoad')) return true;
  if (checkFieldValue('yearOfProduction')) return true;
  if (checkFieldValue('brand')) return true;
  if (checkFieldValue('remark')) return true;
  if (checkFieldDate('permitExpiryDate')) return true;
  if (checkFieldCoordinates('location')) return true;
  return false;
};

export const checkCraneBasicInformationAvalible = (
  basicInfomation?: PlantWillAssign,
) => {
  if (!basicInfomation?.orgId) return false;
  if (!basicInfomation?.name) return false;
  if (!basicInfomation?.serialNumber) return false;
  if (!basicInfomation.location) return false;
  return true;
};
