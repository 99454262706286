import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity="0.1"
        cx="16"
        cy="16"
        r="8"
        fill="currentColor"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9071 14.7071C19.2976 14.3166 19.2976 13.6834 18.9071 13.2929C18.5166 12.9024 17.8834 12.9024 17.4929 13.2929L16.1001 14.6857L14.7073 13.2929C14.3168 12.9024 13.6836 12.9024 13.2931 13.2929C12.9026 13.6834 12.9026 14.3166 13.2931 14.7071L14.6859 16.0999L13.2929 17.4929C12.9024 17.8834 12.9024 18.5166 13.2929 18.9071C13.6834 19.2976 14.3166 19.2976 14.7071 18.9071L16.1001 17.5141L17.4931 18.9071C17.8836 19.2976 18.5168 19.2976 18.9073 18.9071C19.2978 18.5166 19.2978 17.8834 18.9073 17.4929L17.5143 16.0999L18.9071 14.7071Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CloseSvgIcon;
