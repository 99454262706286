import i18n from '../i18n';

const checkEmailPassEmpty = (email: string | undefined | null) => {
  if (!email) {
    return {
      isError: false,
      message: '',
    };
  }

  if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    )
  ) {
    return {
      isError: true,
      message: i18n.t('error:notEmail'),
    };
  }

  return {
    isError: false,
    message: '',
  };
};

export default checkEmailPassEmpty;
