import type { SVGProps } from 'react';
import * as React from 'react';

const DaslockSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 11v2h-6v-2a3 3 0 1 1 6 0Zm-8 2v-2a5 5 0 0 1 10 0v2h2a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V14a1 1 0 0 1 1-1h2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DaslockSvgIcon;
