import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DascasDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dascas-dashboard-page" */ '../../pages/dascas-dashboard'
    ),
);

const dascasDashboardRouter = (
  <Route path="dascas-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DascasDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dascasDashboardRouter;
