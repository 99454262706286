import type { SVGProps } from 'react';
import * as React from 'react';

const AttendanceSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.447 4.106a1 1 0 0 0-.894 0l-8 4A1 1 0 0 0 7 9v5a1 1 0 0 0 .553.894l8 4a1 1 0 0 0 .894 0l8-4A1 1 0 0 0 25 14V9a1 1 0 0 0-.553-.894l-8-4ZM9 13.382v-2.764l6 3v2.764l-6-3Zm7-1.5L10.236 9 16 6.118 21.764 9 16 11.882Zm-8.526 5.267a1 1 0 0 1 .973-.043L16 20.882l7.553-3.776A1 1 0 0 1 25 18v5a1 1 0 0 1-.553.894l-8 4a1 1 0 0 1-.894 0l-8-4A1 1 0 0 1 7 23v-5a1 1 0 0 1 .474-.85ZM9 19.618v2.764l6 3v-2.764l-6-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default AttendanceSvgIcon;
