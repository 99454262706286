import type { SVGProps } from 'react';

const CH2OSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx={11} cy={13} r={2.08} stroke="currentColor" strokeWidth={2} />
    <circle cx={5.5} cy={5.5} r={1.5} stroke="currentColor" strokeWidth={2} />
    <circle cx={19.5} cy={15.5} r={1.5} stroke="currentColor" strokeWidth={2} />
    <circle cx={6.5} cy={19.5} r={1.5} stroke="currentColor" strokeWidth={2} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m6.5 7 2.74 4M7 18l2.5-3m4-2 4 1.5"
    />
  </svg>
);
export default CH2OSvgIcon;
