import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeleteSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V9H18V19ZM13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25V12.75C15 12.3358 14.6642 12 14.25 12C13.8358 12 13.5 12.3358 13.5 12.75V17.25ZM9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25V12.75C10.5 12.3358 10.1642 12 9.75 12C9.33579 12 9 12.3358 9 12.75V17.25ZM13.5 8H7C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H11V5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H13.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DeleteSvgIcon;
