import { WaterLevelStatus } from '../types';
import { DaswaterLAlert } from '../types/Alert';
import { Pipe } from '../types/Resource';

export const getWaterLevelStatus: (
  distance: number | null | undefined,
  height: number,
  maxDistance: number,
  minDistance: number,
  online: boolean,
) => WaterLevelStatus = (
  distance,
  height,
  maxDistance,
  minDistance,
  online,
) => {
  const sensorMaxValue = height - maxDistance;
  const sensorMinValue = height - minDistance;

  if (!online || distance === null || distance === undefined) {
    return 'offline';
  } else if (distance < sensorMaxValue && height > sensorMaxValue) {
    return 'high';
  } else if (distance > sensorMinValue && height > sensorMinValue) {
    return 'low';
  } else {
    return 'normal';
  }
};

export const findTheClosestPipe = (
  pipes: Pipe[] | undefined,
  alert: DaswaterLAlert,
) => {
  let smallestDiff = Infinity;
  let closestPipe: Pipe | null = null;
  if (pipes && pipes.length > 0) {
    for (const pipe of pipes) {
      const updatedAt = new Date(pipe.updatedAt);
      const alertTimestamp = new Date(alert.timestamp);
      if (updatedAt > alertTimestamp) {
        continue;
      }
      const diff = alertTimestamp.getTime() - updatedAt.getTime();
      if (diff < smallestDiff && pipe.id === alert.resourceId) {
        smallestDiff = diff;
        closestPipe = pipe;
      }
    }
  }

  return closestPipe;
};
