import * as React from 'react';

const DascasSvgColor = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1154 6.07694C19.4592 5.93369 19.8541 5.99337 20.1402 6.23179L26.1402 11.2318C26.3682 11.4218 26.5 11.7032 26.5 12V14C26.5 14.5523 26.0523 15 25.5 15H21.5V25C21.5 25.5522 21.0523 26 20.5 26H18.5C17.9477 26 17.5 25.5522 17.5 25V15H7.5C6.94772 15 6.5 14.5523 6.5 14V12C6.5 11.5963 6.74274 11.2322 7.11538 11.0769L19.1154 6.07694ZM21.5 12H23.938L20.2811 8.9526L21.5 12V12ZM18.98 8.30002L17.5 12V12H10.1L18.98 8.30002Z"
      fill="#E6A600"
    />
    <path
      d="M14.5 22C14.5 23.6569 13.1569 25 11.5 25C9.84315 25 8.5 23.6569 8.5 22C8.5 20.3431 9.84315 19 11.5 19V17"
      stroke="#A1A1A1"
      strokeWidth="2"
    />
  </svg>
);

export default DascasSvgColor;
