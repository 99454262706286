import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EnterSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 9H23V23H17"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13 12L17 16L13 20"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect x="8.5" y="15.5" width="8" height="1" fill="white" stroke="white" />
  </SvgIcon>
);

export default EnterSvgIcon;
