import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasloopSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.641 19.6441L25.4569 16.6334C20.9794 14.0342 13.9676 14.1796 9.48439 16.7754C7.38916 17.9916 6.22043 19.5298 6.0287 21.0888L6 21.0715V24.8731C6.07606 25.1717 6.22759 25.4455 6.4399 25.6679C6.65222 25.8903 6.9181 26.0538 7.21178 26.1425C8.23184 26.4523 9.56074 26.6393 10.8529 28.3546C11.4958 29.2079 16.0806 30.955 22.3261 31L22.321 30.9971C25.326 31.0288 27.2073 30.2998 29.5 28.9693C33.9786 26.3747 35.1197 22.2427 30.641 19.6441Z"
        fill="#E6A600"
      />
      <path
        d="M30.6404 28.9312C26.1626 31.6897 18.8998 31.6897 14.4208 28.9312L9.2385 25.7351C4.76062 22.9767 5.00518 18.6483 9.48421 15.8874C13.9632 13.1265 20.9791 12.9751 25.4582 15.736L30.6427 18.9322C35.1195 21.6937 35.1195 26.1703 30.6404 28.9312Z"
        fill="#FFCE4F"
      />
      <path
        d="M7.67799 20.6814C7.67372 22.2149 8.93649 23.2408 10.7696 24.5947C11.8463 25.3899 12.9668 25.9694 14.1432 26.441C14.7077 26.6672 15.3052 26.9043 15.8793 27.0604C19.7748 28.1202 23.7907 27.6081 26.5774 26.0001C28.6376 24.8121 29.8183 23.4182 29.8407 21.8332C29.8444 21.5785 29.976 19.8346 28.0198 16.4246C22.5062 6.81671 8.18598 8.38286 7.67799 20.6814Z"
        fill="#FFB737"
      />
      <path
        d="M7.67799 20.6814C7.67372 22.2149 8.93649 23.2408 10.7696 24.5947C11.8463 25.3899 12.9668 25.9694 14.1432 26.441C14.7077 26.6672 15.3052 26.9043 15.8793 27.0604C19.7748 28.1202 23.7907 27.6081 26.5774 26.0001C28.6376 24.8121 29.8183 23.4182 29.8407 21.8332C29.8444 21.5785 29.976 19.8346 28.0198 16.4246C22.5062 6.81671 8.18598 8.38286 7.67799 20.6814Z"
        fill="#FFB905"
      />
      <path
        d="M16.5414 10.0615C14.6011 10.3321 13.1401 10.9844 11.9573 11.9785C21.5 9.17046 25.6078 22.9038 26.5252 27.4464C27.5 26.9387 29 25.9234 29.5 25.4157C29.5037 25.2371 29.3366 24.2185 29.2485 23.5054C28.5081 17.5103 22.698 9.20302 16.5414 10.0615Z"
        fill="#FFD976"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 27.4463C26.1498 22.4576 21 8.6627 11.975 11.9635C11.9691 11.9685 11.9632 11.9734 11.9573 11.9784C21 10.0615 24.1531 22.712 24.5 26.9386L26.5 27.4463Z"
        fill="#E6A600"
      />
    </SvgIcon>
  );
};

export default DasloopSvgColorMedium;
