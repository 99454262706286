import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar as MuiAvatar,
  Box,
  styled,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { EnvironmentWillAssign, InputValidationError } from '../../../types';

import { useAppSelector } from '../../../hooks';

import envDefaultPng from '../../../assets/images/default-image/environment_default.png';
import UploadSvg from '../../../assets/images/svg/btn_upload_image.svg';
import ErrorSvg from '../../../assets/images/svg/ic_error_ifo.svg';
import OrgAutocompleteBasic from '../../OrgAutocompleteBasic';
import Input from '../../TextField/Input';

import './TargetOperationDialog.css';

const Container = styled('div')`
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  overflow-y: auto;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 10px 10px 10px;
  border-radius: 4px;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const Avatar = styled(MuiAvatar)`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetails = styled('div')`
  margin: 10px 0;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const ContainerImage = styled('div')`
  margin: 10px auto;
  width: 200px;
  position: relative;
`;

const ContainerUploadImage = styled('div')`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
`;

const Error = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > span {
    color: #ff6b00;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const ContainerTextArea = styled('div')`
  & textarea {
    ${({ theme }) => ({ ...theme.typography.body2 })}
    width: 100%;
    font-family: 'Noto Sans TC', 'Noto Sans';
    background: ${({ theme }) => theme.color.secondary.$100};
    border-radius: 4px;
    outline: none;
    color: white;
    padding: 10px;
    border-color: transparent;
    resize: none;
    &:hover {
      background: ${({ theme }) => theme.color.secondary.$100};
    }
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
`;

interface EnvironmentBasicInformationContentProps {
  handleEnvironmentDataWillAssign: (data: EnvironmentWillAssign) => void;
  data: EnvironmentWillAssign | undefined;
}

const EnvironmentBasicInformationContent: React.FC<
  EnvironmentBasicInformationContentProps
> = ({
  handleEnvironmentDataWillAssign,
  data = {
    id: '',
    name: '',
    groupIds: [],
    remark: undefined,
    orgId: '',
    areaId: '',
    imageUrl: null,
  },
}) => {
  const { t } = useTranslation('project-setting');
  const fileRef = useRef<HTMLInputElement | null>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const [imageFormatError, setImageFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [workerNameFormatError, setWorkerNameFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [orgIdError, setOrgIdError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });

  const handleOnChange = (
    propertyObjs: Array<{
      propertyName: keyof EnvironmentWillAssign;
      value: any;
    }>,
  ) => {
    const currentValue = {
      ...data,
    };
    propertyObjs.forEach((obj) => {
      // @ts-ignore
      currentValue[obj.propertyName] = obj.value;
    });
    handleEnvironmentDataWillAssign({
      name: currentValue?.name ?? '',
      groupIds: currentValue?.groupIds ?? [],
      remark: currentValue?.remark,
      orgId: currentValue?.orgId ?? '',
      id: currentValue?.id ?? '',
      avatarFile: currentValue.avatarFile,
      areaId: currentValue?.areaId ?? '',
      imageUrl: currentValue?.imageUrl ?? null,
    });
  };

  const handleOnClickUploadImage = () => {
    fileRef.current?.click();
  };

  const handleOnSelectFile = () => {
    setImageFormatError({ isError: false, message: '' });
    if (fileRef.current && fileRef.current?.files) {
      const fileImge = fileRef.current?.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const newSrc = reader.result as string;
        if (fileRef.current && fileRef.current.files) {
          handleOnChange([
            {
              propertyName: 'avatarFile',
              value: fileRef.current.files[0],
            },
            {
              propertyName: 'imageUrl',
              value: newSrc,
            },
          ]);
        }
      });
      reader.readAsDataURL(fileImge);
    }
  };

  const checkWorkerNameFormat = () => {
    if (data?.name === '') {
      return setWorkerNameFormatError({
        isError: true,
        message: t('error:inputIsEmpty'),
      });
    }
    return setWorkerNameFormatError({ isError: false, message: '' });
  };

  const LIST_ORG = useMemo(() => {
    const orgs = Object.values(orgMap);
    if (orgs.length > 0) {
      return orgs.map((organization) => ({
        id: organization?.id ?? '',
        name: organization?.name ?? '',
        color: organization?.color ?? '',
      }));
    }
    return [
      {
        id: '',
        name: '',
        color: '',
      },
    ];
  }, [orgMap]);

  return (
    <Container ref={dialogRef} className="aaa">
      <ContentTitle>
        {t('basic-information')}
        <SpanRequire>*</SpanRequire>
      </ContentTitle>

      <Content>
        <ContainerImage>
          <Avatar
            src={data?.imageUrl ?? envDefaultPng}
            alt="environment avatar"
            sx={{ marginBottom: '10px' }}
            variant="square"
          />
          <ContainerUploadImage onClick={handleOnClickUploadImage}>
            <img src={UploadSvg} alt="upload icon" />
            <input
              ref={fileRef}
              type="file"
              hidden
              accept=".jpg, .png, .jpeg, .svg"
              onChange={handleOnSelectFile}
              data-cy="input-image-worker"
            />
          </ContainerUploadImage>
        </ContainerImage>
        {imageFormatError.message && imageFormatError.message !== '' && (
          <Error>
            {<img src={ErrorSvg} alt="error" />}
            <span>{imageFormatError.message}</span>
          </Error>
        )}
        <ContainerDetails>
          <Title>
            {t('organization')}
            <SpanRequire>*</SpanRequire>
          </Title>
          <OrgAutocompleteBasic
            data={LIST_ORG}
            onSelect={(d) => {
              handleOnChange([{ propertyName: 'orgId', value: d.id }]);
            }}
            onBlur={() => {
              if (!data?.orgId) {
                setOrgIdError({ isError: true, message: t('error') });
              } else {
                setOrgIdError({ isError: false, message: t('') });
              }
            }}
            error={orgIdError}
            selectedId={data?.orgId}
            data-cy="dropdown-org-worker"
            placeholder={t('page.select-organization')}
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t('name')}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            placeholder={t('name')}
            value={data?.name ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'name',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={checkWorkerNameFormat}
            error={workerNameFormatError.isError}
            errorMessage={workerNameFormatError.message}
            autoComplete="off"
            data-cy="input-name-worker"
          />
        </ContainerDetails>
        <ContainerTextArea>
          <Title>{t('remark')}</Title>
          <TextareaAutosize
            id="group-remark-input"
            name="group-remark"
            value={data?.remark ?? ''}
            maxRows={4}
            minRows={4}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'remark',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-remark-worker"
          />
        </ContainerTextArea>
      </Content>
    </Container>
  );
};

export default EnvironmentBasicInformationContent;
