import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddPouringArea = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 5V9H29V11H25V15H23V11H19V9H23V5H25ZM16.4472 7.10557L17 7.38196V9.61803L16 9.11803L10.2361 12L16 14.882L19.7639 13H21V14.618L17 16.618V24.382L23 21.382V17H25V22C25 22.3788 24.786 22.725 24.4472 22.8944L16.4472 26.8944C16.1657 27.0352 15.8343 27.0352 15.5528 26.8944L7.55279 22.8944C7.214 22.725 7 22.3788 7 22V12C7 11.6212 7.214 11.275 7.55279 11.1056L15.5528 7.10557C15.8343 6.9648 16.1657 6.9648 16.4472 7.10557ZM9 13.618V21.382L15 24.382V16.618L9 13.618Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AddPouringArea;
