import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Contact, Group, PipeWithOrg } from '../../../types';
import { Daswater } from '../../../types/Device';
import { ResourceNavigator } from '../../../types/Resource';

import { navigatorPipe } from '../../../utils/common';

type PipeManagementPage = {
  navigator: ResourceNavigator;
  navigatorIndex: number;
  selectedGroup: Group | undefined;
  pipeSelected: PipeWithOrg | undefined;
  daswaters: Array<Daswater>;
  pipeContacts: Array<Contact>;
  listPipeGroupProject: Array<Group>;
  listPipeGroupSelected: Array<Group>;
  connectedDevice: Array<Daswater>;
};

const initialState: PipeManagementPage = {
  navigator: navigatorPipe,
  navigatorIndex: 1,
  selectedGroup: undefined,
  pipeSelected: undefined,
  daswaters: [],
  pipeContacts: [],
  listPipeGroupProject: [],
  listPipeGroupSelected: [],
  connectedDevice: [],
};

export const pipeManagementPageSlice = createSlice({
  name: 'pipeManagement',
  initialState,
  reducers: {
    setSelectedPipeGroup: (
      state,
      action: PayloadAction<PipeManagementPage['selectedGroup']>,
    ) => {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    },
    setPipeSelected: (
      state,
      action: PayloadAction<PipeManagementPage['pipeSelected']>,
    ) => {
      return {
        ...state,
        pipeSelected: action.payload,
      };
    },
    setDaswaters: (
      state,
      action: PayloadAction<PipeManagementPage['daswaters']>,
    ) => {
      return { ...state, daswaters: action.payload };
    },
    setPipeContacts: (
      state,
      action: PayloadAction<PipeManagementPage['pipeContacts']>,
    ) => {
      return { ...state, pipeContacts: action.payload };
    },
    setListPipeGroupProject: (
      state,
      action: PayloadAction<PipeManagementPage['listPipeGroupProject']>,
    ) => {
      return { ...state, listPipeGroupProject: action.payload };
    },
    setListPipeGroupSelected: (
      state,
      action: PayloadAction<PipeManagementPage['listPipeGroupSelected']>,
    ) => {
      return { ...state, listPipeGroupSelected: action.payload };
    },
    setPipeDeviceConnected: (
      state,
      action: PayloadAction<PipeManagementPage['connectedDevice']>,
    ) => {
      return { ...state, connectedDevice: action.payload };
    },
    setNavigatorIndex: (
      state,
      action: PayloadAction<PipeManagementPage['navigatorIndex']>,
    ) => {
      return {
        ...state,
        navigatorIndex: action.payload,
      };
    },
  },
});

export const {
  setSelectedPipeGroup,
  setDaswaters,
  setPipeContacts,
  setPipeSelected,
  setListPipeGroupProject,
  setListPipeGroupSelected,
  setPipeDeviceConnected,
  setNavigatorIndex,
} = pipeManagementPageSlice.actions;
export default pipeManagementPageSlice.reducer;
