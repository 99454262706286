import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AreaIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10.7324C7.4022 10.3866 7 9.74028 7 9C7 7.89543 7.89543 7 9 7C9.74028 7 10.3866 7.4022 10.7324 8H16.2676C16.6134 7.4022 17.2597 7 18 7C19.1046 7 20 7.89543 20 9C20 9.19896 19.9709 9.39113 19.9169 9.57251L22.8712 14.0041C22.9138 14.0014 22.9567 14 23 14C24.1046 14 25 14.8954 25 16C25 16.7403 24.5978 17.3866 24 17.7324V21.2676C24.5978 21.6134 25 22.2597 25 23C25 24.1046 24.1046 25 23 25C22.2597 25 21.6134 24.5978 21.2676 24H10.7324C10.3866 24.5978 9.74028 25 9 25C7.89543 25 7 24.1046 7 23C7 22.2597 7.4022 21.6134 8 21.2676V10.7324ZM10.7324 22C10.5568 21.6964 10.3036 21.4432 10 21.2676V10.7324C10.3036 10.5568 10.5568 10.3036 10.7324 10H16.2676C16.6134 10.5978 17.2597 11 18 11C18.1488 11 18.2939 10.9837 18.4334 10.9529L21.2069 15.1131C21.0745 15.3804 21 15.6815 21 16C21 16.7403 21.4022 17.3866 22 17.7324V21.2676C21.6964 21.4432 21.4432 21.6964 21.2676 22H10.7324Z"
      />
    </SvgIcon>
  );
};

export default AreaIconSvg;
