import * as React from 'react';

const DaslockSvgColor = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="11.5"
      y="7.5"
      width="9"
      height="11"
      rx="4.5"
      stroke="#A1A1A1"
      strokeWidth="3"
    />
    <path
      d="M7 15C7 13.8954 7.89543 13 9 13H23C24.1046 13 25 13.8954 25 15V25C25 26.1046 24.1046 27 23 27H9C7.89543 27 7 26.1046 7 25V15Z"
      fill="#E5E5E5"
    />
  </svg>
);

export default DaslockSvgColor;
