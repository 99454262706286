import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const QRCodeResourceRedirection = lazy(
  () =>
    import(
      /* webpackChunkName: "resource-information" */ '../../pages/resource-information'
    ),
);

export const qrCodeResourceRedirectRouter = (
  <Route
    path="/resource/information/"
    element={
      <Suspense fallback={<Loading />}>
        <QRCodeResourceRedirection />
      </Suspense>
    }
  ></Route>
);
