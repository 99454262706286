import * as THREE from 'three';
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from 'three-mesh-bvh';
import { IFCSPACE } from 'web-ifc/web-ifc-api';
import { IFCLoader } from 'web-ifc-three/IFCLoader';

import { SpatialStructure } from '../../../../types/Ifc';

import { IFCModel } from 'web-ifc-three/IFC/components/IFCModel';

import { globalTracker } from './ResourceTracker';

class IFCAgent {
  ifcLoader: IFCLoader;

  ifcModelData: Array<{
    ifcModel: IFCModel;
    position: { x: number; y: number; z: number };
    rotation: { heading: number; pitch: number; roll: number };
    scale: number;
  }>;

  originOffset: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 };

  scene: THREE.Scene;

  onProgress?: (progress: number) => void;

  constructor(
    scene: THREE.Scene,
    loadingManager: THREE.LoadingManager,
    config?: { originOffset?: { x: number; y: number; z: number } },
    onProgress?: (progress: number) => void,
  ) {
    this.ifcLoader = new IFCLoader(loadingManager);
    this.ifcModelData = [];
    this.scene = scene;
    this.originOffset = config?.originOffset ?? { x: 0, y: 0, z: 0 };
    this.onProgress = onProgress;
  }

  setOriginOffset(x: number, y: number, z: number) {
    this.originOffset = { x, y, z };
  }

  async init() {
    this.ifcLoader.ifcManager.setupThreeMeshBVH(
      computeBoundsTree,
      disposeBoundsTree,
      acceleratedRaycast,
    );

    this.ifcLoader.ifcManager.setOnProgress((event) => {
      const percent = (event.loaded / event.total) * 100;
      this.onProgress?.(percent);
    });

    await this.ifcLoader.ifcManager.useWebWorkers(
      true,
      '/static/ifc-lib/IFCWorker.js',
    );
    await this.ifcLoader.ifcManager.setWasmPath('../wasm/');
    await this.ifcLoader.ifcManager.parser.setupOptionalCategories({
      [IFCSPACE]: false,
    });
  }

  async loadIfc(
    filePath: string,
    position: { x: number; y: number; z: number },
    rotation: {
      heading: number;
      pitch: number;
      roll: number;
    },
    scale: number,
  ) {
    return new Promise<{
      modelID: number;
      centerPosition: {
        x: number;
        y: number;
        z: number;
      };
    }>((resolve) => {
      this.ifcLoader.load(filePath, (ifcModel) => {
        ifcModel.rotateY(((rotation.heading - 180) * Math.PI) / 180);
        ifcModel.scale.set(scale, scale, scale);
        ifcModel.updateMatrixWorld();
        const boundingbox = new THREE.Box3().setFromObject(ifcModel);
        const centerVector = boundingbox.getCenter(new THREE.Vector3());
        this.ifcModelData.push({
          ifcModel,
          position: {
            x: position.x - this.originOffset.x - centerVector.x,
            y: position.y - this.originOffset.y,
            z: position.z - this.originOffset.z - centerVector.z,
          },
          rotation,
          scale,
        });

        if (import.meta.env.MODE === 'development') {
          const geometry = globalTracker.track(
            new THREE.SphereGeometry(0.2, 32, 16),
          );
          const material = globalTracker.track(
            new THREE.MeshBasicMaterial({ color: 0x00ff00 }),
          );
          const sphere = globalTracker.track(
            new THREE.Mesh(geometry, material),
          );
          sphere.position.set(
            position.x - this.originOffset.x,
            position.y - this.originOffset.y,
            position.z - this.originOffset.z,
          );
          this.scene.add(sphere);
        }

        const centerPosition: {
          x: number;
          y: number;
          z: number;
        } = {
          x: position.x,
          y: position.y + centerVector.y,
          z: position.z,
        };

        resolve({ modelID: ifcModel.modelID, centerPosition });
      });
    });
  }

  private collectSpatialStructureOfBuildingStorey(root: SpatialStructure) {
    let ss: SpatialStructure[] = [];

    if (root.type === 'IFCBUILDINGSTOREY') {
      ss.push(root);
    }

    for (let i = 0; i < root.children.length; i++) {
      ss = ss.concat(
        this.collectSpatialStructureOfBuildingStorey(root.children[i]),
      );
    }

    return ss;
  }

  private collectExpressIds(ss: SpatialStructure) {
    let expressIDs: number[] = [];
    expressIDs.push(ss.expressID);

    for (let i = 0; i < ss.children.length; i++) {
      if (ss.children[i]) {
        expressIDs = expressIDs.concat(this.collectExpressIds(ss.children[i]));
      }
    }

    return expressIDs;
  }

  private getIfcBuildingStoreySubset(modelID: number, root: SpatialStructure) {
    return this.ifcLoader.ifcManager
      .byId(modelID, root.expressID)
      .then((ifcBuildingStorey) => {
        const expressIds: number[] = this.collectExpressIds(root);

        const subset = this.ifcLoader.ifcManager.createSubset({
          modelID,
          scene: this.scene,
          ids: expressIds,
          removePrevious: true,
          customID: ifcBuildingStorey?.Name?.value as string,
        });

        if (Array.isArray(subset.material)) {
          subset.material.forEach((m) => {
            m.transparent = true;
            m.opacity = 0.2;
          });
        } else {
          subset.material.transparent = true;
          subset.material.opacity = 0.2;
        }
        const scaleValue = this.ifcModelData[modelID].scale;
        subset.scale.set(scaleValue, scaleValue, scaleValue);
        subset.updateMatrixWorld();
        const position = this.ifcModelData[modelID].position;
        subset.rotateY(
          ((this.ifcModelData[modelID].rotation.heading - 180) * Math.PI) / 180,
        );
        subset.position.set(position.x, position.y, position.z);
        subset.geometry = subset.geometry.toNonIndexed();

        return {
          ifcBuildingStoreyName: ifcBuildingStorey?.Name?.value as string,
          subset,
        };
      });
  }

  async getModelOfBuildingStoreySubsets(
    modelIndex: number,
    centerPosition: {
      x: number;
      y: number;
      z: number;
    },
  ) {
    const ifcModelData = this.ifcModelData[modelIndex];

    if (!ifcModelData) {
      throw new Error(`ifcModelData[${modelIndex}]: is not exist`);
    }

    const spatialStructure =
      await this.ifcLoader.ifcManager.getSpatialStructure(modelIndex);
    const buildingStoreysSpatialStructures =
      this.collectSpatialStructureOfBuildingStorey(spatialStructure);

    const subsetsPromises = buildingStoreysSpatialStructures.map(
      (ifcBuildingStorey) =>
        this.getIfcBuildingStoreySubset(
          ifcModelData.ifcModel.modelID,
          ifcBuildingStorey,
        ),
    );

    const subsets = await Promise.all(subsetsPromises);

    return {
      subsets,
      centerPosition,
    };
  }

  async dispose() {
    this.ifcLoader.ifcManager.utils.releaseMapping();
    return this.ifcLoader.ifcManager.dispose().then(() => {
      this.ifcModelData.length = 0;
    });
  }
}

export default IFCAgent;
