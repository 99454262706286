import {
  BasicMessage,
  BasicResponse,
  DeviceCountDataIntervalDasgas,
  PagedResponse,
} from '../types';
import { DasgasTypeParam } from '../types/Alert';
import { DasGas } from '../types/Device';

import DsmAxios from './DsmAxios';

export const getUserDasgas = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<DasGas>>('v1/dasgases', {
    params,
  });
};

export const getProjectDasgas = (data: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    id?: string;
    limit?: number;
    nextCursor?: string;
    prevCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<DasGas>>(
    `v1/projects/${data.projectId}/dasgases`,
    {
      params: data.params,
    },
  );
};

export const bindDasgasToWorker = ({
  projectId,
  dasId,
  workerId,
}: {
  projectId: string;
  dasId: string;
  workerId: string;
}) => {
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/dasgases/${dasId}/worker`,
    {
      workerId,
    },
  );
};

export const unbindDasgas = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/dasgas/${dasId}/worker`,
  );
};

export type DasgasIntervalStats = 'hour' | 'minute' | '10m' | 'day' | 'month';

interface BasicGetStatOfGasParams {
  fromDate: string;
  toDate: string;
  timeZone: string;
  endpointId: string;
  type: DasgasTypeParam;
  interval: DasgasIntervalStats;
}

export const getStatsDasgas = (
  projectId: string,
  params?: BasicGetStatOfGasParams,
) => {
  return DsmAxios.get<BasicResponse<DeviceCountDataIntervalDasgas>>(
    `v1/projects/${projectId}/dasgases/stats`,
    { params },
  );
};
