import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DastrackVSvgColorMedium = (props: SvgIconProps) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 14H30H28H25H23V16V19V21H9C8.44772 21 8 21.4477 8 22V24C8 24.5523 8.44772 25 9 25H10.1707C10.5825 23.8348 11.6938 23 13 23C14.3062 23 15.4175 23.8348 15.8293 25H22.1707C22.5825 23.8348 23.6938 23 25 23C26.3062 23 27.4175 23.8348 27.8293 25H31C31.5523 25 32 24.5523 32 24V20C32 19.4477 31.5523 19 31 19H30V16H31V14ZM25 19V16H28V19H25Z"
      fill="#A1A1A1"
    />
    <circle cx="25" cy="26" r="2" fill="#A1A1A1" />
    <circle cx="13" cy="26" r="2" fill="#A1A1A1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11H24V13H22V14V15V20H9C8.44772 20 8 19.5523 8 19V14H20V13V11ZM10 19L12.5 15H14L11.5 19H10ZM13 19L15.5 15H17L14.5 19H13ZM18.5 15L16 19H17.5L20 15H18.5Z"
      fill="#E6A600"
    />
  </SvgIcon>
);

export default DastrackVSvgColorMedium;
