import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { ConnectedDeviceInfo, ResourceType } from '../../../../types';

import { getIconBond } from '../../../../assets/SvgIcon/HandleIcon';

const Container = styled('div')`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  height: 134px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Content = styled('div')`
  border-radius: 4px;
  padding: 10px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
  padding-left: 10px;
`;

const BindToDevice = styled('div')`
  background: rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 12px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.box_bbg};
`;

interface ConnectedDeviceCardViewProps {
  data: ConnectedDeviceInfo;
  index: number;
  resource: ResourceType;
}

const ConnectedDeviceCardView: React.FC<ConnectedDeviceCardViewProps> = ({
  index,
  data,
  resource,
}) => {
  const { t } = useTranslation('project-setting');

  return (
    <Container>
      <Header>
        <Typography variant="body2">
          {t(
            `page.${resource}-management.dialog.create-${resource}.connected-device.title-card`,
            {
              count: index + 1,
            },
          )}
        </Typography>
      </Header>
      <Content>
        <BindToDevice>
          {getIconBond(resource)}
          <Typography variant="body2" sx={{ ml: '9px' }}>
            {data.dasId}
          </Typography>
        </BindToDevice>
      </Content>
    </Container>
  );
};

export default ConnectedDeviceCardView;
