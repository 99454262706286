import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';

import { VehicleWithOrg } from '../../../../types';

import vehicleDefault from '../../../../assets/images/default-image/vehicle_default.png';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;
const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.box_bbg};
`;

const ContainerImage = styled('div')`
  margin: 0 auto;
  width: 205px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 205px;
  height: 205px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetail = styled('div')`
  display: flex;
  /* flex: 1; */
`;

const Row = styled('div')`
  width: 50%;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 2px;
  height: 42px;
  width: 140px;
`;

const ItemRemark = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 300px;
`;

const ContainerRemark = styled('div')`
  height: 70px;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.secondary.$80};
`;

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

interface BasicInformationContentViewProps {
  data: VehicleWithOrg | undefined;
}

const BasicInformationContentView: React.FC<
  BasicInformationContentViewProps
> = ({ data }) => {
  const { t } = useTranslation('project-setting');

  return (
    <Container>
      <ContentTitle>{t('page.basic-information.title')}</ContentTitle>
      <ContainerContent>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? ''}
            alt="worker photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={vehicleDefault} />
          </Avatar>
        </ContainerImage>
        <ContainerDetail>
          <Row>
            <Item>
              <Title>{t('organization')}</Title>
              <div
                style={{
                  display: 'flex',
                  height: '24px',
                  alignItems: 'center',
                }}
              >
                <OrgStatus
                  sx={{ backgroundColor: data?.orgDetail?.color ?? '#000' }}
                />
                <Text title={data?.orgDetail?.name} sx={{ marginLeft: '4px' }}>
                  {data?.orgDetail?.name ?? 'N/A'}
                </Text>
              </div>
            </Item>

            <Item>
              <Title>{t('alert-interval-second')}</Title>
              <Text
                title={
                  data?.alertInterval ? data?.alertInterval.toString() : 'N/A'
                }
              >
                {data?.alertInterval ?? 'N/A'}
              </Text>
            </Item>
          </Row>
          <Row>
            <Item>
              <Title>{t('name')}</Title>
              <Text title={data?.name}>{data?.name ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('license-plate')}</Title>
              <Text title={data?.plateNumber ?? 'N/A'}>
                {data?.plateNumber ?? 'N/A'}
              </Text>
            </Item>
          </Row>
        </ContainerDetail>
        <ContainerRemark>
          <ItemRemark>
            <Title>{t('remark')}</Title>
            <Text title={data?.remark ? data?.remark : ''}>
              {data?.remark ?? 'N/A'}
            </Text>
          </ItemRemark>
        </ContainerRemark>
      </ContainerContent>
    </Container>
  );
};

export default BasicInformationContentView;
