import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const ProjectComponent = lazy(
  () => import(/* webpackChunkName: "project" */ '../../pages/project'),
);

const AccountMainPageComponent = lazy(
  () => import(/* webpackChunkName: "account" */ '../../pages/common/account'),
);

const AccountOverviewComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/AccountOverview'
    ),
);

const EditInformationComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/EditInformation'
    ),
);

const MyDevicesComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/my-devices'
    ),
);

const AddMemberComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/my-devices/AddMember'
    ),
);

const ConfimMemberComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/my-devices/ConfimMember'
    ),
);

// Transfer device is deprecated
// const AddDeviceToTransferComponent = lazy(
//   () =>
//     import(
//       /* webpackChunkName: "account" */ '../../pages/common/account/my-devices/transfer-devices/AddDeviceToTransfer'
//     ),
// );

const NotificationListComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/notification-list'
    ),
);

const LanguageSettingPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/language-setting'
    ),
);

const ChangePasswordPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */ '../../pages/common/account/ChangePassword'
    ),
);

const ProjectStatisticComponent = lazy(
  () =>
    import(/* webpackChunkName: "project" */ '../../pages/project/statistic'),
);

const CreateProjectDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project" */ '../../pages/project/CreateProjectDialog'
    ),
);

const PinLocationDragDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project" */ '../../components/PinLocationDragDialog'
    ),
);

const ProjectInformationDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project" */ '../../pages/project/ProjectInformationDialog'
    ),
);

const projectRouter = (
  <Route
    path="project"
    element={
      <Suspense fallback={<Loading />}>
        <ProjectComponent />
      </Suspense>
    }
  >
    <Route path="account" element={<AccountMainPageComponent />}>
      <Route path="" element={<AccountOverviewComponent />} />
      <Route path="edit-information" element={<EditInformationComponent />} />
      <Route path="my-devices" element={<MyDevicesComponent />}>
        <Route path="assign" element={<AddMemberComponent />}>
          <Route path="confirm" element={<ConfimMemberComponent />} />
        </Route>
        {/* <Route path="transfer" element={<AddDeviceToTransferComponent />} /> */}
      </Route>
      <Route path="notification-list" element={<NotificationListComponent />} />
      <Route
        path="language-setting"
        element={<LanguageSettingPageComponent />}
      />
      <Route path="change-password" element={<ChangePasswordPageComponent />} />
    </Route>
    <Route path="statistic" element={<ProjectStatisticComponent />} />
    <Route path="create" element={<CreateProjectDialogComponent />}>
      <Route path="pin-location" element={<PinLocationDragDialogComponent />} />
    </Route>
    <Route
      path=":projectId/info"
      element={<ProjectInformationDialogComponent mode="view" />}
    />
    <Route
      path=":projectId/edit"
      element={<ProjectInformationDialogComponent mode="edit" />}
    >
      <Route path="pin-location" element={<PinLocationDragDialogComponent />} />
    </Route>
  </Route>
);

export default projectRouter;
