import { DascasType, DeviceType } from '../types';

export const handleTextDeviceType = (
  deviceType: DeviceType | DascasType | undefined,
) => {
  switch (deviceType) {
    case 'dasloop':
      return 'Dasloop';
    case 'dasgas':
      return 'DasGas';
    case 'dastrack':
      return 'Dastrack';
    case 'dascas':
      return 'DasCAS';
    case 'dascas_g':
      return 'Main Detector';
    case 'dascas_tag':
      return 'Hook Tag';
    case 'dascas_alarm':
      return 'CabinAlarm';
    case 'dastrack-v':
      return 'Dastrack-V';
    case 'daspower':
      return 'Daspower';
    case 'dastemp':
      return 'Dastemp';
    case 'dasair':
      return 'Dasair';
    case 'daswater':
      return 'Daswater-L';
    case 'beacon':
      return 'DasBeacon';
    case 'aoa-ble':
      return 'DasAoA';
    case 'aoa-uwb':
      return 'DasAoA';
    case 'aoa-tag':
      return 'AoAtag';
    case 'daslock':
      return 'Daslock';
    case 'dasconcrete':
      return 'DasConcrete';
    case 'das_ai_box':
      return 'DasAiBox';
    case 'das_collision_tag':
      return 'DasCTag';
    default:
      return 'N/A';
  }
};
