import * as React from 'react';

const DastrackSvgColor = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50131 15.3169L10.9014 6.79736H20.6937L22.1414 15.609H22.1348C22.1493 15.7241 22.1567 15.8406 22.1567 15.9582C22.1567 18.2649 19.307 20.1348 15.7917 20.1348C12.2765 20.1348 9.42676 18.2649 9.42676 15.9582C9.42676 15.7401 9.45222 15.526 9.50131 15.3169Z"
      fill="#FF6B00"
    />
    <ellipse
      rx="4.89615"
      ry="2.7978"
      transform="matrix(1 0 0 -1 15.7926 6.7979)"
      fill="#FF8F3E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46874 16.9719H6.97923L6 22.0588H6.0066C6.00231 22.0731 6 22.0866 6 22.0993C6 25.3583 10.3842 28.0001 15.7923 28.0001C21.2005 28.0001 25.5846 25.3583 25.5846 22.0993C25.5846 22.0866 25.5824 22.073 25.5781 22.0588H25.5846L24.6054 16.9719H24.1157C24.1154 20.0621 20.3889 22.5672 15.7922 22.5672C11.1954 22.5672 7.46901 20.0621 7.46874 16.9719Z"
      fill="#727272"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.39196 14.4026C7.46833 15.2859 6.93066 16.3442 6.93066 17.4818C6.93066 20.5722 10.8983 23.0774 15.7927 23.0774C20.6871 23.0774 24.6547 20.5722 24.6547 17.4818C24.6547 16.3437 24.1166 15.2849 23.1921 14.4014L23.3282 15.2988C23.3328 15.3272 23.3371 15.3556 23.3411 15.3842L23.3453 15.4119C23.3496 15.4399 23.3529 15.4681 23.3554 15.4962C23.3726 15.6486 23.3813 15.8026 23.3813 15.9578C23.3813 17.7014 22.3071 19.0937 20.9467 19.9863C19.5707 20.8892 17.7451 21.4061 15.7923 21.4061C13.8396 21.4061 12.014 20.8892 10.638 19.9863C9.2776 19.0937 8.20331 17.7014 8.20331 15.9578C8.20331 15.8064 8.21165 15.6562 8.22803 15.5075C8.23051 15.4756 8.23415 15.4437 8.23898 15.4119L8.39196 14.4026Z"
      fill="#878787"
    />
  </svg>
);

export default DastrackSvgColor;
