import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddStructureSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 4V8H28V10H24V14H22V10H18V8H22V4H24ZM12 8H16V10H12.4142L8.41421 14H16.5858L18.5858 12H20V13.4142L18 15.4142V23.5858L22 19.5858V16H24V20V20.4142L23.7071 20.7071L17.7071 26.7071L17.4142 27H17H6H5V26V15V14.5858L5.29289 14.2929L11.2929 8.29289L11.5858 8H12ZM7 25V16H16V25H7Z"
      fill="#A1A1A1"
    />
  </SvgIcon>
);

export default AddStructureSvgIcon;
