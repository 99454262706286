import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownColor } from '@beeinventor/dasiot-react-component-lib';
import { styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Area, DropDownColorItem } from '../../../types';

import { getAreasOfProject } from '../../../apis/AreaApi';

import { getAreaColor } from '../../../utils/getAreaColor';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  margin-bottom: 8px;
`;

const Content = styled('div')`
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  color: white;
`;

interface ContainerDropdownProps {
  isSelected?: boolean;
}

// prettier-ignore
const ContainerDropdown = styled('div', {shouldForwardProp: (prop)=> prop !== "isSelected"})<ContainerDropdownProps>`
  & > .Dropdown-root {
    color: ${({ isSelected, theme }) => isSelected ? "#fff" : theme.color.secondary.$60};
    font-weight: 400;
    font-size: 14px;
    padding: 0 8px;
    /* border: 1px solid ${({ theme }) => theme.color.secondary.$60}; */
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    & > .Dropdown-icon {
      width: 32px;
      height: 32px;
    }
  }
  & > .Dropdown--disabled {
    background: rgba(0, 0, 0, 0.806);
    color: ${({ theme }) => theme.color.secondary.$40};
  }
  & div[role='tooltip'] {
    & > .MuiBox-root {
      color: ${({ theme }) => theme.color.box_bbg};
      font-weight: 200;
      font-size: 12px;
      background: ${({ theme }) => theme.color.secondary.$100};
      max-height: 120px;
      overflow: auto;
    }
    z-index: 5;
  }
`;

interface AreaContentProps {
  projectId: string | undefined;
  selectedAreaId: string | undefined;
  onChange: (areaId: string) => void;
}

const AreaContent: React.FC<AreaContentProps> = ({
  projectId,
  selectedAreaId,
  onChange,
}) => {
  const { i18n, t } = useTranslation('project-setting');

  const { data: filteredAreas } = useQuery(
    ['get-project-areas-without-boundary-path-pipe', projectId, i18n.language],
    async () => {
      const localAreas: Area[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getAreasOfProject(projectId as string, {
          nextCursor,
          selectVisible: false,
        });
        res.data.data.forEach((area) => {
          localAreas.push(area);
        });

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();
      return localAreas
        .filter(
          (area) =>
            area.type !== 'boundary' &&
            area.type !== 'path' &&
            area.type !== 'pipe',
        )
        .sort((a, b) => {
          const typeResult = a.type.localeCompare(b.type, i18n.language);
          if (typeResult === 0) {
            return a.name.localeCompare(b.name, i18n.language);
          }
          return typeResult;
        });
    },
    {
      enabled: !!projectId,
      initialData: [],
    },
  );

  const areaList = useMemo(() => {
    if (filteredAreas.length > 0) {
      const items: DropDownColorItem[] = filteredAreas.map((a) => ({
        id: a.id,
        value: a.id,
        name: a.name,
        color: getAreaColor(a.type),
      }));
      return items;
    }

    return [];
  }, [filteredAreas]);

  return (
    <Container>
      <ContainerContent>
        <ContentTitle>{t('area')}</ContentTitle>
        <Content>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>
            {t('area')}
          </Typography>
          <ContainerDropdown isSelected={!!selectedAreaId}>
            <DropdownColor
              list={areaList}
              onSelect={(v) => onChange(v as string)}
              selectedId={selectedAreaId}
              mode="dark"
              popperProps={{
                disablePortal: true,
                placement: 'bottom-start',
              }}
              placeholder={t('area')}
            />
          </ContainerDropdown>
        </Content>
      </ContainerContent>
    </Container>
  );
};

export default AreaContent;
