import { BasicMessage, PagedResponse } from '../types';
import { DaslockAlert, RequestAlertsParams } from '../types/Alert';
import { Daslock, DaslockHistory, DaslockTask } from '../types/Device';

import DsmAxios from './DsmAxios';

export const getUserDaslock = (params?: {
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<Daslock>>('v1/daslocks', {
    params,
  });
};

interface GetProjectDaslockArgs {
  projectId: string;
  params?: {
    limit?: number;
    equipmentId?: string;
    prevCursor?: string;
    nextCursor?: string;
    id?: string;
    dasId?: string;
    assignedPlant?: boolean;
    assignedEquipment?: boolean;
    managedOrg?: string;
  };
}

export const getProjectDaslocks = (data: GetProjectDaslockArgs) => {
  return DsmAxios.get<PagedResponse<Daslock>>(
    `v1/projects/${data.projectId}/daslocks`,
    {
      params: data.params,
    },
  );
};

export const unbindDaslock = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/daslocks/${dasId}/equipment`,
  );
};

export const bindDaslockToEquipment = ({
  projectId,
  dasId,
  equipmentId,
}: {
  projectId: string;
  dasId: string;
  equipmentId: string;
}) => {
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/daslocks/${dasId}/equipment`,
    {
      equipmentId,
    },
  );
};

export const getDaslockAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    equipmentId?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<DaslockAlert>>(
    `v1/projects/${projectId}/daslock-alerts`,
    { params },
  );
};

export const dismissDaslockAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daslock-alerts/${alertId}/dismiss`,
  );
};

export const undismissDaslockAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daslock-alerts/${alertId}/undismiss`,
  );
};

export const readDaslockAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daslock-alerts/${alertId}/read`,
  );
};

export interface RequestDaslockTaskArgs {
  projectId: string;
  payload?: {
    endpointId: string;
    type:
      | 'smartlockStaticPasswordUnlock'
      | 'smartlockSublockUnlock'
      | 'smartlockAuthorizedCardManagement'
      | 'smartlockRegisterCardManagement';
    enableRegistration?: boolean;
    cards?: string[];
  };
}

export const requestDaslockTask = (args: RequestDaslockTaskArgs) => {
  return DsmAxios.post(
    `/v1/projects/${args.projectId}/daslock-tasks`,
    args.payload,
  );
};

interface GetStatOfLockArgs {
  projectId: string;
  params: {
    prevCursor?: string;
    nextCursor?: string;
    fromDate: string;
    toDate: string;
    equipmentId?: string;
    timeZone?: string;
    limit?: number;
  };
}

export const getStatOfLock = (args: GetStatOfLockArgs) => {
  return DsmAxios.get<PagedResponse<DaslockHistory>>(
    `/v1/projects/${args.projectId}/stats/lock`,
    {
      params: args.params,
    },
  );
};

interface GetDaslockStatsOfTaskArgs {
  projectId: string;
  params: {
    prevCursor?: string;
    nextCursor?: string;
    fromDate: string;
    toDate: string;
    equipmentId?: string;
    timeZone?: string;
    types?: string;
    limit?: number;
  };
}

export const getDaslockStatsOfTask = (args: GetDaslockStatsOfTaskArgs) => {
  return DsmAxios.get<PagedResponse<DaslockTask>>(
    `/v1/projects/${args.projectId}/daslock-tasks`,
    {
      params: args.params,
    },
  );
};
