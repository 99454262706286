import {
  BasicMessage,
  BasicResponse,
  Group,
  PagedResponse,
  PagedResponseVehicleTracks,
} from '../types';
import { DastrackV } from '../types/Device';
import { Vehicle } from '../types/Resource';

import DsmAxios from './DsmAxios';

export const getVehicleGroups = (
  projectId: string,
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    id?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<Group>>(
    `v1/projects/${projectId}/vehicle-groups`,
    {
      params,
    },
  );
};

export const createVehicleGroup = ({
  projectId,
  name,
  remark,
}: {
  projectId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.post<BasicResponse<Group>>(
    `v1/projects/${projectId}/vehicle-groups`,
    {
      name,
      remark,
    },
  );
};

export const updateVehicleGroup = ({
  projectId,
  groupId,
  name,
  remark,
}: {
  projectId: string;
  groupId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.patch(`v1/projects/${projectId}/vehicle-groups/${groupId}`, {
    name,
    remark,
  });
};

export const getUserDastrackV = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<DastrackV>>('v1/dastrackvs', {
    params,
  });
};

export const getProjectDastrackV = (data: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    id?: string;
    limit?: number;
    nextCursor?: string;
    prevCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<DastrackV>>(
    `v1/projects/${data.projectId}/dastrackvs`,
    {
      params: data.params,
    },
  );
};

export const getProjectVehicles = (data: {
  projectId: string;
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    id?: string;
    registrationNumber?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Vehicle>>(
    `v1/projects/${data.projectId}/vehicles`,
    {
      params: data.params,
    },
  );
};

export const getGroupOfVehicle = ({
  projectId,
  groupId,
}: {
  projectId: string;
  groupId: string;
}) => {
  return DsmAxios.get<PagedResponse<Vehicle>>(
    `v1/projects/${projectId}/vehicle-groups/${groupId}/vehicles`,
  );
};

export const createVehicle = (data: {
  projectId: string;
  payload: {
    name: string;
    areaId: string;
    orgId: string;
    groupIds: string[];
    alertInterval: number;
    plateNumber?: string;
    remark?: string;
  };
}) => {
  return DsmAxios.post<BasicResponse<Vehicle>>(
    `v1/projects/${data.projectId}/vehicles`,
    data.payload,
  );
};

export const updateVehicle = (data: {
  projectId: string;
  vehicleId: string;
  payload?: {
    name: string;
    areaId: string;
    orgId: string;
    groupIds: string[];
    alertInterval: number;
    remark?: string;
    plateNumber?: string;
  };
}) => {
  return DsmAxios.patch<BasicResponse<Vehicle>>(
    `v1/projects/${data.projectId}/vehicles/${data.vehicleId}`,
    data.payload,
  );
};

export const uploadVehicleAvatar = ({
  projectId,
  vehicleId,
  imageFile,
}: {
  projectId: string;
  vehicleId: string;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post(
    `v1/projects/${projectId}/vehicles/${vehicleId}/avatar`,
    formData,
  );
};

export const bindDastrackVToVehicle = ({
  projectId,
  dasId,
  vehicleId,
}: {
  projectId: string;
  dasId: string;
  vehicleId: string;
}) => {
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/dastrackvs/${dasId}/vehicle`,
    {
      vehicleId,
    },
  );
};

export const unbindDastrackVFromVehicle = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/dastrackvs/${dasId}/vehicle`,
  );
};

export const setVehicleToGroup = ({
  projectId,
  vehicleId,
  groupIds,
}: {
  projectId: string;
  vehicleId: string;
  groupIds: Array<string>;
}) => {
  return DsmAxios.put(`v1/projects/${projectId}/vehicles/${vehicleId}/groups`, {
    groupIds,
  });
};

export const setVehiclesToGroup = ({
  groupId,
  projectId,
  vehicleIds,
}: {
  projectId: string;
  groupId: string;
  vehicleIds: string[];
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/vehicle-groups/${groupId}/vehicles`,
    { vehicleIds },
  );
};

export const getVehicleTracks = (
  projectId: string,
  vehicleId: string,
  params: {
    fromDate: string;
    toDate: string;
    limit?: number;
    order: 'desc' | 'asc';
    nextCursor?: string;
  },
) => {
  return DsmAxios.get<PagedResponseVehicleTracks>(
    `v1/projects/${projectId}/vehicles/${vehicleId}/tracks`,
    {
      params,
    },
  );
};

export const getAllVehicleTrack = (
  projectId: string,
  params: {
    fromDate: string;
    toDate: string;
    limit?: number;
    order: 'desc' | 'asc';
    nextCursor?: string;
  },
) => {
  return DsmAxios.get<PagedResponseVehicleTracks>(
    `v1/projects/${projectId}/vehicles/all/tracks`,
    {
      params,
    },
  );
};
