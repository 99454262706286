import * as THREE from 'three';

import { globalTracker } from './ResourceTracker';

class DasbeaconCreator {
  create(config: { name: string; materials: THREE.Material[] }) {
    const geometry: THREE.BoxGeometry = globalTracker.track(
      new THREE.BoxGeometry(0.7, 1, 0.3),
    );
    geometry.name = config.name;

    const mesh: THREE.InstancedMesh = new THREE.InstancedMesh(
      geometry,
      config.materials,
      1000,
    );

    mesh.name = `${config.name}Mesh`;

    return mesh;
  }

  createMesh(config: { name: string; materials: THREE.Material[] }) {
    const geometry = globalTracker.track(new THREE.BoxGeometry(0.3, 0.7, 0.7));
    geometry.rotateY(-90 * (Math.PI / 180));
    geometry.name = config.name;
    const mesh: THREE.Mesh = new THREE.Mesh(geometry, config.materials);
    mesh.name = `${config.name}Mesh`;
    return mesh;
  }

  createAOATag(config: { name: string; materials: THREE.Material[] }) {
    const geometry: THREE.BoxGeometry = globalTracker.track(
      new THREE.BoxGeometry(0.5, 0.8, 0.3),
    );
    geometry.name = config.name;
    const mesh: THREE.InstancedMesh = new THREE.InstancedMesh(
      geometry,
      config.materials,
      1000,
    );
    mesh.name = `${config.name}Mesh`;
    return mesh;
  }
}

export default DasbeaconCreator;
