import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowLeftSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 16L14.8536 20.1464C14.5386 20.4614 14 20.2383 14 19.7929L14 12.2071C14 11.7617 14.5386 11.5386 14.8536 11.8536L19 16Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ArrowLeftSvgIcon;
