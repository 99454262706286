import React, { CSSProperties, useMemo, useState } from 'react';
import { Avatar, styled } from '@mui/material';

import { EquipmentWithOrg } from '../../../../types';

import CheckboxDark from '../../../../components/Checkbox/CheckboxDark';

import DefaultEquipment from '../../../../assets/images/default-image/equipment_default.png';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 10px;
  cursor: pointer;
  width: 266px;
  transition: .3s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  & .info {
    flex: 1;
    div {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      display: flex;
      align-items: center;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-of-type(2) {
        ${({ theme }) => ({ ...theme.externalTypography.body3 })}
        color: ${({ theme }) => theme.color.secondary.$60};
        &:before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 3px;
          background: ${({ orgColor }) =>
            orgColor ? orgColor : 'transparent'};
          margin-right: 6px;
        }
      }
      & > span{
        margin-left: 5px;
        color: ${({theme})=> theme.color.secondary.$60};
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        max-width:  305px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const ContainerCheckbox = styled('div')`
  width: 40px;
  height: 40px;
  margin-left: 12px;
`;

const ContainerItem = styled('div')`
  display: flex;
  height: 64px;
  align-items: center;
`;

interface EquipmentItemPermissionProps {
  onClick?: () => void;
  onChange: (value: any, position: boolean) => void;
  equipment: EquipmentWithOrg;
  isDisabled?: boolean;
}

const EquipmentItemPermission: React.VFC<EquipmentItemPermissionProps> = ({
  onClick,
  onChange,
  equipment: {
    name,
    orgDetail,
    imageURL,
    bindingDaspowers,
    bindingDastemps,
    id,
  },
  isDisabled,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const dasIds = useMemo(() => {
    return bindingDaspowers.concat(bindingDastemps);
  }, [bindingDaspowers, bindingDastemps]);

  return (
    <ContainerItem>
      <ContainerCheckbox>
        <CheckboxDark
          checked={isSelected}
          onChange={(value, checkced) => {
            onChange(value, checkced);
            setIsSelected(!isSelected);
          }}
          value={id}
          disabled={isDisabled}
        />
      </ContainerCheckbox>
      <Container orgColor={orgDetail?.color} onClick={onClick}>
        <Avatar
          variant="square"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginRight: '10px',
          }}
          src={imageURL ?? ''}
        >
          <Avatar
            src={DefaultEquipment}
            alt="equipment beeinventor"
            variant="square"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
        </Avatar>
        <div className="info">
          <div title={name}>
            {name}{' '}
            {dasIds.length > 0 && (
              <span title={`(${dasIds.join(', ')})`}>{`(${dasIds.join(
                ', ',
              )})`}</span>
            )}
          </div>
          <div title={orgDetail?.name}>{orgDetail?.name}</div>
        </div>
      </Container>
    </ContainerItem>
  );
};

export default EquipmentItemPermission;
