import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { SSSSFeatureList } from './types';

import { useAppSelector } from './hooks';

import Loading from './components/Loading';

import HomePage from './pages/home';
import PageNotFound from './pages/PageNotFound';
import aiCameraPageRouter from './routes/ai-camera';
import { alertReactionFormRouter } from './routes/alert-reaction-form';
import areaManagementRouter from './routes/area-management/areaManagementRouter';
import alertDashboardRouter from './routes/dashboard/alertDashboardRouter';
import assetManagementDashboardRouter from './routes/dashboard/assetManagementDashboardRouter';
import attendanceDashboardRouter from './routes/dashboard/attendanceDashboardRouter';
import cctvDashboardRouter from './routes/dashboard/cctvDashboardRouter';
import concreteSensorDashboardRouter from './routes/dashboard/concreteSensorDashboardRouter';
import confinedSpaceDashboardRouter from './routes/dashboard/confinedSpaceDashboardRouter';
import dasairDashboardRouter from './routes/dashboard/dasairDashboardRouter';
import dascartDashboardRouter from './routes/dashboard/DascartDashboardRouter';
import dascasDashboardRouter from './routes/dashboard/dascasDashboardRouter';
import dasgasDashboardRouter from './routes/dashboard/dasgasDashboardRouter';
import dasloockDashboardRouter from './routes/dashboard/daslockDashboardRouter';
import dasloopDashboardRouter from './routes/dashboard/dasloopDashboardRouter';
import daspowerDashboardRouter from './routes/dashboard/daspowerDashboardRouter';
import dastempDashboardRouter from './routes/dashboard/dastempDashboardRouter';
import dastrackDashboardRouter from './routes/dashboard/dastrackDashboardRouter';
import dastrackVDashboardRouter from './routes/dashboard/dastrackVDashboardRouter';
import daswaterDashboardRouter from './routes/dashboard/daswaterDashboardRouter';
import mainDahboardRouter from './routes/dashboard/mainDahboardRouter';
import permitToWorkDashboardRouter from './routes/dashboard/ssss/permitToWorkDashboardRouter';
import safetyMonitoringDashboardRouter from './routes/dashboard/ssss/safetyMonitoringDashboardRouter';
import safetyTrainingVrDashboardRouter from './routes/dashboard/ssss/safetyTrainingVrDashboardRouter';
import tunnelDashboardRouter from './routes/dashboard/tunnelDashboardRouter';
import { qrCodeRedirectRouter } from './routes/device-information';
import downloadDashboardRouter from './routes/download';
import { labRouter } from './routes/lab/labRouter';
import logRouter from './routes/log/logRouter';
import loginRouter from './routes/login/loginRouter';
import oauthRouter from './routes/oauth';
import projectRouter from './routes/project/projectRouter';
import projectOverviewRouter from './routes/project-overview/projectOverviewRouter';
import projectSettingRouter from './routes/project-setting/projectSettingRouter';
import publicAnnouncementRouter from './routes/public-announcement/publicAnnouncementRouter';
import registerRouter from './routes/register/registerRouter';
import reportRouter from './routes/report/reportRouter';
import { qrCodeResourceRedirectRouter } from './routes/resource-information';
import safieCameraRouter from './routes/safie-camera';
import App from './App';

const AppRouter = () => {
  const projectRole = useAppSelector((store) => store.projects.role);

  const featureListMap = useAppSelector((store) => store.system.featureListMap);
  const ProjectPolicies = useAppSelector((store) => store.projects.policies);
  const { org } = useAppSelector((store) => store.user);

  const ssssFeatureList: SSSSFeatureList | undefined =
    featureListMap?.four_s?.metadata;

  let disablePa = true;
  if (featureListMap.dasloop) {
    disablePa = !!featureListMap.dasloop.metadata.disablePa;
  } else if (typeof ssssFeatureList?.disablePa === 'boolean') {
    disablePa = ssssFeatureList.disablePa;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
          }
        >
          {projectRouter}
          {oauthRouter}
          <Route path="*" element={<HomePage />}>
            {mainDahboardRouter}
            {(featureListMap.dasloop ||
              ssssFeatureList?.SmartMonitoringDevices) &&
              dasloopDashboardRouter}
            {(featureListMap.dastrack ||
              ssssFeatureList?.[
                'UnsafeActs/DangerousSituationAlertForMobilePlantOperationDangerZone'
              ]) &&
              dastrackDashboardRouter}
            {featureListMap.daswater && daswaterDashboardRouter}
            {featureListMap.dastrack_v && dastrackVDashboardRouter}
            {featureListMap.daspower && daspowerDashboardRouter}
            {featureListMap.dasgas && dasgasDashboardRouter}
            {(featureListMap.daslock ||
              ssssFeatureList?.HazardousAreasAccessControl) &&
              dasloockDashboardRouter}
            {!disablePa &&
              (projectRole === 'owner' || projectRole === 'admin') &&
              publicAnnouncementRouter}
            {featureListMap.dastemp && dastempDashboardRouter}
            {featureListMap.dasair && dasairDashboardRouter}
            {featureListMap.cctv && cctvDashboardRouter}
            {dascartDashboardRouter}
            {(featureListMap.dascas ||
              ssssFeatureList?.[
                'UnsafeActs/DangerousSituationAlertForTowerCraneLiftingZone'
              ]) &&
              dascasDashboardRouter}
            {alertDashboardRouter}
            {(projectRole === 'owner' ||
              projectRole === 'admin' ||
              ProjectPolicies.reduce<boolean>(
                (prev, policy) => prev || policy.name === 'ReadProjectOverview',
                false,
              )) &&
              projectOverviewRouter}

            {(projectRole === 'owner' ||
              projectRole === 'admin' ||
              ProjectPolicies.reduce<boolean>(
                (prev, policy) => prev || policy.name === 'WriteArea',
                false,
              )) &&
              areaManagementRouter}
            {(projectRole === 'owner' ||
              projectRole === 'admin' ||
              ProjectPolicies.reduce<boolean>(
                (prev, policy) =>
                  prev ||
                  policy.name === 'WriteArea' ||
                  policy.name === 'ReadArea',
                false,
              )) &&
              reportRouter}
            {featureListMap.tunnel && tunnelDashboardRouter}
            {featureListMap.dasconcrete && concreteSensorDashboardRouter}
            {featureListMap.report && downloadDashboardRouter}
            {featureListMap.attendance && attendanceDashboardRouter}
            {logRouter}
            {projectSettingRouter}
            {featureListMap.das_ai_box && aiCameraPageRouter}
            {featureListMap.safie && safieCameraRouter}
            {ssssFeatureList?.DigitisedTrackingSystem &&
              assetManagementDashboardRouter}
            {(featureListMap.confined ||
              ssssFeatureList?.ConfinedSpaceMonitoringSystem) &&
              confinedSpaceDashboardRouter}
            {ssssFeatureList?.DigitalisedPermitToWorkSystem &&
              permitToWorkDashboardRouter}
            {ssssFeatureList?.SafetyMonitoringSystem &&
              safetyMonitoringDashboardRouter}
            {ssssFeatureList?.SafetyTrainingWithVirtualRealityTechnology &&
              safetyTrainingVrDashboardRouter}
            <Route path="*" element={<PageNotFound />} />
          </Route>

          {org.name === 'BeeInventor' && labRouter}
          {qrCodeRedirectRouter}
          {qrCodeResourceRedirectRouter}
        </Route>
        {loginRouter}
        {registerRouter}
        {alertReactionFormRouter}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
