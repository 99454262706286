import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CopyClipboardSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="7"
        y="13"
        width="12"
        height="12"
        rx="1"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9L23 9V17H21V19H24C24.5523 19 25 18.5523 25 18L25 8C25 7.44772 24.5523 7 24 7H14C13.4477 7 13 7.44772 13 8V11H15L15 9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CopyClipboardSvgIcon;
