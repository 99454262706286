import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PlantSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8L14.6531 9.82653L15.25 9H21V13.5H25V19H12V13.5L13.3265 11.6633L11 10.5L6 15.5V13L11 8ZM19 10.5H16.5L14.5 13.5V16.5H19V10.5ZM13 20C11.8954 20 11 20.8954 11 22C11 23.1046 11.8954 24 13 24H24C25.1046 24 26 23.1046 26 22C26 20.8954 25.1046 20 24 20H13ZM8 15L6 17V18C6 19.1046 6.89543 20 8 20H9C10.1046 20 11 19.1046 11 18V17H8V15Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PlantSvgIcon;
