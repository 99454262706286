import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const Content = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 2.125rem;
`;

const ManagementNoPermissionContent = () => {
  const { t } = useTranslation();
  return <Content>{t('no-permission')}</Content>;
};

export default ManagementNoPermissionContent;
