import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DashboardPageComponent = lazy(
  () => import(/* webpackChunkName: "dashboard" */ '../../pages/dashboard'),
);

const ProjectInformationDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project" */ '../../pages/project/ProjectInformationDialog'
    ),
);

const mainDahboardRouter = (
  <Route path="dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DashboardPageComponent />
        </Suspense>
      }
    >
      <Route
        path="info"
        element={<ProjectInformationDialogComponent mode="view" />}
      />
    </Route>
  </Route>
);

export default mainDahboardRouter;
