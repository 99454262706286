import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';
import maplibregl from 'maplibre-gl';

import { SettingMap } from '../../../../types';
import { Ai } from '../../../../types/Resource';

import { useAppSelector } from '../../../../hooks';
import { useLocalStorageObject } from '../../../../hooks/useLocalStorageObject';

import aiDefaultPng from '../../../../assets/images/default-image/ai_default.png';
import { getMapStyle } from '../../../../utils/MapboxUtils';

import 'maplibre-gl/dist/maplibre-gl.css';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;
const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.box_bbg};
`;

const ContainerImage = styled('div')`
  margin: 10px auto;
  width: 205px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 205px;
  height: 205px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetail = styled('div')`
  display: flex;
  flex: 1;
`;

const Row = styled('div')`
  width: 50%;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 140px;
`;

const ItemRemark = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 300px;
`;

const ContainerRemark = styled('div')`
  height: 70px;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.secondary.$80};
`;

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

const ContainerMap = styled('div')`
  height: 140px;
  margin-top: 10px;
  border-radius: 4px;
`;

const StreamList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 50%;
  }
`;

interface BasicInformationContentProps {
  data: Ai | undefined;
}

const BasicInformationContent: React.FC<BasicInformationContentProps> = ({
  data,
}) => {
  const { projectId } = useParams();
  const { t, i18n } = useTranslation('project-setting');
  const mapLibreRef = useRef<maplibregl.Map | null>(null);
  const markerRef = useRef<maplibregl.Marker | null>(null);
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const org = useMemo(() => {
    return orgMap[data?.orgId ?? ''];
  }, [data, orgMap]);
  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const [mapLocal] = useLocalStorageObject<SettingMap>(
    'mapStyleSetting_',
    projectId,
  );

  useEffect(() => {
    const initial = async () => {
      const map = new maplibregl.Map({
        container: 'project-setting-crane-view',
        interactive: false,
        zoom: 15,
        style: getMapStyle(mapLocal?.mapStyle),
        center: [data?.coordinates?.lon ?? 0, data?.coordinates?.lat ?? 0],
        attributionControl: false,
      });

      mapLibreRef.current = map;

      map.on('load', async () => {
        const language = new MapboxLanguage({
          defaultLanguage: i18n.language,
        });

        map.addControl(language as unknown as maplibregl.IControl);
      });

      markerRef.current = new maplibregl.Marker()
        .setLngLat([data?.coordinates?.lon ?? 0, data?.coordinates?.lat ?? 0])
        .addTo(map);
    };

    if (mapRef) {
      if (!mapLibreRef.current) {
        initial();
      }
    }

    return () => {
      mapLibreRef.current?.remove();
      mapLibreRef.current = null;
    };
  }, [mapRef, data, i18n]);

  const streamListItems = data?.streams.map((stream) => {
    return (
      <Item key={`stream-${stream.aiBoxId}-${stream.remark}`}>
        <Title>
          {stream.remark} {t('name')}
        </Title>
        <Text title={stream.name ?? ''}>{stream.name ?? '-'}</Text>
      </Item>
    );
  });

  return (
    <Container>
      <ContentTitle>
        {t('page.ai-management.dialog.create-ai.basic-information.title')}
      </ContentTitle>
      <ContainerContent>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? aiDefaultPng}
            alt="ai photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          />
        </ContainerImage>
        <ContainerDetail>
          <Row>
            <Item>
              <Title>{t('organization')}</Title>
              <div
                style={{
                  display: 'flex',
                  height: '24px',
                  alignItems: 'center',
                }}
              >
                <OrgStatus sx={{ backgroundColor: org?.color ?? '#58B99E' }} />
                <Text title={org?.name} sx={{ marginLeft: '4px' }}>
                  {org?.name ?? 'N/A'}
                </Text>
              </div>
            </Item>
          </Row>
          <Row>
            <Item>
              <Title>{t('name')}</Title>
              <Text title={data?.name}>{data?.name ?? 'N/A'}</Text>
            </Item>
          </Row>
        </ContainerDetail>
        <StreamList>{streamListItems}</StreamList>
        <div>
          <Title>{t('location')}</Title>
          <Text
            title={
              data?.coordinates
                ? `${data.coordinates.lon}, ${data.coordinates.lat}, ${
                    data.coordinates.alt ? data.coordinates.alt : 'N/A'
                  }`
                : 'N/A'
            }
          >
            {data?.coordinates
              ? `${data.coordinates.lon}, ${data.coordinates.lat}, ${
                  data.coordinates.alt ? data.coordinates.alt : 'N/A'
                }`
              : 'N/A'}
          </Text>
          <ContainerMap
            ref={(ref) => setMapRef(ref)}
            id="project-setting-crane-view"
          />
        </div>
        <ContainerRemark>
          <ItemRemark>
            <Title>{t('remark')}</Title>
            <Text title={data?.remark ? data?.remark : ''}>
              {data?.remark ?? 'N/A'}
            </Text>
          </ItemRemark>
        </ContainerRemark>
      </ContainerContent>
    </Container>
  );
};

export default BasicInformationContent;
