import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { getcolorBattery, normalize } from '../../utils/normalize';

interface BatteryLevelSvgIconProps extends SvgIconProps {
  batteryValue: number;
  maxValue: number;
  props?: SvgIconProps;
}

const BatteryLevelSvgIcon = ({
  batteryValue,
  maxValue,
  ...props
}: BatteryLevelSvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="2.5"
        y="6.5"
        width="13"
        height="7"
        rx="1"
        stroke={batteryValue ? getcolorBattery(batteryValue) : 'white'}
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15 8H17C17.5523 8 18 8.44772 18 9V11C18 11.5523 17.5523 12 17 12H15V8Z"
        fill={batteryValue ? getcolorBattery(batteryValue) : 'white'}
      />
      <rect
        x="4"
        y="8"
        width={batteryValue ? normalize(batteryValue, maxValue, 10) : '0'}
        height="4"
        fill={batteryValue ? getcolorBattery(batteryValue) : 'white'}
      />
    </SvgIcon>
  );
};

export default BatteryLevelSvgIcon;
