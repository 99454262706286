import { StructureAssign } from '../../../types';
import { Structure } from '../../../types/Resource';

export const checkStructureBasicInformationAvalible = (
  basicInfomation?: StructureAssign,
) => {
  if (!basicInfomation?.orgId) return false;
  if (!basicInfomation?.name) return false;
  if (!basicInfomation?.fieldType) return false;
  if (!basicInfomation?.materialType) return false;
  if (!basicInfomation?.strengthTarget) return false;
  if (!basicInfomation?.fc28) return false;
  if (!basicInfomation.location) return false;
  return true;
};

export const checkIsFormStructureEdited = (
  structureSelected: Structure,
  structureWillAssign: StructureAssign,
) => {
  const checkFieldValue = (field: keyof Structure) => {
    const selectedValue = structureSelected[field];
    const willAssignValue = structureWillAssign[field];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue !== willAssignValue) return true;
    }
    return false;
  };

  const checkFieldCoordinates = (field: keyof Structure) => {
    const selectedValue = structureSelected[field] as Structure['location'];
    const willAssignValue = structureWillAssign[field] as Structure['location'];

    if (selectedValue == null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue.alt === null) {
        if (willAssignValue?.alt) return true;
      } else {
        if (selectedValue.alt !== willAssignValue?.alt) return true;
      }

      if (selectedValue.lat !== willAssignValue?.lat) return true;
      if (selectedValue.lon !== willAssignValue?.lon) return true;
    }
  };

  const areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const chcek3Array = (field: keyof Structure) => {
    const selectedValue = structureSelected[field] as Structure['size'];
    const willAssignValue = structureWillAssign[field] as Structure['size'];

    if (selectedValue === undefined || willAssignValue === undefined) {
      return false;
    }

    return !areArraysEqual(selectedValue, willAssignValue);
  };

  if (checkFieldValue('orgId')) return true;
  if (checkFieldValue('name')) return true;
  if (checkFieldValue('fieldType')) return true;
  if (checkFieldValue('materialType')) return true;
  if (checkFieldValue('strengthTarget')) return true;
  if (checkFieldValue('fc28')) return true;
  if (checkFieldValue('remark')) return true;
  if (checkFieldValue('settingAngle')) return true;
  if (checkFieldValue('alertAngle')) return true;
  if (chcek3Array('size')) return true;

  if (checkFieldCoordinates('location')) return true;

  return false;
};
