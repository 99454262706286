import { BasicResponse, Org } from '../types';

import DsmAxios from './DsmAxios';

export const getOrgs = (params?: {
  taxId?: string;
  country?: string;
  id?: string;
}) => {
  return DsmAxios.get<BasicResponse<Org[]>>(`/v1/orgs`, { params });
};
