export const normalize = (val: number, max: number, scaleMax: number) => {
  return (val / max) * scaleMax;
};

export const getcolorBattery = (val: number) => {
  if (val <= 30) {
    return '#FF6B00';
  } else if (val > 30 && val <= 80) {
    return '#FFB905';
  } else {
    return '#ffff';
  }
};
