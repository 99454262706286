import {
  BasicMessage,
  BasicResponse,
  FloorPlan,
  FloorPlanRectangle,
} from '../types';

import DsmAxios from './DsmAxios';

export const getFloorPlans = (projectId: string) => {
  return DsmAxios.get<BasicResponse<FloorPlan[]>>(
    `v1/projects/${projectId}/floorplans`,
  );
};

export const createFloorPlan = (
  projectId: string,
  params: {
    name: string;
    altitude: number;
    rectangle: FloorPlanRectangle;
    transparency: number;
  },
) => {
  return DsmAxios.post<BasicResponse<FloorPlan>>(
    `v1/projects/${projectId}/floorplans`,
    params,
  );
};

export const uploadFloorPlanImage = (
  projectId: string,
  { floorplanId, imageFile }: { floorplanId: string; imageFile: File },
) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/floorplans/${floorplanId}/image`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const deleteFloorPlan = (projectId: string, floorplanId: string) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/floorplans/${floorplanId}`,
  );
};

export const updateFloorPlan = (
  projectId: string,
  floorPlanId: string,
  params: {
    name: string;
    altitude: number;
    rectangle: FloorPlanRectangle;
    transparency: number;
  },
) => {
  return DsmAxios.patch(
    `v1/projects/${projectId}/floorplans/${floorPlanId}`,
    params,
  );
};
