import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Contact,
  DasIdWillAssign,
  Group,
  StatusType,
  StructureAssign,
} from '../../../types';
import { DasConcrete } from '../../../types/Device';
import { Structure } from '../../../types/Resource';

import {
  bindProjectContact,
  unBindProjectContact,
} from '../../../apis/ProjectApi';
import {
  bindDeviceToResource,
  unbindDeviceFromResource,
  updateResource,
} from '../../../apis/ResourceApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useHasPolicy from '../../../hooks/useHasPolicy';
import { setPinLocation } from '../../../slices/pages/projectPageSlice';

import CompleteButtonSvgIcon from '../../../assets/SvgIcon/CompleteButtonSvgIcon';
import LoadingButtonSvgIcon from '../../../assets/SvgIcon/LoadingButtonSvgIcon';
import SaveSvgIcon from '../../../assets/SvgIcon/SaveSvgIcon';
import mainTheme from '../../../theme';
import { checkIsArrayEqual } from '../../../utils/checkArrayEqual';
import { navigatorStructure as navigator } from '../../../utils/common';
import CheckListItem from '../../CheckListItem';
import ConfirmButton from '../../ConfirmButton';
import ManagementDialog from '../../Dialog/ManagementDialog';
import ManagementNoPermissionContent from '../../ManagementNoPermissionContent';
import ConnectedDeviceContent from '../edit-content/ConnectedDeviceContent';
import ContactEdit from '../edit-content/ContactEdit';
import BasicInformationContent from '../edit-content/StructureBasicInformationContent';
import {
  checkIsFormStructureEdited,
  checkStructureBasicInformationAvalible,
} from '../validation/concrete-validation';
import { checkContactAvailable } from '../validation/environment-validation';
import {
  checkIsDasIdEqual,
  hasAssignedDevice,
} from '../validation/management-validation';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

interface EditStructureProps extends DialogProps {
  title: string;
  navigatorIndex: number;
  groups: Array<Group> | undefined;
  selectedResource: Structure | undefined;
  selectedGroups: Array<Group>;
  resourceContacts: Array<Contact>;
  connectedDevice: Array<DasConcrete>;
  onSuccessCreateCrane: () => void;
  onSuccessBind?: () => void;
  onCloseDialog: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const EditStructure: React.FC<EditStructureProps> = ({
  title,
  navigatorIndex,
  selectedResource,
  resourceContacts,
  connectedDevice,
  onSuccessCreateCrane,
  onSuccessBind,
  onCloseDialog,
  onSelectNavigatorIndex,
}) => {
  const { projectId } = useParams();
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );
  const queryClient = useQueryClient();

  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const { t } = useTranslation('project-setting');

  const [saveButtonStatus, setSaveButtonStatus] = useState<
    'loading' | 'success'
  >();

  const [structureAssign, setStructureAssign] = useState<StructureAssign>();
  const [dasconcreteWillAssign, setDasconcreteWillAssign] = useState<
    Array<DasIdWillAssign>
  >([]);

  const [initDasconcrete, setInitDasconcrete] = useState<
    Array<DasIdWillAssign>
  >([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const hasBindDevicePolicy = useHasPolicy(policies, 'WriteDeviceBinding');
  const [listContact, setListContact] = useState<string[]>(
    resourceContacts.map((contact) => contact.id),
  );
  const initContact = resourceContacts.map((contact) => contact.id);

  const { mutateAsync: mutateUnbindContact } =
    useMutation(unBindProjectContact);

  const { mutateAsync: mutateBindContact } = useMutation(bindProjectContact);
  const { mutateAsync: mutateEditStructure } = useMutation(updateResource);

  const { mutateAsync: mutateBindDasconcrete } =
    useMutation(bindDeviceToResource);
  const { mutateAsync: mutateUnbindDasconcrete } = useMutation(
    unbindDeviceFromResource,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setStructureAssign({
      id: selectedResource?.id ?? '',
      name: selectedResource?.name ?? '',
      fieldType: selectedResource?.fieldType,
      materialType: selectedResource?.materialType,
      size: selectedResource?.size ?? undefined,
      deviceCad: selectedResource?.deviceCad ?? undefined,
      deviceCadPosition: selectedResource?.deviceCadPosition ?? undefined,
      strengthTarget: selectedResource?.strengthTarget,
      location: selectedResource?.location ?? undefined,
      startTime: selectedResource?.startTime,
      endTime: selectedResource?.endTime,
      fc28: selectedResource?.fc28,
      settingAngle: selectedResource?.settingAngle,
      alertAngle: selectedResource?.alertAngle,
      orgId: selectedResource?.orgId,
      remark: selectedResource?.remark,
      bindingDevicesLocation: selectedResource?.bindingDevicesLocation,
    });
    if (selectedResource?.location?.lon && selectedResource?.location?.lat) {
      dispatch(
        setPinLocation([
          selectedResource.location.lon,
          selectedResource.location.lat,
        ]),
      );
    }
  }, [selectedResource]);

  useEffect(() => {
    if (connectedDevice.length > 0) {
      const init: Array<DasIdWillAssign> = connectedDevice.map(
        (dasconcrete) => ({
          id: dasconcrete.id,
          name: dasconcrete.dasId,
          batteryLevel: 0,
          dasId: dasconcrete.dasId,
          location: selectedResource?.bindingDevicesLocation.find(
            (d) => d.dasId === dasconcrete.dasId,
          )?.location,
        }),
      );
      setDasconcreteWillAssign(init);
      setInitDasconcrete(init);
    } else {
      setDasconcreteWillAssign([]);
      setInitDasconcrete([]);
    }
  }, []);

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [navigatorIndex]);

  const couldBindDevice =
    userProjectRole === 'owner' ||
    userProjectRole === 'admin' ||
    hasBindDevicePolicy;

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setInitDasconcrete([]);
    setDasconcreteWillAssign([]);

    dispatch(setPinLocation([0, 0]));
    // setGroupWillAssign([]);
    onSelectNavigatorIndex(0);
    setStatus('default');
    setMessage('');
    setStructureAssign(undefined);
  };

  const unbindContacts = async (contactIds: string[]) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateUnbindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: structureAssign?.id ?? '',
      });
      return data;
    });
    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const bindContacts = async (contactIds: string[]) => {
    const requestBindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: structureAssign?.id ?? '',
        type: 'field',
      });
      return data;
    });
    await Promise.all(requestBindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries(['project-contacts', projectId as string]);
  };

  const handleBindDasconcrete = async (
    dasIds: DasIdWillAssign[],
    id: string,
  ) => {
    const requestBindDasconcrete = dasIds.map(async (dasId) => {
      const { data } = await mutateBindDasconcrete({
        projectId: projectId as string,
        dasId: dasId.name,
        resourceType: 'field',
        data: {
          resourceId: id,
          location: dasId.location,
        },
      });

      return data;
    });

    const result = await Promise.all(requestBindDasconcrete).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });

    return result;
  };

  const handleUnbindDasconcrete = async (
    dasIds: DasIdWillAssign[],
    id: string,
  ) => {
    const requestUnbindDasconcrete = dasIds.map(async (d) => {
      const { data } = await mutateUnbindDasconcrete({
        dasId: d.name,
        projectId: projectId as string,
        resourceType: 'field',
        data: {
          resourceId: id,
        },
      });
      return data;
    });

    await Promise.all(requestUnbindDasconcrete).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleSubmit = async () => {
    if (!structureAssign) return;
    try {
      setSaveButtonStatus('loading');
      setStatus('default');

      await mutateEditStructure({
        projectId: projectId ?? '',
        resourceId: structureAssign.id ?? '',
        resourceType: 'field',
        payload: {
          name: structureAssign.name ?? '',
          orgId: structureAssign?.orgId ?? '',
          alertAngle: structureAssign.alertAngle,
          bindingDevices: structureAssign.bindingDevices,
          deviceCad: structureAssign.deviceCad,
          deviceCadPosition: structureAssign.deviceCadPosition,
          fc28: structureAssign.fc28 ?? 0,
          fieldType: structureAssign.fieldType ?? undefined,
          groupIds: structureAssign.groupIds ?? [],
          strengthTarget: structureAssign.strengthTarget,
          materialType: structureAssign.materialType,
          location: structureAssign.location
            ? {
                ...structureAssign.location,
                alt: structureAssign.location.alt
                  ? Number(structureAssign.location.alt)
                  : null,
              }
            : undefined,
          settingAngle: structureAssign.settingAngle,
          size: structureAssign.size,
          remark: structureAssign.remark,
        },
      });

      // UNBIND CONTACT REQUEST
      const listContactUnbind = initContact.filter(
        (id) => !listContact.includes(id),
      );
      if (listContactUnbind.length > 0) {
        unbindContacts(listContactUnbind);
      }

      // BIND CONTACT REQUEST
      const listContactWillBind = listContact.filter(
        (id) => !initContact.includes(id),
      );
      if (listContactWillBind.length > 0) {
        bindContacts(listContactWillBind);
      }

      if (couldBindDevice && dasconcreteWillAssign.length > 0) {
        await handleBindDasconcrete(
          dasconcreteWillAssign,
          structureAssign.id ?? '',
        );
      }

      const dasIdWillUnbind = initDasconcrete.filter(
        (item) => !dasconcreteWillAssign.some((dw) => dw.name === item.name),
      );

      if (couldBindDevice && dasIdWillUnbind.length > 0) {
        await handleUnbindDasconcrete(
          dasIdWillUnbind,
          structureAssign?.id ?? '',
        );
      }

      let timer2: NodeJS.Timeout;
      const timer = setTimeout(() => {
        setSaveButtonStatus('success');
        onSuccessBind?.();
        onSuccessCreateCrane();

        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
          handleCloseEditDialog();
        }, 500);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setSaveButtonStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  let saveButtonStatusIcon;
  switch (saveButtonStatus) {
    case 'loading':
      saveButtonStatusIcon = <LoadingButtonSvgIcon />;
      break;
    case 'success':
      saveButtonStatusIcon = <CompleteButtonSvgIcon />;
      break;
    default:
      saveButtonStatusIcon = <SaveSvgIcon />;
  }

  const saveable = () => {
    if (selectedResource && structureAssign) {
      if (
        !checkIsDasIdEqual(initDasconcrete, dasconcreteWillAssign) ||
        !checkIsArrayEqual(initContact, listContact) ||
        checkIsFormStructureEdited(selectedResource, structureAssign)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  return (
    <ManagementDialog
      open
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator.map((item, index) => {
            let checked: boolean = false;

            switch (item.name) {
              case 'structure-basic-information':
                checked =
                  checkStructureBasicInformationAvalible(structureAssign);
                break;
              case 'emergency-contact':
                checked = checkContactAvailable(listContact);
                break;
              case 'connected-device':
                checked = hasAssignedDevice(dasconcreteWillAssign);
                break;
            }

            return (
              <CheckListItem
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={checked}
                onClick={() => onSelectNavigatorIndex(index)}
                selected={checkedNavigator[index]}
                key={index}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator[navigatorIndex].name === 'structure-basic-information' && (
            <BasicInformationContent
              handleStructureAssign={(structure) =>
                setStructureAssign(structure)
              }
              data={structureAssign}
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactEdit onChange={handleOnChange} listContact={listContact} />
          )}
          {navigator[navigatorIndex].name === 'connected-device' && (
            <>
              {couldBindDevice ? (
                <ConnectedDeviceContent
                  data={dasconcreteWillAssign}
                  handleDasIDWillAssign={(dasIds) =>
                    setDasconcreteWillAssign(dasIds)
                  }
                  projectId={projectId}
                  resourceType="structure"
                  location={structureAssign?.location}
                />
              ) : (
                <ManagementNoPermissionContent />
              )}
            </>
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: mainTheme.color.secondary.$60,
            },
            marginRight: 'auto',
          }}
          color="secondary"
          onClick={handleCloseEditDialog}
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButton
          sx={{
            '& .MuiButton-startIcon > svg': {
              width: '32px',
              height: '32px',
            },
          }}
          startIcon={saveButtonStatusIcon}
          status={saveButtonStatus}
          onClick={handleSubmit}
          disabled={!saveable()}
        >
          {t('save')}
        </ConfirmButton>
      </DialogActions>
    </ManagementDialog>
  );
};

export default EditStructure;
