import { DascasType, PagedResponse } from '../types';
import { DascasAlert, RequestAlertsParams } from '../types/Alert';
import { Dascas, DascasG } from '../types/Device';
import { CreatePlantBasicInformation, Plant } from '../types/Resource';

import DsmAxios from './DsmAxios';

export const getUserDascas = <T extends Dascas | DascasG>(params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  type: DascasType;
  towerId?: string;
}) => {
  return DsmAxios.get<PagedResponse<T>>('v1/dascases', {
    params,
  });
};

export const getProjectDascas = <T extends Dascas | DascasG>(data: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    id?: string;
    limit?: number;
    nextCursor?: string;
    prevCursor?: string;
    type: DascasType;
  };
}) => {
  return DsmAxios.get<PagedResponse<T>>(
    `v1/projects/${data.projectId}/dascases`,
    {
      params: data.params,
    },
  );
};

type UnbindDastrackParam = {
  projectId: string;
  dasId: string;
};

export const unbindDascas = (obj: UnbindDastrackParam) => {
  return DsmAxios.delete(
    `v1/projects/${obj.projectId}/dascases/${obj.dasId}/plant`,
  );
};

interface BindDascasToPlantParam {
  projectId: string;
  dasId: string;
  data: {
    plantId: string;
  };
}

export const bindDascasToPlant = ({
  projectId,
  dasId,
  data,
}: BindDascasToPlantParam) => {
  return DsmAxios.put<any>(
    `v1/projects/${projectId}/dascases/${dasId}/plant`,
    data,
  );
};

export const getDascasAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    plantId?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<DascasAlert>>(
    `v1/projects/${projectId}/dascas-alerts`,
    { params },
  );
};

export const createCraneApi = (data: {
  projectId: string;
  payload: CreatePlantBasicInformation;
}) => {
  return DsmAxios.post<{ data: Plant }>(
    `v1/projects/${data.projectId}/plants`,
    data.payload,
  );
};

interface GetProjectDascasesData {
  projectId: string;
  params?: {
    dasId?: string;
    prevCursor?: string;
    nextCursor?: string;
    assignedPlant?: boolean;
  };
}

export const getProjectDascases = (data: GetProjectDascasesData) => {
  return DsmAxios.get<PagedResponse<Dascas>>(
    `/v1/projects/${data.projectId}/dascases`,
    {
      params: { ...data.params, type: 'dascas' },
    },
  );
};

export const getProjectDascasGs = (data: GetProjectDascasesData) => {
  return DsmAxios.get<PagedResponse<DascasG>>(
    `/v1/projects/${data.projectId}/dascases`,
    {
      params: { ...data.params, type: 'dascas_g' },
    },
  );
};
