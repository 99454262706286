import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasAIServerSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="dark/btn24/type">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 2H11.7069V10.2069H3.5V2ZM10.535 4.34482C10.535 4.66857 10.2725 4.93102 9.94878 4.93102C9.62503 4.93102 9.36257 4.66857 9.36257 4.34482C9.36257 4.02106 9.62503 3.75861 9.94878 3.75861C10.2725 3.75861 10.535 4.02106 10.535 4.34482ZM9.36234 5.51723H10.5348V8.44827H9.36234V5.51723ZM8.7764 8.44827L7.01778 3.75861H5.84537L4.08675 8.44827H5.25916L5.47899 7.86206H7.38416L7.60399 8.44827H8.7764ZM6.43157 5.32183L6.9445 6.68965H5.91864L6.43157 5.32183ZM7.60345 13.1379V11.3793H11.023L13.4655 12.8448V16.6551L7.60345 13.1379ZM14.0496 11.9655L12.8792 11.2635V5.6339L14.0496 4.931L19.9138 8.45049L14.0496 11.9655ZM7.60345 14.3103L13.4655 17.8276V21.6379L7.60345 18.1207V14.3103ZM20.5 9.32755L14.6379 12.8448V16.6551L20.5 13.1379V9.32755ZM19.9138 10.6926L18.1552 11.7645V13.6236L19.9138 12.5517V10.6926ZM14.6379 17.8276L20.5 14.3103V18.1207L14.6379 21.6379V17.8276ZM18.1552 16.7473L19.9138 15.6753V17.5344L18.1552 18.6064V16.7473Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};

export default DasAIServerSvgIcon;
