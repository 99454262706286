import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RenameSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7H11L11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18L13 7H18C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5H12H6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default RenameSvgIcon;
