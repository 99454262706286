import { useCallback, useMemo } from 'react';

export type ThreeConfig = {
  displayLabel: {
    worker: boolean;
    daswatch: boolean;
    plant: boolean;
    locator: boolean;
    area: boolean;
    aoaTag: boolean;
    daswater: boolean;
    cctv: boolean;
  };
};

const useThreeConfig = () => {
  const config = useMemo<ThreeConfig | null>(() => {
    const text = localStorage.getItem('threeConfig');
    if (text) {
      return JSON.parse(text);
    }
    return null;
  }, []);

  const setDisplayLabelToLocalStorage = useCallback(
    (type: keyof ThreeConfig['displayLabel'], display: boolean) => {
      const text = localStorage.getItem('threeConfig');
      let localConfig: ThreeConfig = {
        displayLabel: {
          worker: true,
          daswatch: true,
          plant: false,
          locator: false,
          area: false,
          aoaTag: true,
          daswater: false,
          cctv: false,
        },
      };
      if (text) {
        localConfig = JSON.parse(text);
        localConfig.displayLabel[type] = display;
      }

      localStorage.setItem('threeConfig', JSON.stringify(localConfig));
    },
    [],
  );

  return {
    config,
    setDisplayLabelToLocalStorage,
  };
};

export default useThreeConfig;
