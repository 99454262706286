import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';

import { UserBrief } from '../../types';

import suspendPng from '../../assets/images/png/suspend.png';
import translateName from '../../utils/translateName';

const Dailog = styled(MuiDialog)`
  & .MuiPaper-root {
    text-align: center;
    background-color: ${({ theme }) => theme.color.secondary.$80};
    padding: 60px;
  }
`;
const DialogTitle = styled(MuiDialogTitle)`
  width: 320px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.5;
  color: #fff;
  padding: 0;
`;
const DialogContent = styled(MuiDialogContent)`
  width: max-content;
  color: #fff;
  margin: 30px auto;
  padding: 0;

  & div {
    font-size: 0.875rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.color.secondary.$60};
  }

  & img {
    display: block;
    margin: 30px auto;
  }
`;
const DialogActions = styled(MuiDialogActions)`
  button {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    padding: 13px;
  }
`;

interface SuspendDialogProps extends MuiDialogProps {
  owner: UserBrief | undefined;
}

const SuspendDialog: React.FC<SuspendDialogProps> = ({
  owner,
  ...otherProps
}) => {
  const { i18n, t } = useTranslation('project');
  const navigate = useNavigate();

  const p1 = t('please-contact-with');
  const name = translateName(i18n, owner?.firstName, owner?.lastName);
  const email = owner?.email;

  return (
    <Dailog {...otherProps}>
      <DialogTitle>{t('project-has-been-suspended')}</DialogTitle>
      <DialogContent>
        <div>
          <Trans i18nKey="suspend-dialog-description">
            {{ p1 }}
            <br />
            {{ name }}
            <br />
            {{ email }}
          </Trans>
        </div>
        <img src={suspendPng} />
      </DialogContent>
      <DialogActions>
        <DialogButton
          variant="contained"
          color="primary"
          onClick={() => navigate('project')}
        >
          {t('back-to-project-list')}
        </DialogButton>
      </DialogActions>
    </Dailog>
  );
};

export default SuspendDialog;
