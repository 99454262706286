import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Audio } from '../../types';
import { Dasloop } from '../../types/Device';

type State = {
  dasloops: { [index: string]: Dasloop | undefined };
  audios: Audio[];
  refetch: object | null;
};

const initialState: State = {
  dasloops: {},
  audios: [],
  refetch: null,
};

export const userSlice = createSlice({
  name: 'public-announcement',
  initialState,
  reducers: {
    setDasloops: (state, action: PayloadAction<Dasloop[]>) => {
      const newDasloops = {};
      action.payload.forEach((dasloop) => {
        newDasloops[dasloop.id] = dasloop;
      });
      return {
        ...state,
        dasloops: newDasloops,
      };
    },
    setAudios: (state, action: PayloadAction<State['audios']>) => {
      return {
        ...state,
        audios: action.payload,
      };
    },
    doRefetch: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        refetch: action.payload ? {} : null,
      };
    },
  },
});

export const { setDasloops, setAudios, doRefetch } = userSlice.actions;
export default userSlice.reducer;
