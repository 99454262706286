import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DasloopDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasloop-dashboard-page" */ '../../pages/dasloop-dashbaord'
    ),
);

const dasloopDashboardRouter = (
  <Route path="dasloop-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DasloopDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dasloopDashboardRouter;
