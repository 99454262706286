import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { LocationOn } from '@mui/icons-material';
import { Dialog as MuiDialog, styled } from '@mui/material';
import maplibregl from 'maplibre-gl';

import { SettingMap } from '../types';

import { useAppDispatch, useAppSelector } from '../hooks';
import useDebounce from '../hooks/useDebounce';
import { useLocalStorageObject } from '../hooks/useLocalStorageObject';
import useMapboxArea from '../pages/dashboard/hooks/useMapboxArea';
import useMapboxFloorPlan from '../pages/dashboard/hooks/useMapboxFloorPlan';
import { setPinLocation } from '../slices/pages/projectPageSlice';

import CloseMediumSvgIcon from '../assets/SvgIcon/CloseMediumSvgIcon';
import i18n from '../i18n';
import { addAreasLayer } from '../pages/dashboard/Map2d/addLayers';
import { getMapStyle } from '../utils/MapboxUtils';
import {
  isLatitude,
  isLongitude,
  separateLongLat,
} from '../utils/separateLongLat';

import IconContainer from './IconContainer';
import MapSwitch from './MapSwitch';

const IconContainerStyled = styled(IconContainer)`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.color.secondary.$100};
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: 0.3s;
  :hover {
    color: ${({ theme }) => theme.color.secondary.$100};
    background-color: #3e3e3e2b;
  }
`;

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    max-width: unset;
    max-height: unset;
    margin: 0;
  }
  #map-crane {
    width: 100vw;
    height: 100vh;
    background: #ffffff;
  }

  & .maplibregl-popup-content {
    font-size: 1rem;
    color: ${({ theme }) => theme.color.secondary.$80};
    text-align: center;
    padding: 30px 37px;
    border-radius: 10px;
    & h3 {
      font-size: 1rem;
      font-weight: 500;
      color: black;
      margin: 0;
      margin-bottom: 12px;
    }
  }

  & button {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: white;
    background: ${({ theme }) => theme.color.primary.$100};
    padding: 4px 44px;
    border: none;
    border-radius: 4px;
    margin-top: 16px;
  }
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerInput = styled('div')`
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  & > .container-input {
    display: flex;
    align-items: center;
    background: white;
    width: 366px;
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
    margin-bottom: 10px;
    & > input {
      flex: 1;
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      color: ${({ theme }) => theme.color.secondary.$100};
      padding: 0;
      border: 0;
      outline: none;
    }
  }
`;

interface DialogSetPinLocationProps {
  open;
  onClose;
}

const DialogSetPinLocation: React.FC<DialogSetPinLocationProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const pinLocation = useAppSelector((store) => store.projectPage.pinLocation);
  const mapboxRef = useRef<maplibregl.Map | null>(null);
  const markerRef = useRef<maplibregl.Marker | null>(null);
  const popUpInfoRef = useRef<maplibregl.Popup | null>(null);
  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null);
  const popupRefArea = useRef<maplibregl.Popup | null>(null);
  const [queryLatLong, setQueryLatLong] = useState('');
  const queryLatLongSearch = useDebounce(queryLatLong, 1000);
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const [mapLocal, setMapLocal] = useLocalStorageObject<SettingMap>(
    'mapStyleSetting_',
    projectId,
  );

  const { projectArea, projectFloorPlan } = useAppSelector(
    (store) => store.projects,
  );

  useEffect(() => {
    const initial = async () => {
      mapboxRef.current = new maplibregl.Map({
        container: 'map-crane',
        center: pinLocation,
        zoom: 15,
        style: getMapStyle(mapLocal?.mapStyle),
        attributionControl: false,
      });

      mapboxRef.current.addControl(
        new MapboxLanguage({
          defaultLanguage: i18n.language,
        }),
      );

      markerRef.current = new maplibregl.Marker({ draggable: true })
        .setLngLat(pinLocation)
        .addTo(mapboxRef.current);

      popUpInfoRef.current = new maplibregl.Popup({
        offset: 55,
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(pinLocation)
        .setDOMContent(popupRef as HTMLDivElement)
        .addTo(mapboxRef.current);

      mapboxRef.current.on('load', () => {
        if (mapboxRef.current) {
          addAreasLayer(mapboxRef.current);
          const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
            className: 'tooltip-area',
          });

          popupRefArea.current = popup;
        }

        const onDragEnd = () => {
          const pos = markerRef.current?.getLngLat();

          dispatch(setPinLocation([pos?.lng ?? 0, pos?.lat ?? 0]));
        };

        markerRef.current?.on('drag', onDragEnd);

        setIsMapReady(true);
      });
    };

    if (mapRef && popupRef && !mapboxRef.current) {
      initial();
    }
    return () => {
      mapboxRef.current?.remove();
      mapboxRef.current = null;
    };
  }, [mapRef, popupRef, mapLocal]);

  useMapboxArea(isMapReady, projectArea, mapboxRef, popupRefArea);

  useMapboxFloorPlan(isMapReady, projectFloorPlan, mapboxRef.current);

  useEffect(() => {
    markerRef.current?.setLngLat(pinLocation);
    popUpInfoRef.current?.setLngLat(pinLocation);

    if (isLongitude(pinLocation[0]) && isLatitude(pinLocation[1])) {
      setQueryLatLong(pinLocation.join(', '));
    }
  }, [pinLocation]);

  useEffect(() => {
    const value = String(queryLatLongSearch);
    const { lon, lat } = separateLongLat(value);
    if (isLongitude(lon) && isLatitude(lat)) {
      dispatch(setPinLocation([lon, lat]));
    }
  }, [queryLatLongSearch]);

  return (
    <Dialog className="PinLocationDialog" open={open}>
      <ContainerInput>
        <div className="container-input">
          <IconContainer size={32}>
            <LocationOn />
          </IconContainer>
          <input
            value={queryLatLong}
            onChange={(e) => setQueryLatLong(e.currentTarget.value)}
            placeholder={t('long-lat')}
          />
        </div>
      </ContainerInput>
      <div ref={(ref) => setMapRef(ref)} id="map-crane" />
      <MapSwitch
        mapLocal={mapLocal}
        setMapLocal={setMapLocal}
        sx={{
          position: 'absolute',
          margin: '10px',
          top: '20px',
          left: '406px',
          zIndex: '5',
        }}
      />
      <Content ref={(ref) => setPopupRef(ref)}>
        <h3>{t('project:dialog.pinLocationDialog.title')}</h3>
        {pinLocation[0]}, {pinLocation[1]}
        <DialogButton
          color="primary"
          variant="contained"
          data-cy="btn-pin-location-submit"
          onClick={onClose}
        >
          {t('project:dialog.pinLocationDialog.submit')}
        </DialogButton>
      </Content>
      <IconContainerStyled onClick={onClose}>
        <CloseMediumSvgIcon sx={{ width: '32px', height: '32px' }} />
      </IconContainerStyled>
    </Dialog>
  );
};

export default DialogSetPinLocation;
