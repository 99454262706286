import { PagedResponse } from '../types';
import { DasCTag } from '../types/Device';

import { delay } from '../utils/common';

import DsmAxios from './DsmAxios';

export const getUserDasCTags = async (params?: {
  dasId?: string;
  keyword?: string;
  id?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  /**
   * limit too large will cause server response timeout
   */
  let limit = params?.limit ?? 100;
  if (import.meta.env.MODE === 'development') {
    limit = params?.limit ?? 100;
  }
  await delay(100);
  return DsmAxios.get<PagedResponse<DasCTag>>(`v1/dascollisiontags`, {
    params: {
      ...params,
      limit,
    },
  });
};

export const getProjectDasCTags = async (data: {
  projectId: string;
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    id?: string;
    dasId?: string;
    keyword?: string;
    assignedWorker?: boolean;
    orderBy?: 'das_id' | 'last_data_point_received_at';
    limit?: number;
  };
}) => {
  /**
   * limit too large will cause server response timeout
   */
  let limit = data.params?.limit ?? 100;
  if (import.meta.env.MODE === 'development') {
    limit = data.params?.limit ?? 100;
  }
  await delay(100);
  return DsmAxios.get<PagedResponse<DasCTag>>(
    `v1/projects/${data.projectId}/dascollisiontags`,
    {
      params: { ...data.params, limit },
    },
  );
};

export const unbindDasCTag = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/dascollisiontags/${dasId}/worker`,
  );
};

export const bindDasCTagToWorker = ({
  projectId,
  dasId,
  workerId,
}: {
  projectId: string;
  dasId: string;
  workerId: string;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/dascollisiontags/${dasId}/worker`,
    {
      workerId,
    },
  );
};
