import {
  BasicMessage,
  BasicResponse,
  LoginType,
  PolicyType,
  ProjectInvitaionData,
  User,
  UserBrief,
} from '../types';

import DsmAxios from './DsmAxios';

type ResponseInvitaionData = {
  email: string;
  id: string;
  orgId: string;
};

export const getMe = () => {
  return DsmAxios.get<BasicResponse<User>>('v1/me');
};

export const forgotPassword = (username: string, type: LoginType) => {
  return DsmAxios.post('v1/forgot-password', {
    [type === 'phone' ? 'username' : 'email']: username,
  });
};

export const resetForgotPassword = (
  username: string,
  verificationCode: string,
  type: LoginType,
) => {
  return DsmAxios.post('v1/reset-password', {
    [type === 'phone' ? 'username' : 'email']: username,
    verificationCode,
  });
};

export const resetPassword = (password: string) => {
  return DsmAxios.put('v1/me/reset-password', {
    password,
  });
};

export const updateUser = (data: User) => {
  return DsmAxios.patch(`v1/me`, {
    firstName: data.firstName,
    lastName: data.lastName,
  });
};

export const updateUserAvatar = (imageFile: File) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post(`/v1/me/avatar`, formData);
};

export const getRegisterInvitationInfo = (invitationCode: string) => {
  return DsmAxios.get<BasicResponse<ResponseInvitaionData>>(
    `v1/user/register?invitationCode=${invitationCode}`,
  );
};

export const getMyProjectInvitations = () => {
  return DsmAxios.get<BasicResponse<ProjectInvitaionData[]>>(
    `v1/me/project-invitations`,
  );
};

export const acceptProjectInvitation = (projectInvitationId: string) => {
  return DsmAxios.post(
    `/v1/me/project-invitations/${projectInvitationId}/accept`,
  );
};

export const declineProjectInvitation = (projectInvitationId: string) => {
  return DsmAxios.post(
    `/v1/me/project-invitations/${projectInvitationId}/decline`,
  );
};

export const who = (options: {
  email?: string;
  username?: string;
  id?: string;
}) => {
  const { email, username, id } = options;

  if (email) {
    return DsmAxios.get<BasicResponse<UserBrief>>(`/v1/who`, {
      params: { email },
    });
  }

  if (username) {
    return DsmAxios.get<BasicResponse<UserBrief>>(`/v1/who`, {
      params: { username },
    });
  }

  if (id) {
    return DsmAxios.get<BasicResponse<UserBrief>>(`/v1/who`, {
      params: { id },
    });
  }

  return Promise.reject(new Error('Need options'));
};

// only for role=owner or admin
export const getProjectUserPolicies = (projectId: string, userId: string) => {
  return DsmAxios.get<
    BasicResponse<Array<{ name: PolicyType; groupId?: string }>>
  >(`v1/projects/${projectId}/users/${userId}/policies`);
};

export const changePassword = ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) => {
  return DsmAxios.post(`v1/me/change-password`, {
    oldPassword,
    newPassword,
  });
};

export const unassignDevice = ({
  dasId,
  isProjectRemove,
}: {
  dasId: string;
  isProjectRemove: boolean;
}) => {
  return DsmAxios.post<BasicMessage>('v1/devices/unassign', {
    dasId,
    isProjectRemove,
  });
};

type GetWhoPayload = {
  id: string;
};

export const getWho = ({ id }: GetWhoPayload) => {
  return DsmAxios.get<BasicResponse<User>>(`/v1/who`, {
    params: {
      id,
    },
  });
};
