import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DaspowerSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3253 8.40385L11.9829 21.4616C11.5576 22.1273 12.0357 23 12.8256 23H18.5L17.8354 30.9747C17.7488 32.0146 19.1129 32.4755 19.6747 31.5961L28.0171 18.5384C28.4424 17.8727 27.9643 17 27.1744 17H21.5L22.1646 9.02529C22.2512 7.9854 20.8871 7.52451 20.3253 8.40385Z"
        fill="#FFCE4F"
      />
    </SvgIcon>
  );
};

export default DaspowerSvgColorMedium;
