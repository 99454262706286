import React from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

interface LoadingProps extends BoxProps {
  size?: React.CSSProperties['width'];
}

const Loading = React.forwardRef<HTMLDivElement, LoadingProps>(
  ({ sx, size = '2rem', height = 'calc(2rem * 1.3)', ...props }, ref) => (
    <Box
      ref={ref}
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
        ...sx,
      }}
      {...props}
    >
      <CircularProgress size={size} />
    </Box>
  ),
);

Loading.displayName = 'Loading';

export default Loading;
