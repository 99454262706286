import * as THREE from 'three';
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry';

import { globalTracker } from './ResourceTracker';

export default class AreaCreator {
  /**
   * @deprecated this func createMesh by create()
   */
  createMesh(
    data: { positions: Array<[number, number, number]>; height: number },
    material: THREE.Material,
  ) {
    const vectors = data.positions
      .map((p) => {
        return [
          new THREE.Vector3(p[0], p[1], p[2]),
          new THREE.Vector3(p[0], p[1] + data.height, p[2]),
        ];
      })
      .flat(1);
    const geometry: ConvexGeometry = globalTracker.track(
      new ConvexGeometry(vectors),
    );

    const mesh: THREE.Mesh = globalTracker.track(
      new THREE.Mesh(geometry, material),
    );

    return mesh;
  }

  create(
    data: { positions: Array<[number, number, number]>; height: number },
    material: THREE.Material,
  ) {
    const shape = new THREE.Shape();
    shape.moveTo(data.positions[0][0], data.positions[0][2]);
    for (let i = 1; i < data.positions.length; i++) {
      shape.lineTo(data.positions[i][0], data.positions[i][2]);
    }
    shape.lineTo(data.positions[0][0], data.positions[0][2]);
    const extrudeSettings = { depth: data.height, bevelEnabled: false };
    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

    const mesh: THREE.Mesh = globalTracker.track(
      new THREE.Mesh(geometry, material),
    );

    mesh.rotation.x = Math.PI / 2;

    mesh.scale.z = -1;

    mesh.position.y = data.positions[0][1];

    return mesh;
  }
}
