import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const CCTVDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasloop-dashboard-page" */ '../../pages/cctv-dashboard'
    ),
);

const CCTVSettingComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/cctv/CCTVSetting'
    ),
);

const PinLocationDialog3DPlusComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../components/PinLocationDialog3DPlus'
    ),
);

const cctvDashboardRouter = (
  <Route path="cctv-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <CCTVDashboardPageComponent />
        </Suspense>
      }
    >
      <Route
        path="cctv-setting"
        element={
          <Suspense fallback={<Loading />}>
            <CCTVSettingComponent />
          </Suspense>
        }
      >
        <Route
          path="pin-location"
          element={<PinLocationDialog3DPlusComponent />}
        />
      </Route>
    </Route>
  </Route>
);

export default cctvDashboardRouter;
