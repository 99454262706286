import React from 'react';
import { theme as beeinventerTheme } from '@beeinventor/dasiot-react-component-lib';
import { createTheme, SxProps } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    textStyles: {
      T: string;
      P: string;
    };
    externalTypography: {
      body3?: React.CSSProperties;
      body3Bold?: React.CSSProperties;
      subtitle3?: React.CSSProperties;
    };
    externalColor: {
      dark: {
        $15: string;
        $20: string;
      };
      red: {
        $100: string;
        $120: string;
      };
      green: {
        $100: string;
      };
      primary: {
        $60: string;
      };
      secondary: {
        $140: string;
        $120: string;
      };
      randrom: {
        $1: string;
        $2: string;
        $3: string;
        $4: string;
        $5: string;
        $6: string;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    textStyles?: {
      T?: string;
      P?: string;
    };
    externalTypography?: {
      body3?: React.CSSProperties;
      body3Bold?: React.CSSProperties;
      subtitle3?: React.CSSProperties;
    };
    externalColor: {
      dark: {
        $15: string;
        $20: string;
      };
      red: {
        $100: string;
        $120: string;
      };
      green: {
        $100: string;
      };
      primary: {
        $60: string;
      };
      secondary: {
        $140: string;
        $120: string;
      };
      randrom: {
        $1: string;
        $2: string;
        $3: string;
        $4: string;
        $5: string;
        $6: string;
      };
    };
  }
}

const theme = createTheme({
  ...beeinventerTheme,
  components: {
    ...beeinventerTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: beeinventerTheme.color.secondary.$100,
        },
      },
    },
  },
  palette: {
    ...beeinventerTheme.palette,
    success: {
      main: '#78DC00',
    },
    error: {
      main: '#FF6B00',
    },
  },
  typography: {
    fontFamily: '"Noto Sans TC", "Noto Sans"',
    h1: {
      fontSize: '1.75rem',
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h3: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '1.875rem',
      lineHeight: 1.3,
      fontWeight: 'normal',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
    caption: {
      fontSize: '0.625rem',
      lineHeight: 1.5,
    },
  },
  textStyles: {
    T: `
      font-size: 1rem;
      line-height: 1.5em;
    `,
    P: `
      font-size: 1rem;
      line-height: 1.5em;
    `,
  },
  externalTypography: {
    body3: {
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
    body3Bold: {
      fontSize: '0.75rem',
      lineHeight: 1.5,
      fontWeight: 700,
    },
    subtitle3: {
      fontSize: '1.5rem',
      lineHeight: 1.3,
    },
  },
  externalColor: {
    dark: {
      $15: '#333333',
      $20: '#262626',
    },
    red: {
      $100: '#FF6B00',
      $120: '#CC0000',
    },
    green: {
      $100: '#78DC00',
    },
    primary: {
      $60: '#FFCE4F',
    },
    secondary: {
      $140: '#262626',
      $120: '#333333',
    },
    randrom: {
      $1: '#58B99E',
      $2: '#E96363',
      $3: '#EF8C34',
      $4: '#5296D5',
      $5: '#B152C6',
      $6: '#1C4252',
    },
  },
});

export const minimumDialogProps: SxProps = {
  '& > .MuiDialog-container > .MuiPaper-root': {
    minWidth: '480px',
    '@media (max-width: 600px)': {
      minWidth: '275px',
    },
  },
};

export const minimumDeviceDialogProps: SxProps = {
  '& > .MuiDialog-container > .MuiPaper-root': {
    minWidth: '480px',
    minHeight: '383px',
    '@media (max-width: 600px)': {
      minWidth: '275px',
    },
  },
};

export const datePickerPopperSx: SxProps = {
  color: 'white !important',
  backgroundColor: theme.color.secondary.$100,
  '& .MuiPaper-root': {
    color: 'white !important',
    backgroundColor: theme.color.secondary.$100,
  },
  '& button': {
    color: 'white',
    fontSize: '1rem',
  },
  '& .MuiPickersDay-root': {
    color: 'white !important',
    backgroundColor: 'black',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    '&.Mui-selected': {
      color: 'black',
      background: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.38) !important',
    },
  },
  '& .MuiButtonBase-root': {
    color: 'white',
  },
  '& .MuiTypography-root': {
    color: 'white',
  },
  '& span[role="option"]': {
    color: 'white',
    '&[aria-disabled="true"]': {
      color: 'black',
    },
  },
  '& .PrivatePickersYear-yearButton': {
    fontSize: '1.5rem',
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  '& .MuiCalendarPicker-viewTransitionContainer': {
    '& span.MuiTypography-caption': {
      color: 'white',
    },
  },
};

export const buttonResponsiveSx: SxProps = {
  '@media (max-width: 500px)': {
    fontSize: '12px',
    padding: '6px',
    '& >svg': {
      width: '16px',
      height: '16px',
      margin: '0',
    },
  },
};

export const svgIconSizeResponsiveSx: SxProps = {
  '@media (max-width: 500px)': {
    fontSize: '12px',
    padding: '6px',
    '& >svg': {
      width: '16px',
      height: '16px',
      margin: '0',
    },
  },
};

export const svgIconResponsiveSx: SxProps = {
  '@media (max-width: 498px)': {
    display: 'none',
  },
};

export default theme;
