import {
  BasicMessage,
  BasicResponse,
  Building,
  Contact,
  ContactReferenceType,
  FeatureListItem,
  FeatureType,
  GetProjectInvitations,
  PagedResponse,
  Policy,
  PolicyWithGroups,
  Project,
  ProjectCustomLogo,
  ProjectCustomLogoData,
  ProjectData,
  ProjectRole,
  ProjectUser,
  ResourceType,
} from '../types';
import {
  AssetAlert,
  DasGasAlert,
  DasloopAlert,
  DastrackAlert,
  RequestAlertsParams,
} from '../types/Alert';
import { DasEndpoint } from '../types/Device';
import { AssetStatusType, Equipment } from '../types/Resource';

import DsmAxios from './DsmAxios';

interface GetProjectsParams {
  archived?: boolean;
  id?: string;
  sort?:
    | '+createdAt'
    | '-createdAt'
    | '+startDate'
    | '-startDate'
    | '+endDate'
    | '-endDate';
  hasUserId?: string;
}

type AlertActionType = 'read' | 'dismiss' | 'unread' | 'undismiss';

export const getProjects = (params?: GetProjectsParams) => {
  return DsmAxios.get<BasicResponse<Project[]>>('v1/projects', {
    params,
  });
};

export const getProject = async (projectId?: string) => {
  const projects = await DsmAxios.get<BasicResponse<Project[]>>('v1/projects', {
    params: {
      id: projectId,
    },
  });

  if (projects.data.data.length >= 1) {
    for (let i = 0; i < projects.data.data.length; i += 1) {
      if (projects.data.data[i].id === projectId) return projects.data.data[i];
    }
  }
  return undefined;
};

interface CreateProjectData {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  center: [number, number];
}

export const createProject = (data: CreateProjectData) => {
  return DsmAxios.post<BasicResponse<Project>>('v1/projects', {
    name: data.name,
    description: data.description,
    startDate: data.startDate.toISOString(),
    endDate: data.endDate.toISOString(),
    center: data.center,
  });
};

export const uploadProjectAvatar = (projectId: string, imageFile: File) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return (
    DsmAxios.post(`v1/projects/${projectId}/avatar`, formData),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const updateProject = (projectId: string, data: ProjectData) => {
  return DsmAxios.patch(`v1/projects/${projectId}/edit`, {
    name: data.name,
    description: data.description,
    startDate: data.startDate?.toISOString(),
    endDate: data.endDate?.toISOString(),
    center: [data.location?.lng, data.location?.lat],
  });
};

export const setArchiveProject = (projectId: string, isArchived: boolean) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/${isArchived ? 'archive' : 'unarchive'}`,
  );
};

export const getProjectRole = (projectId: string) => {
  return DsmAxios.get<BasicResponse<ProjectRole>>(
    `/v1/projects/${projectId}/me/role`,
  );
};

export const getProjectInvitations = (projectId: string) => {
  return DsmAxios.get<BasicResponse<GetProjectInvitations[]>>(
    `v1/projects/${projectId}/invitations`,
  );
};

export type CreateProjectInvitationRequestData = {
  email: string;
  orgId: string;
  role: ProjectRole;
  grantPolicies: Array<{ name: string; groupId?: string }>;
};

export const createProjectInvitation = (
  projectId: string,
  data: CreateProjectInvitationRequestData,
) => {
  const payload = data;
  if (data.role === 'supervisor' || data.role === 'viewer') {
    payload.grantPolicies = [
      ...data.grantPolicies,
      { name: 'ReadProjectUser' },
    ];
  }
  return DsmAxios.post(`v1/projects/${projectId}/invitations`, payload);
};

export const deleteProjectInvitation = (
  projectId: string,
  invitationId: string,
) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/invitations/${invitationId}`,
  );
};

export const getProjectUsers = (projectId: string) => {
  return DsmAxios.get<BasicResponse<ProjectUser[]>>(
    `v1/projects/${projectId}/users`,
  );
};

export const setUserPolicies = (
  projectId: string,
  userId: string,
  policies: { grantPolicies: PolicyWithGroups[] },
) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/users/${userId}/policies`,
    policies,
  );
};

export const getDasloopAlerts = (
  projectId: string,
  params?: RequestAlertsParams & { workerId?: string },
) => {
  return DsmAxios.get<PagedResponse<DasloopAlert>>(
    `v1/projects/${projectId}/dasloop-alerts`,
    { params },
  );
};

export const getDasgasAlerts = (
  projectId: string,
  params?: RequestAlertsParams & { workerId?: string },
) => {
  return DsmAxios.get<PagedResponse<DasGasAlert>>(
    `v1/projects/${projectId}/dasgases-alerts`,
    { params },
  );
};

export const getDastrackAlerts = (
  projectId: string,
  params?: RequestAlertsParams & { plantId?: string },
) => {
  return DsmAxios.get<PagedResponse<DastrackAlert>>(
    `v1/projects/${projectId}/dastrack-alerts`,
    { params },
  );
};

export const getResourceAlerts = <T extends AssetAlert>(
  projectId: string,
  resourceType: ResourceType,
  params?: RequestAlertsParams & {
    assetId?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<T>>(
    `v1/projects/${projectId}/resources/${resourceType}/${resourceType}-alert`,
    { params },
  );
};

export const getAssetAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    assetId?: string;
  },
) => {
  return getResourceAlerts<AssetAlert>(projectId, 'asset', params);
};

export const createRepairingAlert = (
  projectId: string,
  resourceType: ResourceType,
  params?: {
    assetId: string;
    field: AssetStatusType;
    condition: string;
  },
) => {
  return DsmAxios.post<BasicResponse<AssetAlert>>(
    `v1/projects/${projectId}/resources/${resourceType}/asset-alert`,
    params,
  );
};

export const dismissDasloopAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasloop-alerts/${alertId}/dismiss`,
  );
};

export const undismissDasloopAlert = (projectId, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasloop-alerts/${alertId}/undismiss`,
  );
};

export const readDasloopAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasloop-alerts/${alertId}/read`,
  );
};

export const dismissDasgasAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasgases-alerts/${alertId}/dismiss`,
  );
};

export const undismissDasgasAlert = (projectId, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasgases-alerts/${alertId}/undismiss`,
  );
};

export const readDasgasAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasgases-alerts/${alertId}/read`,
  );
};

export const readDaswaterLAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daswater-alerts/${alertId}/read`,
  );
};

export const dismissDastrackAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastrack-alerts/${alertId}/dismiss`,
  );
};

export const undismissDastrackAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastrack-alerts/${alertId}/undismiss`,
  );
};

export const actionResourceAlert = (
  projectId: string,
  alertId: string,
  action: AlertActionType,
  resourceType: ResourceType,
) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/resources/${resourceType}/${resourceType}-alert/${alertId}/${action}`,
  );
};

export const readDastrackAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastrack-alerts/${alertId}/read`,
  );
};

export const readDasairAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasair-alerts/${alertId}/read`,
  );
};

export const dismissDasairAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasair-alerts/${alertId}/dismiss`,
  );
};

export const dismissDaswaterLAlert = (projectId: string, alerId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daswater-alerts/${alerId}/dismiss`,
  );
};

export const undismissDasairAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasair-alerts/${alertId}/undismiss`,
  );
};

export const unReadDasairAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasair-alerts/${alertId}/unread`,
  );
};

export const readDascasAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dascas-alerts/${alertId}/read`,
  );
};

export const dismissDascasAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dascas-alerts/${alertId}/dismiss`,
  );
};

export const undismissDascasAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dascas-alerts/${alertId}/undismiss`,
  );
};

export const readDasConcreteAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasconcrete-alerts/${alertId}/read`,
  );
};

export const unReadDasConcreteAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasconcrete-alerts/${alertId}/unread`,
  );
};

export const dismissDasConcreteAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasconcrete-alerts/${alertId}/dismiss`,
  );
};

export const undismissDasConcreteAlert = (
  projectId: string,
  alertId: string,
) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasconcrete-alerts/${alertId}/undismiss`,
  );
};

export const getProjectEquipments = (data: {
  projectId: string;
  params?: {
    id?: string;
    limit?: number;
    nextCursor?: string;
    prevCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Equipment>>(
    `v1/projects/${data.projectId}/equipments`,
    { params: data.params },
  );
};

export const getProjectEquipmentById = (data: {
  projectId: string;
  id: string;
}) => {
  return DsmAxios.get<BasicResponse<Equipment>>(
    `v1/projects/${data.projectId}/equipments/${data.id}`,
  );
};

export const removeMember = (projectId: string, userId: string) => {
  return DsmAxios.delete(`v1/projects/${projectId}/users/${userId}`);
};

export const getMyProjectPolicies = (projectId: string) => {
  return DsmAxios.get<BasicResponse<Policy[]>>(
    `v1/projects/${projectId}/me/policies`,
  );
};

export const get3dAssets = (projectId: string) => {
  return DsmAxios.get<BasicResponse<Building[]>>(
    `v1/projects/${projectId}/3d-assets`,
  );
};

export const getProjectCustomLogo = (projectId: string) => {
  return DsmAxios.get<BasicResponse<ProjectCustomLogo[]>>(
    `v1/projects/${projectId}/logos`,
  );
};

export const createProjectCustomLogoInfo = (
  projectId: string,
  data: ProjectCustomLogoData,
) => {
  return DsmAxios.post<BasicResponse<ProjectCustomLogo>>(
    `v1/projects/${projectId}/logos`,
    {
      name: data.name,
      pinned: data.pinned,
    },
  );
};

export const uploadProjectCustomLogo = ({
  projectId,
  logoId,
  imageFile,
}: {
  projectId: string;
  logoId: string;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);

  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/logos/${logoId}/image`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const updateProjectCustomLogoInfo = ({
  projectId,
  logoId,
  name,
  pinned,
}: {
  projectId: string;
  logoId: string;
  name?: string;
  pinned?: boolean;
}) => {
  return DsmAxios.patch<BasicResponse<ProjectCustomLogo>>(
    `v1/projects/${projectId}/logos/${logoId}`,
    {
      name,
      pinned,
    },
  );
};

export const deleteProjectCustomLogo = (projectId: string, logoId: string) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/logos/${logoId}`,
  );
};

export const getProjectFeatureList = (projectId: string) => {
  return DsmAxios.get<BasicResponse<{ [key in FeatureType]: FeatureListItem }>>(
    `v1/projects/${projectId}/features/list`,
  );
};

export const getProjectContact = (projectId: string) => {
  return DsmAxios.get<BasicResponse<Contact[]>>(
    `/v1/projects/${projectId}/contacts`,
  );
};

export const createProjectContact = (data: {
  projectId: string;
  payload: {
    name: string;
    role: string;
    language: string;
    orgId: string;
    phone: string;
    email: string;
    telegram: string;
  };
}) => {
  return DsmAxios.post(`v1/projects/${data.projectId}/contacts`, data.payload);
};

export const deleteProjectContact = ({
  projectId,
  contactId,
}: {
  projectId: string;
  contactId: string;
}) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/contacts/${contactId}`,
  );
};

export const updateProjectContact = (data: {
  projectId: string;
  contactId: string;
  payload: {
    name?: string;
    role?: string;
    language?: string;
    orgId?: string;
    phone?: string;
    email?: string;
    telegram?: string;
  };
}) => {
  return DsmAxios.patch(
    `v1/projects/${data.projectId}/contacts/${data.contactId}`,
    data.payload,
  );
};

export const bindProjectContact = ({
  projectId,
  contactId,
  type,
  referenceId,
}: {
  projectId: string;
  contactId: string;
  type: ContactReferenceType;
  referenceId: string;
}) => {
  return DsmAxios.post<BasicResponse<Contact>>(
    `v1/projects/${projectId}/contacts/${contactId}/references`,
    {
      type,
      referenceId,
    },
  );
};

export const unBindProjectContact = ({
  projectId,
  contactId,
  referenceId,
}: {
  projectId: string;
  contactId: string;
  referenceId: string;
}) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/contacts/${contactId}/references/${referenceId}`,
  );
};

export const dismissOrReadAllDasloopAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/dasloop-alerts/${action}/all`);
};

export const dismissOrReadAllDasgasAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasgases-alerts/${action}/all`,
  );
};

export const dismissOrReadAllDaspowerAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daspower-alerts/${action}/all`,
  );
};

export const dismissOrReadAllDastempAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/dastemp-alerts/${action}/all`);
};

export const dismissOrReadAllDaslockAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/daslock-alerts/${action}/all`);
};

export const dismissOrReadAllDastrackAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastrack-alerts/${action}/all`,
  );
};

export const dismissOrReadAllDasairAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/dasair-alerts/${action}/all`);
};

export const dismissOrReadAllDaswaterAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daswater-alerts/${action}/all`,
  );
};

export const dismissOrReadAllAreaAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/area-alerts/${action}/all`);
};

export const dismissOrReadAllDascasAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/dascas-alerts/${action}/all`);
};

export const dismissOrReadAllDasConcreteAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dasconcrete-alerts/${action}/all`,
  );
};

export const dismissOrReadAllDasAiAlert = ({
  projectId,
  action,
}: {
  projectId: string;
  action: AlertActionType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/resources/ai/resource-alert/${action}/all`,
  );
};

export const undismissDasAiAlert = (projectId, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/resources/ai/resource-alert/${alertId}/undismiss`,
  );
};

export const dismissOrReadAllProjectAlert = ({
  projectId,
  action,
  resourceType,
}: {
  projectId: string;
  action: AlertActionType;
  resourceType: ResourceType;
}) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/resources/asset/${resourceType}-alert/${action}/all`,
  );
};

export const getProjectEndpointByDasId = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.get<BasicResponse<DasEndpoint>>(
    `/v1/projects/${projectId}/endpoints/${dasId}`,
  );
};
