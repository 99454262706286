import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CCTVMode, PinLocationMode } from '../../types';

type ProjectPageState = {
  pinLocation: [number, number];
  altitude: number;
  height: number;
  rotation: number;
  mode: PinLocationMode;
  cctvMode: CCTVMode;
};

const initialState: ProjectPageState = {
  pinLocation: [0, 0],
  altitude: 0,
  height: 0,
  rotation: 0,
  mode: 'none',
  cctvMode: 'normal',
};

export const userSlice = createSlice({
  name: 'projectPage',
  initialState,
  reducers: {
    setPinLocation: (state, action: PayloadAction<[number, number]>) => {
      return {
        ...state,
        pinLocation: action.payload,
      };
    },
    setAltitude: (
      state,
      action: PayloadAction<ProjectPageState['altitude']>,
    ) => {
      return { ...state, altitude: action.payload };
    },
    setHeight: (state, action: PayloadAction<ProjectPageState['height']>) => {
      return { ...state, height: action.payload };
    },
    setRotation: (
      state,
      action: PayloadAction<ProjectPageState['rotation']>,
    ) => {
      return { ...state, rotation: action.payload };
    },
    setMode3D: (state, action: PayloadAction<ProjectPageState['mode']>) => {
      return { ...state, mode: action.payload };
    },
    setCCTVMode: (
      state,
      action: PayloadAction<ProjectPageState['cctvMode']>,
    ) => {
      return { ...state, cctvMode: action.payload };
    },
  },
});

export const {
  setPinLocation,
  setAltitude,
  setHeight,
  setRotation,
  setMode3D,
  setCCTVMode,
} = userSlice.actions;
export default userSlice.reducer;
