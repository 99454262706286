export const checkIsArrayEqual = (
  array1: Array<string>,
  array2: Array<string>,
) => {
  const n = array1.length;
  const m = array2.length;
  const array1Sorted = [...array1].sort();
  const array2Sorted = [...array2].sort();
  if (n !== m) {
    return false;
  }

  for (let i = 0; i < n; i += 1) {
    if (array1Sorted[i] !== array2Sorted[i]) return false;
  }
  return true;
};
