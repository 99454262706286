import React from 'react';
import {
  styled,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
} from '@mui/material';

const ContainerTextArea = styled('div')`
  & textarea {
    ${({ theme }) => ({ ...theme.typography.body2 })}
    width: 100%;
    font-family: 'Noto Sans TC', 'Noto Sans';
    background: ${({ theme }) => theme.color.secondary.$100};
    border-radius: 4px;
    outline: none;
    color: white;
    padding: 10px;
    border-color: transparent;
    resize: none;
    &:hover {
      background: ${({ theme }) => theme.color.secondary.$100};
    }
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

interface InputTextAreaProps extends TextareaAutosizeProps {
  title: string;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
}

const InputTextArea: React.FC<InputTextAreaProps> = ({
  title,
  onChange,
  ...otherProps
}) => {
  return (
    <ContainerTextArea>
      <Title>{title}</Title>
      <TextareaAutosize
        maxRows={4}
        minRows={4}
        onChange={onChange}
        autoComplete="off"
        {...otherProps}
      />
    </ContainerTextArea>
  );
};

export default InputTextArea;
