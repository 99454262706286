import * as THREE from 'three';

import { globalTracker } from './ResourceTracker';

class DasconcreteCreator {
  create(config: { name: string; material: THREE.Material }) {
    const geometry: THREE.SphereGeometry = globalTracker.track(
      new THREE.SphereGeometry(0.5, 64, 32),
    );
    geometry.name = config.name;

    const mesh: THREE.InstancedMesh = new THREE.InstancedMesh(
      geometry,
      config.material,
      1000,
    );

    mesh.name = `${config.name}Mesh`;

    return mesh;
  }

  createMesh(config: { name: string; materials: THREE.Material }) {
    const geometry = globalTracker.track(new THREE.SphereGeometry(0.5, 64, 32));

    geometry.name = config.name;
    const mesh: THREE.Mesh = new THREE.Mesh(geometry, config.materials);
    mesh.name = `${config.name}Mesh`;
    return mesh;
  }
}

export default DasconcreteCreator;
