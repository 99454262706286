import { useMemo } from 'react';

import { phoneRegex } from '../utils/common';

const usePhone = (fullPhone: string | undefined) => {
  return useMemo(() => {
    if (fullPhone) {
      const result = phoneRegex.exec(fullPhone);
      if (result?.length === 3) {
        return { code: result[1], phone: result[2] };
      }
    }
    return { code: undefined, phone: undefined };
  }, [fullPhone]);
};

export default usePhone;
