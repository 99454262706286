import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface FreeFallSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const DasgasAlertSvgIcon = ({ ...props }: FreeFallSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.5C9 11.3954 9.89543 10.5 11 10.5H29C30.1046 10.5 31 11.3954 31 12.5V28.5C31 29.6046 30.1046 30.5 29 30.5H11C9.89543 30.5 9 29.6046 9 28.5V12.5ZM16 26.5C16 27.6046 15.1046 28.5 14 28.5C12.8954 28.5 12 27.6046 12 26.5C12 25.3954 12.8954 24.5 14 24.5C15.1046 24.5 16 25.3954 16 26.5ZM26 28.5C27.1046 28.5 28 27.6046 28 26.5C28 25.3954 27.1046 24.5 26 24.5C24.8954 24.5 24 25.3954 24 26.5C24 27.6046 24.8954 28.5 26 28.5ZM12 13.5H28V22.5H12V13.5ZM27 14.5H13V21.5H27V14.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DasgasAlertSvgIcon;
