import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddMemberSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2V7a1 1 0 0 1 1-1Zm-6 8.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM19 26c0-4.51-2.91-7-6.5-7S6 21.49 6 26h13Z"
    />
  </SvgIcon>
);

export default AddMemberSvgIcon;
