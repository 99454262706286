import * as React from 'react';

const DasloopLiveCallSvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0415 15.0846C27.0694 15.6163 27.0603 16.1494 27.0144 16.6798C27.0144 16.6798 27.0687 17.765 24.7073 19.6833C20.9022 22.7708 15.6532 22.9854 12.1906 22.6137C12.2036 19.8901 12.6004 11.7293 16.7698 8.6993C21.5719 5.20908 25.0324 8.94931 25.1031 9.03118C26.6001 11.0014 26.9731 13.4905 27.0415 15.0846Z"
      fill="#FFB905"
    />
    <path
      d="M12.1901 22.6137C12.1901 22.8272 12.1901 23.0075 12.1901 23.1491C11.7661 23.1049 11.3855 23.054 11.0583 23.002C10.7311 22.95 10.4539 22.9013 10.2386 22.8593C9.52602 22.7279 8.83069 22.5134 8.16642 22.2199C8.16642 22.1093 8.1588 21.9079 8.15771 21.6181V21.5484C8.15771 18.9974 8.49147 10.6419 12.738 7.57312C13.8763 6.75228 14.9494 6.32417 15.9083 6.16597C16.6366 6.05387 17.3722 5.9984 18.1087 6.00004C21.6464 6.00004 23.7969 7.31758 25.0972 9.03116C25.0244 8.95041 21.566 5.21018 16.7639 8.69929C12.6043 11.7293 12.2031 19.8901 12.1901 22.6137Z"
      fill="#FFCE4F"
    />
    <path
      d="M15.9106 6.16595C14.9517 6.32415 13.8786 6.75226 12.7404 7.5731C8.4938 10.6418 8.16005 18.9973 8.16005 21.5484V21.6181H8.14699C7.02501 21.0373 6.86084 20.4299 6.86084 20.4299C6.87497 19.7972 6.89889 18.9918 6.96956 18.0825C7.32833 13.8057 8.83846 7.28548 15.9106 6.16595Z"
      fill="#E6A600"
    />
    <path
      d="M25.3061 20.4476C23.5361 21.8858 19.6332 24.1503 13.0067 23.669C12.9603 23.743 12.9047 23.8107 12.8415 23.8704C12.6634 24.0362 12.4306 24.1279 12.1892 24.127C12.1566 24.127 12.1239 24.127 12.0902 24.1215C11.688 24.0795 11.2912 24.0275 10.9117 23.9688C10.6128 23.9224 10.3235 23.8726 10.0539 23.8184C9.26862 23.6727 8.5025 23.4351 7.77083 23.1104C7.60678 23.0351 7.46685 22.9143 7.36706 22.7619C7.26728 22.6096 7.21169 22.4317 7.20659 22.2486C7.20659 22.2342 7.20659 22.2165 7.20659 22.1977C6.23573 21.5561 5.98785 20.8979 5.93132 20.6899C5.9062 20.5981 5.89445 20.5031 5.89649 20.4078C5.90302 20.1379 5.91065 19.8447 5.92261 19.5328C4.85391 20.6069 4.13092 21.6922 4.0135 22.659C3.56667 26.3517 14.3059 28.9846 21.8608 24.2332C25.0234 22.242 28.4296 19.2009 27.9556 16.932C27.8643 17.5205 27.3968 18.7495 25.3061 20.4476Z"
      fill="#FFB905"
    />
    <path
      d="M5 22.6667C7.90601 24.0202 12.3184 24.9739 14.3333 25.3333C14.6986 21.2357 15.4855 18.1469 15.6667 16.6667L6.33333 14.6667C5 18 5.01473 21.5325 5 22.6667Z"
      fill="#878787"
    />
    <path
      d="M9.47462 22.8913C11.2377 22.8913 12.6723 21.4567 12.6723 19.6936C12.6723 17.9306 11.2377 16.496 9.47462 16.496C7.71157 16.496 6.27698 17.9306 6.27698 19.6936C6.27845 21.4567 7.71304 22.8913 9.47462 22.8913Z"
      fill="#C4C4C4"
    />
    <path
      d="M9.47456 21.9869C10.7383 21.9869 11.7664 20.9588 11.7664 19.6951C11.7664 18.4314 10.7383 17.4033 9.47456 17.4033C8.21082 17.4033 7.18274 18.4314 7.18274 19.6951C7.18274 20.9588 8.21229 21.9869 9.47456 21.9869Z"
      fill="#76EFFF"
    />
    <path
      d="M17 16L15.6667 16.6667C14.6 18.2667 14.3334 23.1111 14.3334 25.3333L15.6667 24.6667L17 16Z"
      fill="#333333"
    />
    <path
      d="M7.66671 14L6.33337 14.6667L15.6667 16.6667L17 16L7.66671 14Z"
      fill="#A1A1A1"
    />
  </svg>
);

export default DasloopLiveCallSvgColor;
