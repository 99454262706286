import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface AreaAlertSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const AreaAlertSvgIcon = ({ ...props }: AreaAlertSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 14.7324C11.9022 14.3866 11.5 13.7403 11.5 13C11.5 11.8954 12.3954 11 13.5 11C14.2403 11 14.8866 11.4022 15.2324 12H20.7676C21.1134 11.4022 21.7597 11 22.5 11C23.6046 11 24.5 11.8954 24.5 13C24.5 13.199 24.4709 13.3911 24.4169 13.5725L27.3712 18.0041C27.4138 18.0014 27.4567 18 27.5 18C28.6046 18 29.5 18.8954 29.5 20C29.5 20.7403 29.0978 21.3866 28.5 21.7324V25.2676C29.0978 25.6134 29.5 26.2597 29.5 27C29.5 28.1046 28.6046 29 27.5 29C26.7597 29 26.1134 28.5978 25.7676 28H15.2324C14.8866 28.5978 14.2403 29 13.5 29C12.3954 29 11.5 28.1046 11.5 27C11.5 26.2597 11.9022 25.6134 12.5 25.2676V14.7324ZM15.2324 26C15.0568 25.6964 14.8036 25.4432 14.5 25.2676V14.7324C14.8036 14.5568 15.0568 14.3036 15.2324 14H20.7676C21.1134 14.5978 21.7597 15 22.5 15C22.6488 15 22.7939 14.9837 22.9334 14.9529L25.7069 19.1131C25.5745 19.3804 25.5 19.6815 25.5 20C25.5 20.7403 25.9022 21.3866 26.5 21.7324V25.2676C26.1964 25.4432 25.9432 25.6964 25.7676 26H15.2324Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AreaAlertSvgIcon;
