import React, { CSSProperties, useState } from 'react';
import { Avatar, styled } from '@mui/material';

import CheckboxDark from '../../../../components/Checkbox/CheckboxDark';

import DefaultVehicle from '../../../../assets/images/default-image/vehicle_default.png';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 10px;
  transition: .3s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  & .info {
    flex: 1;
    cursor: pointer;
    div {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      display: flex;
      align-items: center;
      max-width: 305px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-of-type(2) {
        ${({ theme }) => ({ ...theme.externalTypography.body3 })}
        color: ${({ theme }) => theme.color.secondary.$60};
        &:before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 3px;
          background: ${({ orgColor }) =>
            orgColor ? orgColor : 'transparent'};
          margin-right: 6px;
        }
      }
      & > span{
        margin-left: 5px;
        color: ${({theme})=> theme.color.secondary.$60};
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        max-width: 305px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const ContainerCheckbox = styled('div')`
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

interface VehicleItemCheckboxProps {
  onClick?: () => void;
  onChange: (value: any, position: boolean) => void;
  name: string;
  orgName: string;
  orgColor?: CSSProperties['color'];
  imageURL: string | null;
  idSelected: string;
  disabled?: boolean;
  dasIds: string[];
}

const VehicleItemCheckbox: React.FC<VehicleItemCheckboxProps> = ({
  onClick,
  onChange,
  name,
  orgName,
  orgColor,
  imageURL,
  idSelected,
  disabled,
  dasIds,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  return (
    <>
      <Container orgColor={orgColor}>
        <ContainerCheckbox>
          <CheckboxDark
            onChange={(value, checked) => {
              onChange(value, checked);
              setIsSelected(checked);
            }}
            checked={isSelected}
            value={idSelected}
            disabled={disabled}
          />
        </ContainerCheckbox>
        <Avatar
          variant="square"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginRight: '10px',
          }}
          src={imageURL ?? ''}
        >
          <Avatar
            src={DefaultVehicle}
            alt="vehicle beeinventor"
            variant="square"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
        </Avatar>
        <div className="info" onClick={onClick}>
          <div title={name}>
            {name}{' '}
            {dasIds.length > 0 && (
              <span title={`(${dasIds.join(', ')})`}>{`(${dasIds.join(
                ', ',
              )})`}</span>
            )}
          </div>
          <div title={orgName}>{orgName}</div>
        </div>
      </Container>
    </>
  );
};

export default VehicleItemCheckbox;
