import { Group } from '../types';
import { Pipe } from '../types/Resource';

export const sortByCreatedAt = (
  a: { createdAt: string },
  b: { createdAt: string },
): number => {
  const CreatedAtA = new Date(a.createdAt);
  const CreatedAtB = new Date(b.createdAt);
  return CreatedAtA.getTime() - CreatedAtB.getTime();
};

export const sortPipeByUpdateAt = (a: Pipe, b: Pipe): number => {
  const UdatedAtA = new Date(a.updatedAt);
  const UdatedAtB = new Date(b.updatedAt);
  return UdatedAtB.getTime() - UdatedAtA.getTime();
};

export function sortGroupByIsDefault(a: Group, b: Group) {
  if (a.isDefault && !b.isDefault) {
    return -1;
  }
  if (!a.isDefault && b.isDefault) {
    return 1;
  }
  return 0;
}

export function sortBySelectedGroupId(
  this: {
    selectedGroupIdMap?: { [id: string]: boolean };
    locales?: string | string[];
  },
  a: Group,
  b: Group,
): number {
  if (this.selectedGroupIdMap?.[a.id] && this.selectedGroupIdMap?.[b.id]) {
    return 0;
  }
  if (this.selectedGroupIdMap?.[a.id]) {
    return -1;
  }
  return 1;
}

export function groupCompareFn(
  this: {
    selectedGroupIdMap?: { [id: string]: boolean };
    locales?: string | string[];
  },
  a: Group,
  b: Group,
) {
  let result = sortGroupByIsDefault(a, b);
  if (result === 0) {
    result = a.name.localeCompare(b.name, this.locales);
  }
  return result;
}
