const DasAiServerSvgColor = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 17V17.298L16.1813 22.1401V17H8ZM17 15.6818L25.1804 10.8452L17 6.0023V15.6818ZM16.1813 23.7545L8 18.9125V24.158L16.1813 29.0001V23.7545ZM17.8187 16.8945L25.9999 12.0524V17.298L17.8187 22.1401V16.8945ZM22.7287 15.4074L25.1831 13.9317V16.4911L22.7287 17.9668V15.4074ZM25.9999 18.9119L17.8187 23.754V28.9996L25.9999 24.1575V18.9119ZM25.1831 20.7912L22.7287 22.2669V24.8263L25.1831 23.3506V20.7912Z"
      fill="#A1A1A1"
    />
    <rect x="3" y="3" width="12" height="12" fill="#E6A600" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62492 6H6.62492H8.37486H8.37487L10.9998 13.0122H9.24984L8.87094 12H6.12885L5.74994 13.0122H4L6.62492 6ZM6.87752 10L7.49989 8.33739L8.12226 10L6.87752 10ZM13.9999 7C13.9999 7.55228 13.5522 8 12.9999 8C12.4476 8 11.9999 7.55228 11.9999 7C11.9999 6.44772 12.4476 6 12.9999 6C13.5522 6 13.9999 6.44772 13.9999 7ZM13.9999 9V13H11.9999V9H13.9999Z"
      fill="white"
    />
  </svg>
);

export default DasAiServerSvgColor;
