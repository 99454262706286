import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { Contact } from '../../../../types';

import EmptyData from '../../../../components/EmptyData';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const ContainerCard = styled('div')`
  margin-top: 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 8px;
  height: 42px;
`;

const ContainerDetail = styled('div')``;

interface ContactViewProps {
  data: Array<Contact>;
}

const ContactView: React.FC<ContactViewProps> = ({ data }) => {
  const { t } = useTranslation('project-setting');

  const item =
    data.length > 0 ? (
      data.map((contact, index) => (
        <ContainerCard key={contact.id}>
          <Text>{`${t('contact-form.title')} ${index + 1}`}</Text>
          <ContainerDetail>
            <Item>
              <Title>{t('contact-form.name')}</Title>
              <Text>{contact.name ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('contact-form.role')}</Title>
              <Text>{contact.role ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('language')}</Title>
              <Text>
                {t(
                  `page.worker-management.dialog.create-worker.emergency-contact.${contact.language}`,
                ) ?? 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title>{t('phone')}</Title>
              <Text>{contact.phone ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('email')}</Title>
              <Text>{contact.email ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('telegram')}</Title>
              <Text>{contact.telegram ?? 'N/A'}</Text>
            </Item>
          </ContainerDetail>
        </ContainerCard>
      ))
    ) : (
      <EmptyData />
    );

  return (
    <Container>
      <ContentTitle>
        {t(
          'page.worker-management.dialog.create-worker.emergency-contact.title-contact',
        )}
      </ContentTitle>
      <ContainerContent>{item}</ContainerContent>
    </Container>
  );
};

export default ContactView;
