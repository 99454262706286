import type { SVGProps } from 'react';

const PM25SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx={12} cy={12} r={1} fill="currentColor" />
    <circle cx={12} cy={6} r={1} fill="currentColor" />
    <circle cx={17} cy={9} r={1} fill="currentColor" />
    <circle cx={7} cy={9} r={1} fill="currentColor" />
    <circle cx={12} cy={18} r={1} fill="currentColor" />
    <circle cx={17} cy={15} r={1} fill="currentColor" />
    <circle cx={7} cy={15} r={1} fill="currentColor" />
    <circle cx={21} cy={12} r={1} fill="currentColor" />
    <circle cx={3} cy={12} r={1} fill="currentColor" />
  </svg>
);
export default PM25SvgIcon;
