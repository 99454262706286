import React, { CSSProperties, useState } from 'react';
import { Avatar, styled } from '@mui/material';

import CheckboxDark from '../../../../components/Checkbox/CheckboxDark';

import DefaultPlant from '../../../../assets/images/default-image/plant_default.png';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 10px;
  transition: .3s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  & .info {
    flex: 1;
    div {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      display: flex;
      align-items: center;
      max-width: 305px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-of-type(2) {
        ${({ theme }) => ({ ...theme.externalTypography.body3 })}
        color: ${({ theme }) => theme.color.secondary.$60};
        &:before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 3px;
          background: ${({ orgColor }) =>
            orgColor ? orgColor : 'transparent'};
          margin-right: 6px;
        }
      }
      & > span{
        margin-left: 5px;
        color: ${({theme})=> theme.color.secondary.$60};
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        max-width: 305px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

const ContainerCheckbox = styled('div')`
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

interface PlantItemProps {
  onClick?: () => void;
  onChange: (value: any, position: boolean) => void;
  name: string;
  orgName: string;
  orgColor?: CSSProperties['color'];
  imageURL: string | null;
  workerId: string;
  disabledPlant?: boolean;
  dasIds: string[];
}

const PlantItem: React.VFC<PlantItemProps> = ({
  onClick,
  onChange,
  name,
  orgName,
  orgColor,
  imageURL,
  workerId,
  disabledPlant,
  dasIds,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  return (
    <>
      <Container orgColor={orgColor} onClick={onClick}>
        <ContainerCheckbox>
          <CheckboxDark
            onChange={(value, checked) => {
              onChange(value, checked);
              setIsSelected(checked);
            }}
            checked={isSelected}
            value={workerId}
            disabled={disabledPlant}
          />
        </ContainerCheckbox>
        <Avatar
          variant="square"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginRight: '10px',
          }}
          src={imageURL ?? ''}
        >
          <Avatar
            src={DefaultPlant}
            alt="plant beeinventor"
            variant="square"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
        </Avatar>
        <div className="info">
          <div title={name}>
            {name}{' '}
            {dasIds.length > 0 && (
              <span title={`(${dasIds.join(', ')})`}>{`(${dasIds.join(
                ', ',
              )})`}</span>
            )}
          </div>
          <div title={orgName}>{orgName}</div>
        </div>
      </Container>
    </>
  );
};

export default PlantItem;
