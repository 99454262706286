import type { SVGProps } from 'react';

const H2SSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4a7 7 0 1 0 3.062 13.297 4.004 4.004 0 0 1 3.235-3.235A7 7 0 0 0 12 4Zm8.395 10.25a9 9 0 1 0-16.79 0 4.002 4.002 0 1 0 5.145 5.145A8.979 8.979 0 0 0 12 20a8.979 8.979 0 0 0 3.25-.605A4.002 4.002 0 0 0 23 18a4.001 4.001 0 0 0-2.605-3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default H2SSvgIcon;
