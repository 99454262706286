import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';

import { Contact, DasIdWillAssign, Group } from '../../../../types';
import { DasConcrete } from '../../../../types/Device';
import { Structure } from '../../../../types/Resource';

import CheckListItem from '../../../../components/CheckListItem';

import CleanDateSvgIcon from '../../../../assets/SvgIcon/CleanDateSvgIcon';
import EditSvgIcon from '../../../../assets/SvgIcon/EditSvgIcon';
import { navigatorStructure as navigator } from '../../../../utils/common';
import ConnectedDeviceView from '../../content/ConnectedDeviceView';
import ContactView from '../../content/ContactView';
import { checkStructureBasicInformationAvalible } from '../../validation/concrete-validation';
import { checkContactAvailable } from '../../validation/environment-validation';
import { hasAssignedDevice } from '../../validation/management-validation';

import BasicInformationContentView from './BasicInformationContent';

const Dialog = styled(MuiDialog)`
  color: white;
  & .MuiDialog-paper {
    width: 676px;
    height: 686px;
    max-width: unset;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px 8px 16px;
  height: 48px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  font-family: 'Montserrat';
  flex: 1;
  color: white;
  padding: 0;
`;

const ContentWrapper = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  @media only screen and (max-width: 600px) {
    min-height: 240px;
  }
`;

const ContainerSelected = styled('div')`
  width: 380px;
  border-radius: 4px;
  padding: 10px 20px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const IconButtonStyled = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: 0.3s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

interface ViewStructureProps extends DialogProps {
  title: string;
  navigatorIndex: number;
  selectedStructure: Structure | undefined;
  contacts: Array<Contact>;
  selectedGroups: Array<Group>;
  onCloseDialog: () => void;
  onClickEdit: () => void;
  onSelectNavigatorIndex: (index: number) => void;
  connectedDevice: Array<DasConcrete>;
}

const ViewStructure: React.FC<ViewStructureProps> = ({
  open,
  title,
  navigatorIndex,
  selectedStructure,
  contacts,
  onCloseDialog,
  onClickEdit,
  onSelectNavigatorIndex,
  connectedDevice,
}) => {
  const [checkedValue, setCheckedValue] = useState(
    new Array(navigator.length).fill(false),
  );
  const { t } = useTranslation('project-setting');

  useEffect(() => {
    const updateChecked = checkedValue.map((item, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedValue(updateChecked);
  }, [navigatorIndex]);

  const listConnected: DasIdWillAssign[] = useMemo(() => {
    return connectedDevice.map((dasconcrete) => ({
      id: dasconcrete.id,
      name: dasconcrete.dasId,
      batteryLevel: 0,
      location: selectedStructure?.bindingDevicesLocation.find(
        (d) => d.dasId === dasconcrete.dasId,
      )?.location,
      dasId: dasconcrete.dasId,
    }));
  }, [connectedDevice, selectedStructure]);

  return (
    <Dialog open={open}>
      <Header>
        <DialogTitle>{title}</DialogTitle>
        <IconButtonStyled
          onClick={() => {
            onCloseDialog();
            onClickEdit();
          }}
        >
          <EditSvgIcon
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          />
        </IconButtonStyled>
        <CleanDateSvgIcon
          sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={onCloseDialog}
        />
      </Header>
      <ContentWrapper>
        <DialogContent>
          <CheckList>
            {navigator.map((item, index) => {
              let checked: boolean = false;
              switch (item.name) {
                case 'group':
                  checked = true;
                  break;
                case 'structure-basic-information':
                  checked =
                    checkStructureBasicInformationAvalible(selectedStructure);
                  break;
                case 'emergency-contact':
                  checked = checkContactAvailable(contacts.map((cp) => cp.id));
                  break;
                case 'connected-device':
                  checked = hasAssignedDevice(listConnected);
                  break;
              }

              return (
                <CheckListItem
                  key={item.id}
                  name={t(`navigator.${item.name}`)}
                  required={item.required}
                  checked={checked}
                  onClick={() => onSelectNavigatorIndex(index)}
                  selected={checkedValue[index]}
                />
              );
            })}
          </CheckList>
        </DialogContent>
        <ContainerSelected>
          {navigator[navigatorIndex].name === 'structure-basic-information' && (
            <BasicInformationContentView data={selectedStructure} />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactView data={contacts} />
          )}
          {navigator[navigatorIndex].name === 'connected-device' && (
            <ConnectedDeviceView data={listConnected} resource="structure" />
          )}
        </ContainerSelected>
      </ContentWrapper>
    </Dialog>
  );
};

export default ViewStructure;
