import React, { useRef } from 'react';
import { styled, Tooltip as MuiTooltip } from '@mui/material';

import { Group } from '../../../types';

import ArrowLeftSvgIcon from '../../../assets/SvgIcon/ArrowLeftSvgIcon';

interface ContainerProps {
  selected?: boolean;
}

const Container = styled('div')<ContainerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 24px 10px 24px 16px;
  border-left: 3px solid transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 80px;
  ${({ theme, selected }) => {
    if (selected) {
      return {
        borderLeft: `3px solid ${theme.color.primary.$100}`,
        background: 'rgba(0, 0, 0, 0.1)',
        '& > div': {
          display: 'flex',
        },
      };
    } else {
      return {
        '& > div': {
          display: 'none',
        },
      };
    }
  }}

  transition: 0.2s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    & > div {
      display: flex;
    }
  }
`;

const ContainerIcon = styled('div')`
  margin-left: auto;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Tooltip = styled(MuiTooltip)``;

interface GroupItemProps {
  data: Group;
  selected?: boolean;
  onClick: () => void;
}

const GroupItem: React.FC<GroupItemProps> = ({ data, selected, onClick }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  return (
    <Container ref={containerRef} selected={selected} onClick={onClick}>
      <Tooltip title={data.remark ? data.remark : ''} placement="right">
        <span>{data.name}</span>
      </Tooltip>
      <ContainerIcon>
        <ArrowLeftSvgIcon />
      </ContainerIcon>
    </Container>
  );
};

export default GroupItem;
