import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

const MoreSvgIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="16" cy="16" r="1.5" fill="white" />
      <circle cx="9.5" cy="16" r="1.5" fill="white" />
      <circle cx="22.5" cy="16" r="1.5" fill="white" />
    </svg>
  );
};

export default MoreSvgIcon;
