import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DasairDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasair-dashboard"*/ '../../pages/dasair-dashboard'
    ),
);

const dasairDashboardRouter = (
  <Route path="dasair-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DasairDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dasairDashboardRouter;
