import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

import Root from './Root';

const AlertReactionForm = lazy(
  () =>
    import(
      /* webpackChunkName: "alert-reaction-form" */ '../../pages/alert-reaction-form'
    ),
);

export const alertReactionFormRouter = (
  <Route
    path="/alert-reaction-form"
    element={
      <Suspense fallback={<Loading />}>
        <Root />
        <Outlet />
      </Suspense>
    }
  >
    <Route path=":id" element={<AlertReactionForm />} />
  </Route>
);
