import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LeaveSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 9H9V23H15"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M19 12L23 16L19 20"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect x="14.5" y="15.5" width="8" height="1" fill="white" stroke="white" />
  </SvgIcon>
);

export default LeaveSvgIcon;
