import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../../components/Loading';

const PermitToWorkDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "permit-to-work-dashboard-page" */ '../../../pages/permit-to-work-dashboard'
    ),
);

const permitToWorkDashboardRouter = (
  <Route path="permit-to-work-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <PermitToWorkDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default permitToWorkDashboardRouter;
