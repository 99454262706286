import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import { VehicleWithOrg } from '../../../../types';

import { useAppSelector } from '../../../../hooks';

import EmptyDataWarning from '../../../../components/EmptyDataWarning';
import Loading from '../../../../components/Loading';
import ProjectPanel from '../../../../components/Panel/ProjectPanel';
import Search from '../../../../components/Search';

import MuiTheme from '../../../../theme';

import VehicleItemCheckbox from './VehicleItemCheckbox';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type ListOfGroupProps = {
  selectedIds: (ids: Array<string>) => void;
  data: Array<VehicleWithOrg>;
  isLoading: boolean;
};

const ListOfGroup: React.FC<ListOfGroupProps> = ({
  selectedIds,
  data,
  isLoading,
}) => {
  const { i18n } = useTranslation();
  const { selectedGroup } = useAppSelector((store) => store.vehicleManagement);
  const [filterText, setFilterText] = useState('');

  const [idsAssign, setIdsAssign] = useState<Array<string>>([]);

  useEffect(() => {
    const initIds: Array<string> = data.map((d) => d.id);
    setIdsAssign(initIds);
  }, [data]);

  const handleOnchange = (value: any, checked: boolean) => {
    const updateValue = value;

    if (checked) {
      setIdsAssign((prev) => prev.filter((id) => id !== updateValue));
    } else {
      setIdsAssign([...idsAssign, updateValue]);
    }
  };

  useEffect(() => {
    selectedIds(idsAssign);
  }, [idsAssign]);

  const items = data
    .filter((vehicle) => vehicle.name.indexOf(filterText) !== -1)
    .sort((a, b) => {
      if (a.groupIds.length === 1 && b.groupIds.length > 1) {
        return 1;
      } else if (a.groupIds.length > 1 && b.groupIds.length === 1) {
        return -1;
      }
      return a.name.localeCompare(b.name, i18n.language);
    })
    .map((vehicle) => (
      <VehicleItemCheckbox
        key={`Vehicle-item${vehicle.id}`}
        name={vehicle.name}
        orgName={vehicle.orgDetail?.name ?? ''}
        dasIds={vehicle.bindingDastrackVs}
        orgColor={vehicle.orgDetail?.color}
        imageURL={vehicle.imageURL}
        idSelected={vehicle.id}
        onChange={handleOnchange}
        disabled={vehicle.groupIds.length === 1 ? true : false}
      />
    ));

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={selectedGroup?.name ?? 'N/A'}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer data-cy="container-vehicle-list-group">
        {isLoading ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
    </ProjectPanel>
  );
};

export default ListOfGroup;
