import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { useAppSelector } from '../../../../hooks';

import ContactListComp from '../../../../components/ContactListComp';
import EmptyData from '../../../../components/EmptyData';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  margin-bottom: 8px;
`;

const Content = styled('div')`
  border-radius: 4px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.2);
`;

interface ContactContentProps {
  onChange: (value: any) => void;
  listContact: Array<string>;
}

const ContactEdit: React.FC<ContactContentProps> = ({
  onChange,
  listContact,
}) => {
  const { t } = useTranslation('project-setting');

  const projectContacts = useAppSelector(
    (store) => store.projects.projectContact,
  );

  const items = projectContacts.map((contact) => (
    <ContactListComp
      key={`Contact-List${contact.id}`}
      contact={contact}
      checked={listContact.includes(contact.id)}
      disabled={listContact.length > 2 && !listContact.includes(contact.id)}
      onChange={onChange}
    />
  ));

  return (
    <Container>
      <ContainerContent>
        <ContentTitle>{t('contact')}</ContentTitle>
        <Content>{items.length > 0 ? items : <EmptyData />}</Content>
      </ContainerContent>
    </Container>
  );
};

export default ContactEdit;
