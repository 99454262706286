import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface TemperatureSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const TemperatureSvgIcon = ({ ...props }: TemperatureSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <mask id="path-1-inside-1_119_2646" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8C17.2386 8 15 10.2386 15 13V21.101C13.7628 22.3636 13 24.0927 13 26C13 29.866 16.134 33 20 33C23.866 33 27 29.866 27 26C27 24.0927 26.2372 22.3636 25 21.101V13C25 10.2386 22.7614 8 20 8Z"
      />
    </mask>
    <path
      d="M15 21.101L16.4285 22.5008L17 21.9176V21.101H15ZM25 21.101H23V21.9176L23.5715 22.5008L25 21.101ZM17 13C17 11.3431 18.3431 10 20 10V6C16.134 6 13 9.13401 13 13H17ZM17 21.101V13H13V21.101H17ZM15 26C15 24.637 15.5428 23.4047 16.4285 22.5008L13.5715 19.7012C11.9829 21.3224 11 23.5484 11 26H15ZM20 31C17.2386 31 15 28.7614 15 26H11C11 30.9706 15.0294 35 20 35V31ZM25 26C25 28.7614 22.7614 31 20 31V35C24.9706 35 29 30.9706 29 26H25ZM23.5715 22.5008C24.4572 23.4047 25 24.637 25 26H29C29 23.5484 28.0171 21.3224 26.4285 19.7012L23.5715 22.5008ZM23 13V21.101H27V13H23ZM20 10C21.6569 10 23 11.3431 23 13H27C27 9.13401 23.866 6 20 6V10Z"
      fill="currentColor"
      mask="url(#path-1-inside-1_119_2646)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 15C19.4477 15 19 15.4477 19 16V23.1707C17.8348 23.5825 17 24.6938 17 26C17 27.6569 18.3431 29 20 29C21.6569 29 23 27.6569 23 26C23 24.6938 22.1652 23.5825 21 23.1707V16C21 15.4477 20.5523 15 20 15Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TemperatureSvgIcon;
