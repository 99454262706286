import { useEffect } from 'react';
import { addYears } from 'date-fns';
import SocketIO from 'socket.io-client';

import { DeviceType } from '../types';

import { getAreaAlerts, getLatestDeviationAlerts } from '../apis/AreaApi';
import { getDasairAlerts } from '../apis/DasAirApi';
import { getDascasAlerts } from '../apis/DascasApi';
import { getDasConcreteAlerts } from '../apis/DasconcreteApi';
import { getDaslockAlerts } from '../apis/DaslockApi';
import { getDaspowerAlerts } from '../apis/DaspowerApi';
import { getDastempAlerts } from '../apis/DastempApi';
import { getDaswaterLAlerts } from '../apis/DaswaterApi';
import {
  getAssetAlerts,
  getDasgasAlerts,
  getDasloopAlerts,
  getDastrackAlerts,
} from '../apis/ProjectApi';
import { getResourceAlerts } from '../apis/ResourceApi';

import PubSub from '../utils/PubSub';

export const alertHandler = async (
  currentProjectId,
  type: DeviceType | 'area' | 'deviation' | 'dascas_g' | 'asset' | 'ai',
  data: {
    id: string;
    projectId: string;
    timestamp: string;
  },
) => {
  if (currentProjectId === data.projectId) {
    let res;

    switch (type) {
      case 'dasloop': {
        res = await getDasloopAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'dastrack': {
        res = await getDastrackAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'dasgas': {
        res = await getDasgasAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'daspower': {
        res = await getDaspowerAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'dastemp': {
        res = await getDastempAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'dasair': {
        res = await getDasairAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'daswater': {
        res = await getDaswaterLAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'area': {
        res = await getAreaAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'deviation': {
        res = await getLatestDeviationAlerts(data.projectId, {
          vehicleId: (data as any).deviation.vehicleId,
          fromDate: addYears(new Date(), -1).toISOString(),
          toDate: new Date().toISOString(),
          order: 'desc',
        });
        break;
      }
      case 'daslock': {
        res = await getDaslockAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'dascas_g': {
        res = await getDascasAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'dasconcrete': {
        res = await getDasConcreteAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'asset': {
        res = await getAssetAlerts(data.projectId, {
          id: data.id,
        });
        break;
      }
      case 'ai': {
        res = await getResourceAlerts({
          projectId: data.projectId,
          resourceType: 'ai',
          params: {
            id: data.id,
            limit: 1,
          },
        });
      }
    }

    if (res?.data.data.length === 1) {
      return {
        type: type,
        data: res.data.data[0],
        pipes: res.data?.pipes,
      };
    }

    return null;
  }

  return null;
};

const useWebsocket = (accessToken: string | null, pubsub: PubSub | null) => {
  useEffect(() => {
    if (accessToken && pubsub) {
      const socket = SocketIO(import.meta.env.VITE_WEBSOCKET_URL ?? '', {
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      socket.connect();

      socket.onAny((eventName, data) => {
        switch (eventName) {
          case 'dasloop-protocol-alert':
          case 'das-endpoint-alert':
            pubsub.publish('alert', { ...data, type: data.endpointType });
            break;
          case 'deviation-alert':
            pubsub.publish('alert', { ...data, type: 'deviation' });
            break;
          case 'area-alert':
            pubsub.publish('alert', { ...data, type: 'area' });
            break;
          case 'smartlock-protocol-alert':
            pubsub.publish('alert', { ...data, type: 'daslock' });
            break;
          case 'resource-ai-alert':
            pubsub.publish('alert', { ...data, type: 'ai' });
            break;
          default:
            pubsub.publish(eventName, data);
        }
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [accessToken, pubsub]);
};

export default useWebsocket;
