import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasairSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4339 13.3032C25.1688 13.0016 25.9768 12.9229 26.7563 13.0765C27.5359 13.2302 28.2532 13.6096 28.817 14.1683C29.381 14.7271 29.7661 15.4402 29.9223 16.2179C30.0784 16.9957 29.9981 17.8017 29.6921 18.5337C29.3861 19.2657 28.8686 19.8898 28.2064 20.3282C27.5443 20.7666 26.7668 21 25.9722 21H10C9.44772 21 9 20.5523 9 20C9 19.4477 9.44772 19 10 19H25.9722C26.3753 19 26.7686 18.8815 27.1023 18.6606C27.4359 18.4397 27.6945 18.1268 27.8469 17.7623C27.9992 17.398 28.0389 16.9976 27.9614 16.6115C27.8839 16.2254 27.6923 15.8693 27.4093 15.589C27.1263 15.3086 26.7647 15.1166 26.3696 15.0388C25.9744 14.9609 25.5649 15.0009 25.1933 15.1535C24.8217 15.306 24.5055 15.5636 24.2838 15.8924C24.0622 16.2211 23.9445 16.6065 23.9445 17C23.9445 17.5523 23.4967 18 22.9445 18C22.3922 18 21.9445 17.5523 21.9445 17C21.9445 16.2068 22.1819 15.4322 22.6256 14.7742C23.0693 14.1162 23.699 13.6049 24.4339 13.3032Z"
          fill="#5296D5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4138 9.30255C16.1508 9.0016 16.9611 8.9231 17.7426 9.07633C18.5242 9.22958 19.2442 9.60816 19.8106 10.1665C20.3771 10.7249 20.7646 11.4382 20.9218 12.2169C21.0789 12.9957 20.9981 13.8028 20.6902 14.5354C20.3824 15.2679 19.8621 15.8917 19.1974 16.3296C18.5329 16.7672 17.7531 17 16.9565 17H10C9.44772 17 9 16.5523 9 16C9 15.4477 9.44772 15 10 15H16.9565C17.3639 15 17.7609 14.8809 18.0973 14.6593C18.4336 14.4378 18.6935 14.1245 18.8464 13.7606C18.9992 13.3969 19.039 12.9976 18.9613 12.6125C18.8836 12.2273 18.6913 11.8715 18.4066 11.5908C18.1217 11.3101 17.757 11.1172 17.3578 11.039C16.9586 10.9607 16.5449 11.001 16.1698 11.1542C15.7948 11.3073 15.4766 11.5656 15.2537 11.8944C15.031 12.2229 14.9131 12.6077 14.9131 13C14.9131 13.5523 14.4653 14 13.9131 14C13.3608 14 12.9131 13.5523 12.9131 13C12.9131 12.2057 13.1521 11.4303 13.5982 10.7722C14.0442 10.1143 14.6765 9.60357 15.4138 9.30255Z"
          fill="#5296D5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 24C9 23.4477 9.44772 23 10 23H16.9565C17.7531 23 18.5329 23.2328 19.1974 23.6704C19.8621 24.1083 20.3824 24.7321 20.6902 25.4646C20.9981 26.1972 21.0789 27.0043 20.9218 27.7831C20.7646 28.5618 20.3771 29.2751 19.8106 29.8335C19.2442 30.3918 18.5242 30.7704 17.7426 30.9237C16.9611 31.0769 16.1508 30.9984 15.4138 30.6974C14.6765 30.3964 14.0442 29.8857 13.5982 29.2278C13.1521 28.5697 12.9131 27.7943 12.9131 27C12.9131 26.4477 13.3608 26 13.9131 26C14.4653 26 14.9131 26.4477 14.9131 27C14.9131 27.3923 15.031 27.7771 15.2537 28.1056C15.4766 28.4344 15.7948 28.6927 16.1698 28.8458C16.5449 28.999 16.9586 29.0393 17.3578 28.961C17.757 28.8828 18.1217 28.6899 18.4066 28.4092C18.6913 28.1285 18.8836 27.7727 18.9613 27.3875C19.039 27.0024 18.9992 26.6031 18.8464 26.2394C18.6935 25.8755 18.4336 25.5622 18.0973 25.3407C17.7609 25.1191 17.3639 25 16.9565 25H10C9.44772 25 9 24.5523 9 24Z"
          fill="#5296D5"
        />
      </svg>
    </SvgIcon>
  );
};

export default DasairSvgColorMedium;
