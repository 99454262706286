import type { SVGProps } from 'react';

const NH3SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx={12} cy={12} r={2.08} stroke="currentColor" strokeWidth={2} />
    <circle cx={15.5} cy={3.5} r={1.5} stroke="currentColor" strokeWidth={2} />
    <circle cx={20.5} cy={20.5} r={1.5} stroke="currentColor" strokeWidth={2} />
    <circle cx={3.5} cy={17.5} r={1.5} stroke="currentColor" strokeWidth={2} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m14.5 5-1.62 4.8M5 17l5.24-3.68m3.08.44 5.72 5.28"
    />
  </svg>
);
export default NH3SvgIcon;
