import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const LogPageComponent = lazy(
  () => import(/* webpackChunkName: "log-page" */ '../../pages/log'),
);

const logRouter = (
  <Route
    path="log/:projectId"
    element={
      <Suspense fallback={<Loading />}>
        <LogPageComponent />
      </Suspense>
    }
  />
);

export default logRouter;
