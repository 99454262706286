import React, { useEffect, useMemo, useRef, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button as MuiButton,
  ClickAwayListener,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { DasIdEquipmentWillAssign, EquipmentGroup, Org } from '../../../types';
import { Equipment } from '../../../types/Resource';

import {
  getEquipmentById,
  updateEquipmentGroup,
} from '../../../apis/DaspowerApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setConnectedDevice,
  setEquipmentContacts,
  setEquipmentGroupProject,
  setEquipmentGroupSelected,
  setEquipmentSelected,
  setGroupEquipmentSelected,
} from '../../../slices/pages/project-setting/equipmentManagementPageSlice';

import EmptyDataWarning from '../../../components/EmptyDataWarning';
import Loading from '../../../components/Loading';
import ProjectPanel from '../../../components/Panel/ProjectPanel';
import Search from '../../../components/Search';

import AddEquipmentSvgIcon from '../../../assets/SvgIcon/AddEquipmentSvgIcon';
import DeviceBoxSvgIcon from '../../../assets/SvgIcon/DeviceBoxSvgIcon';
import MoreSvgIcon from '../../../assets/SvgIcon/MoreSvgIcon';
import RenameSvgIcon from '../../../assets/SvgIcon/RenameSvgIcon';
import MuiTheme from '../../../theme';
import { getBatteryLevelEquipment } from '../../../utils/getBatteryLevelEquipment';
import RenameDialog from '../management/group-dialog/RenameDialog';

import EquipmentItem from './EquipmentItem';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

const MoreContainer = styled('div')`
  cursor: pointer;
`;

const ContainerPopper = styled(Box)`
  width: 10.5rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  overflow: hidden;
`;

interface ContainerButtonPopperProps {
  disabled?: boolean;
}

const ContainerButtonPopper = styled('div')<ContainerButtonPopperProps>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.externalColor.dark.$20};
    color: ${({ theme }) => theme.color.secondary.$40};
  }
`;

const TextPopper = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: 'inherit',
  marginLeft: '2px',
  transition: '0.2s',
}));

const Button = styled(MuiButton)`
  &.Mui-disabled {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.secondary.$60};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  }
`;

interface EquipmentGroupEquipmentListProps {
  equipmentGroupSelected: EquipmentGroup | undefined;
  data: Array<Equipment & { orgDetail: Org | undefined }>;
  equipmentGroupProject: Array<EquipmentGroup>;
  refetchGroup: () => void;
  isLoadingEquipmentOfAProject: boolean;
}

const EquipmentGroupEquipmentList: VFC<EquipmentGroupEquipmentListProps> = ({
  equipmentGroupSelected,
  equipmentGroupProject,
  refetchGroup,
  data,
  isLoadingEquipmentOfAProject,
}) => {
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const { t } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const anchorRef = useRef<HTMLDivElement>(null);
  const { projectId } = useParams();

  const [open, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedEquipment, setSelectedEquipment] = useState<
    Equipment & { orgDetail: Org | undefined }
  >();

  const [showUpdateEquipmentGroup, setShowUpdateEquipmentGroup] =
    useState(false);

  const { role, policies } = useAppSelector((store) => store.projects);
  const { daspowers, dastemps, daslocks } = useAppSelector(
    (store) => store.equipmentManagement,
  );

  const { projectContact } = useAppSelector((store) => store.projects);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const [searchParams] = useSearchParams();
  const equipmentIdSelected = searchParams.get('equipmentId');
  const groupIdSelected = searchParams.get('groupId');

  useEffect(() => {
    if (
      equipmentIdSelected &&
      groupIdSelected &&
      data.length > 0 &&
      equipmentIdSelected !== selectedEquipment?.id
    ) {
      const equipment = data.find((item) => item.id === equipmentIdSelected);
      setSelectedEquipment(Object.assign({}, equipment));
    }
  }, [equipmentIdSelected, groupIdSelected, data, selectedEquipment]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsOpen(false);
  };

  const { data: dataEquipmentById } = useQuery(
    ['get-equipment-by-id', selectedEquipment?.id],
    async () =>
      getEquipmentById(projectId as string, selectedEquipment?.id ?? ''),
    {
      enabled: !!selectedEquipment && !!projectId,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const selectedEquipmentById: (Equipment & { orgDetail: Org }) | undefined =
    useMemo(() => {
      const EmptyOrg: Org = {
        id: '',
        ownerId: '',
        name: '',
        country: '',
        taxId: '',
        color: '',
        displayName: '',
        type: 'customer',
        imageURL: null,
        status: 'ACTIVE',
      };
      if (dataEquipmentById) {
        return {
          ...dataEquipmentById.data.data,
          orgDetail: orgMap[dataEquipmentById.data.data.orgId] ?? EmptyOrg,
        };
      }
      return;
    }, [dataEquipmentById, orgMap]);

  const handleRenameGroup = () => {
    setShowUpdateEquipmentGroup(true);
  };

  const handleEditGroup = () => {
    navigate(`edit-equipment-group?groupId=${groupIdSelected}`);
  };
  const dispatch = useAppDispatch();

  const groupEquipmentSelected = useMemo(() => {
    return equipmentGroupProject.filter((group) =>
      selectedEquipment ? selectedEquipment.groupIds.includes(group.id) : [],
    );
  }, [selectedEquipment]);

  const connectedDevice: Array<DasIdEquipmentWillAssign> = useMemo(() => {
    if (daspowers.length > 0 || dastemps.length > 0) {
      const daspowerConnected: Array<DasIdEquipmentWillAssign> = daspowers
        .filter((daspower) =>
          selectedEquipment?.bindingDaspowers.includes(daspower.dasId),
        )
        .map((d) => ({
          id: d.id,
          name: d.dasId,
          batteryLevel:
            d.shadow.dataPoint &&
            d.shadow.dataPoint.batteryVoltage &&
            d.shadow.dataPoint.batteryVoltage.value
              ? getBatteryLevelEquipment(
                  d.shadow.dataPoint.batteryVoltage.value,
                )
              : 0,
          type: 'daspower',
        }));

      const dastemConnected: Array<DasIdEquipmentWillAssign> = dastemps
        .filter((dastemp) =>
          selectedEquipment?.bindingDastemps.includes(dastemp.dasId),
        )
        .map((dastemp) => ({
          id: dastemp.id,
          name: dastemp.dasId,
          type: 'dastemp',
          batteryLevel:
            dastemp.shadow.dataPoint &&
            dastemp.shadow.dataPoint.batteryVoltage &&
            dastemp.shadow.dataPoint.batteryVoltage.value
              ? dastemp.shadow.dataPoint.batteryVoltage.value
              : 0,
        }));

      const daslockConnected: Array<DasIdEquipmentWillAssign> = daslocks
        .filter((daslock) =>
          selectedEquipment?.bindingDaslocks.includes(daslock.dasId),
        )
        .map((daslock) => ({
          id: daslock.id,
          name: daslock.dasId,
          type: 'daslock',
          batteryLevel: daslock.shadow.dataPoint?.batteryLevel?.value
            ? daslock.shadow.dataPoint.batteryLevel.value
            : 0,
        }));

      return daspowerConnected.concat(dastemConnected.concat(daslockConnected));
    }
    return [];
  }, [selectedEquipment, daspowers, dastemps, daslocks]);

  useEffect(() => {
    if (projectContact.length > 0 && selectedEquipment) {
      const equipmentContacts = projectContact.filter((contact) =>
        contact.bindingProjectReference.some(
          (ref) =>
            ref.type === 'equipment' && ref.refId === selectedEquipment.id,
        ),
      );

      dispatch(setEquipmentContacts(equipmentContacts));
    }
  }, [projectContact, selectedEquipment]);

  const editableEquipment = () => {
    const isAuthorizedWriteOneEquipmentGroup = policies.some(
      (policy) => policy.name === 'WriteOneEquipmentGroup',
    );
    const isGroupAuthorized = policies
      .filter((policy) => policy.name === 'WriteOneEquipmentGroup')
      .some((policy) => policy.groupId === equipmentGroupSelected?.id);

    if (role === 'owner' || role === 'admin') {
      return true;
    } else if (isAuthorizedWriteOneEquipmentGroup && isGroupAuthorized) {
      return true;
    } else {
      return false;
    }
  };

  const items = data
    .filter((d) => d.name.indexOf(filterText) !== -1)
    .map((d) => (
      <EquipmentItem
        key={`Equipment-${d.id}`}
        equipment={d}
        onClick={() => {
          const equipment = Object.assign({}, d);
          setSelectedEquipment(equipment);
          navigate(
            `view?groupId=${equipmentGroupSelected?.id}&equipmentId=${equipment.id}`,
          );
        }}
        disabled={!editableEquipment()}
        isInList={false}
      />
    ));

  useEffect(() => {
    if (equipmentGroupProject) {
      dispatch(setEquipmentGroupProject(equipmentGroupProject));
    }
  }, [equipmentGroupProject]);

  useEffect(() => {
    dispatch(setEquipmentGroupSelected(equipmentGroupSelected));
  }, [equipmentGroupSelected]);

  useEffect(() => {
    dispatch(setGroupEquipmentSelected(groupEquipmentSelected));
  }, [groupEquipmentSelected]);

  useEffect(() => {
    dispatch(setEquipmentSelected(selectedEquipmentById));
  }, [selectedEquipmentById]);

  useEffect(() => {
    dispatch(setConnectedDevice(connectedDevice));
  }, [connectedDevice]);

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 87px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={equipmentGroupSelected?.name}
      subtitle={t('project-setting:page.devices.subtitle.equipment', {
        count: items.length,
      })}
      rightNode={
        <>
          <MoreContainer
            onClick={handleToggle}
            ref={anchorRef}
            data-cy="btn-more-edit-equipment-list"
          >
            <MoreSvgIcon />
          </MoreContainer>
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="right-end"
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [45, 0],
                  },
                },
              ]}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <ContainerPopper>
                {/* <ContainerButtonPopper onClick={handleDeleteGroup}>
                  <DeleteSvgIcon />
                  <TextPopper>{t('delete-group')}</TextPopper>
                </ContainerButtonPopper> */}
                <ContainerButtonPopper
                  onClick={handleRenameGroup}
                  disabled={!editableEquipment()}
                  data-cy="btn-rename-group-equipment"
                >
                  <RenameSvgIcon />
                  <TextPopper>{t('rename-group')}</TextPopper>
                </ContainerButtonPopper>
                <ContainerButtonPopper
                  onClick={handleEditGroup}
                  disabled={!editableEquipment()}
                  data-cy="btn-edit-equipment-list"
                >
                  <DeviceBoxSvgIcon />
                  <TextPopper>{t('edit-equipment-list')}</TextPopper>
                </ContainerButtonPopper>
              </ContainerPopper>
            </Popper>
          </ClickAwayListener>
        </>
      }
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
        <Button
          sx={{
            width: '100%',
            color: MuiTheme.color.secondary.$60,
            marginBottom: '10px',
            borderColor: MuiTheme.color.secondary.$60,
            '&:hover': {
              color: 'white',
              borderColor: 'white',
            },
          }}
          variant="outlined"
          startIcon={
            <AddEquipmentSvgIcon sx={{ width: '32px', height: '32px' }} />
          }
          onClick={() =>
            navigate(`create?groupId=${equipmentGroupSelected?.id}`)
          }
          disabled={!editableEquipment()}
          data-cy="btn-add-equipment-in-group"
        >
          {t(
            'page.equipment-management.equipment-group-equipment-list.add-equipment',
          )}
        </Button>
      </div>
      <ListContainer>
        {isLoadingEquipmentOfAProject ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>

      {showUpdateEquipmentGroup && (
        <RenameDialog
          open
          title={t(
            'page.equipment-management.button-more.rename-equipment-group',
          )}
          submitText={t('OK')}
          name={equipmentGroupSelected?.name ?? ''}
          remark={equipmentGroupSelected?.remark ?? ''}
          groupId={equipmentGroupSelected?.id ?? ''}
          operationApi={updateEquipmentGroup}
          onClose={() => setShowUpdateEquipmentGroup(false)}
          onSuccess={() => {
            setShowUpdateEquipmentGroup(false);
            refetchGroup();
          }}
        />
      )}
    </ProjectPanel>
  );
};

export default EquipmentGroupEquipmentList;
