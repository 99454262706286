import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AOA,
  AoATag,
  AOAUwb,
  Beacon,
  CCTV,
  DataDevice,
  SelectedDevice,
} from '../../../types';
import {
  Dasair,
  Dasloop,
  Daspower,
  Dastemp,
  Dastrack,
  DastrackV,
} from '../../../types/Device';

type MyDeviceListState = {
  projectSelected: string[];
  dataDeviceSelected: DataDevice[];
  deviceSelected: SelectedDevice;
  projectWillAssign: {
    id: string;
    name: string;
  };
  isSuccessToAssigned: boolean;
  dastracks: Dastrack[];
  dasloops: Dasloop[];
  daspowers: Daspower[];
  dastemps: Dastemp[];
  cctvItem: CCTV;
  isSuccessToEdit: boolean;
  aoaItem: AOA;
  aoaUwbItem: AOAUwb;
  aoaTagItem: AoATag;
  dastrackVItem: DastrackV | null;
  dasairItem: Dasair;
  beaconItem: Beacon;
  aoas: AOA[];
  aoaType: 'aoa-ble' | 'aoa-uwb' | 'none';
  beacons: Beacon[];
};

const initialState: MyDeviceListState = {
  projectSelected: [],
  dataDeviceSelected: [],
  deviceSelected: 'dasloop',
  projectWillAssign: {
    id: '',
    name: '',
  },
  isSuccessToAssigned: false,
  dastracks: [
    {
      id: '',
      owner: null,
      ownerUserId: '',
      dasId: '',
      projectId: '',
      plantId: null,
      shadow: {
        dataPoint: {
          satellites: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          beacons: {
            timestamp: '',
            value: [
              {
                id: 0,
                rssi: 0,
              },
            ],
            stale: true,
          },
          strength: {
            timestamp: '',
            value: 5,
            stale: false,
          },
          wifi: {
            timestamp: '',
            value: null,
            stale: false,
          },
          batteryLevel: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          altitude: {
            timestamp: '',
            value: null,
            stale: false,
          },
          bootResult: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          deviceStatus: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          timestamp: {
            timestamp: '',
            value: '',
            stale: false,
          },
          firmwareVersion: {
            timestamp: '',
            value: '',
            stale: true,
          },
          debugData: {
            timestamp: '',
            value: {
              batteryCurrent: 0,
              stationaryTime: 0,
              networkDisconnectCount: 0,
              moduleRecoverCount: 0,
              serverDisconnectCount: 0,
            },
            stale: false,
          },
          bootCount: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          reportInterval: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          fix: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          lpwan: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          geoPoint: {
            timestamp: '',
            value: {
              lat: 0,
              lon: 0,
            },
            stale: false,
          },
          speed: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          ambientTemperature: {
            timestamp: '',
            value: null,
            stale: false,
          },
          shutdown: {
            timestamp: '',
            value: 0,
            stale: false,
          },
        },
      },
      coordinates: {
        alt: 0,
        lat: 0,
        lon: 0,
      },
      coordinatesSource: 'gps',
      lastCoordinatesUpdatedAt: '',
      createdAt: '',
    },
  ],
  dasloops: [
    {
      id: '',
      owner: null,
      ownerUserId: '',
      dasId: '',
      projectId: '',
      workerId: '',
      shadow: {
        dataPoint: {
          geoPoint: {
            timestamp: '',
            value: {
              lat: 0,
              lon: 0,
            },
            stale: false,
          },
          batteryLevel: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          heartRate: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          fix: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          standstillTokenExpiredDate: {
            timestamp: null,
            value: null,
            stale: true,
          },
          standstillTokenExpiredRemainingCount: {
            timestamp: null,
            value: null,
            stale: true,
          },
          speed: {
            timestamp: '',
            value: 255,
            stale: false,
          },
          lpwan: {
            timestamp: '',
            value: 20,
            stale: false,
          },
          debugData: {
            timestamp: '',
            value: {
              wearingTime: 0,
              batteryCurrent: 0,
              stationaryTime: 0,
              networkDisconnectCount: 0,
              moduleRecoverCount: 0,
              serverDisconnectCount: 0,
            },
            stale: false,
          },
          firmwareVersion: {
            timestamp: '',
            value: '',
            stale: true,
          },
          altitude: {
            timestamp: '',
            value: null,
            stale: false,
          },
          ambientTemperature: {
            timestamp: null,
            value: null,
            stale: true,
          },
          strength: {
            timestamp: '',
            value: 17,
            stale: false,
          },
          wifi: {
            timestamp: '',
            value: null,
            stale: false,
          },
          reportInterval: {
            timestamp: '',
            value: 10,
            stale: true,
          },
          standstillTokenActivatedDuration: {
            timestamp: null,
            value: null,
            stale: true,
          },
          satellites: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          bootCount: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          bodyTemperature: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          timestamp: {
            timestamp: '',
            value: '',
            stale: false,
          },
          beacons: {
            timestamp: '',
            value: [
              {
                id: 0,
                rssi: 0,
              },
            ],
            stale: true,
          },
          standstillTokenActivatedDate: {
            timestamp: null,
            value: null,
            stale: true,
          },
          deviceStatus: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          standstillTokenActivatedRemainingCount: {
            timestamp: null,
            value: null,
            stale: true,
          },
          heartRateQuility: {
            timestamp: '',
            value: 29,
            stale: true,
          },
          bootResult: {
            timestamp: '',
            value: 20482,
            stale: true,
          },
          shutdown: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          humidity: {
            timestamp: null,
            value: null,
            stale: true,
          },
          pressure: {
            timestamp: null,
            value: null,
            stale: true,
          },
        },
      },
      dasgasIds: [],
      coordinates: {
        alt: 0,
        lat: 0,
        lon: 0,
      },
      coordinatesSource: 'gps',
      lastCoordinatesUpdatedAt: '',
      createdAt: '',
      isOnline: false,
      isWearing: false,
    },
  ],
  daspowers: [
    {
      id: '',
      owner: null,
      ownerUserId: '',
      dasId: '',
      projectId: '',
      equipmentId: '',
      shadow: {
        dataPoint: {
          current: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          batteryVoltage: {
            timestamp: '',
            value: 0,
            stale: false,
          },
        },
      },
      createdAt: '',
    },
  ],
  cctvItem: {
    id: '',
    name: '',
    remark: '',
    mode: 'normal',
    coordinates: null,
    projectId: '',
    rtmpEndpoint: '',
    hlsEndpoint: '',
    createdAt: '',
    updatedAt: '',
  },
  isSuccessToEdit: false,
  aoaItem: {
    id: '',
    name: '',
    dasId: '',
    devEUI: '',
    coordinates: null,
    projectId: '',
    ownerUserId: '',
    rotation: 0,
    envFactor: 0,
    createdAt: '',
    updatedAt: '',
  },
  aoaUwbItem: {
    id: '',
    name: '',
    dasId: '',
    devEUI: '',
    coordinates: null,
    projectId: '',
    ownerUserId: '',
    rotation: 0,
    createdAt: '',
    updatedAt: '',
  },
  aoaTagItem: {
    id: '',
    name: '',
    dasId: '',
    projectId: '-',
    ownerUserId: '',
    coordinates: { lon: 0, lat: 0, alt: 0 },
    coordinatesSource: 'ble_aoa',
    lastCoordinatesUpdatedAt: null,
    createdAt: '',
  },
  dastrackVItem: null,
  dasairItem: {
    id: '',
    dasId: '',
    owner: null,
    ownerUserId: '',
    projectId: '',
    shadow: {
      dataPoint: {
        co: {
          timestamp: null,
          value: null,
          stale: true,
        },
        o2: {
          timestamp: null,
          value: null,
          stale: true,
        },
        co2: {
          timestamp: null,
          value: null,
          stale: true,
        },
        nh3: {
          timestamp: null,
          value: null,
          stale: true,
        },
        ch2o: {
          timestamp: null,
          value: null,
          stale: true,
        },
        pm10: {
          timestamp: null,
          value: null,
          stale: true,
        },
        voc: {
          timestamp: null,
          value: null,
          stale: true,
        },
        pm2_5: {
          timestamp: null,
          value: null,
          stale: true,
        },
        h2s: {
          timestamp: null,
          value: null,
          stale: true,
        },
        ch4: {
          timestamp: null,
          value: null,
          stale: true,
        },
        humidity: {
          timestamp: null,
          value: null,
          stale: true,
        },
        temperature: {
          timestamp: null,
          value: null,
          stale: true,
        },
      },
    },
    coordinates: null,
    coordinatesSource: null,
    createdAt: '',
  },
  beaconItem: {
    id: '',
    name: '',
    dasId: '',
    beaconId: '',
    projectId: '',
    coordinates: null,
    ownerUserId: '',
    createdAt: '',
    updatedAt: '',
  },
  dastemps: [],
  aoas: [],
  beacons: [],
  aoaType: 'none',
};

export const myDevicePageSlice = createSlice({
  name: 'devicePage',
  initialState,
  reducers: {
    setDataDeviceSelected: (
      state,
      action: PayloadAction<MyDeviceListState['dataDeviceSelected']>,
    ) => {
      return { ...state, dataDeviceSelected: action.payload };
    },
    updateDeviceSelected: (
      state,
      action: PayloadAction<MyDeviceListState['deviceSelected']>,
    ) => {
      return { ...state, deviceSelected: action.payload };
    },
    updateProjectWillAssign: (
      state,
      action: PayloadAction<MyDeviceListState['projectWillAssign']>,
    ) => {
      return { ...state, projectWillAssign: action.payload };
    },
    updateIsSuccessToAssign: (
      state,
      action: PayloadAction<MyDeviceListState['isSuccessToAssigned']>,
    ) => {
      return { ...state, isSuccessToAssigned: action.payload };
    },
    updateIsSuccessToEdit: (
      state,
      action: PayloadAction<MyDeviceListState['isSuccessToEdit']>,
    ) => {
      return { ...state, isSuccessToEdit: action.payload };
    },
    setDastracks: (
      state,
      action: PayloadAction<MyDeviceListState['dastracks']>,
    ) => {
      return { ...state, dastracks: action.payload };
    },
    setDasloops: (
      state,
      action: PayloadAction<MyDeviceListState['dasloops']>,
    ) => {
      return { ...state, dasloops: action.payload };
    },
    setDaspowers: (
      state,
      action: PayloadAction<MyDeviceListState['daspowers']>,
    ) => {
      return { ...state, daspowers: action.payload };
    },
    setDastemps: (
      state,
      action: PayloadAction<MyDeviceListState['dastemps']>,
    ) => {
      return { ...state, dastemps: action.payload };
    },
    setDasairData: (
      state,
      action: PayloadAction<MyDeviceListState['dasairItem']>,
    ) => {
      return { ...state, dasairItem: action.payload };
    },
    setCCTVonProject: (
      state,
      action: PayloadAction<MyDeviceListState['cctvItem']>,
    ) => {
      return { ...state, cctvItem: action.payload };
    },
    setAOAData: (
      state,
      action: PayloadAction<MyDeviceListState['aoaItem']>,
    ) => {
      return { ...state, aoaItem: action.payload };
    },
    setAOAUwbData: (
      state,
      action: PayloadAction<MyDeviceListState['aoaUwbItem']>,
    ) => {
      return { ...state, aoaUwbItem: action.payload };
    },
    setAOATagData: (
      state,
      action: PayloadAction<MyDeviceListState['aoaTagItem']>,
    ) => {
      return { ...state, aoaTagItem: action.payload };
    },
    setDastrackVData: (
      state,
      action: PayloadAction<MyDeviceListState['dastrackVItem']>,
    ) => {
      return { ...state, dastrackVItem: action.payload };
    },
    setAOAs: (state, action: PayloadAction<MyDeviceListState['aoas']>) => {
      return { ...state, aoas: action.payload };
    },
    setBeaconData: (
      state,
      action: PayloadAction<MyDeviceListState['beaconItem']>,
    ) => {
      return { ...state, beaconItem: action.payload };
    },
    setBeacons: (
      state,
      action: PayloadAction<MyDeviceListState['beacons']>,
    ) => {
      return { ...state, beacons: action.payload };
    },
    setAOAType: (
      state,
      action: PayloadAction<MyDeviceListState['aoaType']>,
    ) => {
      return { ...state, aoaType: action.payload };
    },
  },
});

export const {
  setDataDeviceSelected,
  updateDeviceSelected,
  updateProjectWillAssign,
  updateIsSuccessToAssign,
  updateIsSuccessToEdit,
  setDastracks,
  setDasloops,
  setDaspowers,
  setDastemps,
  setCCTVonProject,
  setDasairData,
  setAOAData,
  setAOAType,
  setAOAUwbData,
  setAOATagData,
  setDastrackVData,
  setAOAs,
  setBeaconData,
  setBeacons,
} = myDevicePageSlice.actions;
export default myDevicePageSlice.reducer;
