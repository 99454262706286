import React from 'react';
import { styled, Typography } from '@mui/material';

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
`;

const PageNotFound = () => {
  return (
    <Container>
      <Typography>Page not found.</Typography>
    </Container>
  );
};

export default PageNotFound;
