import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { PlantGroup } from '../../../../types';

import { useAppSelector } from '../../../../hooks';
import useFilteredGroups from '../../../../hooks/useFilteredGroups';

import CheckboxDefault from '../../../../components/Checkbox/CheckboxDefault';

import { groupCompareFn } from '../../../../utils/Sort';

const Container = styled('div')`
  max-height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
`;

const GroupItemContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 12px 10px;
  background-color: ${({ theme }) => theme.color.secondary.$100};
`;

const ContentTitle = styled(Typography)`
  color: white;
  line-height: 2.125rem;
`;

interface PlantGroupContentEditProps {
  plantGroupProject: Array<PlantGroup> | undefined;
  plantGroupIdsSelected: Array<string>;
  handlePlantGroupWIllAssign: (group: Array<string>) => void;
}
const PlantGroupContentEdit: React.FC<PlantGroupContentEditProps> = ({
  plantGroupProject,
  plantGroupIdsSelected,
  handlePlantGroupWIllAssign,
}) => {
  const { role, policies } = useAppSelector((store) => store.projects);
  const { t, i18n } = useTranslation('project-setting');
  const selectedGroupIdsMap = plantGroupIdsSelected.reduce((prev, curr) => {
    prev[curr] = true;
    return prev;
  }, {});

  const filteredGroups = useFilteredGroups(
    'WriteOnePlantGroup',
    plantGroupProject ?? [],
    role,
    policies,
  );

  const handleOnChange = (groupId: string, checked: boolean) => {
    const newSelectedGroupIds = Object.entries(selectedGroupIdsMap)
      .filter(([key, value]) => {
        if (key !== groupId) return value;
      })
      .map(([key]) => key);
    if (checked) {
      newSelectedGroupIds.push(groupId);
    }
    handlePlantGroupWIllAssign(newSelectedGroupIds);
  };

  const item =
    filteredGroups
      .sort(
        groupCompareFn.bind({
          selectedGroupIdMap: selectedGroupIdsMap,
          locales: i18n.language,
        }),
      )
      .map((group, index) => (
        <GroupItemContainer key={`Plant-group ${index}`}>
          <CheckboxDefault
            value={group.id}
            onChange={handleOnChange}
            checked={selectedGroupIdsMap[group.id] ?? false}
            disabled={group.isDefault}
            selectType="default"
            label={group.name}
          />
        </GroupItemContainer>
      )) ?? [];

  return (
    <Container>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.plant-group.title')}
      </ContentTitle>
      <ContainerContent>{item}</ContainerContent>
    </Container>
  );
};

export default PlantGroupContentEdit;
