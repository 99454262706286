import React from 'react';
import { styled, SxProps, Theme } from '@mui/material';

interface ContainerProps {
  orgColor?: string;
}

const shouldForwardProp = (prop) => !/sx|orgColor/.test(prop.toString());

const Container = styled('div', { shouldForwardProp })<ContainerProps>`
  display: inline-flex;
  align-items: center;
  min-width: 0;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:before {
    display: ${({ orgColor }) => (orgColor ? 'inline-block' : 'none')};
    content: '';
    width: 6px;
    height: 14px;
    margin-right: 6px;
    background: ${({ orgColor }) => (orgColor ? orgColor : 'unset')};
    border-radius: 3px;
  }
`;

interface OrgTextProps {
  name?: string;
  orgColor?: string;
  sx?: SxProps<Theme>;
}

const OrgText: React.VFC<OrgTextProps> = ({ sx, name, orgColor }) => {
  return (
    <Container sx={sx} orgColor={orgColor} title={name}>
      <span>{name}</span>
    </Container>
  );
};

export default OrgText;
