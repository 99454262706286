import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DaswaterSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24L17 24L17 22L14 22L14 20L17 20L17 18L14 18L14 16L17 16L17 14L14 14L14 12L17 12L17 10L12 10L12 24Z"
        fill="#E5E5E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2635 26C16.0947 28.8856 18.7963 31 21.9999 31C25.8663 31 28.9999 27.9217 28.9999 24.1258C29.001 18.0288 21.9999 11 21.9999 11C21.9999 11 20.5683 12.4374 19 14.576V26H15.2635Z"
        fill="#5296D5"
      />
    </SvgIcon>
  );
};

export default DaswaterSvgColorMedium;
