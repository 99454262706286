import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DasgasDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasgas-dashboard-page" */ '../../pages/dasgas-dashboard'
    ),
);

const HistoricalRecordComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasgas-dashboard-page" */ '../../pages/dasgas-dashboard/hostorical-record'
    ),
);

const dasgasDashboardRouter = (
  <Route path="dasgas-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DasgasDashboardPageComponent />
        </Suspense>
      }
    >
      <Route path="historical-record" element={<HistoricalRecordComponent />} />
    </Route>
  </Route>
);

export default dasgasDashboardRouter;
