import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  DialogButton,
  Dropdown,
} from '@beeinventor/dasiot-react-component-lib';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  FormControl,
  styled,
  TextareaAutosize,
  Typography,
  // TextareaAutosize,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { DropdownItem, ResourceType } from '../../../types';
import { OperationApiPayload } from '../../../types/Resource';

import { get3dAssets } from '../../../apis/ProjectApi';

import CleanDateSvgIcon from '../../../assets/SvgIcon/CleanDateSvgIcon';
import mainTheme from '../../../theme';
import Input from '../../TextField/Input';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    width: 480px;
    background: ${({ theme }) => theme.color.secondary.$80};
    border-radius: 10px;
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  font-family: 'Montserrat';
  font-weight: 700;
  padding: 8px 8px 8px 16px;

  & .MuiDialogTitle-root {
    flex: 1;
    padding: 0;
  }

  & .MuiSvgIcon-root {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  ${({ theme }) => ({ ...theme.externalTypography.body3 })}
  padding: 20px 80px;
  color: white;

  & .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & .MuiInputBase-root {
      width: 100%;
    }

    & textarea {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      width: 100%;
      font-family: 'Noto Sans TC', 'Noto Sans';
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      outline: none;
      color: white;
      padding: 10px;
      border-color: transparent;
      resize: none;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

const DialogActions = styled(MuiDialogActions)`
  justify-content: space-between;
  padding: 24px;
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const ContainerDropdown = styled('div', { label: 'Container-Dropdown' })`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.secondary.$60};
  & > .Dropdown-empty {
    color: red;
  }
  & > .Dropdown-root {
    color: ${({ theme }) => theme.color.secondary.$60};
    font-weight: 400;
    font-size: 14px;
    padding: 0 8px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    & > .Dropdown-icon {
      width: 32px;
      height: 32px;
    }
  }

  & > .Dropdown--disabled {
    ${({ theme }) => ({ ...theme.typography.body2 })}
    background: #e5e5e5;
    color: ${({ theme }) => theme.color.secondary.$80};
  }
  & div[role='tooltip'] {
    & > .MuiBox-root {
      color: ${({ theme }) => theme.color.box_bbg};
      ${({ theme }) => ({ ...theme.typography.body2 })}
      background: ${({ theme }) => theme.color.secondary.$100};
      max-height: 150px;
      overflow: scroll;
    }
    z-index: 5;
  }
`;

interface GroupDialogProps extends Omit<DialogProps, 'onClose'> {
  name?: string;
  remark?: string;
  groupId?: string;
  title: string;
  resourceType: ResourceType;
  placeholder?: string;
  submitText: string;
  operationApi: (payload: OperationApiPayload) => Promise<any>;
  onClose: () => void;
  onSuccess: () => void;
  threeDimensionalAssetsId?: string | null | undefined;
}

const RenameDialog: React.FC<GroupDialogProps> = ({
  name,
  remark,
  groupId,
  open,
  title,
  resourceType,
  onClose,
  operationApi,
  submitText,
  onSuccess: onSuccessOperation,
  threeDimensionalAssetsId,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [localName, setLocalName] = useState<string | undefined>();
  const [localRemark, setLocalRemark] = useState<string | undefined>();
  const operationRequest = useMutation<any, unknown, OperationApiPayload>(
    async (payload) => operationApi(payload),
  );
  const [selectedIdAsset, setSelectedIdAsset] = useState<string | undefined>(
    threeDimensionalAssetsId ?? undefined,
  );

  const { data: projectOf3dAssets } = useQuery(
    ['3d-assets', projectId],
    async () => {
      const res = await get3dAssets(projectId as string);
      return res.data.data;
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (name !== localName && name) {
      setLocalName(name);
    }
  }, [name]);

  useEffect(() => {
    if (remark !== localRemark) {
      setLocalRemark(remark);
    }
  }, [remark]);

  const saveable = () => {
    if (localName === '') return false;
    return true;
  };

  const handleOperation = () => {
    if (projectId && groupId && localName) {
      operationRequest.mutate(
        {
          projectId,
          groupId,
          resourceType,
          name: localName,
          remark: localRemark,
          payload: {
            name: localName,
            remark: localRemark,
            threeDimensionalAssetsId: selectedIdAsset,
          },
        },
        {
          onSuccess: onSuccessOperation,
        },
      );
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (operationRequest.isError) {
      if (operationRequest.error instanceof Error) {
        alert(operationRequest.error.message);
      } else {
        alert(JSON.stringify(operationRequest.error));
      }
    }
  }, [operationRequest.isError]);

  const LIST_3D_FILES: DropdownItem = useMemo(
    () =>
      projectOf3dAssets?.map((d) => {
        return {
          id: d.id,
          name: d.name,
          value: d.id,
        };
      }) ?? [],
    [projectOf3dAssets],
  );

  return (
    <Dialog open={open}>
      <Header>
        <MuiDialogTitle>{title}</MuiDialogTitle>
        <CleanDateSvgIcon onClick={onClose} />
      </Header>
      <DialogContent>
        <FormControl>
          <label htmlFor="group-name-input">{t('name')}</label>
          <Input
            id="group-name-input"
            name="group-name"
            value={localName}
            onChange={(e) => setLocalName(e.currentTarget.value)}
            data-cy="input-rename-equipment-group-name"
          />
        </FormControl>
        {resourceType === 'field' && (
          <ContainerDropdown>
            <Title>
              {t('3d-file')} <SpanRequire>*</SpanRequire>
            </Title>
            <Dropdown
              mode="dark"
              list={LIST_3D_FILES}
              onSelect={(value) => {
                setSelectedIdAsset(value as string);
              }}
              popperProps={{ disablePortal: true }}
              selectedId={selectedIdAsset}
              placeholder={
                LIST_3D_FILES.length > 0
                  ? t('select-a-file')
                  : t('no-3d-file-imported')
              }
              disabled={LIST_3D_FILES.length === 0}
            />
          </ContainerDropdown>
        )}

        <FormControl>
          <label htmlFor="group-remark-input">{t('remark')}</label>
          <TextareaAutosize
            id="group-remark-input"
            name="group-remark"
            maxRows={4}
            minRows={4}
            value={localRemark}
            onChange={(e) => setLocalRemark(e.currentTarget.value)}
            data-cy="input-rename-equipment-group-remark"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': { background: mainTheme.color.secondary.$60 },
          }}
          color="secondary"
          onClick={handleOnClose}
        >
          {t('cancel')}
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={operationRequest.isLoading || !saveable()}
          onClick={handleOperation}
          data-cy="btn-submit-rename-equipment-group"
        >
          {operationRequest.isLoading && (
            <CircularProgress
              sx={{ color: 'white', mr: '4px' }}
              size={'1rem'}
            />
          )}
          {submitText}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default RenameDialog;
