import type { SVGProps } from 'react';
const COSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle
      cx={11.5}
      cy={12.5}
      r={8.75}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fill="currentColor"
      d="M8.508 16a3.539 3.539 0 0 1-1.784-.458 3.515 3.515 0 0 1-1.264-1.265A3.535 3.535 0 0 1 5 12.495c0-.662.15-1.259.45-1.791.301-.533.713-.948 1.235-1.246A3.433 3.433 0 0 1 8.45 9c.352 0 .699.042 1.038.127.34.084.63.204.872.36l-.264 1.509c-.47-.175-.895-.263-1.274-.263-.523 0-.924.152-1.205.457-.281.3-.421.73-.421 1.295 0 .552.147.984.44 1.295.301.305.716.458 1.245.458.202 0 .392-.02.568-.059.176-.039.392-.11.647-.214l.274 1.539A3.633 3.633 0 0 1 8.508 16ZM14.394 16c-.705 0-1.332-.15-1.88-.448a3.276 3.276 0 0 1-1.275-1.236c-.3-.533-.45-1.136-.45-1.811 0-.682.15-1.289.45-1.82a3.188 3.188 0 0 1 1.274-1.237C13.062 9.149 13.69 9 14.394 9c.706 0 1.33.15 1.872.448.548.292.973.704 1.273 1.236.307.526.46 1.133.46 1.82 0 .676-.153 1.28-.46 1.812-.3.525-.725.938-1.273 1.236-.549.299-1.173.448-1.872.448Zm0-1.772c.412 0 .748-.159 1.01-.477.267-.318.401-.737.401-1.256 0-.344-.062-.649-.186-.915a1.44 1.44 0 0 0-.5-.633 1.223 1.223 0 0 0-.735-.233c-.267 0-.509.077-.725.233-.215.15-.382.36-.5.633a2.245 2.245 0 0 0-.176.915c0 .52.131.938.392 1.256.262.318.601.477 1.02.477Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.409 9.194a4 4 0 1 0-5.602-5.602 9.528 9.528 0 0 1 5.602 5.602Z"
      clipRule="evenodd"
    />
  </svg>
);
export default COSvgIcon;
