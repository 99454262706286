import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WorkerSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11ZM12 12C15.866 12 19 14.8453 19 20H5C5 14.8453 8.13401 12 12 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default WorkerSvgIcon;
