import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Pipe } from '../types/Resource';
import {
  WebSocketAlert,
  WebSocketCoordinates,
  WebSocketDascartDataPoint,
  WebSocketDasgasDataPoint,
  WebSocketDasloopDataPoint,
  WebSocketDastrackDataPoint,
  WebSocketDastrackVDataPoint,
  WebSocketDaswatchDataPoint,
  WebSocketDaswaterDataPoint,
  WebSocketLiveCall,
} from '../types/Websocket';

interface WebSocketState {
  currentProjectId: string | null;
  dasloopDataPoint: WebSocketDasloopDataPoint | null;
  dasgasDataPoint: WebSocketDasgasDataPoint | null;
  daswatchDataPoint: WebSocketDaswatchDataPoint | null;
  dastrackDataPoint: WebSocketDastrackDataPoint | null;
  dastrackVDataPoint: WebSocketDastrackVDataPoint | null;
  daswaterDataPoint: WebSocketDaswaterDataPoint | null;
  dascartDataPoint: WebSocketDascartDataPoint | null;
  liveCallDataPoint: WebSocketLiveCall | null;
  alert: WebSocketAlert | null;
  coordinates: WebSocketCoordinates | null;
  alertPipes: Pipe[];
}

const initialState: WebSocketState = {
  currentProjectId: null,
  dasloopDataPoint: null,
  dasgasDataPoint: null,
  daswatchDataPoint: null,
  dastrackDataPoint: null,
  dastrackVDataPoint: null,
  daswaterDataPoint: null,
  dascartDataPoint: null,
  liveCallDataPoint: null,
  alert: null,
  alertPipes: [],
  coordinates: null,
};

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setCurrentProjectId: (
      state,
      action: PayloadAction<WebSocketState['currentProjectId']>,
    ) => {
      return {
        ...state,
        currentProjectId: action.payload,
      };
    },
    dasloopDataReceived: (
      state,
      action: PayloadAction<WebSocketState['dasloopDataPoint']>,
    ) => {
      return {
        ...state,
        dasloopDataPoint: action.payload,
      };
    },
    dasgasDataReceived: (
      state,
      action: PayloadAction<WebSocketState['dasgasDataPoint']>,
    ) => {
      return {
        ...state,
        dasgasDataPoint: action.payload,
      };
    },
    daswatchDataReceived: (
      state,
      action: PayloadAction<WebSocketState['daswatchDataPoint']>,
    ) => {
      return { ...state, daswatchDataPoint: action.payload };
    },
    dastrackDataReceived: (
      state,
      action: PayloadAction<WebSocketState['dastrackDataPoint']>,
    ) => {
      return {
        ...state,
        dastrackDataPoint: action.payload,
      };
    },
    dastrackVDataReceived: (
      state,
      action: PayloadAction<WebSocketState['dastrackVDataPoint']>,
    ) => {
      return {
        ...state,
        dastrackVDataPoint: action.payload,
      };
    },
    daswaterDataReceived: (
      state,
      action: PayloadAction<WebSocketState['daswaterDataPoint']>,
    ) => {
      return {
        ...state,
        daswaterDataPoint: action.payload,
      };
    },
    dascartkDataReceived: (
      state,
      action: PayloadAction<WebSocketState['dascartDataPoint']>,
    ) => {
      return {
        ...state,
        dascartDataPoint: action.payload,
      };
    },
    liveCallDataReceived: (
      state,
      action: PayloadAction<WebSocketState['liveCallDataPoint']>,
    ) => {
      return { ...state, liveCallDataPoint: action.payload };
    },
    alertReceived: (state, action: PayloadAction<WebSocketAlert | null>) => {
      return {
        ...state,
        alert: action.payload,
      };
    },
    setAlertPipes: (
      state,
      action: PayloadAction<WebSocketState['alertPipes']>,
    ) => {
      return {
        ...state,
        alertPipes: action.payload,
      };
    },
    coordinateReceived: (
      state,
      action: PayloadAction<WebSocketState['coordinates']>,
    ) => {
      return {
        ...state,
        coordinates: action.payload,
      };
    },
    resetAll: () => initialState,
  },
});

export const {
  setCurrentProjectId,
  dasloopDataReceived,
  dasgasDataReceived,
  dastrackDataReceived,
  dastrackVDataReceived,
  daswatchDataReceived,
  dascartkDataReceived,
  daswaterDataReceived,
  liveCallDataReceived,
  alertReceived,
  setAlertPipes,
  coordinateReceived,
  resetAll,
} = websocketSlice.actions;
export default websocketSlice.reducer;
