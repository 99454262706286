import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DaslockDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "daslock-dashboard-page" */ '../../pages/daslock-dashboard'
    ),
);

const dasloopDashboardRouter = (
  <Route path="daslock-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DaslockDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dasloopDashboardRouter;
