import * as THREE from 'three';

class ResourceTracker {
  resources: Set<any>;

  constructor() {
    this.resources = new Set();
  }

  track(resource) {
    if (resource || resource.dispose) {
      this.resources.add(resource);
    }
    return resource;
  }

  untrack(resource) {
    if (resource instanceof THREE.Object3D) {
      if (resource.parent) {
        resource.parent.remove(resource);
      }
    }
    if (resource.dispose) {
      resource.dispose();
    }
    this.resources.delete(resource);
  }

  dispose() {
    this.resources.forEach((resource) => {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    });
    this.resources.clear();
  }

  getAllResources() {
    return this.resources;
  }
}

export const globalTracker = new ResourceTracker();

export default ResourceTracker;
