import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import {
  ChevronLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { Area, PolicyType, User } from '../../types';
import {
  WebSocketAlert,
  WebSocketProjectAttendanceReportCreated,
} from '../../types/Websocket';

import { getAreasOfProject } from '../../apis/AreaApi';
import { getFloorPlans } from '../../apis/FloorPlanApi';
import {
  getMyProjectPolicies,
  getProjectContact,
  getProjectFeatureList,
  getProjectRole,
  getProjectUsers,
} from '../../apis/ProjectApi';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAlertQueueTimeOut } from '../../hooks/useAlertQueueTimeOut';
import useAlertSnackBar from '../../hooks/useAlertSnackBar';
import { alertHandler } from '../../hooks/useWebSocket';
import {
  setCurrenProject,
  setCurrentMembers,
  setIsLoadingProjectContact,
  setProjectArea,
  setProjectContact,
  setProjectFloorPlan,
  setProjectPolicies,
  setProjectRole,
} from '../../slices/projectSlice';
import { setFeatureListMap } from '../../slices/systemSlice';
import { setCurrentProjectId } from '../../slices/websocketSlice';

import CloseSvgIcon from '../../assets/SvgIcon/CloseSvgIcon';
import { PubSubContext } from '../../utils/PubSub';

import BasicMenu from './BasicMenu';
import SSSSMenu from './SSSSMenu';
import alertDashboard from '../../assets/images/svg/alert-dashboard.svg';
import areaManagementSvg from '../../assets/images/svg/area-management.svg';
import logSvg from '../../assets/images/svg/book-open.svg';
import downloadSvg from '../../assets/images/svg/download.svg';
import gearSvg from '../../assets/images/svg/gear.svg';
import overviewSvg from '../../assets/images/svg/img_overview.svg';
import paSvg from '../../assets/images/svg/public-announcement.svg';
import reportSvg from '../../assets/images/svg/report_svg_icon.svg';
import tunnelSvg from '../../assets/images/svg/tunnel.svg';
import HomeSvgIcon from '../../assets/SvgIcon/HomeSvgIcon';
import IconContainer from '../IconContainer';
import DarkTooltip from '../Toolip/DarkTooltip';

interface SidebarProps {
  expand: boolean;
}

const Sidebar = styled('nav')<SidebarProps>`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  width: ${({ expand }) => (expand ? 'fit-content' : '60px')};
  height: 100vh;
  color: white;
  background-color: ${({ theme }) => theme.color.primary.$100};

  & > .backward-button {
    flex: 0 0 auto;
    height: 64px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.externalColor.secondary.$140};
    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  & > .feature-list {
    flex: 100;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    & .event-block {
      height: 2px;
      // Used for debugging
      /* background-color: red; */
    }
  }

  & > .bottom-list {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }

  & a {
    cursor: pointer;
    display: flex;
    text-decoration: none;
    list-style-type: none;
    color: white;
    gap: 0.5rem;
    & > div {
      width: ${({ expand }) => (expand ? '10%' : 'auto')};
    }

    &.active {
      background-color: ${({ theme }) => theme.color.secondary.$100};
    }

    & .icon {
      width: 100%;
      height: 40px;
    }

    &.home {
      display: flex;
      height: 50px;
    }

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${({ expand }) => (expand ? '10%' : '100%')};

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
    & > span {
      display: flex;
      align-items: center;
      width: ${({ expand }) => (expand ? '90%' : 'auto')};
    }
  }
`;

const ENTER_DELAY = 1000;

export const Divider = styled('div', { label: 'ProjectSidebar-Divider' })`
  margin: 10px;
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 1px;
  }
`;

const Icon = styled('div')`
  & > svg {
    display: block;
    width: 40px;
    height: 40px;
  }
`;

const ProjectSidebar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const pubSub = useContext(PubSubContext);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const selectedProjectId = useAppSelector(
    (store) => store.system.selectedProjectId,
  );
  const projectRole = useAppSelector((store) => store.projects.role);
  const featureListRef = useRef<HTMLUListElement | null>(null);
  const commonFeatureListRef = useRef<HTMLUListElement | null>(null);
  const topIndicatorRef = useRef<HTMLDivElement | null>(null);
  const bottomIndicatorRef = useRef<HTMLDivElement | null>(null);
  const commonFeatureTopIndicatorRef = useRef<HTMLDivElement | null>(null);
  const commonFeatureBottomIndicatorRef = useRef<HTMLDivElement | null>(null);
  const [websocketAlert, setWebsocketAlert] = useState<WebSocketAlert | null>(
    null,
  );
  const [featureScroll, setFeatureScroll] = useState({
    showTop: false,
    showBottom: true,
  });
  const [commonFeatureScroll, setCommonFeatureScroll] = useState({
    showTop: false,
    showBottom: true,
  });
  const [expand] = useState(false);

  useAlertSnackBar(selectedProjectId ?? '', websocketAlert);

  useAlertQueueTimeOut(projectId, 30000);

  useEffect(() => {
    let featureObserver: IntersectionObserver | undefined;
    let commonFeatureObserver: IntersectionObserver | undefined;
    if (featureListRef.current) {
      featureObserver = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.target.id === 'top-indicator') {
              if (entry.isIntersecting) {
                setFeatureScroll((origin) => ({
                  ...origin,
                  showTop: false,
                }));
              } else {
                setFeatureScroll((origin) => ({
                  ...origin,
                  showTop: true,
                }));
              }
            }

            if (entry.target.id === 'bottom-indicator') {
              if (entry.isIntersecting) {
                setFeatureScroll((origin) => ({
                  ...origin,
                  showBottom: false,
                }));
              } else {
                setFeatureScroll((origin) => ({
                  ...origin,
                  showBottom: true,
                }));
              }
            }
          }
        },
        {
          root: featureListRef.current,
        },
      );
    }

    if (commonFeatureListRef.current) {
      commonFeatureObserver = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (entry.target.id === 'common-feature-top-indicator') {
              if (entry.isIntersecting) {
                setCommonFeatureScroll((origin) => ({
                  ...origin,
                  showTop: false,
                }));
              } else {
                setCommonFeatureScroll((origin) => ({
                  ...origin,
                  showTop: true,
                }));
              }
            }

            if (entry.target.id === 'common-feature-bottom-indicator') {
              if (entry.isIntersecting) {
                setCommonFeatureScroll((origin) => ({
                  ...origin,
                  showBottom: false,
                }));
              } else {
                setCommonFeatureScroll((origin) => ({
                  ...origin,
                  showBottom: true,
                }));
              }
            }
          }
        },
        {
          root: commonFeatureListRef.current,
        },
      );
    }

    if (featureObserver && topIndicatorRef.current) {
      featureObserver.observe(topIndicatorRef.current);
    }
    if (featureObserver && bottomIndicatorRef.current) {
      featureObserver.observe(bottomIndicatorRef.current);
    }

    if (commonFeatureObserver && commonFeatureTopIndicatorRef.current) {
      commonFeatureObserver.observe(commonFeatureTopIndicatorRef.current);
    }
    if (commonFeatureObserver && commonFeatureBottomIndicatorRef.current) {
      commonFeatureObserver.observe(commonFeatureBottomIndicatorRef.current);
    }

    return () => {
      if (featureObserver) {
        featureObserver.disconnect();
      }
      if (commonFeatureObserver) {
        commonFeatureObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (pubSub) {
      const handler = async (data) => {
        const alert = await alertHandler(
          projectId,
          data.type ?? data.resourceType,
          data,
        );
        setWebsocketAlert(alert);
      };

      const attendanceReportHandler = (
        data: WebSocketProjectAttendanceReportCreated,
      ) => {
        if (
          (data.projectId === projectId && projectRole === 'owner') ||
          projectRole === 'admin'
        ) {
          enqueueSnackbar(`${t('report-generated')}`, {
            key: `report-created-${data.projectId}-${data.link.id}`,
            variant: 'success',
            autoHideDuration: 30000,
            action: (
              <Icon
                onClick={() =>
                  closeSnackbar(
                    `report-created-${data.projectId}-${data.link.id}`,
                  )
                }
              >
                <CloseSvgIcon width={40} height={40} />
              </Icon>
            ),
          });
        }
      };

      pubSub.subscribe('alert', handler);
      pubSub.subscribe(
        'project-attendance-report-created',
        attendanceReportHandler,
      );
      pubSub.subscribe(
        'project-plant-attendance-report-created',
        attendanceReportHandler,
      );

      return () => {
        pubSub.unsubscribe('alert', handler);
        pubSub.unsubscribe(
          'project-attendance-report-created',
          attendanceReportHandler,
        );
        pubSub.unsubscribe(
          'project-plant-attendance-report-created',
          attendanceReportHandler,
        );
      };
    }
  }, [pubSub, projectId, projectRole]);

  const data = useQueries({
    queries: [
      {
        queryKey: ['project-role', selectedProjectId],
        queryFn: () =>
          getProjectRole(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (role) => dispatch(setProjectRole(role)),
      },
      {
        queryKey: ['project-policies', selectedProjectId],
        queryFn: () =>
          getMyProjectPolicies(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (policies) => dispatch(setProjectPolicies(policies)),
      },
      {
        queryKey: ['project-contacts', selectedProjectId],
        queryFn: () =>
          getProjectContact(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (contacts) => {
          dispatch(setProjectContact(contacts));
          dispatch(setIsLoadingProjectContact(false));
        },
        onError: () => {
          dispatch(setIsLoadingProjectContact(false));
        },
      },
      {
        queryKey: ['project-floor-plan', selectedProjectId],
        queryFn: () =>
          getFloorPlans(selectedProjectId as string).then(
            (res) => res.data.data,
          ),
        enabled: !!selectedProjectId,
        refetchOnWindowFocus: false,
        onSuccess: (floorPlan) => {
          dispatch(setProjectFloorPlan(floorPlan));
        },
      },
      {
        queryKey: ['get-project-merbers', projectId],
        queryFn: async () => {
          const res = await getProjectUsers(projectId as string);
          const projectMemberMap = res.data.data.reduce((prev, curr) => {
            prev[curr.id] = curr;
            return prev;
          }, {});
          return projectMemberMap as { [id: string]: User | undefined };
        },
        enabled: !!projectId,
        refetchOnWindowFocus: false,
        onSuccess: (memberMap) => {
          dispatch(setCurrentMembers(memberMap));
        },
      },
    ],
  });

  const { data: dataFeatures } = useQuery(
    ['get-project-feature-list', selectedProjectId],
    async () =>
      getProjectFeatureList(selectedProjectId as string).then(
        (res) => res.data.data,
      ),
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        dispatch(setFeatureListMap(res));
      },
    },
  );

  const handleReset = () => {
    dispatch(setProjectRole(null));
    dispatch(setProjectPolicies([]));
    dispatch(setCurrenProject(null));
    dispatch(setCurrentProjectId(null));
  };

  const policyMap = useMemo(() => {
    const map = new Map<PolicyType, boolean>();
    data[1].data?.forEach((policy) => {
      map.set(policy.name, true);
    });
    return map;
  }, [data?.[1]?.data]);

  const alertReadable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('ReadAlert');

  const areaReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadArea');

  const areaWriteable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('WriteArea');

  const overviewReadable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('ReadProjectOverview');

  const reportReadable =
    data[0].data === 'admin' ||
    data[0].data === 'owner' ||
    policyMap.has('ReadArea') ||
    policyMap.has('WriteArea');

  let disablePa = true;
  if (dataFeatures?.dasloop) {
    disablePa = dataFeatures?.dasloop.metadata.disablePa;
  } else if (dataFeatures?.four_s) {
    disablePa = dataFeatures?.four_s.metadata.disablePa;
  }

  const PAreadable =
    !disablePa && (data[0].data === 'admin' || data[0].data === 'owner');

  useQuery(
    ['project-area', selectedProjectId],
    async () => {
      const areas: Area[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getAreasOfProject(selectedProjectId as string, {
          nextCursor,
          limit: 200,
          selectVisible: false,
        });
        res.data.data.forEach((area) => {
          areas.push(area);
        });

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return areas;
    },
    {
      enabled: !!selectedProjectId && areaReadable,
      refetchOnWindowFocus: false,
      onSuccess: (areas) => {
        dispatch(setProjectArea(areas));
      },
    },
  );

  return (
    <Sidebar className="sidebar" expand={expand}>
      {/^\/dashboard.*/.test(location.pathname) && (
        <div
          className="backward-button"
          onClick={() => {
            handleReset();
            navigate('/project');
          }}
        >
          <div>
            <ChevronLeft />
          </div>
        </div>
      )}
      <NavLink
        className="home"
        to={`/dashboard/${selectedProjectId}`}
        data-cy="nav-sidebar-home"
      >
        <div>
          <HomeSvgIcon sx={{ width: '32px', height: '32px' }} />
        </div>
        {expand && <span> Home</span>}
      </NavLink>
      <Divider sx={{}} />
      {featureScroll.showTop && (
        <IconContainer
          onClick={() => {
            if (featureListRef.current) {
              featureListRef.current.scrollTo(0, 0);
            }
          }}
          sx={{
            width: '100%',
            height: 'unset',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <KeyboardArrowUp />
        </IconContainer>
      )}
      <ul ref={featureListRef} className="feature-list">
        <div ref={topIndicatorRef} id="top-indicator" className="event-block" />
        {dataFeatures?.four_s ? (
          <SSSSMenu
            dataFeatures={dataFeatures}
            selectedProjectId={selectedProjectId}
            enterDelay={ENTER_DELAY}
            expand={expand}
          />
        ) : (
          <BasicMenu
            dataFeatures={dataFeatures}
            selectedProjectId={selectedProjectId}
            enterDelay={ENTER_DELAY}
            expand={expand}
          />
        )}
        <div
          ref={bottomIndicatorRef}
          id="bottom-indicator"
          className="event-block"
        />
      </ul>
      {featureScroll.showBottom && (
        <IconContainer
          onClick={() => {
            if (featureListRef.current) {
              featureListRef.current.scrollTo(
                0,
                featureListRef.current.scrollHeight,
              );
            }
          }}
          sx={{
            width: '100%',
            height: 'unset',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <KeyboardArrowDown />
        </IconContainer>
      )}
      <Divider sx={{}} />
      {commonFeatureScroll.showTop && (
        <IconContainer
          onClick={() => {
            if (commonFeatureListRef.current) {
              commonFeatureListRef.current.scrollTo(0, 0);
            }
          }}
          sx={{
            width: '100%',
            height: 'unset',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <KeyboardArrowUp />
        </IconContainer>
      )}
      <ul ref={commonFeatureListRef} className="feature-list">
        <div
          ref={commonFeatureTopIndicatorRef}
          id="common-feature-top-indicator"
          className="event-block"
        />
        {overviewReadable && (
          <NavLink
            to={`/overview-dashboard/${selectedProjectId}`}
            data-cy="nav-sidebar-overview"
          >
            <DarkTooltip
              title={t('tooltip.project-overview')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={overviewSvg} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.project-overview')}</span>}
          </NavLink>
        )}
        {reportReadable && (
          <NavLink
            to={`/report-dashboard/${selectedProjectId}`}
            data-cy="nav-sidebar-report"
          >
            <DarkTooltip
              title={t('tooltip.report')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={reportSvg} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.report')}</span>}
          </NavLink>
        )}
        {alertReadable && (
          <NavLink
            to={`/alert-dashboard/${selectedProjectId}`}
            data-cy="nav-sidebar-alert"
          >
            <DarkTooltip
              title={t('tooltip.alert')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={alertDashboard} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.alert')}</span>}
          </NavLink>
        )}
        {PAreadable && (
          <NavLink
            to={`public-announcement/${selectedProjectId}`}
            data-cy="nav-sidebar-public-announcement"
          >
            <DarkTooltip
              title={t('tooltip.public-announcement')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={paSvg} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.public-announcement')}</span>}
          </NavLink>
        )}
        {areaWriteable && (
          <NavLink
            to={`/area-management-dashboard/${selectedProjectId}`}
            data-cy="nav-sidebar-area-management"
          >
            <DarkTooltip
              title={t('tooltip.area')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={areaManagementSvg} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.area')}</span>}
          </NavLink>
        )}
        {dataFeatures?.tunnel && (
          <NavLink
            to={`/tunnel-dashboard/${selectedProjectId}`}
            data-cy="nav-sidebar-tunnel"
          >
            <DarkTooltip
              title={t('tooltip.tunnel')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={tunnelSvg} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.tunnel')}</span>}
          </NavLink>
        )}
        {dataFeatures?.report && (
          <NavLink
            to={`/data-download-dashboard/${selectedProjectId}`}
            data-cy="nav-sidebar-data-download"
          >
            <DarkTooltip
              title={t('tooltip.data-download')}
              placement="right"
              enterDelay={ENTER_DELAY}
            >
              <div>
                <IconContainer className="icon">
                  <img src={downloadSvg} alt="" />
                </IconContainer>
              </div>
            </DarkTooltip>
            {expand && <span>{t('tooltip.data-download')}</span>}
          </NavLink>
        )}
        <div
          ref={commonFeatureBottomIndicatorRef}
          id="common-feature-bottom-indicator"
          className="event-block"
        />
      </ul>
      {commonFeatureScroll.showBottom && (
        <IconContainer
          onClick={() => {
            if (commonFeatureListRef.current) {
              commonFeatureListRef.current.scrollTo(
                0,
                commonFeatureListRef.current.scrollHeight,
              );
            }
          }}
          sx={{
            width: '100%',
            height: 'unset',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <KeyboardArrowDown />
        </IconContainer>
      )}
      <ul className="bottom-list">
        <NavLink to={`/log/${selectedProjectId}`} data-cy="nav-sidebar-log">
          <DarkTooltip
            title={t('tooltip.log')}
            placement="right"
            enterDelay={ENTER_DELAY}
          >
            <div>
              <IconContainer className="icon">
                <img src={logSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.log')}</span>}
        </NavLink>
        <NavLink
          to={`/project-setting/${selectedProjectId}`}
          data-cy="nav-sidebar-project-setting"
          rel="noopener noreferrer"
        >
          <DarkTooltip
            title={t('tooltip.project-setting')}
            placement="right"
            enterDelay={ENTER_DELAY}
          >
            <div>
              <IconContainer className="icon">
                <img src={gearSvg} alt="" />
              </IconContainer>
            </div>
          </DarkTooltip>
          {expand && <span>{t('tooltip.project-setting')}</span>}
        </NavLink>
      </ul>
    </Sidebar>
  );
};

export default ProjectSidebar;
