import {
  BasicMessage,
  BasicResponse,
  EquipmentGroup,
  PagedResponse,
} from '../types';
import { DaspowerAlert, RequestAlertsParams } from '../types/Alert';
import { Daspower } from '../types/Device';
import { Equipment } from '../types/Resource';

import DsmAxios from './DsmAxios';

interface BasicGetStatOfPowerParams {
  fromDate: string;
  toDate: string;
  interval: 'hour' | 'minute' | 'day' | 'month';
}

type GetStatOfPowerParams = BasicGetStatOfPowerParams &
  (
    | {
        by: 'equipment';
        equipmentId: string;
      }
    | {
        by: 'group';
        groupId: string;
      }
    | {
        by: 'project';
      }
  );

interface GetStatOfPowerData {
  by: 'project' | 'group' | 'equipment';
  intervals: { [index: string]: number | null };
}

export const getEquipmentGroups = (
  projectId: string,
  params?: { nextCursor?: string },
) => {
  return DsmAxios.get<PagedResponse<EquipmentGroup>>(
    `v1/projects/${projectId}/equipment-groups`,
    {
      params,
    },
  );
};

export const getUserDaspowers = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<Daspower>>(`v1/daspowers`, { params });
};

/**TODO: Merge to getDaspower */
export const getDaspowerBySearchId = (searchId: string) => {
  return DsmAxios.get<PagedResponse<Daspower>>(
    `v1/daspowers?dasId=${searchId}`,
  );
};

export const getDaspowerByProject = (
  projectId: string,
  params?: {
    dasId?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<Daspower>>(
    `v1/projects/${projectId}/daspowers`,
    { params },
  );
};

export const getDaspowerByDasId = (data: { dasId: string }) => {
  return DsmAxios.get<BasicResponse<Daspower>>(`v1/daspowers/${data.dasId}`);
};

export const getEquipmentById = (projectId: string, equipmentId: string) => {
  return DsmAxios.get<{ data: Equipment }>(
    `v1/projects/${projectId}/equipments/${equipmentId}`,
  );
};

export const getGroupOfEquipment = ({
  projectId,
  groupId,
}: {
  projectId: string;
  groupId: string;
}) => {
  return DsmAxios.get<PagedResponse<Equipment>>(
    `v1/projects/${projectId}/equipment-groups/${groupId}/equipments`,
  );
};

export const getEquipment = (projectId: string, equipmentId: string) => {
  return DsmAxios.get<BasicResponse<Equipment>>(
    `v1/projects/${projectId}/equipments/${equipmentId}`,
  );
};

export const unbindDaspower = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/daspowers/${dasId}/equipment`,
  );
};

export const bindDaspowerToEquipment = ({
  projectId,
  dasId,
  equipmentId,
}: {
  projectId: string;
  dasId: string;
  equipmentId: string;
}) => {
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/daspowers/${dasId}/equipment`,
    {
      equipmentId,
    },
  );
};

export const getStatOfPower = (
  projectId: string,
  params?: Partial<GetStatOfPowerParams>,
) => {
  return DsmAxios.get<BasicResponse<GetStatOfPowerData>>(
    `v1/projects/${projectId}/stats/power`,
    { params },
  );
};

export const getProjectDaspowers = (data: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    nextCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Daspower>>(
    `v1/projects/${data.projectId}/daspowers`,
    {
      params: data.params,
    },
  );
};

export const getDaspowerAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    equipmentId?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<DaspowerAlert>>(
    `v1/projects/${projectId}/daspower-alerts`,
    { params },
  );
};

export const dismissDaspowerAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daspower-alerts/${alertId}/dismiss`,
  );
};

export const undismissDaspowerAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daspower-alerts/${alertId}/undismiss`,
  );
};

export const readDaspowerAlert = (projectId: string, alertId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daspower-alerts/${alertId}/read`,
  );
};

export const createEquipmentGroup = ({
  projectId,
  name,
  remark,
}: {
  projectId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.post(`v1/projects/${projectId}/equipment-groups`, {
    name,
    remark,
  });
};

export const updateEquipmentGroup = ({
  projectId,
  groupId,
  name,
  remark,
}: {
  projectId: string;
  groupId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.patch(
    `v1/projects/${projectId}/equipment-groups/${groupId}`,
    {
      name,
      remark,
    },
  );
};

interface CoordinatesEquipment<T> {
  alt: T;
  lat: T;
  lon: T;
}

export const createEquipment = (data: {
  projectId: string;
  payload: {
    name: string;
    voltage?: number;
    powerRating?: number | null;
    phase: number;
    location?: CoordinatesEquipment<number>;
    remark?: string;
    groupIds: string[];
    orgId: string;
  };
}) => {
  return DsmAxios.post<{ data: Equipment }>(
    `v1/projects/${data.projectId}/equipments`,
    data.payload,
  );
};

export const uploadEquipmentAvatar = ({
  projectId,
  equipmentId,
  imageFile,
}: {
  projectId: string;
  equipmentId: string;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post(
    `v1/projects/${projectId}/equipments/${equipmentId}/avatar`,
    formData,
  );
};

export const updateEquipment = (data: {
  projectId: string;
  equipmentId: string;
  payload: {
    name?: string;
    voltage?: number | null;
    powerRating?: number | null;
    phase?: number | null;
    location?: CoordinatesEquipment<number> | null;
    remark?: string;
    orgId: string;
  };
}) => {
  return DsmAxios.patch(
    `v1/projects/${data.projectId}/equipments/${data.equipmentId}`,
    data.payload,
  );
};

export const updateEquipmentGroupIds = ({
  projectId,
  equipmentId,
  groupIds,
}: {
  projectId: string;
  equipmentId: string;
  groupIds: string[];
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/equipments/${equipmentId}/groups`,
    {
      groupIds,
    },
  );
};

export const setEquipmentToAGroup = ({
  projectId,
  groupId,
  equipmentIds,
}: {
  projectId: string;
  groupId: string;
  equipmentIds: Array<string>;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/equipment-groups/${groupId}/equipments`,
    {
      equipmentIds,
    },
  );
};

export const setCurrentLimit = ({
  projectId,
  equipmentId,
  maxCurrent,
  minCurrent,
}: {
  projectId: string;
  equipmentId: string;
  maxCurrent?: number | null;
  minCurrent?: number | null;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/equipments/${equipmentId}/current-limit`,
    {
      maxCurrent,
      minCurrent,
    },
  );
};
