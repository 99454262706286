import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const Main = lazy(() => import('../../pages/ai-camera'));

const aiCameraRouter = (
  <Route path="ai-camera" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <Main />
        </Suspense>
      }
    ></Route>
  </Route>
);

export default aiCameraRouter;
