import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const OverviewPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "overview-dashboard-page" */ '../../pages/overview-dashboard'
    ),
);

const projectOverviewRouter = (
  <Route path="overview-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <OverviewPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default projectOverviewRouter;
