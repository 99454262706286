import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LoadingButtonSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 16C10 19.3137 12.6863 22 16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10"
        stroke="url(#paint0_linear_2337_58748)"
        strokeWidth="3"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        fill="none"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2337_58748"
          x1="8.28571"
          y1="21.1429"
          x2="8.28571"
          y2="15.1429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default LoadingButtonSvgIcon;
