import { createContext } from 'react';

class PubSub {
  events: { [eventName: string]: Function[] };

  constructor() {
    this.events = {};
  }

  subscribe(eventName: string, fn: Function) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  unsubscribe(eventName: string, fn: Function) {
    if (this.events[eventName]) {
      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }

  unsubscribeAll() {
    this.events = {};
  }

  publish(eventName: string, data: any) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn) => {
        fn(data);
      });
    }
  }
}

export const PubSubContext = createContext<PubSub | null>(null);

export default PubSub;
