import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';

import { Worker } from '../../../../types/Resource';

import { useAppSelector } from '../../../../hooks';

import WorkerDefault from '../../../../assets/images/default-image/worker_default.png';
import { getAge } from '../../../../utils/getAge';
import toCapitalize from '../../../../utils/toCapitalize';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;
const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.box_bbg};
`;

const ContainerImage = styled('div')`
  margin: 10px auto;
  width: 205px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 205px;
  height: 205px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetail = styled('div')`
  display: flex;
  flex: 1;
`;

const Row = styled('div')`
  width: 50%;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 140px;
`;

const ItemRemark = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 300px;
`;

const ContainerRemark = styled('div')`
  height: 70px;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.secondary.$80};
`;

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

interface BasicInformationContentProps {
  data: Worker | undefined;
}

const BasicInformationContent: React.VFC<BasicInformationContentProps> = ({
  data,
}) => {
  const { t } = useTranslation('project-setting');
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const org = useMemo(() => {
    return orgMap[data?.orgId ?? ''];
  }, [data, orgMap]);

  return (
    <Container>
      <ContentTitle>
        {t(
          'page.worker-management.dialog.create-worker.basic-information.title',
        )}
      </ContentTitle>
      <ContainerContent>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? ''}
            alt="worker photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={WorkerDefault} alt="worker beeinventor" />
          </Avatar>
        </ContainerImage>
        <ContainerDetail>
          <Row>
            <Item>
              <Title>{t('organization')}</Title>
              <div
                style={{
                  display: 'flex',
                  height: '24px',
                  alignItems: 'center',
                }}
              >
                <OrgStatus sx={{ backgroundColor: org?.color ?? '#58B99E' }} />
                <Text title={org?.name} sx={{ marginLeft: '4px' }}>
                  {org?.name ?? 'N/A'}
                </Text>
              </div>
            </Item>
            <Item>
              <Title>{t('email')}</Title>
              <Text title={data?.email ?? undefined}>
                {data?.email ?? 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title>{t('gender-title')}</Title>
              <Text title={data?.gender}>
                {t('gender.type', {
                  type: toCapitalize(data?.gender ?? 'N/A'),
                })}
              </Text>
            </Item>
            <Item>
              <Title>{t('registration-number')}</Title>
              <Text
                title={data?.registrationNumber ? data?.registrationNumber : ''}
              >
                {data?.registrationNumber ?? 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title>{t('division')}</Title>
              <Text title={data?.division ? data?.division : ''}>
                {data?.division ?? 'N/A'}
              </Text>
            </Item>
          </Row>
          <Row>
            <Item>
              <Title>{t('worker-name')}</Title>
              <Text title={data?.name}>{data?.name ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('phone')}</Title>
              <Text title={data?.phone ? data?.phone : 'N/A'}>
                {data?.phone ? data?.phone : 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title>{t('age')}</Title>
              <Text title={data?.birthday ? data?.birthday : ''}>
                {data?.birthday ? getAge(data.birthday) : 'N/A'}
              </Text>
            </Item>
          </Row>
        </ContainerDetail>
        <ContainerRemark>
          <ItemRemark>
            <Title>{t('remark')}</Title>
            <Text title={data?.remark ? data?.remark : ''}>
              {data?.remark ?? 'N/A'}
            </Text>
          </ItemRemark>
        </ContainerRemark>
      </ContainerContent>
    </Container>
  );
};

export default BasicInformationContent;
