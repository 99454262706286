import { AiWillAssign, EnvironmentWillAssign } from '../../../types';
import { Environment } from '../../../types/Resource';

export const checkEnvironmentBasicInformationAvailable = (
  basicInformation: EnvironmentWillAssign | undefined,
) => {
  if (!basicInformation?.orgId) return false;
  if (!basicInformation?.name) return false;
  return true;
};

export const checkArea = (areaId: string | undefined) => {
  return !!areaId;
};

export const checkIsFormEnvironmentEdited = (
  selectedEnvironment: Environment,
  environmentWillAssign: EnvironmentWillAssign,
) => {
  const checkFieldValue = (field: keyof Environment) => {
    const selectedValue = selectedEnvironment[field];
    const willAssignValue = environmentWillAssign[field];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue !== willAssignValue) return true;
    }
    return false;
  };

  if (checkFieldValue('orgId')) return true;
  if (checkFieldValue('name')) return true;
  return false;
};

export const checkContactAvailable = (contacts: string[]) => {
  if (contacts.length === 0) return false;
  return true;
};

export const checkAiBasicInformationAvailable = (
  basicInformation: AiWillAssign | undefined,
) => {
  if (!basicInformation?.orgId) return false;
  if (!basicInformation?.name) return false;
  return true;
};
