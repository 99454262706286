import {
  BasicMessage,
  BasicResponse,
  Coordinates,
  DeviceCountDaswaterInterval,
  Group,
  PagedResponse,
  PagedResponseDaswater,
} from '../types';
import { DaswaterLAlert, RequestAlertsParams } from '../types/Alert';
import { Daswater } from '../types/Device';
import { Pipe } from '../types/Resource';

import DsmAxios from './DsmAxios';

export const getPipeGroups = (
  projectId: string,
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    id?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<Group>>(
    `v1/projects/${projectId}/pipe-groups`,
    {
      params,
    },
  );
};

export const createPipeGroup = ({
  projectId,
  name,
  remark,
}: {
  projectId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.post<BasicResponse<Group>>(
    `v1/projects/${projectId}/pipe-groups`,
    {
      name,
      remark,
    },
  );
};

export const updatePipeGroup = ({
  projectId,
  groupId,
  name,
  remark,
}: {
  projectId: string;
  groupId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.patch(`v1/projects/${projectId}/pipe-groups/${groupId}`, {
    name,
    remark,
  });
};

export const getProjectPipes = (data: {
  projectId: string;
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    id?: string;
    registrationNumber?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Pipe>>(
    `v1/projects/${data.projectId}/pipes`,
    {
      params: data.params,
    },
  );
};

export const getProjectDaswaters = (data: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    id?: string;
    limit?: number;
    nextCursor?: string;
    prevCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Daswater>>(
    `v1/projects/${data.projectId}/daswaters`,
    {
      params: data.params,
    },
  );
};

export const getGroupOfPipe = ({
  projectId,
  groupId,
}: {
  projectId: string;
  groupId: string;
}) => {
  return DsmAxios.get<PagedResponse<Pipe>>(
    `v1/projects/${projectId}/pipe-groups/${groupId}/pipes`,
  );
};

export const createPipe = (data: {
  projectId: string;
  payload: {
    name: string;
    height: number;
    orgId: string;
    distanceMax?: number | null;
    distanceMin?: number | null;
    batteryMin?: number;
    coordinates?: Coordinates;
    groupIds: string[];
  };
}) => {
  return DsmAxios.post<BasicResponse<Pipe>>(
    `v1/projects/${data.projectId}/pipes`,
    data.payload,
  );
};

export const updatePipe = (data: {
  projectId: string;
  pipeId: string;
  payload?: {
    name?: string;
    height?: number;
    orgId?: string;
    areaId?: string;
    distanceMax?: number | null;
    distanceMin?: number | null;
    batteryMin?: number;
    coordinates?: Coordinates;
    groupIds?: string[];
  };
}) => {
  return DsmAxios.patch<BasicResponse<Pipe>>(
    `v1/projects/${data.projectId}/pipes/${data.pipeId}`,
    data.payload,
  );
};

export const uploadPipeAvatar = ({
  projectId,
  pipeId,
  imageFile,
}: {
  projectId: string;
  pipeId: string;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post(
    `v1/projects/${projectId}/pipes/${pipeId}/avatar`,
    formData,
  );
};

export const bindDaswater = ({
  projectId,
  dasId,
  resourceId,
}: {
  projectId: string;
  dasId: string;
  resourceId: string;
}) => {
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/daswaters/${dasId}/pipe`,
    {
      resourceId,
    },
  );
};

export const unbindDaswater = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete<BasicMessage>(
    `v1/projects/${projectId}/daswaters/${dasId}/pipe`,
  );
};

export const setPipeToGroups = ({
  projectId,
  pipeId,
  groupIds,
}: {
  projectId: string;
  pipeId: string;
  groupIds: Array<string>;
}) => {
  return DsmAxios.put(`v1/projects/${projectId}/pipes/${pipeId}/groups`, {
    groupIds,
  });
};

export const setPipesToGroup = ({
  groupId,
  projectId,
  pipeIds,
}: {
  projectId: string;
  groupId: string;
  pipeIds: string[];
}) => {
  return DsmAxios.put(`v1/projects/${projectId}/pipe-groups/${groupId}/pipes`, {
    pipeIds,
  });
};

export const getUserDaswater = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<Daswater>>('v1/daswaters', {
    params,
  });
};

interface ParamsDaswater {
  fromDate: string;
  toDate: string;
  interval: 'minute' | '10m' | 'hour' | 'day';
  by: 'pipe';
  resourceId: string;
  timeZone?: string;
}

export const getStatsDaswater = (
  projectId: string,
  params?: ParamsDaswater,
) => {
  return DsmAxios.get<BasicResponse<DeviceCountDaswaterInterval>>(
    `v1/projects/${projectId}/stats/pipe`,
    { params },
  );
};

export const getDaswaterLAlerts = (
  projectId: string,
  params?: RequestAlertsParams & { resourceId?: string },
) => {
  return DsmAxios.get<PagedResponseDaswater<DaswaterLAlert>>(
    `v1/projects/${projectId}/daswater-alerts`,
    { params },
  );
};
