import { useContext, useEffect, useRef, useState } from 'react';
import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';

import { DataPoint } from '../../types';
import { DasCTag } from '../../types/Device';
import {
  WebSocketCoordinates,
  WebSocketDasCTagDataPoint,
} from '../../types/Websocket';

import { getProjectDasCTags } from '../../apis/DasCTagApi';

import { PubSubContext } from '../../utils/PubSub';

type UseProjectDasCTagMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  indexBy?: 'id' | 'dasId';
  orderBy?: 'das_id' | 'last_data_point_received_at';
  queryOptions?: {
    enabled?: boolean;
  };
  onProgress?: (progress) => void;
};

type DasCTagMap = { [id: string]: DasCTag | undefined };

export const useProjectDasCTagMapWithPages = ({
  projectId,
  indexBy = 'id',
  orderBy,
  queryKey,
  queryOptions,
  onProgress,
}: UseProjectDasCTagMapParams) => {
  const dataPointQueueRef = useRef<WebSocketDasCTagDataPoint[]>([]);
  const coordinatesQueueRef = useRef<WebSocketCoordinates[]>([]);
  const pubSub = useContext(PubSubContext);
  const [dasCTagMap, setDasCTagMap] = useState<DasCTagMap>({});
  const [total, setTotal] = useState(0);

  const { data, ...others } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const res = await getProjectDasCTags({
        projectId: projectId as string,
        params: {
          orderBy,
          nextCursor: pageParam,
        },
      });
      setTotal((origin) => {
        if (origin !== res.data.metadata.total) {
          return res.data.metadata.total;
        }

        return origin;
      });
      return res.data;
    },
    getNextPageParam: (res) => res.paging.nextCursor,
    enabled: !!projectId && (queryOptions?.enabled ?? true),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      const newDasCTagMap: DasCTagMap = {};
      let count = 0;
      data.pages
        .map((page) => page.data)
        .flat()
        .forEach((dasCTag) => {
          count++;
          if (indexBy === 'dasId') {
            newDasCTagMap[dasCTag.dasId] = dasCTag;
          } else if (indexBy === 'id') {
            newDasCTagMap[dasCTag.id] = dasCTag;
          }
        });
      setDasCTagMap((origin) => ({ ...origin, ...newDasCTagMap }));
      onProgress?.((count / (total == 0 ? 1 : total)) * 100);
    }
  }, [data, indexBy, total]);

  useEffect(() => {
    const dataPointHandler = (datapointData: WebSocketDasCTagDataPoint) => {
      if (projectId === datapointData.endpoint.metadata['dsm/projectId']) {
        dataPointQueueRef.current.push(datapointData);
      }
    };

    const coordinatesHandler = (coordinatesData: WebSocketCoordinates) => {
      if (projectId === coordinatesData.endpoint.metadata['dsm/projectId']) {
        coordinatesQueueRef.current.push(coordinatesData);
      }
    };

    pubSub?.subscribe(
      'das_collision_tag:data-point-received',
      dataPointHandler,
    );
    pubSub?.subscribe('coordinates-updated', coordinatesHandler);
    return () => {
      pubSub?.unsubscribe(
        'das_collision_tag:data-point-received',
        dataPointHandler,
      );
      pubSub?.unsubscribe('coordinates-updated', coordinatesHandler);
    };
  }, [pubSub, projectId, indexBy]);

  useEffect(() => {
    const dataPointQueueTimer = setInterval(() => {
      const dataPoints: WebSocketDasCTagDataPoint[] = [];

      while (dataPointQueueRef.current.length > 0) {
        const newDataPoint =
          dataPointQueueRef.current.shift() as WebSocketDasCTagDataPoint;
        dataPoints.push(newDataPoint);
      }

      if (dataPoints.length > 0) {
        setDasCTagMap((origin) => {
          const newDasCTagMap = { ...origin };
          dataPoints
            .filter((datapointData) => {
              return !!origin[datapointData.endpoint[indexBy]];
            })
            .map((datapointData) => {
              const newDasCTag = origin[datapointData.endpoint[indexBy]];

              if (newDasCTag) {
                const updateDataPoint: { [key: string]: DataPoint<any> } =
                  Object.entries(datapointData.dataPoint).reduce(
                    (prev, [key, value]) => {
                      if (value !== undefined || value !== null) {
                        prev[key] = {
                          timestamp: datapointData.timestamp,
                          value,
                          stale: false,
                        };
                      }
                      return prev;
                    },
                    {},
                  );

                newDasCTag.shadow.dataPoint = {
                  ...newDasCTag.shadow.dataPoint,
                  ...updateDataPoint,
                };

                newDasCTagMap[newDasCTag[indexBy]] = newDasCTag;
              }
            });

          return newDasCTagMap;
        });
      }
    }, 1000);

    const coordinatesQueueTimer = setInterval(() => {
      const coords: WebSocketCoordinates[] = [];

      while (coordinatesQueueRef.current.length > 0) {
        const webSocketCoordinates =
          coordinatesQueueRef.current.shift() as WebSocketCoordinates;
        coords.push(webSocketCoordinates);
      }

      if (coords.length > 0) {
        setDasCTagMap((origin) => {
          const newDasCTagMap = { ...origin };
          coords
            .filter((coord) => {
              return !!origin[coord.endpoint[indexBy]];
            })
            .map((coord) => {
              const newDasCTag = origin[coord.endpoint[indexBy]];

              if (newDasCTag) {
                newDasCTag.coordinates = coord.coordinates;
                newDasCTag.coordinatesSource = coord.source;
                newDasCTag.lastCoordinatesUpdatedAt = coord.timestamp;
                newDasCTagMap[newDasCTag[indexBy]] = newDasCTag;
              }
            });

          return newDasCTagMap;
        });
      }
    }, 1000);

    return () => {
      clearInterval(dataPointQueueTimer);
      clearInterval(coordinatesQueueTimer);
    };
  }, []);

  return {
    data: dasCTagMap,
    ...others,
  };
};
