import { useMemo } from 'react';

import { Group, Policy, PolicyType, ProjectRole } from '../types';

const useFilteredGroups = (
  policyName: PolicyType,
  groups: Group[],
  role: ProjectRole | null,
  policies: Policy[],
) => {
  const filteredGroups = useMemo(() => {
    let list: Group[] = [];
    if (role === 'owner' || role === 'admin') {
      list = groups;
    } else {
      const groupMap =
        groups.reduce<{ [groupId: string]: Group }>((prev, group) => {
          prev[group.id] = group;
          return prev;
        }, {}) ?? {};
      policies
        .filter((policy) => policy.name === policyName)
        .forEach((policy) => {
          if (policy.groupId && groupMap[policy.groupId]) {
            list.push(groupMap[policy.groupId]);
          }
        });
    }
    return list;
  }, [groups, role, policies]);
  return filteredGroups;
};

export default useFilteredGroups;
