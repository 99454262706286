import React, { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  capitalize,
  ClickAwayListener,
  Popper,
  styled,
  Typography,
} from '@mui/material';

import { FieldType, ResourceType } from '../../types';

import CopyClipboardSvgIcon from '../../assets/SvgIcon/CopyClipboardSvgIcon';
import DeleteSvgIcon from '../../assets/SvgIcon/DeleteSvgIcon';
import EditSvgIcon from '../../assets/SvgIcon/EditSvgIcon';
import MoreSvgIconVertical from '../../assets/SvgIcon/MoreSvgIconVertical';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
  isInList: boolean;
  disabled: boolean;
  isStructureItem?: boolean;
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' && prop !== "isInList" && prop !== "isStructureItem" })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding:  ${({ isStructureItem }) => (isStructureItem === true ? '20px 10px' : ' 12px 10px')};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  & .info {
    flex: 1;
    min-width: 0;
    & > .container-detail {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      & > span{
        margin-left: 5px;
        color: ${({theme})=> theme.color.secondary.$60};
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        max-width: 305px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    & .org {
        display: flex;
        align-items: center; 
        ${({ theme }) => ({ ...theme.externalTypography.body3 })}
        color: ${({ theme }) => theme.color.secondary.$60};
        &:before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 3px;
          background: ${({ orgColor }) =>
            orgColor ? orgColor : 'transparent'};
          margin-right: 6px;
        }
      }

    & > .info-detail{
      & > .sub-text{
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: ${({theme}) => theme.color.secondary.$60};
      }
    }
  }
  
  & > .container-icon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.arrow {
    :hover {
        background: rgba(255, 255, 255, 0.1);
     }
    }
    &.more {
        margin-left: auto;
    }

    &.hide{
        visibility: hidden;
    }
  }
`;

const ContainerPopper = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  overflow: hidden;
`;

const TextPopper = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: 'inherit',
  marginLeft: '4px',
  transition: '0.2s',
}));

interface ContainerButtonPopperProps {
  disabled?: boolean;
}

const ContainerButtonPopper = styled('div')<ContainerButtonPopperProps>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.externalColor.dark.$20};
    color: ${({ theme }) => theme.color.secondary.$40};
  }
`;

interface ResourceItemProps {
  id?: string;
  style?: React.CSSProperties;
  name: string;
  dasIds: string[];
  orgName: string;
  orgColor?: CSSProperties['color'];
  defaultImage?: string;
  imageURL: string | null;
  isInList: boolean;
  disabled: boolean;
  resourceType?: ResourceType;
  fieldType?: FieldType;
  deviceTotal?: string;
  onClick: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickDuplicate?: () => void;
}

const ResourceItem: React.FC<ResourceItemProps> = ({
  id,
  style,
  name,
  dasIds,
  orgName,
  orgColor,
  defaultImage,
  imageURL,
  isInList,
  disabled,
  resourceType,
  fieldType,
  deviceTotal,
  onClick,
  onClickEdit,
  onClickDelete,
  onClickDuplicate,
}) => {
  const { t } = useTranslation('project-setting');
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [open, setIsOpen] = useState(false);
  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement) &&
      openEdit
    ) {
      return;
    }
    setIsOpen(false);
  };
  const onMouseMove = (state: boolean) => {
    if (resourceType === 'structure') {
      setOpenEdit(state);
    }
  };

  return (
    <>
      <Container
        id={id}
        style={style}
        orgColor={orgColor}
        onClick={onClick}
        disabled={disabled}
        isInList={isInList}
        isStructureItem={resourceType === 'structure'}
        onMouseLeave={() => onMouseMove(false)}
        onMouseEnter={() => onMouseMove(true)}
      >
        <Avatar
          variant="square"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginRight: '10px',
          }}
          src={imageURL ?? defaultImage}
        />
        <div className="info">
          <div
            className="container-detail"
            title={`${name}${
              dasIds.length > 0 ? `(${dasIds.join(', ')})` : ''
            }`}
          >
            {name}{' '}
            {dasIds.length > 0 && <span>{`(${dasIds.join(', ')})`}</span>}
          </div>
          {resourceType !== 'structure' ? (
            <div className="org" title={orgName}>
              {orgName}
            </div>
          ) : (
            <div className="info-detail">
              <div className="sub-text">{fieldType}</div>
              <div className="sub-text">{deviceTotal}</div>
            </div>
          )}
        </div>
        <div
          className={`container-icon more arrow ${openEdit ? '' : 'hide'}`}
          onClick={handleToggle}
          ref={anchorRef}
        >
          <MoreSvgIconVertical />
        </div>
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="right-end"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [70, 0],
                },
              },
            ]}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <ContainerPopper>
              <ContainerButtonPopper
                onClick={(e) => {
                  e.stopPropagation();
                  onClickEdit?.();
                }}
              >
                <EditSvgIcon />
                <TextPopper>{t(`edit`)}</TextPopper>
              </ContainerButtonPopper>
              <ContainerButtonPopper
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDelete?.();
                }}
              >
                <DeleteSvgIcon />
                <TextPopper>{t(`delete`)}</TextPopper>
              </ContainerButtonPopper>
              <ContainerButtonPopper
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDuplicate?.();
                }}
              >
                <CopyClipboardSvgIcon />
                <TextPopper>{capitalize(t(`duplicate`))}</TextPopper>
              </ContainerButtonPopper>
            </ContainerPopper>
          </Popper>
        </ClickAwayListener>
      </Container>
    </>
  );
};

export default ResourceItem;
