import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    sx={{ color: 'transparent' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      d="M2.5 8.5L6.5 12.5L14.5 4.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);

export default CheckSvgIcon;
