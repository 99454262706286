import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddEquipmentSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 6C23.5523 6 24 6.44772 24 7V9H26C26.5523 9 27 9.44772 27 10C27 10.5523 26.5523 11 26 11H24V13C24 13.5523 23.5523 14 23 14C22.4477 14 22 13.5523 22 13V11H20C19.4477 11 19 10.5523 19 10C19 9.44772 19.4477 9 20 9H22V7C22 6.44772 22.4477 6 23 6ZM14 12L20 16L14 20L8 16L14 12ZM7.5 17V23.5L13.5 27.5V21L7.5 17ZM14.5 21L20.5 17V23.5L14.5 27.5V21Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AddEquipmentSvgIcon;
