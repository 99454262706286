import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasloopIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_437_20511)">
        <path
          d="M15.3093 4.8842C14.2815 5.06131 13.3109 5.95332 12.6455 7.3327C11.5253 9.65581 10.9683 12.3099 10.6944 14.2044C10.6927 14.2261 10.6896 14.2477 10.6852 14.269C10.6144 14.7687 10.5621 15.2128 10.5264 15.5812C12.7612 15.6549 15.7266 15.3026 17.9947 13.401C19.3174 12.2924 19.4799 11.6292 19.4977 11.5264C19.4977 11.5116 19.4977 11.52 19.4977 11.4909C19.4977 11.4618 19.6639 8.02239 17.7215 5.83826C16.9816 5.06842 16.1464 4.7407 15.3093 4.8842Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M14.6283 4.13581C14.133 4.04355 13.6308 3.99812 13.1277 4.00006H13.1166C12.7473 4.00006 12.3916 4.01881 12.0543 4.05371C10.4011 4.37174 9.09996 5.91724 8.18226 8.64305C7.67941 10.1414 7.36673 11.7877 7.17285 13.2485C7.568 13.4308 8.46538 13.7469 9.89393 13.723C10.1943 11.7929 10.7729 9.20863 11.8764 6.92043C12.5566 5.51454 13.5389 4.52816 14.6283 4.13581Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M7.0624 14.1812C7.01932 14.5864 6.98485 14.9691 6.95777 15.3201C6.95223 15.3905 6.94731 15.4584 6.94238 15.525C7.31196 15.6822 7.69347 15.8066 8.08289 15.8967C8.56112 16.002 9.20677 16.0414 9.61792 16.0563C9.61792 16.0285 9.62223 16.0001 9.62469 15.9703C9.653 15.6284 9.69732 15.1721 9.76626 14.6323H9.73733C8.47742 14.633 7.59172 14.3899 7.0624 14.1812Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M6.42131 8.19501C5.54423 10.277 5.4636 12.6247 5.43652 13.8438C5.63238 14.1269 5.87783 14.3682 6.15972 14.5548C6.44346 11.5355 7.1796 7.39284 9.07407 5.00122C7.91141 5.69996 7.02326 6.76779 6.42131 8.19501Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M20.3241 10.2756C20.3638 10.6901 20.3763 11.1069 20.3616 11.5232C20.3616 11.7655 20.225 12.6866 18.5324 14.1087C17.1986 15.2269 14.8536 16.4958 11.088 16.4958C10.8808 16.4958 10.6691 16.4917 10.4528 16.4835C10.4528 16.5216 10.4528 16.5423 10.4528 16.543C10.4475 16.6591 10.3997 16.7686 10.3195 16.8488C10.2393 16.929 10.1327 16.9736 10.022 16.9735C9.9703 16.9735 8.74979 16.9735 7.9078 16.7854C7.35709 16.6585 6.82069 16.4709 6.30753 16.2256C6.22918 16.1868 6.16377 16.1241 6.11985 16.0457C6.07593 15.9673 6.05554 15.8768 6.06134 15.7861C6.06626 15.713 6.07118 15.6361 6.07672 15.5566C5.01746 14.9645 4.65677 14.2541 4.61615 14.1695C4.58532 14.1038 4.57011 14.0313 4.57184 13.9581C4.57799 13.6763 4.58661 13.388 4.60015 13.0939C3.71077 13.9768 3.10267 14.876 3.00973 15.6723C2.67614 18.5222 10.992 20.7542 16.641 17.0872C19.4802 15.2412 22.3373 12.0525 20.3241 10.2756Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_437_20511">
          <rect
            width="18"
            height="15"
            fill="white"
            transform="translate(3 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DasloopIconSvg;
