import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import { Org } from '../../../../types';
import { Plant } from '../../../../types/Resource';

import { useAppSelector } from '../../../../hooks';

import EmptyDataWarning from '../../../../components/EmptyDataWarning';
import Loading from '../../../../components/Loading';
import ProjectPanel from '../../../../components/Panel/ProjectPanel';
import Search from '../../../../components/Search';

import MuiTheme from '../../../../theme';

import PlantItem from './PlantItem';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type PlantListOfGroupProps = {
  selectedId: (workerIds: Array<string>) => void;
  data: Array<Plant & { orgDetail: Org }>;
  isLoadingPlantOfAGroup: boolean;
};

const PlantListOfGroup: React.VFC<PlantListOfGroupProps> = ({
  selectedId,
  data,
  isLoadingPlantOfAGroup,
}) => {
  const { i18n } = useTranslation();
  const { selectedGroup } = useAppSelector((store) => store.plantManagement);
  const [filterText, setFilterText] = useState('');

  const [plantWillAssign, setPlantWillAssign] = useState<Array<string>>([]);

  useEffect(() => {
    const initPlantIds: Array<string> = data.map((plant) => plant.id);
    setPlantWillAssign(initPlantIds);
  }, [data]);

  const handleOnchange = (value: any, checked: boolean) => {
    const updateValue = value;

    if (checked) {
      setPlantWillAssign((prev) =>
        prev.filter((plantId) => plantId !== updateValue),
      );
    } else {
      setPlantWillAssign([...plantWillAssign, updateValue]);
    }
  };

  useEffect(() => {
    selectedId(plantWillAssign);
  }, [plantWillAssign]);

  const items = data
    .filter((plant) => plant.name.indexOf(filterText) !== -1)
    .sort((a, b) => {
      if (a.groupIds.length === 1 && b.groupIds.length > 1) {
        return 1;
      } else if (a.groupIds.length > 1 && b.groupIds.length === 1) {
        return -1;
      }
      return a.name.localeCompare(b.name, i18n.language);
    })
    .map((plant) => (
      <PlantItem
        key={`Plant-item${plant.id}`}
        name={plant.name}
        orgName={plant.orgDetail.name}
        dasIds={plant.bindingDastracks}
        orgColor={plant.orgDetail.color}
        imageURL={plant.imageURL}
        workerId={plant.id}
        onChange={handleOnchange}
        disabledPlant={plant.groupIds.length === 1 ? true : false}
      />
    ));

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={selectedGroup.name}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer data-cy="container-plant-list-group">
        {isLoadingPlantOfAGroup ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
    </ProjectPanel>
  );
};

export default PlantListOfGroup;
