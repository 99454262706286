export const separateLongLat = (searchString: string) => {
  const commaPos = searchString.indexOf(',');
  const lon = parseFloat(searchString.substring(0, commaPos));
  const lat = parseFloat(
    searchString.substring(commaPos + 1, searchString.length),
  );
  return { lon, lat };
};

export const splitCoord = (text: string) => {
  const [initLon, initLat] = text.split(',');
  const lon = parseFloat(initLon);
  const lat = parseFloat(initLat);
  return { lon, lat };
};

export const isLatitude = (num: number) => {
  return isFinite(num) && Math.abs(num) <= 90;
};

export const isLongitude = (num: number) => {
  return isFinite(num) && Math.abs(num) <= 180;
};
