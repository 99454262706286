import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CCTV } from '../../types';

type State = {
  cctv: CCTV | null;
  isDownloadVodDialog: boolean;
};

const initialState: State = {
  cctv: null,
  isDownloadVodDialog: false,
};

export const cctvDashboardPageSlice = createSlice({
  name: 'cctv-dashboard',
  initialState,
  reducers: {
    setCctv: (state, action: PayloadAction<State['cctv']>) => {
      return {
        ...state,
        cctv: action.payload,
      };
    },
    setIsDownloadVodDialog: (
      state,
      action: PayloadAction<State['isDownloadVodDialog']>,
    ) => {
      return {
        ...state,
        isDownloadVodDialog: action.payload,
      };
    },
  },
});

export const { setCctv, setIsDownloadVodDialog } =
  cctvDashboardPageSlice.actions;
export default cctvDashboardPageSlice.reducer;
