import axios from 'axios';

import { Auth } from '../types';

const BATH_AUTH_URL = import.meta.env.VITE_BASE_AUTH_URL;
if (!BATH_AUTH_URL) {
  throw new Error('VITE_BASE_AUTH_URL is required');
}

const AUTH_CLIENT_ID = import.meta.env.VITE_AUTH_CLIENT_ID;
if (!AUTH_CLIENT_ID) {
  throw new Error('VITE_AUTH_CLIENT_ID is required');
}

const AuthAxios = axios.create({
  baseURL: `${BATH_AUTH_URL}/auth/realms/dasiot/protocol/openid-connect/`,
});

export const login = async (username: string, password: string) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('username', username);
  urlencoded.append('password', password);
  urlencoded.append('grant_type', 'password');
  urlencoded.append('client_id', AUTH_CLIENT_ID);
  return AuthAxios.post<Auth>('token', urlencoded, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const logout = async () => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('client_id', AUTH_CLIENT_ID);
  urlencoded.append(
    'refresh_token',
    localStorage.getItem('refreshToken') ?? '',
  );
  return AuthAxios.post('logout', urlencoded, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const refresh = async () => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('client_id', AUTH_CLIENT_ID);
  urlencoded.append('grant_type', 'refresh_token');
  urlencoded.append(
    'refresh_token',
    localStorage.getItem('refreshToken') ?? '',
  );
  return AuthAxios.post<Auth>('token', urlencoded, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export default AuthAxios;
