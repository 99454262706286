import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const TunnelDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "tunnel-dashboard" */ '../../pages/tunnel-dashboard'
    ),
);

const tunnelDashboardRouter = (
  <Route path="tunnel-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <TunnelDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default tunnelDashboardRouter;
