import { AreaType, AreaTypeColor, CardColor, PathColor } from '../types';

import theme from '../theme';

export const THEME_AREA_COLOR: {
  normal: string;
  restricted: string;
  danger: string;
  transparent: string;
  boundary: string;
  white: string;
  black: string;
  pipe: string;
  attendance: string;
  confined: string;
  rest: string;
  'path-red': string;
  'path-orange': string;
  'path-yellow': string;
  'path-green': string;
  'path-blue': string;
  'path-purple': string;
} = {
  normal: '#5296D5',
  restricted: '#E6A600',
  danger: '#CC0000',
  boundary: '#A1A1A1',
  black: '#000000',
  transparent: 'transparent',
  white: '#ffffff',
  pipe: '#00D4FF',
  attendance: '#B152C6',
  confined: '#E96363',
  rest: '#58B99E',
  'path-red': '#FF6B00',
  'path-orange': '#EF8C34',
  'path-yellow': '#FFCE4F',
  'path-green': '#58B99E',
  'path-blue': '#5296D5',
  'path-purple': '#B152C6',
};

export const listCardColor: { colorName: CardColor; colorHex: string }[] = [
  {
    colorName: 'red',
    colorHex: '#FF6B00',
  },
  {
    colorName: 'orange',
    colorHex: '#EF8C34',
  },
  {
    colorName: 'yellow',
    colorHex: '#FFCE4F',
  },
  {
    colorName: 'green',
    colorHex: '#58B99E',
  },
  {
    colorName: 'blue',
    colorHex: '#5296D5',
  },
  {
    colorName: 'purple',
    colorHex: '#B152C6',
  },
  {
    colorName: 'gray',
    colorHex: '#A1A1A1',
  },
  {
    colorName: 'white',
    colorHex: '#ffffff',
  },
];

export const getCardColor = (color: CardColor) => {
  switch (color) {
    case 'red':
      return listCardColor[0].colorHex;
    case 'orange':
      return listCardColor[1].colorHex;
    case 'yellow':
      return listCardColor[2].colorHex;
    case 'green':
      return listCardColor[3].colorHex;
    case 'blue':
      return listCardColor[4].colorHex;
    case 'purple':
      return listCardColor[5].colorHex;
    case 'gray':
      return listCardColor[6].colorHex;
    case 'white':
      return listCardColor[7].colorHex;
    default:
      return listCardColor[7].colorHex;
  }
};

export const getAreaColor: (areaType: AreaTypeColor) => string = (areaType) => {
  switch (areaType) {
    case 'normal':
      return THEME_AREA_COLOR.normal;
    case 'boundary':
      return THEME_AREA_COLOR.black;
    case 'restricted':
      return THEME_AREA_COLOR.restricted;
    case 'danger':
      return THEME_AREA_COLOR.danger;
    case 'path':
      return THEME_AREA_COLOR.white;
    case 'pipe':
      return THEME_AREA_COLOR.pipe;
    case 'attendance':
      return THEME_AREA_COLOR.pipe;
    case 'rest':
      return THEME_AREA_COLOR.rest;
    case 'path-red':
      return THEME_AREA_COLOR['path-red'];
    case 'path-orange':
      return THEME_AREA_COLOR['path-orange'];
    case 'path-yellow':
      return THEME_AREA_COLOR['path-yellow'];
    case 'path-green':
      return THEME_AREA_COLOR['path-green'];
    case 'path-blue':
      return THEME_AREA_COLOR['path-blue'];
    case 'path-purple':
      return THEME_AREA_COLOR['path-purple'];
    case 'confined':
      return THEME_AREA_COLOR.confined;
    default:
      return THEME_AREA_COLOR.boundary;
  }
};

export const getAreaColorFromType = (type: AreaType, pathColor: PathColor) => {
  let colorType: AreaTypeColor;
  if (type === 'path') {
    colorType = (type + '-' + pathColor) as AreaTypeColor;
  } else {
    colorType = type;
  }
  return colorType;
};

export const getHexColor = (color: PathColor) => {
  switch (color) {
    case 'red':
      return THEME_AREA_COLOR['path-red'];
    case 'green':
      return THEME_AREA_COLOR['path-green'];
    case 'blue':
      return THEME_AREA_COLOR['path-blue'];
    case 'orange':
      return THEME_AREA_COLOR['path-orange'];
    case 'purple':
      return THEME_AREA_COLOR['path-purple'];
    case 'yellow':
      return THEME_AREA_COLOR['path-yellow'];
    default:
      return THEME_AREA_COLOR.boundary;
  }
};

export const getAreaOutlineColor: (areaType: AreaTypeColor) => string = (
  areaType,
) => {
  switch (areaType) {
    case 'normal':
      return THEME_AREA_COLOR.normal;
    case 'boundary':
      return THEME_AREA_COLOR.black;
    case 'restricted':
      return THEME_AREA_COLOR.restricted;
    case 'danger':
      return THEME_AREA_COLOR.danger;
    case 'path':
      return THEME_AREA_COLOR.white;
    case 'pipe':
      return THEME_AREA_COLOR.pipe;
    case 'attendance':
      return THEME_AREA_COLOR.attendance;
    case 'confined':
      return THEME_AREA_COLOR.confined;
    case 'rest':
      return THEME_AREA_COLOR.rest;
    case 'path-red':
      return THEME_AREA_COLOR['path-red'];
    case 'path-orange':
      return THEME_AREA_COLOR['path-orange'];
    case 'path-yellow':
      return THEME_AREA_COLOR['path-yellow'];
    case 'path-green':
      return THEME_AREA_COLOR['path-green'];
    case 'path-blue':
      return THEME_AREA_COLOR['path-blue'];
    case 'path-purple':
      return THEME_AREA_COLOR['path-purple'];
    default:
      return THEME_AREA_COLOR.boundary;
  }
};

export const handleCircleAreaColor: (areaType: AreaType) => string = (
  areaType,
) => {
  if (areaType === 'normal') {
    return THEME_AREA_COLOR.normal;
  } else if (areaType === 'boundary') {
    return THEME_AREA_COLOR.boundary;
  } else if (areaType === 'restricted') {
    return THEME_AREA_COLOR.restricted;
  } else if (areaType === 'danger') {
    return THEME_AREA_COLOR.danger;
  } else if (areaType === 'attendance') {
    return THEME_AREA_COLOR.attendance;
  } else if (areaType === 'confined') {
    return THEME_AREA_COLOR.confined;
  } else if (areaType === 'pipe') {
    return THEME_AREA_COLOR.pipe;
  } else if (areaType === 'rest') {
    return THEME_AREA_COLOR.rest;
  } else {
    return THEME_AREA_COLOR.transparent;
  }
};

export const getHazardRankColor = (value: number) => {
  if (value >= 0 && value <= 26.8) {
    return theme.color.secondary.$40;
  } else if (value > 26.8 && value <= 32.2) {
    return theme.color.green.$100;
  } else if (value > 32.2 && value <= 40.6) {
    return theme.color.primary.$60;
  } else if (value > 40.6 && value <= 54.4) {
    return theme.color.primary.$80;
  } else if (value > 54.4) {
    return theme.color.red.$100;
  } else {
    return THEME_AREA_COLOR.transparent;
  }
};
