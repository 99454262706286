import { BasicResponse, PagedResponse } from '../types';
import {
  DasairAlert,
  DasairTypeParam,
  RequestAlertsParams,
} from '../types/Alert';
import { Dasair } from '../types/Device';

import DsmAxios from './DsmAxios';

export const getUserDasairs = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<Dasair>>(`v1/dasairs`, { params });
};

export const getProjectDasairs = (data: {
  projectId: string;
  params?: {
    id?: string;
    dasId?: string;
    beaconId?: string;
    nextCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Dasair>>(
    `v1/projects/${data.projectId}/dasairs`,
    {
      params: data.params,
    },
  );
};

export const getProjectDasair = ({
  projectId,
  params,
}: {
  projectId: string;
  params?: {
    id?: string;
    dasId?: string;
    beaconId?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Dasair>>(
    `v1/projects/${projectId}/dasairs`,
    {
      params,
    },
  );
};

export const getDasairByDasId = (data: { dasId: string }) => {
  return DsmAxios.get<BasicResponse<Dasair>>(`v1/dasairs/${data.dasId}`);
};

export const getDasairAlerts = (
  projectId: string,
  params?: RequestAlertsParams & { endpointId?: string },
) => {
  return DsmAxios.get<PagedResponse<DasairAlert>>(
    `v1/projects/${projectId}/dasair-alerts`,
    { params },
  );
};

interface BasicGetStatOfAirParams {
  fromDate: string;
  toDate: string;
  timeZone: string;
  endpointId: string;
  type: DasairTypeParam;
}

type DeviceCountDataInterval = {
  intervals: {
    [time: string]: {
      value: number | null;
    };
  };
};

export const getStatsDasair = (
  projectId: string,
  params?: Partial<BasicGetStatOfAirParams>,
) => {
  return DsmAxios.get<DeviceCountDataInterval>(
    `v1/projects/${projectId}/stats/air`,
    { params },
  );
};
