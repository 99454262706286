import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ReportPageState = {
  resourceIds: string[];
};

const initialState: ReportPageState = {
  resourceIds: [],
};

export const reportPageSlice = createSlice({
  name: 'reportPage',
  initialState,
  reducers: {
    setWorkerIdsSelected: (
      state,
      action: PayloadAction<ReportPageState['resourceIds']>,
    ) => {
      return {
        ...state,
        resourceIds: action.payload,
      };
    },
  },
});

export const { setWorkerIdsSelected } = reportPageSlice.actions;
export default reportPageSlice.reducer;
