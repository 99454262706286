import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasCTagSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1091 7.66434C20.2233 6.77855 18.7872 6.77855 17.9014 7.66434L11.4859 14.0798C10.6001 14.9656 10.6001 16.4017 11.4859 17.2875L16.2977 22.0992L12.2881 26.1087C11.8452 26.5516 11.8452 27.2697 12.2881 27.7126C12.731 28.1555 13.4491 28.1555 13.892 27.7126L17.9015 23.7031L19.5052 25.3067L13.8917 30.9202L13.8574 30.9548C13.0468 31.7653 13.1499 32.6789 15.8907 32.9881C17.1918 33.1348 17.5543 31.9054 17.5273 30.4923L21.109 26.9106L22.7129 28.5145C23.5987 29.4003 25.0349 29.4003 25.9207 28.5145L32.3361 22.0991C33.2219 21.2133 33.2219 19.7771 32.3361 18.8913L21.1091 7.66434Z"
        fill="#A1A1A1"
      />
      <path
        d="M13.0894 18.8914L14.6932 20.4952L9.04524 26.1431C8.23467 26.9537 7.32106 26.8507 7.01192 24.1098C6.86519 22.8088 8.0945 22.4463 9.50746 22.4733L13.0894 18.8914Z"
        fill="#A1A1A1"
      />
    </SvgIcon>
  );
};

export default DasCTagSvgColorMedium;
