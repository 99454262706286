import { BasicMessage, BasicResponse, PagedResponse } from '../types';
import { DastempAlert, RequestAlertsParams } from '../types/Alert';
import { Dastemp } from '../types/Device';

import DsmAxios from './DsmAxios';

export const getDastempAlerts = (
  projectId: string,
  params?: RequestAlertsParams & { equipmentId?: string },
) => {
  return DsmAxios.get<PagedResponse<DastempAlert>>(
    `v1/projects/${projectId}/dastemp-alerts`,
    { params },
  );
};

export const getDastempStatOfTemperature = (
  projectId: string,
  params: {
    fromDate?: string;
    toDate?: string;
    by: 'equipment';
    equipmentId: string;
  },
) => {
  return DsmAxios.get<
    BasicResponse<{
      intervals: { [index: string]: number | null };
    }>
  >(`v1/projects/${projectId}/stats/temperature`, { params });
};

export const dismissDatempAlert = (projectId: string, alerId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastemp-alerts/${alerId}/dismiss`,
  );
};

export const undismissDatempAlert = (projectId: string, alerId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastemp-alerts/${alerId}/undismiss`,
  );
};

export const undismissDaswaterAlert = (projectId: string, alerId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/daswater-alerts/${alerId}/undismiss`,
  );
};

export const readDastempAlert = (projectId: string, alerId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastemp-alerts/${alerId}/read`,
  );
};

export const unReadDastempAlert = (projectId: string, alerId: string) => {
  return DsmAxios.post(
    `v1/projects/${projectId}/dastemp-alerts/${alerId}/unread`,
  );
};

export const getUserDastemps = (params?: {
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<Dastemp>>(`v1/dastemps`, {
    params,
  });
};

export const getProjectDastemps = ({
  projectId,
  params,
}: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    nextCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Dastemp>>(
    `v1/projects/${projectId}/dastemps`,
    {
      params,
    },
  );
};

export const getDastempByDasId = (data: { dasId: string }) => {
  return DsmAxios.get<BasicResponse<Dastemp>>(`v1/dastemps/${data.dasId}`);
};

export const bindDastempToEquipment = ({
  projectId,
  dasId,
  equipmentId,
}: {
  projectId: string;
  dasId: string;
  equipmentId: string;
}) => {
  return DsmAxios.put<BasicMessage>(
    `v1/projects/${projectId}/dastemps/${dasId}/equipment`,
    {
      equipmentId,
    },
  );
};

export const unbindDastempFromEquipment = ({
  projectId,
  dasId,
}: {
  projectId: string;
  dasId: string;
}) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/dastemps/${dasId}/equipment`,
  );
};

export const setTemperatureLimit = ({
  projectId,
  equipmentId,
  maxTemperature,
  minTemperature,
}: {
  projectId: string;
  equipmentId: string;
  maxTemperature?: number | null;
  minTemperature?: number | null;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/equipments/${equipmentId}/temperature-limit`,
    {
      maxTemperature,
      minTemperature,
    },
  );
};
