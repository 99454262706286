import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, styled, Typography } from '@mui/material';

import EmptyIconSvg from '../assets/SvgIcon/EmptyIconSvg';

interface EmptyDataWarningProps extends BoxProps {
  mode: 'dark' | 'light';
}

const Container = styled(Box)<EmptyDataWarningProps>`
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 210px;
  background-color: ${({ mode }) => (mode === 'light' ? 'white' : '#333333')};
  border-radius: 4px;
`;

const ContainerEmpty = styled('div')`
  color: ${({ theme }) => theme.color.secondary.$60};
`;

const TextData = styled(Typography)`
  color: ${({ theme }) => theme.color.secondary.$60};
`;

const EmptyDataWarning: React.VFC<EmptyDataWarningProps> = ({
  mode = 'dark',
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Container mode={mode} {...props}>
      <ContainerEmpty>
        <EmptyIconSvg sx={{ width: '160px', height: '160px' }} />
      </ContainerEmpty>
      <TextData variant="body2">
        {t('account:page.my-devices.no-data')}
      </TextData>
    </Container>
  );
};

export default EmptyDataWarning;
