import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateAccountData } from '../../types';

type LoginPageState = {
  createAccountData: CreateAccountData | null;
};

const initialState: LoginPageState = {
  createAccountData: null,
};

export const registerPageSlice = createSlice({
  name: 'registerPage',
  initialState,
  reducers: {
    setCreateAccountData: (
      state,
      action: PayloadAction<CreateAccountData | null>,
    ) => {
      return {
        ...state,
        createAccountData: action.payload,
      };
    },
  },
});

export const { setCreateAccountData } = registerPageSlice.actions;
export default registerPageSlice.reducer;
