import { Box3, Group, Mesh, Scene, Vector3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { MeshoptDecoder } from '../../../../utils/3d/meshopt_decoder.module';

class GLTFAgent {
  gltfLoader: GLTFLoader;

  gltfModelData: Array<{
    gltfModel: Group;
    position: { x: number; y: number; z: number };
    rotation: { heading: number; pitch: number; roll: number };
  }>;

  originOffset: { x: number; y: number; z: number } = { x: 0, y: 0, z: 0 };

  scene: Scene;

  onProgress?: (progress: number) => void;

  constructor(
    scene: Scene,
    config?: { originOffset?: { x: number; y: number; z: number } },
    onProgress?: (progress: number) => void,
  ) {
    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.setMeshoptDecoder(MeshoptDecoder);
    this.gltfModelData = [];
    this.scene = scene;
    this.originOffset = config?.originOffset ?? { x: 0, y: 0, z: 0 };
    this.onProgress = onProgress;
  }

  setOriginOffset(x: number, y: number, z: number) {
    this.originOffset = { x, y, z };
  }

  loadGLTF(
    filePath: string,
    position: { x: number; y: number; z: number },
    rotation: {
      heading: number;
      pitch: number;
      roll: number;
    },
    scale: number,
    rotationTuneParams: {
      heading: number;
      pitch: number;
      roll: number;
    } | null,
  ) {
    return new Promise<{
      group: THREE.Group;
      centerPosition: {
        x: number;
        y: number;
        z: number;
      };
    }>((resolve) => {
      this.gltfLoader.load(
        filePath,
        (gltf) => {
          const gltfModel = gltf.scene;

          gltfModel.rotation.set(
            (((rotation?.roll ?? 0) + (rotationTuneParams?.roll ?? 0) - 180) *
              Math.PI) /
              180,
            (((rotation?.heading ?? 0) +
              (rotationTuneParams?.heading ?? 0) -
              180) *
              Math.PI) /
              180,
            (((rotation?.pitch ?? 0) + (rotationTuneParams?.pitch ?? 0) - 180) *
              Math.PI) /
              180,
          );
          gltfModel.scale.set(scale, scale, scale);
          gltfModel.updateMatrixWorld();
          const boundingbox = new Box3().setFromObject(gltfModel);
          const centerVector = boundingbox.getCenter(new Vector3());

          const posX = position.x - this.originOffset.x - centerVector.x;
          const posY = position.y - this.originOffset.y;
          const posZ = position.z - this.originOffset.z - centerVector.z;

          gltfModel.position.set(posX, posY, posZ);

          this.gltfModelData.push({
            gltfModel,
            position: {
              x: posX,
              y: posY,
              z: posZ,
            },
            rotation,
          });

          gltfModel.traverse((child) => {
            if (child instanceof Mesh) {
              const mat = child.material as THREE.Material;
              mat.transparent = true;
              mat.opacity = 0.4;
            }
          });

          const centerPosition: {
            x: number;
            y: number;
            z: number;
          } = {
            x: position.x,
            y: position.y + centerVector.y,
            z: position.z,
          };
          resolve({ group: gltf.scene, centerPosition });

          this.scene.add(gltf.scene);
        },
        (event) => {
          const percent = (event.loaded / event.total) * 100;
          this.onProgress?.(percent);
        },
        () => {
          throw new Error('Failed load gltf model.');
        },
      );
    });
  }

  dispose() {
    if (this.gltfModelData.length > 0) {
      this.gltfModelData.forEach((gltfModel) => {
        this.scene.remove(gltfModel.gltfModel);
      });
    }
  }
}

export default GLTFAgent;
