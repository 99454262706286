import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { Group, ResourceType } from '../../../types';

import EmptyData from '../../../components/EmptyData';

const Container = styled('div', { label: 'Container-Group-Content' })`
  height: calc(100% - 48px);
  width: 100%;
  overflow-y: auto;
`;
const ContainerContent = styled('div')`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 12px 10px;
  background-color: ${({ theme }) => theme.color.secondary.$100};
`;

const TextGroup = styled(Typography)`
  color: white;
  margin-left: 12px;
`;

interface GroupViewProps {
  group: Array<Group>;
  resource: ResourceType;
}

const GroupView: React.FC<GroupViewProps> = ({ group, resource }) => {
  const { t } = useTranslation('project-setting');

  return (
    <Container>
      <ContentTitle>
        {t(
          `page.${resource}-management.dialog.create-${resource}.${resource}-group.title`,
        )}
      </ContentTitle>
      <ContainerContent>
        {group.length > 0 ? (
          group.map((g) => (
            <ItemContainer key={g.id}>
              <TextGroup>{g.name}</TextGroup>
            </ItemContainer>
          ))
        ) : (
          <EmptyData />
        )}
      </ContainerContent>
    </Container>
  );
};

export default GroupView;
