import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, styled, Typography } from '@mui/material';

const Container = styled(Box)`
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  color: ${({ theme }) => theme.color.box_bbg};
  border-radius: 4px;
`;
const EmptyData: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Container {...props}>
      <Typography variant="body2">{t('no-data')}</Typography>
    </Container>
  );
};

export default EmptyData;
