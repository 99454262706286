import * as React from 'react';

const DasAoATagSvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1961 4.55493C22.8407 4.19961 22.3588 4 21.8563 4L18.067 4C17.5646 4 17.0826 4.19961 16.7273 4.55493L4.55493 16.7273C3.81502 17.4672 3.81502 18.6669 4.55493 19.4068L12.5932 27.4451C13.3331 28.185 14.5328 28.185 15.2727 27.4451L27.4451 15.2727C27.8004 14.9174 28 14.4355 28 13.933L28 10.1437C28 9.64117 27.8004 9.15926 27.4451 8.80394L23.1961 4.55493ZM20.909 7.62676C20.3122 7.02998 20.7349 6.00958 21.5789 6.00958C21.8301 6.00958 22.0711 6.10938 22.2487 6.28704L25.713 9.75127C25.8906 9.92893 25.9904 10.1699 25.9904 10.4211C25.9904 11.2651 24.97 11.6878 24.3732 11.091L20.909 7.62676ZM16.6124 7.3493C16.9823 6.97934 17.5822 6.97934 17.9521 7.3493L24.6507 14.0479C25.0207 14.4178 25.0207 15.0177 24.6507 15.3876L14.6028 25.4355C14.2329 25.8054 13.6331 25.8054 13.2631 25.4355L6.56451 18.7369C6.19455 18.3669 6.19455 17.7671 6.56451 17.3972L16.6124 7.3493Z"
      fill="#A1A1A1"
    />
    <path
      d="M17.6172 9.02396C17.4322 8.83898 17.1323 8.83898 16.9474 9.02396L8.2392 17.7321C8.05422 17.9171 8.05422 18.217 8.2392 18.402L13.5981 23.7609C13.783 23.9458 14.083 23.9458 14.2679 23.7609L22.9761 15.0527C23.1611 14.8677 23.1611 14.5678 22.9761 14.3828L17.6172 9.02396Z"
      fill="#E5E5E5"
    />
  </svg>
);

export default DasAoATagSvgColor;
