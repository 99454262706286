import * as React from 'react';

const DasloopSvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0414 15.0845C27.0692 15.6162 27.0602 16.1493 27.0142 16.6797C27.0142 16.6797 27.0686 17.7649 24.7072 19.6832C20.9021 22.7707 15.6531 22.9853 12.1904 22.6136C12.2035 19.89 12.6003 11.7292 16.7697 8.69921C21.5718 5.20899 25.0323 8.94922 25.103 9.03109C26.6 11.0013 26.9729 13.4904 27.0414 15.0845Z"
      fill="#FFB905"
    />
    <path
      d="M12.1906 22.6137C12.1906 22.8272 12.1906 23.0075 12.1906 23.1491C11.7666 23.1049 11.386 23.054 11.0588 23.002C10.7315 22.95 10.4544 22.9013 10.2391 22.8593C9.52651 22.7279 8.83118 22.5134 8.16691 22.2199C8.16691 22.1093 8.15929 21.9079 8.1582 21.6181V21.5484C8.1582 18.9974 8.49196 10.6419 12.7385 7.57312C13.8768 6.75228 14.9499 6.32417 15.9088 6.16597C16.6371 6.05387 17.3726 5.9984 18.1092 6.00004C21.6469 6.00004 23.7974 7.31758 25.0977 9.03116C25.0248 8.95041 21.5665 5.21018 16.7643 8.69929C12.6048 11.7293 12.2036 19.8901 12.1906 22.6137Z"
      fill="#FFCE4F"
    />
    <path
      d="M15.9111 6.16602C14.9522 6.32421 13.8791 6.75233 12.7408 7.57316C8.49429 10.6419 8.16054 18.9974 8.16054 21.5484V21.6181H8.14748C7.0255 21.0373 6.86133 20.43 6.86133 20.43C6.87546 19.7972 6.89938 18.9919 6.97005 18.0825C7.32882 13.8058 8.83895 7.28554 15.9111 6.16602Z"
      fill="#E6A600"
    />
    <path
      d="M25.3061 20.4476C23.5361 21.8857 19.6332 24.1502 13.0067 23.6689C12.9603 23.7429 12.9047 23.8106 12.8415 23.8703C12.6634 24.0362 12.4306 24.1278 12.1892 24.1269C12.1566 24.1269 12.1239 24.1269 12.0902 24.1214C11.688 24.0794 11.2912 24.0274 10.9117 23.9687C10.6128 23.9223 10.3235 23.8725 10.0539 23.8183C9.26862 23.6726 8.5025 23.435 7.77083 23.1103C7.60678 23.035 7.46685 22.9143 7.36706 22.7619C7.26728 22.6095 7.21169 22.4316 7.20659 22.2485C7.20659 22.2341 7.20659 22.2164 7.20659 22.1976C6.23573 21.556 5.98785 20.8978 5.93132 20.6898C5.9062 20.598 5.89445 20.503 5.89649 20.4077C5.90302 20.1378 5.91065 19.8446 5.92261 19.5327C4.85391 20.6068 4.13092 21.6921 4.0135 22.6589C3.56667 26.3516 14.3059 28.9845 21.8608 24.2331C25.0234 22.2419 28.4296 19.2008 27.9556 16.9319C27.8643 17.5204 27.3968 18.7495 25.3061 20.4476Z"
      fill="#FFB905"
    />
  </svg>
);

export default DasloopSvgColor;
