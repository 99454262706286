import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Contact, DeviceType, Group, ResourceType } from '../../../types';
import { Dascas, DasConcrete, Dasloop, Device } from '../../../types/Device';
import {
  Environment,
  Plant,
  Resource,
  Structure,
  Worker,
} from '../../../types/Resource';

import EditEnvironment from './EditEnvironment';
import EditStructure from './EditStructure';
import EditTowerCrane from './EditTowerCrane';
import EditWorker from './EditWorker';

interface EditResourceProps {
  projectId: string | undefined;
  resourceType: ResourceType;
  navigatorIndex: number;
  selectedResource: Resource | undefined;
  groups: Group[];
  resourceContacts: Contact[];
  assignedDevices: Array<{ type: DeviceType; device: Device }>;
  onSelectNavigatorIndex: (index: number) => void;
  onCloseDialog: () => void;
  onSuccess: () => void;
  onSuccessBind?: () => void;
}

const EditResource: React.FC<EditResourceProps> = ({
  resourceType,
  navigatorIndex,
  selectedResource,
  groups,
  resourceContacts,
  assignedDevices,
  onSelectNavigatorIndex,
  onCloseDialog,
  onSuccess,
  onSuccessBind,
}) => {
  const { t } = useTranslation('project-setting');
  const title = t(
    `page.${resourceType}-management.button-more.edit-resource-information`,
  );
  const selectedGroups = useMemo(() => {
    return groups.filter((group) =>
      selectedResource?.groupIds.includes(group.id),
    );
  }, [selectedResource, groups]);

  if (resourceType === 'worker') {
    return (
      <EditWorker
        open
        title={title}
        navigatorIndex={navigatorIndex}
        groups={groups}
        selectedGroups={selectedGroups}
        selectedResource={selectedResource as Worker}
        resourceContacts={resourceContacts}
        connectedDevice={assignedDevices
          .filter(({ type }) => type === 'dasloop')
          .map<Dasloop>(({ device }) => device as Dasloop)}
        onCloseDialog={onCloseDialog}
        onSuccessCreateWorker={onSuccess}
        onSuccessBind={onSuccessBind}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
      />
    );
  } else if (resourceType === 'environment') {
    return (
      <EditEnvironment
        open
        title={title}
        navigatorIndex={navigatorIndex}
        groups={groups}
        selectedGroups={selectedGroups}
        selectedResource={selectedResource as Environment}
        resourceContacts={resourceContacts}
        onCloseDialog={onCloseDialog}
        onSuccessCreate={onSuccess}
        onSuccessBind={onSuccessBind}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
      />
    );
  } else if (resourceType === 'crane') {
    return (
      <EditTowerCrane
        open
        title={title}
        navigatorIndex={navigatorIndex}
        groups={groups}
        selectedGroups={selectedGroups}
        selectedResource={selectedResource as Plant}
        resourceContacts={resourceContacts}
        connectedDevice={assignedDevices
          .filter(({ type }) => type === 'dascas')
          .map<Dascas>(({ device }) => device as Dascas)}
        onCloseDialog={onCloseDialog}
        onSuccessCreateCrane={onSuccess}
        onSuccessBind={onSuccessBind}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
      />
    );
  } else if (resourceType === 'structure') {
    return (
      <EditStructure
        open
        title={title}
        navigatorIndex={navigatorIndex}
        groups={groups}
        selectedGroups={selectedGroups}
        selectedResource={selectedResource as Structure}
        resourceContacts={resourceContacts}
        connectedDevice={assignedDevices
          .filter(({ type }) => type === 'dasconcrete')
          .map<DasConcrete>(({ device }) => device as DasConcrete)}
        onCloseDialog={onCloseDialog}
        onSuccessCreateCrane={onSuccess}
        onSuccessBind={onSuccessBind}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
      />
    );
  }

  return null;
};

export default EditResource;
