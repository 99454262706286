import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';

import {
  Contact,
  VehicleAssign,
  VehicleGroup,
  VehicleWithOrg,
} from '../../../../types';
import { DastrackV } from '../../../../types/Device';
import { ResourceNavigator } from '../../../../types/Resource';

import CheckListItem from '../../../../components/CheckListItem';

import CleanDateSvgIcon from '../../../../assets/SvgIcon/CleanDateSvgIcon';
import EditSvgIcon from '../../../../assets/SvgIcon/EditSvgIcon';
import ConnectedDeviceView from '../../management/content/ConnectedDeviceView';
import ContactView from '../../management/content/ContactView';
import GroupView from '../../management/content/GroupView';
import PathView from '../../management/content/PathView';
import {
  checkResourceHasContact,
  hasAssignedDevice,
} from '../../management/validation/management-validation';
import {
  checkIsPathSelected,
  checkIsVehicleInformationValid,
} from '../../management/validation/vehicle-validation';

import BasicInformationContentView from './BasicInformationContentView';

const Dialog = styled(MuiDialog)`
  color: white;
  & .MuiDialog-paper {
    width: 676px;
    height: 686px;
    max-width: unset;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px 8px 16px;
  height: 48px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  font-family: 'Montserrat';
  flex: 1;
  color: white;
  padding: 0;
`;

const ContentWrapper = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  @media only screen and (max-width: 600px) {
    min-height: 240px;
  }
`;

const ContainerSelected = styled('div')`
  width: 380px;
  border-radius: 4px;
  padding: 10px 20px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const IconButtonStyled = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: 0.3s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

interface ViewVehicleProps extends DialogProps {
  title: string;
  navigator: ResourceNavigator;
  navigatorIndex: number;
  vehicleSelected: VehicleWithOrg | undefined;
  groupSelected: Array<VehicleGroup>;
  connectedDevice: Array<DastrackV>;
  vehicleContact: Contact[];
  onCloseDialog: () => void;
  setDialogEdit: (isOpen: boolean) => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const ViewVehicle: React.FC<ViewVehicleProps> = ({
  open,
  title,
  navigator,
  navigatorIndex,
  vehicleSelected,
  groupSelected,
  connectedDevice,
  vehicleContact,
  onCloseDialog,
  setDialogEdit,
  onSelectNavigatorIndex,
}) => {
  const { t } = useTranslation('project-setting');
  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const [vehicleAssign, setVehicleAssign] = useState<VehicleAssign>();

  useEffect(() => {
    setVehicleAssign(vehicleSelected);
  }, [vehicleSelected]);

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [navigatorIndex]);

  const dasIdAssign = connectedDevice.map((device) => ({
    id: device.id,
    dasId: device.dasId,
    name: device.dasId,
    batteryLevel: device.shadow.dataPoint.batteryLevel.value
      ? device.shadow.dataPoint.batteryLevel.value
      : 0,
  }));

  return (
    <Dialog open={open}>
      <Header>
        <DialogTitle>{title}</DialogTitle>
        <IconButtonStyled
          onClick={() => {
            onCloseDialog();
            setDialogEdit(true);
          }}
          data-cy="dialog-edit-vehicle"
        >
          <EditSvgIcon
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          />
        </IconButtonStyled>
        <CleanDateSvgIcon
          sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={onCloseDialog}
        />
      </Header>
      <ContentWrapper>
        <DialogContent>
          <CheckList>
            {navigator.map((item, index) => {
              let checked: boolean = false;
              switch (item.name) {
                case 'group':
                  checked = true;
                  break;
                case 'path':
                  checked = checkIsPathSelected(vehicleSelected?.areaId);
                  break;
                case 'vehicle-basic-information':
                  checked = checkIsVehicleInformationValid(vehicleAssign);
                  break;
                case 'emergency-contact':
                  checked = checkResourceHasContact(
                    vehicleContact.map((cp) => cp.id),
                  );
                  break;
                case 'connected-device':
                  checked = hasAssignedDevice(dasIdAssign);
              }

              return (
                <CheckListItem
                  key={item.id}
                  name={t(`navigator.${item.name}`)}
                  required={item.required}
                  checked={checked}
                  onClick={() => onSelectNavigatorIndex(index)}
                  selected={checkedNavigator[index]}
                />
              );
            })}
          </CheckList>
        </DialogContent>
        <ContainerSelected>
          {navigator[navigatorIndex].name === 'group' && (
            <GroupView group={groupSelected} resource="vehicle" />
          )}
          {navigator[navigatorIndex].name === 'vehicle-basic-information' && (
            <BasicInformationContentView data={vehicleSelected} />
          )}
          {navigator[navigatorIndex].name === 'path' && (
            <PathView
              onChange={() => {}}
              selectedPath={vehicleSelected?.areaId}
              disabled
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactView data={vehicleContact} />
          )}
          {navigator[navigatorIndex].name === 'connected-device' && (
            <ConnectedDeviceView data={dasIdAssign} resource="vehicle" />
          )}
        </ContainerSelected>
      </ContentWrapper>
    </Dialog>
  );
};

export default ViewVehicle;
