import { useEffect } from 'react';

import { FloorPlan } from '../../../types';

import { addImageFloorPlan } from '../Map2d/addLayers';

import '../Map2d/index.css';

const useMapboxFloorPlan = (
  isMapReady: boolean,
  floorPlans: FloorPlan[],
  mapLibreRef: maplibregl.Map | null,
) => {
  useEffect(() => {
    if (isMapReady && floorPlans.length > 0 && mapLibreRef) {
      floorPlans?.forEach((floorPlan) => {
        if (floorPlan.imageURL) {
          addImageFloorPlan(
            mapLibreRef,
            {
              id: floorPlan.id,
              url: floorPlan.imageURL,
              coordinates: floorPlan.rectangle,
              altitude: floorPlan.altitude,
              opacity: floorPlan.transparency,
            },
            'area-layer',
          );
        }
      });
    }
  }, [isMapReady, floorPlans]);
};

export default useMapboxFloorPlan;
