import * as React from 'react';

const DasairSvgColor = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 16H21.9722C22.5711 16 23.1564 15.8241 23.6544 15.4944C24.1523 15.1648 24.5403 14.6962 24.7695 14.148C24.9987 13.5999 25.0587 12.9967 24.9418 12.4147C24.825 11.8328 24.5366 11.2982 24.1132 10.8787C23.6897 10.4591 23.1503 10.1734 22.563 10.0576C21.9756 9.94189 21.3668 10.0013 20.8136 10.2284C20.2603 10.4554 19.7874 10.8399 19.4547 11.3333C19.122 11.8266 18.9445 12.4067 18.9445 13"
      stroke="#5296D5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 12H12.9565C13.5585 12 14.1469 11.8241 14.6474 11.4944C15.1479 11.1648 15.5379 10.6962 15.7683 10.148C15.9986 9.59987 16.059 8.99666 15.9415 8.41471C15.8241 7.83277 15.5342 7.29823 15.1086 6.87867C14.6829 6.45911 14.1406 6.1734 13.5502 6.05765C12.9598 5.94189 12.3479 6.00129 11.7918 6.22836C11.2356 6.45542 10.7604 6.83994 10.426 7.33329C10.0915 7.82664 9.91305 8.40665 9.91305 9"
      stroke="#5296D5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 20H12.9565C13.5585 20 14.1469 20.1759 14.6474 20.5056C15.1479 20.8352 15.5379 21.3038 15.7683 21.852C15.9986 22.4001 16.059 23.0033 15.9415 23.5853C15.8241 24.1672 15.5342 24.7018 15.1086 25.1213C14.6829 25.5409 14.1406 25.8266 13.5502 25.9424C12.9598 26.0581 12.3479 25.9987 11.7918 25.7716C11.2356 25.5446 10.7604 25.1601 10.426 24.6667C10.0915 24.1734 9.91305 23.5933 9.91305 23"
      stroke="#5296D5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DasairSvgColor;
