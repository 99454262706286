import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DownloadDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard'
    ),
);

const VodDownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard/vod-download'
    ),
);

const DevicesDataDownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard/devices-data'
    ),
);

const AlertDataDownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard/alert-data'
    ),
);

const AttendanceDataDownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard/attendance-data'
    ),
);

const DetailTimePage = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard/detail-time'
    ),
);

const PlantAttendanceDataDownloadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "download-dashboard" */ '../../pages/download-dashboard/plant-attendance-data'
    ),
);

const downloadDashboardRouter = (
  <Route path="data-download-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DownloadDashboardComponent />
        </Suspense>
      }
    >
      <Route
        path="vod"
        element={
          <Suspense fallback={<Loading />}>
            <VodDownloadPage />
          </Suspense>
        }
      />
      <Route
        path="devices-data"
        element={
          <Suspense fallback={<Loading />}>
            <DevicesDataDownloadPage />
          </Suspense>
        }
      />
      <Route
        path="alert-data"
        element={
          <Suspense fallback={<Loading />}>
            <AlertDataDownloadPage />
          </Suspense>
        }
      />
      <Route
        path="detail-time-report"
        element={
          <Suspense fallback={<Loading />}>
            <DetailTimePage />
          </Suspense>
        }
      />
      <Route
        path="attendance-report"
        element={
          <Suspense fallback={<Loading />}>
            <AttendanceDataDownloadPage />
          </Suspense>
        }
      />
      <Route
        path="plant-attendance-report"
        element={
          <Suspense fallback={<Loading />}>
            <PlantAttendanceDataDownloadPage />
          </Suspense>
        }
      />
    </Route>
  </Route>
);

export default downloadDashboardRouter;
