import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EquipmentWithOrg,
  PipeWithOrg,
  PlantWithOrg,
  SelectedDevice,
  VehicleWithOrg,
} from '../../../types';
import { Dastrack } from '../../../types/Device';

type DeviceManagementState = {
  dastracks: Dastrack[];
  plants: Array<PlantWithOrg>;
  equipments: Array<EquipmentWithOrg>;
  vehicles: Array<VehicleWithOrg>;
  pipes: Array<PipeWithOrg>;
  dasIdSelected: string;
  modeDevice: SelectedDevice;
  assignValue: string | null;
};

const initialState: DeviceManagementState = {
  dastracks: [],
  plants: [],
  equipments: [],
  vehicles: [],
  pipes: [],
  modeDevice: 'dasloop',
  dasIdSelected: '',
  assignValue: null,
};

export const deviceManagementPageSlice = createSlice({
  name: 'deviceManagementPage',
  initialState,
  reducers: {
    setDastracks: (
      state,
      action: PayloadAction<DeviceManagementState['dastracks']>,
    ) => {
      return { ...state, dastracks: action.payload };
    },
    setPlants: (
      state,
      action: PayloadAction<DeviceManagementState['plants']>,
    ) => {
      return { ...state, plants: action.payload };
    },
    setEquipments: (
      state,
      action: PayloadAction<DeviceManagementState['equipments']>,
    ) => {
      return { ...state, equipments: action.payload };
    },
    setVehicles: (
      state,
      action: PayloadAction<DeviceManagementState['vehicles']>,
    ) => {
      return { ...state, vehicles: action.payload };
    },
    setPipes: (
      state,
      action: PayloadAction<DeviceManagementState['pipes']>,
    ) => {
      return { ...state, pipes: action.payload };
    },
    setDasIdSelected: (
      state,
      action: PayloadAction<DeviceManagementState['dasIdSelected']>,
    ) => {
      return { ...state, dasIdSelected: action.payload };
    },
    setMode: (
      state,
      action: PayloadAction<DeviceManagementState['modeDevice']>,
    ) => {
      return { ...state, modeDevice: action.payload };
    },
    setValueAssign: (
      state,
      action: PayloadAction<DeviceManagementState['assignValue']>,
    ) => {
      return { ...state, assignValue: action.payload };
    },
  },
});

export const {
  setDastracks,
  setPlants,
  setEquipments,
  setVehicles,
  setPipes,
  setDasIdSelected,
  setMode,
  setValueAssign,
} = deviceManagementPageSlice.actions;
export default deviceManagementPageSlice.reducer;
