import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';

import { Contact, EnvironmentWillAssign, Group } from '../../../../types';
import { Environment } from '../../../../types/Resource';

import CheckListItem from '../../../../components/CheckListItem';

import CleanDateSvgIcon from '../../../../assets/SvgIcon/CleanDateSvgIcon';
import EditSvgIcon from '../../../../assets/SvgIcon/EditSvgIcon';
import { navigatorEnvironmant as navigator } from '../../../../utils/common';
import AreaView from '../../content/AreaView';
import ContactView from '../../content/ContactView';
import GroupView from '../../content/GroupView';
import {
  checkArea,
  checkEnvironmentBasicInformationAvailable,
} from '../../validation/environment-validation';
import { checkWorkerContactAvailable } from '../../validation/worker-validation';

import BasicInformationContent from './BasicInformationContent';

const Dialog = styled(MuiDialog)`
  color: white;
  & .MuiDialog-paper {
    width: 676px;
    height: 686px;
    max-width: unset;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px 8px 16px;
  height: 48px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  font-family: 'Montserrat';
  flex: 1;
  color: white;
  padding: 0;
`;

const ContentWrapper = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  @media only screen and (max-width: 600px) {
    min-height: 240px;
  }
`;

const ContainerSelected = styled('div')`
  width: 380px;
  border-radius: 4px;
  padding: 10px 20px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const IconButtonStyled = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: 0.3s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

interface ViewEnvironmentProps extends DialogProps {
  title: string;
  navigatorIndex: number;
  selectedEnv: Environment | undefined;
  contacts: Array<Contact>;
  selectedGroups: Array<Group>;
  onCloseDialog: () => void;
  onClickEdit: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const ViewEnvironment: React.FC<ViewEnvironmentProps> = ({
  open,
  title,
  navigatorIndex,
  selectedGroups,
  selectedEnv,
  contacts,
  onCloseDialog,
  onClickEdit,
  onSelectNavigatorIndex,
}) => {
  const { projectId } = useParams();
  const [checkedValue, setCheckedValue] = useState(
    new Array(navigator.length).fill(false),
  );
  const { t } = useTranslation('project-setting');

  useEffect(() => {
    const updateChecked = checkedValue.map((item, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedValue(updateChecked);
  }, [navigatorIndex]);

  return (
    <Dialog open={open}>
      <Header>
        <DialogTitle>{title}</DialogTitle>
        <IconButtonStyled
          onClick={() => {
            onCloseDialog();
            onClickEdit();
          }}
          data-cy="dialog-edit-worker"
        >
          <EditSvgIcon
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          />
        </IconButtonStyled>
        <CleanDateSvgIcon
          sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={onCloseDialog}
        />
      </Header>

      <ContentWrapper>
        <DialogContent>
          <CheckList>
            {navigator.map((item, index) => {
              let checked: boolean = false;
              switch (item.name) {
                case 'group':
                  checked = true;
                  break;
                case 'environment-basic-information':
                  checked = checkEnvironmentBasicInformationAvailable(
                    selectedEnv as EnvironmentWillAssign,
                  );
                  break;
                case 'area':
                  checked = checkArea(selectedEnv?.areaId);
                  break;
                case 'emergency-contact':
                  checked = checkWorkerContactAvailable(
                    contacts.map((cp) => cp.id),
                  );
                  break;
              }

              return (
                <CheckListItem
                  key={item.id}
                  name={t(`navigator.${item.name}`)}
                  required={item.required}
                  checked={checked}
                  onClick={() => onSelectNavigatorIndex(index)}
                  selected={checkedValue[index]}
                />
              );
            })}
          </CheckList>
        </DialogContent>
        <ContainerSelected>
          {navigator[navigatorIndex].name === 'group' && (
            <GroupView group={selectedGroups} resource="environment" />
          )}
          {navigator[navigatorIndex].name ===
            'environment-basic-information' && (
            <BasicInformationContent data={selectedEnv} />
          )}
          {navigator[navigatorIndex].name === 'area' && (
            <AreaView
              projectId={projectId}
              selectedAreaId={selectedEnv?.areaId}
              disabled
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactView data={contacts} />
          )}
        </ContainerSelected>
      </ContentWrapper>
    </Dialog>
  );
};

export default ViewEnvironment;
