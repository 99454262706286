import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { ArrowBackIos } from '@mui/icons-material';
import { styled } from '@mui/material';

import {
  BuildingField,
  BuildingGroup,
  FieldGroup,
  Group,
  ResourceType,
} from '../../../types';

import useProjectFieldGroupMap from '../../../hooks/resources/useProjectFieldGroupMap';

import GroupOfFieldList from '../GroupOfFieldList';
import GroupOfResourceList from '../GroupOfResourceList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  flex-direction: column;
`;
const Header = styled('div')`
  height: 32px;
  margin-bottom: 10px;
`;

const ContainerMain = styled('div')`
  display: flex;
  height: calc(100vh - 129px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const EditGroupOfFieldResource: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.pathname
    .split('/')?.[3]
    .replace('-group-management', '');
  const mode = location.pathname
    .split('/')?.[4]
    .replace('-structure-list', '') as 'edit' | 'none';

  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedBuildingId = searchParams.get('buildingId');
  const selectedFieldId = searchParams.get('fieldId');
  const resourceType = (type === 'concrete' ? 'field' : type) as ResourceType;
  const { data: fieldGroupMapData, refetch: refetchBuildingGroups } =
    useProjectFieldGroupMap({
      projectId: projectId,
    });
  const buildingGroups = useMemo(() => {
    const { buildingGroupMap } = fieldGroupMapData || {};

    if (buildingGroupMap && Object.values(buildingGroupMap).length > 0) {
      return Object.values(buildingGroupMap) as (Group & {
        buildingGroupId: string | null;
        imageUrl: string;
        threeDimensionalAssetsId: string | null;
      } & {
        roomList: BuildingField[];
      })[];
    }

    return [];
  }, [fieldGroupMapData]);

  const selectedGroup = useMemo(() => {
    return buildingGroups.find(
      (group) => group.id === selectedBuildingId,
    ) as BuildingGroup;
  }, [selectedBuildingId, buildingGroups]);

  const selectedField = useMemo(
    () => fieldGroupMapData?.fieldGroupMap?.[selectedFieldId ?? ''],
    [selectedFieldId, fieldGroupMapData],
  );

  const resourceGroup = useMemo(
    () => Object.values(fieldGroupMapData?.fieldGroupMap ?? {}) as FieldGroup[],
    [fieldGroupMapData],
  );

  return (
    <Container>
      <Header>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: 'transparent',
            },
          }}
          color="secondary"
          onClick={() =>
            navigate(
              `/project-setting/${projectId}/concrete-group-management?buildingId=${selectedBuildingId}`,
            )
          }
          startIcon={<ArrowBackIos />}
          data-cy={`btn-end-edit-${resourceType}-list`}
        >
          {t('end-editing')}
        </DialogButton>
      </Header>
      <ContainerMain>
        {selectedGroup && resourceType === 'field' && (
          <GroupOfFieldList
            resourceType={resourceType}
            selectedGroup={selectedGroup}
            refetchGroup={refetchBuildingGroups}
            mode={mode}
            selectedFieldGroup={
              fieldGroupMapData?.fieldGroupMap?.[selectedFieldId ?? '']
            }
            onSelectFieldGroup={(fieldId) => {
              setSearchParams({
                buildingId: selectedBuildingId ?? '',
                fieldId,
              });
            }}
          />
        )}
        {selectedField && (
          <GroupOfResourceList
            resourceType={'structure'}
            selectedGroup={selectedField}
            refetchGroup={refetchBuildingGroups}
            groups={resourceGroup}
          />
        )}
      </ContainerMain>
    </Container>
  );
};

export default EditGroupOfFieldResource;
