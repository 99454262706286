import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material';

const StyledMain = styled('main')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;

const Main = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slideIn = () => {};

    mainRef.current?.addEventListener('mousemove', slideIn);

    return () => mainRef.current?.removeEventListener('mousemove', slideIn);
  }, []);

  return <StyledMain ref={mainRef}>{children}</StyledMain>;
};

export default Main;
