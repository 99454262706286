import { useLayoutEffect, useState } from 'react';

export const useLocalStorageObject = <T>(
  key: string,
  projectId: string | null | undefined,
) => {
  const [projectObject, setProjectObject] = useState<T | null>(() => {
    if (projectId) {
      const storedObjectString = localStorage.getItem(key + projectId);
      if (storedObjectString) {
        return JSON.parse(storedObjectString) as T;
      }
    }
    return null;
  });

  useLayoutEffect(() => {
    if (projectObject && projectId) {
      const objectString = JSON.stringify(projectObject);
      localStorage.setItem(key + projectId, objectString);
    }
  }, [projectId, projectObject, key]);

  return [projectObject, setProjectObject] as const;
};
