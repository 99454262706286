import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Contact, DeviceType, Group, ResourceType } from '../../../types';
import { DasAiBox, Dascas, DasConcrete, Device } from '../../../types/Device';
import {
  Ai,
  Environment,
  Plant,
  Resource,
  Structure,
  Worker,
} from '../../../types/Resource';

import ViewAi from './ViewAi';
import ViewEnvironment from './ViewEnvironment';
import ViewStructure from './ViewStructure';
import ViewTowerCrane from './ViewTowerCrane';
import ViewWorker from './ViewWorker';

interface ViewResourceProps {
  projectId: string | undefined;
  resourceType: ResourceType;
  navigatorIndex: number;
  selectedResource: Resource | undefined;
  groups: Group[];
  resourceContacts: Contact[];
  assignedDevices: Array<{ type: DeviceType; device: Device }>;
  onSelectNavigatorIndex: (index: number) => void;
  onCloseDialog: () => void;
  onClickEdit: () => void;
}

const ViewResource: React.FC<ViewResourceProps> = ({
  projectId,
  resourceType,
  navigatorIndex,
  selectedResource,
  groups,
  resourceContacts,
  assignedDevices,
  onSelectNavigatorIndex,
  onCloseDialog,
  onClickEdit,
}) => {
  const { t } = useTranslation('project-setting');
  const title = t(
    `page.${resourceType}-management.button-more.resource-information`,
  );

  const selectedGroups = useMemo(() => {
    return groups.filter((group) =>
      selectedResource?.groupIds.includes(group.id),
    );
  }, [selectedResource, groups]);

  if (selectedResource && resourceType === 'worker') {
    return (
      <ViewWorker
        open
        projectId={projectId}
        title={title}
        navigatorIndex={navigatorIndex}
        workerSelected={selectedResource as Worker}
        workerGroupSelected={selectedGroups}
        contacts={resourceContacts}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
        onCloseDialog={onCloseDialog}
        onClickEdit={onClickEdit}
      />
    );
  } else if (selectedResource && resourceType === 'environment') {
    return (
      <ViewEnvironment
        open
        title={title}
        navigatorIndex={navigatorIndex}
        selectedEnv={selectedResource as Environment}
        contacts={resourceContacts}
        selectedGroups={selectedGroups}
        onCloseDialog={onCloseDialog}
        onClickEdit={onClickEdit}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
      />
    );
  } else if (selectedResource && resourceType === 'crane') {
    return (
      <ViewTowerCrane
        open
        title={title}
        navigatorIndex={navigatorIndex}
        selectedCrane={selectedResource as Plant}
        contacts={resourceContacts}
        selectedGroups={selectedGroups}
        onCloseDialog={onCloseDialog}
        onClickEdit={onClickEdit}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
        connectedDevice={assignedDevices
          .filter(({ type }) => type === 'dascas')
          .map<Dascas>(({ device }) => device as Dascas)}
      />
    );
  } else if (selectedResource && resourceType === 'structure') {
    return (
      <ViewStructure
        open
        title={title}
        navigatorIndex={navigatorIndex}
        selectedStructure={selectedResource as Structure}
        contacts={resourceContacts}
        selectedGroups={selectedGroups}
        onCloseDialog={onCloseDialog}
        onClickEdit={onClickEdit}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
        connectedDevice={assignedDevices
          .filter(({ type }) => type === 'dasconcrete')
          .map<DasConcrete>(({ device }) => device as DasConcrete)}
      />
    );
  } else if (selectedResource && resourceType === 'ai') {
    return (
      <ViewAi
        open
        title={title}
        navigatorIndex={navigatorIndex}
        selectedAi={selectedResource as Ai}
        contacts={resourceContacts}
        connectedDevice={assignedDevices
          .filter(({ type }) => type === 'das_ai_box')
          .map<DasAiBox>(({ device }) => device as DasAiBox)}
        selectedGroups={selectedGroups}
        onCloseDialog={onCloseDialog}
        onClickEdit={onClickEdit}
        onSelectNavigatorIndex={onSelectNavigatorIndex}
      />
    );
  }

  return null;
};

export default ViewResource;
