import type { SVGProps } from 'react';
const AiPpeRefAlertSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 9A7 7 0 1 1 2 9a7 7 0 0 1 14 0M6.707 5.293a1 1 0 0 0-1.414 1.414L7.586 9l-2.293 2.293a1 1 0 1 0 1.414 1.414L9 10.414l2.293 2.293a1 1 0 0 0 1.414-1.414L10.414 9l2.293-2.293a1 1 0 0 0-1.414-1.414L9 7.586zM9 20c.898 0 1.58-.89 2.092-2.244a9.02 9.02 0 0 0 6.527-6.158c.672 2.033 1.735 2.794 2.381 2.902V25H9zm0 7h11v2H9zm0 4h11v4H9zM29.5 7H25c0 5.6-2 7.333-3 7.5V25h11v-5c-2.8 0-3.5-8.667-3.5-13M33 27H22v2h11zm0 4H22v4h11z"
      clipRule="evenodd"
    />
  </svg>
);
export default AiPpeRefAlertSvgIcon;
