import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const AreaManagementPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "area-dashboard-page" */ '../../pages/area-dashboard'
    ),
);

const AddNewAreaComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "area-dashboard-page-add-new-area" */ '../../pages/area-dashboard/manage-area/AddNewArea'
    ),
);

const EditAreaDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "area-dashboard-page-edit-area-dialog" */ '../../components/EditAreaDialogMap3DPlus'
    ),
);

const EditPathDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "area-dashboard-page-edit-path-dialog" */ '../../components/EditPathDialogMap'
    ),
);

const EditAreaComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "area-dashboard-page-edit-area" */ '../../pages/area-dashboard/manage-area/edit-area/EditArea'
    ),
);

const areaManagementRouter = (
  <Route path="area-management-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <AreaManagementPageComponent />
        </Suspense>
      }
    >
      <Route
        path="create-area"
        element={
          <Suspense fallback={<Loading />}>
            <AddNewAreaComponent />
          </Suspense>
        }
      >
        <Route path="set-area" element={<EditAreaDialogComponent />} />
        <Route path="set-path" element={<EditPathDialogComponent />} />
      </Route>
      <Route
        path="edit-area"
        element={
          <Suspense fallback={<Loading />}>
            <EditAreaComponent />
          </Suspense>
        }
      >
        <Route path="set-area" element={<EditAreaDialogComponent />} />
        <Route path="set-path" element={<EditPathDialogComponent />} />
      </Route>
    </Route>
  </Route>
);

export default areaManagementRouter;
