import * as React from 'react';

const DaswaterSvgColor = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 20L13 20L13 18L10 18L10 16L13 16L13 14L10 14L10 12L13 12L13 10L10 10L10 8L13 8L13 6L8 6L8 20Z"
      fill="#E5E5E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2634 22C12.0947 24.8856 14.7963 27 17.9998 27C21.8663 27 24.9998 23.9217 24.9998 20.1258C25.0009 14.0288 17.9998 7 17.9998 7C17.9998 7 16.5683 8.43743 15 10.576V22H11.2634Z"
      fill="#5296D5"
    />
  </svg>
);

export default DaswaterSvgColor;
