import type { SVGProps } from 'react';
const AiPpeHelAlertSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 9A7 7 0 1 1 2 9a7 7 0 0 1 14 0M6.707 5.293a1 1 0 0 0-1.414 1.414L7.586 9l-2.293 2.293a1 1 0 1 0 1.414 1.414L9 10.414l2.293 2.293a1 1 0 0 0 1.414-1.414L10.414 9l2.293-2.293a1 1 0 0 0-1.414-1.414L9 7.586zM9.79 24.157c.032-1.412.113-3.821.753-6.289a9 9 0 0 0 1.768-.496c-.753 2.676-1.168 5.499-1.396 7.866a4.1 4.1 0 0 1-1.125-1.08m2.701-.905c.29-2.14.753-4.542 1.486-6.752a9 9 0 0 0 3.942-6.29 5.4 5.4 0 0 1 2.165-.936 17 17 0 0 1 1.653-.082h.017c.783-.002 1.564.067 2.334.207-1.694.596-3.222 2.096-4.28 4.233-1.717 3.479-2.617 7.407-3.084 10.342-2.222.036-3.618-.445-4.233-.722m12.657-12.715c-1.599.269-3.109 1.625-4.143 3.722-1.743 3.532-2.61 7.567-3.036 10.447a45.13 45.13 0 0 0-.261 2.093c3.476.112 8.09-.424 11.618-3.315 2.057-1.685 2.31-2.693 2.337-2.85v-.063c.01-.264.193-5.337-2.763-8.584-1.15-1.17-2.45-1.668-3.752-1.45m-12.99 15.865c.042-.534.096-1.115.162-1.732.824.318 2.202.687 4.162.686h.044a45 45 0 0 0-.224 2.077q-.005.045-.006.088c-.64-.022-1.644-.082-2.388-.242q-.911-.206-1.774-.566l.004-.056zm20.849-5.772q.034-.95-.058-1.897c3.131 2.702-1.313 7.55-5.73 10.356-8.787 5.574-21.723 2.181-21.204-2.151.145-1.211 1.09-2.578 2.474-3.92q-.03.67-.044 1.313a.7.7 0 0 0 .069.322c.063.129.624 1.209 2.272 2.109l-.015.224-.009.124a.7.7 0 0 0 .091.395c.068.12.17.215.292.274a12.2 12.2 0 0 0 2.49.85c1.31.287 3.208.287 3.288.287a.66.66 0 0 0 .463-.19.7.7 0 0 0 .207-.465v-.09q.505.018.988.018c5.858 0 9.506-1.929 11.58-3.629 2.633-2.161 2.846-3.562 2.846-3.93"
      clipRule="evenodd"
    />
  </svg>
);
export default AiPpeHelAlertSvgIcon;
