import { PagedResponse } from '../../types';
import { DasAiBox } from '../../types/Device';

import DsmAxios from '../DsmAxios';

type GetProjectDasAiBoxesPayload = {
  projectId: string;
  params?: Partial<{
    dasId: string;
    assignedDasAiBox: boolean;
    limit: number;
    nextCursor: string | null;
    prevCursor: string | null;
  }>;
};

export const getProjectDasAiBoxes = (payload: GetProjectDasAiBoxesPayload) => {
  return DsmAxios.get<PagedResponse<DasAiBox>>(
    `/v1/projects/${payload.projectId}/dasaiboxes`,
    {
      params: payload.params,
    },
  );
};

type DismissAiAlertPayload = {
  projectId: string;
  alertId: string;
};

export const dismissAiAlert = ({
  projectId,
  alertId,
}: DismissAiAlertPayload) => {
  return DsmAxios.post(
    `/v1/projects/${projectId}/resources/ai/resource-alert/${alertId}/dismiss`,
  );
};

type ReadAiAlertPayload = {
  projectId: string;
  alertId: string;
};

export const readAiAlert = ({ projectId, alertId }: ReadAiAlertPayload) => {
  return DsmAxios.post(
    `/v1/projects/${projectId}/resources/ai/resource-alert/${alertId}/read`,
  );
};
