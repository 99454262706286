import type { SVGProps } from 'react';

const O2SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM20.5 13a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Zm1.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.425 15.616c.453.256.972.384 1.555.384.577 0 1.093-.128 1.546-.384.453-.256.804-.609 1.053-1.06.253-.456.38-.973.38-1.552 0-.59-.127-1.11-.38-1.56-.249-.457-.6-.81-1.053-1.06-.448-.256-.963-.384-1.546-.384-.583 0-1.102.128-1.555.384-.448.25-.799.604-1.053 1.06-.248.456-.372.976-.372 1.56 0 .579.124 1.096.372 1.552.254.451.605.804 1.053 1.06Zm2.388-1.544a1.016 1.016 0 0 1-.833.41c-.346 0-.627-.137-.843-.41-.215-.272-.323-.631-.323-1.076 0-.295.048-.556.145-.785.098-.233.235-.414.413-.542a.974.974 0 0 1 .6-.2c.226 0 .429.067.607.2.178.128.316.309.413.542.102.229.153.49.153.785 0 .445-.11.804-.332 1.076Zm4.066-.884c-.324.411-.805.912-1.441 1.502v1.177h3.53v-1.219h-1.952c.664-.395 1.16-.81 1.49-1.243.33-.44.494-.913.494-1.419 0-.34-.076-.64-.227-.901a1.574 1.574 0 0 0-.655-.626 2.003 2.003 0 0 0-.988-.234c-.319 0-.624.04-.915.117a4.347 4.347 0 0 0-.875.35l.397 1.244c.362-.217.71-.325 1.044-.325.179 0 .319.047.422.141.108.095.162.231.162.41 0 .272-.162.614-.486 1.026ZM3.5 7a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default O2SvgIcon;
