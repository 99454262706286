import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@beeinventor/dasiot-react-component-lib';
import { Avatar as MuiAvatar, Box, styled, Typography } from '@mui/material';
import { format, isEqual } from 'date-fns';

import {
  InputValidationError,
  WorkerCertificateWillAssign,
} from '../../../types';

import CertificateDefault from '../../../assets/images/default-image/certificate_default.png';
import UploadSvg from '../../../assets/images/svg/btn_upload_image.svg';
import ErrorSvg from '../../../assets/images/svg/ic_error_ifo.svg';
import DeleteSvgIcon from '../../../assets/SvgIcon/DeleteSvgIcon';
import Input from '../../TextField/Input';

import './TargetOperationDialog.css';

const ContainerContent = styled('div')`
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 10px 10px 10px;
  border-radius: 4px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
`;

const ContainerCertificate = styled('div')``;

const ContainerImage = styled('div')`
  margin: 10px auto 0 auto;
  width: 300px;
  height: 210px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 300px;
  height: 210px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const ContainerUploadImage = styled('div')`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
`;

const Error = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > span {
    color: #ff6b00;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const ContainerDetails = styled('div')`
  margin: 10px 0;
  height: 58px;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const ContainerDatePicker = styled('div', { label: 'ContainerDatePicker' })`
  & .DatePicker {
    display: flex;
    color: ${({ theme }) => theme.color.secondary.$60};
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    background: rgba(0, 0, 0, 0.2);
    padding-left: 8px;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    & .MuiSvgIcon-root path {
      stroke: ${({ theme }) => theme.color.secondary.$60};
    }
  }
  & .DatePicker span {
    ${({ theme }) => ({ ...theme.typography.body2 })}
  }
  & div[role='tooltip'] {
    z-index: 1;
  }
`;

const ContainerDelete = styled('div')`
  height: 32px;
  width: 32px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

interface CertificateCardProps {
  onChange: (index: number, datas: WorkerCertificateWillAssign) => void;
  data: WorkerCertificateWillAssign;
  index: number;
  onDeleteCard: (index: number) => void;
}

const CertificateCard: React.VFC<CertificateCardProps> = ({
  index,
  onChange,
  data,
  onDeleteCard,
}) => {
  const { t } = useTranslation('project-setting');
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(data.imageURL ?? '');
  const [imageFormatError, setImageFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [certificateName, setCertificateName] = useState(data.name ?? '');
  const [certificateNameFormatError, setCertificateNameFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [ceritificateNumber, setCertificateNumber] = useState(
    data.number ?? '',
  );
  const [certificateNumberFormatError, setCertificateNumberFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });

  const [expiryDate, setExpiryDate] = useState<Date | undefined>(
    data.expiryDate ? new Date(data.expiryDate) : undefined,
  );
  const [expiryDateFormatError, setExpiryDateFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const dialogRef = useRef<HTMLDivElement>(null);
  const [localImageFile, setImageFile] = useState(data?.imageFile);

  const handleOnClickUploadImage = () => {
    fileRef.current?.click();
  };

  const handleOnSelectFile = () => {
    if (fileRef.current && fileRef.current?.files) {
      setImageFormatError({ isError: false, message: '' });

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const newSrc = reader.result as string;
        setImageUrl(newSrc);
      });
      const fileImge = fileRef.current?.files[0];
      reader.readAsDataURL(fileImge);
      setImageFile(fileRef.current?.files[0]);
    }
  };

  const handleCertificateNameFormat = () => {
    if (certificateName === '') {
      return setCertificateNameFormatError({
        isError: true,
        message: t('error:inputIsEmpty'),
      });
    }
    return setCertificateNameFormatError({ isError: false, message: '' });
  };

  const handleCertificateNumberFormat = () => {
    if (ceritificateNumber === '') {
      return setCertificateNumberFormatError({
        isError: true,
        message: t('error:inputIsEmpty'),
      });
    }
    return setCertificateNumberFormatError({ isError: false, message: '' });
  };

  useEffect(() => {
    if (data.imageURL !== imageUrl) {
      setImageUrl(data.imageURL ?? '');
    }
    if (data.name && data.name !== certificateName) {
      setCertificateName(data.name);
    }
    if (data.number && data.number !== ceritificateNumber) {
      setCertificateNumber(data.number);
    }
    if (
      data.expiryDate &&
      expiryDate !== undefined &&
      !isEqual(new Date(data.expiryDate), expiryDate)
    ) {
      setExpiryDate(new Date(data.expiryDate));
    }
  }, [data]);

  useEffect(() => {
    onChange(index, {
      name: certificateName,
      number: ceritificateNumber,
      expiryDate: expiryDate ? format(expiryDate, 'yyyy-MM-dd') : '',
      imageFile: localImageFile,
      imageURL: imageUrl,
      id: data.id,
    });
  }, [
    certificateName,
    ceritificateNumber,
    expiryDate,
    localImageFile,
    imageUrl,
  ]);

  const handleDateFormat = (date) => {
    if (!date) {
      setExpiryDateFormatError({
        isError: true,
        message: t('error:inputIsEmpty'),
      });
    } else {
      setExpiryDateFormatError({
        isError: false,
        message: '',
      });
    }
  };

  return (
    <ContainerContent ref={dialogRef}>
      <Header>
        <Typography variant="body2">
          {t(
            'page.worker-management.dialog.create-worker.certificate.title-card',
            {
              count: index + 1,
            },
          )}
        </Typography>
        <ContainerDelete
          onClick={() => {
            onDeleteCard(index);
          }}
        >
          <DeleteSvgIcon />
        </ContainerDelete>
      </Header>
      <ContainerCertificate>
        <ContainerImage>
          <Avatar
            src={imageUrl ?? ''}
            alt="worker photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={CertificateDefault} alt="worker beeinventor" />
          </Avatar>
          <ContainerUploadImage onClick={handleOnClickUploadImage}>
            <img src={UploadSvg} alt="upload icon" />
            <input
              ref={fileRef}
              type="file"
              hidden
              accept=".jpg, .png, .jpeg, .svg"
              onChange={handleOnSelectFile}
              data-cy={`input-image-certificate-${index}`}
            />
          </ContainerUploadImage>
        </ContainerImage>
        {imageFormatError.message && imageFormatError.message !== '' && (
          <Error>
            {<img src={ErrorSvg} alt="error" />}
            <span>{imageFormatError.message}</span>
          </Error>
        )}
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.certificate.certificate-name',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.certificate.certificate-name',
            )}
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            onBlur={handleCertificateNameFormat}
            error={certificateNameFormatError.isError}
            errorMessage={certificateNameFormatError.message}
            autoComplete="off"
            data-cy={`input-certificate-name-${index}`}
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.certificate.certificate-number',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.worker-management.dialog.create-worker.certificate.certificate-number',
            )}
            value={ceritificateNumber}
            onChange={(e) => setCertificateNumber(e.target.value)}
            onBlur={handleCertificateNumberFormat}
            error={certificateNumberFormatError.isError}
            errorMessage={certificateNumberFormatError.message}
            autoComplete="off"
            data-cy={`input-certificate-number-${index}`}
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.certificate.expiry-date',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <ContainerDatePicker>
            <DatePicker
              className="DatePicker"
              startDate={expiryDate}
              onSelect={(date) => {
                setExpiryDate(date as Date);
                handleDateFormat(date);
              }}
              dateFormat="yyyy-MM-dd"
              popperProps={{
                container: dialogRef.current,
                className: 'dropdown-date-picker',
              }}
              mode="dark"
              data-cy={`datepicker-expiryDate-certificate-${index}`}
            />
          </ContainerDatePicker>
          {expiryDateFormatError.message &&
            expiryDateFormatError.message !== '' && (
              <Error>
                <img src={ErrorSvg} alt="error" />
                <span>{expiryDateFormatError.message}</span>
              </Error>
            )}
        </ContainerDetails>
      </ContainerCertificate>
    </ContainerContent>
  );
};

export default CertificateCard;
