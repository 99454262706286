import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const LoginComponent = lazy(
  () => import(/* webpackChunkName: "login" */ '../../pages/login'),
);

const loginRouter = (
  <Route
    path="/login"
    element={
      <Suspense fallback={<Loading />}>
        <LoginComponent />
      </Suspense>
    }
  />
);

export default loginRouter;
