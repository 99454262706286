import { BasicResponse, PagedResponse } from '../../types';
import { DasConcrete } from '../../types/Device';

import DsmAxios from '../DsmAxios';

type GetDasConcreteStrengthPayload = {
  projectId: string;
  params: {
    by: 'strength' | 'mixed';
    resourceId?: string;
    endpointId: string;
    fromDate: string;
    toDate: string;
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
  };
};

export type DasConcreteBasicData = {
  endpoint: {
    id: string;
    dasId: string;
    type: 'dasconcrete';
    metadata: {
      'dsm/projectId': string | null;
      'dsm/boundOrgId': string | null;
      'dsm/ownerOrgId': string | null;
      'dsm/resourceId': string | null;
      'dsm/ownerUserId': string | null;
    };
  };
  resourceId: string;
  sensor: string;
  timestamp: string;
};

export type DasConcreteStrengthData = DasConcreteBasicData & {
  actual: number;
};

export type DasConcreteMixedData = DasConcreteBasicData & {
  concreteTemperature: number;
  tiltAngle;
};

export const getDasConcreteStats = ({
  projectId,
  params,
}: GetDasConcreteStrengthPayload) => {
  return DsmAxios.get<
    PagedResponse<DasConcreteStrengthData | DasConcreteMixedData>
  >(`/v1/projects/${projectId}/stats/field`, {
    params,
  });
};

type GetProjectDasConcretesData = {
  projectId: string;
  params: {
    dasId?: string;
    limit?: number;
    prevCursor?: string;
    nextCursor?: string;
  };
};

export const getProjectDasConcretes = ({
  projectId,
  params,
}: GetProjectDasConcretesData) => {
  return DsmAxios.get<PagedResponse<DasConcrete>>(
    `/v1/projects/${projectId}/dasconcretes`,
    {
      params,
    },
  );
};

type GetDasConcreteByDasIdData = {
  dasId: string;
};

export const getDasConcreteByDasId = (data: GetDasConcreteByDasIdData) => {
  return DsmAxios.get<BasicResponse<DasConcrete>>(
    `v1/dasconcretes/${data.dasId}`,
  );
};
