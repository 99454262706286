import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const ConcreteSensorDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "attendance-dashboard" */ '../../pages/concrete-sensor-dashboard'
    ),
);

const concreteSensorDashboardRouter = (
  <Route path="concrete-sensor-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <ConcreteSensorDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default concreteSensorDashboardRouter;
