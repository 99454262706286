import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddPlantSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 6C23.5523 6 24 6.44772 24 7V9H26C26.5523 9 27 9.44772 27 10C27 10.5523 26.5523 11 26 11H24V13C24 13.5523 23.5523 14 23 14C22.4477 14 22 13.5523 22 13V11H20C19.4477 11 19 10.5523 19 10C19 9.44772 19.4477 9 20 9H22V7C22 6.44772 22.4477 6 23 6ZM11.8497 11.9249L9 10.5L5 14.5V16.5L9 12.5L10.8092 13.4046L10 14.5556V19H20V14.5556H17V11H12.5L11.8497 11.9249ZM13.5 12.5H15.5V17H12V15L13.5 12.5ZM9 21.5C9 20.6716 9.67157 20 10.5 20H19.5C20.3284 20 21 20.6716 21 21.5C21 22.3284 20.3284 23 19.5 23H10.5C9.67157 23 9 22.3284 9 21.5ZM5 18L7 16V18H9C9 19.1046 8.10457 20 7 20C5.89543 20 5 19.1046 5 18Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AddPlantSvgIcon;
