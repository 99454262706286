import React from 'react';
import { InputBaseProps, styled, Typography } from '@mui/material';

import { InputValidationError } from '../../types';

import Input from './Input';

const ContainerDetails = styled('div')`
  margin: 10px 0;
  height: 58px;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

interface InputFieldProps extends Omit<InputBaseProps, 'error'> {
  title: string;
  name: string;
  value: unknown;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  error?: InputValidationError;
  isRequired?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  title,
  name,
  value,
  onChange,
  onBlur,
  error,
  isRequired,
  ...otherProps
}) => (
  <ContainerDetails>
    <Title>
      {title}
      {isRequired && <SpanRequire>*</SpanRequire>}
    </Title>
    <Input
      name={name}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      error={error?.isError}
      errorMessage={error?.message}
      autoComplete="off"
      {...otherProps}
    />
  </ContainerDetails>
);

export default InputField;
