import { forwardRef } from 'react';
import { styled } from '@mui/material';

const Container = styled('div')`
  cursor: pointer;
  padding: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      background-color: unset;
    }
  }
`;

interface DeviceItemProps {
  name: string;
  disabled?: boolean;
  onClick?: () => void;
}

const DeviceItem = forwardRef<HTMLDivElement, DeviceItemProps>((props, ref) => {
  const { name, disabled, onClick } = props;
  return (
    <Container
      ref={ref}
      onClick={onClick}
      className={disabled ? 'disabled' : undefined}
      title={name}
    >
      {name}
    </Container>
  );
});

DeviceItem.displayName = 'DeviceItem';

export default DeviceItem;
