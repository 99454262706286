import * as React from 'react';

const DasBeaconSvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 27L16 22L12 27H20Z" fill="#A1A1A1" />
    <path
      d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
      fill="#FF6B00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.302 24.4803C24.1237 22.7124 26 19.5753 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 19.5753 7.87631 22.7124 10.698 24.4803L9.42292 26.0387C6.15675 23.8944 4 20.1991 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 20.1991 25.8432 23.8944 22.5771 26.0387L21.302 24.4803ZM19.3801 22.1313C21.5384 20.9389 23 18.6402 23 16C23 12.134 19.866 9 16 9C12.134 9 9 12.134 9 16C9 18.6402 10.4616 20.9389 12.6199 22.1313L13.9165 20.5465C12.1953 19.7565 11 18.0178 11 16C11 13.2386 13.2386 11 16 11C18.7614 11 21 13.2386 21 16C21 18.0178 19.8047 19.7565 18.0835 20.5465L19.3801 22.1313Z"
      fill="#A1A1A1"
    />
  </svg>
);

export default DasBeaconSvgColor;
