import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DastrackVDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasloop-dashboard-page" */ '../../pages/dastrack-v-dashboard'
    ),
);

const dastrackVDashboardRouter = (
  <Route path="dastrack-v-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DastrackVDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dastrackVDashboardRouter;
