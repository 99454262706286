import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9299 14.4649C17.9299 16.3786 16.3786 17.9299 14.4649 17.9299C12.5513 17.9299 11 16.3786 11 14.4649C11 12.5513 12.5513 11 14.4649 11C16.3786 11 17.9299 12.5513 17.9299 14.4649ZM18.1088 19.8059C17.0713 20.5151 15.8165 20.9299 14.4649 20.9299C10.8944 20.9299 8 18.0354 8 14.4649C8 10.8944 10.8944 8 14.4649 8C18.0354 8 20.9299 10.8944 20.9299 14.4649C20.9299 15.8165 20.5151 17.0713 19.8059 18.1088L23.6265 21.9294C24.0951 22.3981 24.0951 23.1579 23.6265 23.6265C23.1579 24.0951 22.3981 24.0951 21.9295 23.6265L18.1088 19.8059Z"
    />
  </SvgIcon>
);

export default SearchSvgIcon;
