import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Popper, styled } from '@mui/material';
import { addDays, addHours, addMonths, format, isBefore } from 'date-fns';

import {
  Area,
  GetAttendanceDetailOfPlantsRTO,
  GetAttendanceDetailOfWorkersRTO,
} from '../../../types';

const Container = styled('div')`
  ${({ theme }) => ({
    ...theme.externalTypography.body3,
  })}
  position: relative;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr repeat(24, 24px);
  justify-items: center;
  align-items: center;
  color: white;
  column-gap: 6.87px;
  row-gap: 10px;

  & > .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  & .tooltip-content {
    color: ${({ theme }) => theme.color.secondary.$40};
    background-color: ${({ theme }) => theme.externalColor.secondary.$140};
    border-radius: 4px;
    padding: 13px 10px;
    max-width: 200px;
    max-height: 300px;
    overflow: auto;

    & li {
      list-style-type: none;
    }
  }

  & .no-data-container {
    grid-column: 1 / 26;
    font-size: 1.5rem;
  }
`;

interface AttendanceReportHourTableProps {
  refDate: Date;
  timeZone?: string;
  areaMap:
    | {
        [index: string]: Area | undefined;
      }
    | undefined;
  attendanceOfReasourceData:
    | GetAttendanceDetailOfWorkersRTO['workers'][string]['intervals']
    | GetAttendanceDetailOfPlantsRTO['plants'][string]['intervals']
    | undefined;
}

const AttendanceReportHourTable: React.FC<AttendanceReportHourTableProps> = ({
  refDate,
  timeZone,
  areaMap,
  attendanceOfReasourceData,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const hourDomRef = useRef<Array<HTMLDivElement | null>>([]);
  const { t } = useTranslation('report-dashboard');
  const [tooltipConfigs, setTooltipConfigs] = useState<{
    show: boolean;
    domIndex: number;
    content: React.ReactNode | null;
  }>({
    show: false,
    domIndex: 0,
    content: null,
  });

  const { max, dataRows, data } = useMemo(() => {
    const startOfMonthDate = new Date(
      `${refDate.getFullYear()}-${(refDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-01T00:00:00.000${timeZone ?? 'Z'}`,
    );
    const nextMonthDate = addMonths(startOfMonthDate, 1);
    let currentDate = startOfMonthDate;
    let localDataRows = 0;
    let localMax = 1;
    const localData: Array<{
      key: string;
      value: number | string;
      areas: string[];
    }> = [];

    if (attendanceOfReasourceData) {
      while (isBefore(currentDate, nextMonthDate)) {
        const formatString = format(
          currentDate,
          `yyyy-MM-dd'T'00:00:00.000${timeZone ?? 'Z'}`,
        );

        localDataRows++;
        localData.push({
          key: `cell-date-${formatString}`,
          value: format(currentDate, 'yyyy - MM - dd'),
          areas: [],
        });
        for (let i = 0; i < 24; i += 1) {
          const hourString = format(
            addHours(currentDate, i),
            `yyyy-MM-dd'T'HH:mm:ss.000${timeZone ?? 'Z'}`,
          );
          const areaArray = Object.keys(
            attendanceOfReasourceData[hourString]?.areas ?? {},
          );

          if (localMax < areaArray.length) {
            localMax = areaArray.length;
          }

          localData.push({
            key: `cell-date-${hourString}-${i}`,
            value: areaArray.length,
            areas:
              areaArray.length === 0
                ? [t('no-record')]
                : areaArray.map(
                    (area) => areaMap?.[area]?.name ?? t('unknown'),
                  ),
          });
        }
        currentDate = addDays(currentDate, 1);
      }

      return {
        data: localData,
        dataRows: localDataRows,
        max: localMax,
      };
    }

    return {
      data: [],
      dataRows: 0,
      max: 1,
    };
  }, [refDate, attendanceOfReasourceData, timeZone, areaMap]);

  const handleOpenToolTip = useCallback(
    (domIndex: number, reacNode: React.ReactNode) => {
      setTooltipConfigs({
        show: true,
        domIndex,
        content: reacNode,
      });
    },
    [],
  );

  const handleCloseTooltip = useCallback(() => {
    setTooltipConfigs({
      show: false,
      domIndex: 0,
      content: null,
    });
  }, []);

  const items = useMemo(() => {
    return data.map((d, i) => {
      if (i % 25 === 0) {
        hourDomRef.current[i] = null;
        return <div key={d.key}>{d.value}</div>;
      }
      const value = d.value as number;
      return (
        <div
          key={d.key}
          ref={(ref) => {
            hourDomRef.current[i] = ref;
          }}
          className="cell"
          style={{
            color: value > 0 ? `#3E3E3E` : 'initial',
            backgroundColor:
              value > 0
                ? `hsl(43, 100%, ${
                    80 - Math.floor(((value as number) / max) * (80 - 50))
                  }%)`
                : undefined,
          }}
          onClick={() => {
            handleOpenToolTip(
              i,
              <>
                {d.areas.map((areaName, j) => (
                  <div key={`date-${d.key}-area-${j}`}>{areaName}</div>
                ))}
              </>,
            );
          }}
        >
          {value > 1 ? value : undefined}
        </div>
      );
    });
  }, [data]);

  return (
    <Container
      ref={contentRef}
      className="content"
      style={{ gridTemplateRows: `repeat(${1 + dataRows}, 24px)` }}
    >
      <div></div>
      <div>00</div>
      <div>01</div>
      <div>02</div>
      <div>03</div>
      <div>04</div>
      <div>05</div>
      <div>06</div>
      <div>07</div>
      <div>08</div>
      <div>09</div>
      <div>10</div>
      <div>11</div>
      <div>12</div>
      <div>13</div>
      <div>14</div>
      <div>15</div>
      <div>16</div>
      <div>17</div>
      <div>18</div>
      <div>19</div>
      <div>20</div>
      <div>21</div>
      <div>22</div>
      <div>23</div>
      {items.length > 0 ? (
        items
      ) : (
        <div className="no-data-container">{t('no-data')}</div>
      )}
      {!!hourDomRef.current[tooltipConfigs.domIndex] && (
        <Popper
          className="my-popper"
          open={tooltipConfigs.show}
          anchorEl={hourDomRef.current[tooltipConfigs.domIndex]}
          placement="right-start"
          container={contentRef.current}
        >
          <ClickAwayListener onClickAway={handleCloseTooltip}>
            <div className="tooltip-content">{tooltipConfigs.content}</div>
          </ClickAwayListener>
        </Popper>
      )}
    </Container>
  );
};

export default AttendanceReportHourTable;
