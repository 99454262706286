import React, { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ClickAwayListener,
  Popper,
  styled,
  Typography,
} from '@mui/material';

import EditSvgIcon from '../../assets/SvgIcon/EditSvgIcon';
import MoreSvgIconVertical from '../../assets/SvgIcon/MoreSvgIconVertical';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
  isInList: boolean;
  disabled: boolean;
  selected?: boolean;
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' && prop !== "isInList" })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 10px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  & .info{
      & > .main-text{
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: white;
      }

      & > .sub-text{
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: ${({theme}) => theme.color.secondary.$60};
      }
  }

  & > .container-icon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.arrow {
    :hover {
        background: rgba(255, 255, 255, 0.1);
     }
    }
    &.more {
        margin-left: auto;
    }

    &.hide{
        visibility: hidden;
    }
  }
  ${({ theme, selected }) => {
    if (selected) {
      return {
        borderLeft: `3px solid ${theme.color.primary.$100}`,
        background: 'rgba(0, 0, 0, 0.1)',
      };
    }
  }}
`;
const ContainerPopper = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  overflow: hidden;
`;

const TextPopper = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: 'inherit',
  marginLeft: '4px',
  transition: '0.2s',
}));

interface ContainerButtonPopperProps {
  disabled?: boolean;
}

const ContainerButtonPopper = styled('div')<ContainerButtonPopperProps>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.externalColor.dark.$20};
    color: ${({ theme }) => theme.color.secondary.$40};
  }
`;

interface FieldItemProps {
  onClickEdit: () => void;
  onClick: () => void;
  style?: React.CSSProperties;
  name: string;
  isInList: boolean;
  disabled: boolean;
  selected?: boolean;
  deviceTotal?: string;
}

const FieldItem: React.FC<FieldItemProps> = ({
  onClickEdit,
  onClick,
  style,
  name,
  isInList,
  disabled,
  selected,
  deviceTotal,
}) => {
  const { t } = useTranslation('project-setting');
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [open, setIsOpen] = useState(false);
  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement) &&
      openEdit
    ) {
      return;
    }
    setIsOpen(false);
  };

  const handleOnClick = () => {
    onClick();
  };

  return (
    <Container
      style={style}
      disabled={disabled}
      isInList={isInList}
      onClick={handleOnClick}
      onMouseLeave={() => setOpenEdit(false)}
      onMouseEnter={() => setOpenEdit(true)}
      selected={selected}
    >
      <div className="info">
        <div className="main-text">{name}</div>
        <div className="sub-text">{deviceTotal}</div>
      </div>
      <div
        className={`container-icon more arrow ${openEdit ? '' : 'hide'}`}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <MoreSvgIconVertical />
      </div>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="right-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ]}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          <ContainerPopper>
            <ContainerButtonPopper
              onClick={(e) => {
                e.stopPropagation();
                onClickEdit();
              }}
            >
              <EditSvgIcon />
              <TextPopper>{t(`edit`)}</TextPopper>
            </ContainerButtonPopper>
          </ContainerPopper>
        </Popper>
      </ClickAwayListener>
    </Container>
  );
};

export default FieldItem;
