export interface Point {
  position: [number, number, number];
  type: 'indoor' | 'outdoor';
}

export interface Line {
  position: number[];
  type: 'indoor' | 'outdoor';
  startIndex: number;
  endIndex: number;
}

export interface DrawRange {
  start: number;
  count: number;
}

export const toLineSegments = (points: Point[]) => {
  const lineSegments: Line[] = [];

  let lineSegment: Line = {
    position: [],
    type: 'indoor',
    startIndex: 0,
    endIndex: 0,
  };

  points.forEach((point, i) => {
    if (i === 0) {
      lineSegment.type = point.type;
      lineSegment.position.push(...point.position);
    } else if (lineSegment.type !== point.type) {
      lineSegment.endIndex = i - 1;
      lineSegments.push(lineSegment);
      lineSegment = {
        position: [...point.position],
        type: point.type,
        startIndex: i - 1,
        endIndex: i,
      };
    } else {
      lineSegment.position.push(...point.position);
    }
  });
  lineSegment.endIndex = points.length - 1;
  lineSegments.push(lineSegment);

  return lineSegments;
};

export const toDrawRanges = (lines: Line[], endPointer: number) => {
  const drawRanges: DrawRange[] = lines.map<DrawRange>((line) => {
    if (endPointer <= line.startIndex) {
      return {
        start: 0,
        count: 0,
      };
    }

    if (line.endIndex <= endPointer) {
      return {
        start: 0,
        count: line.position.length / 3,
      };
    }

    return { start: 0, count: endPointer - line.startIndex + 1 };
  });
  return drawRanges;
};

export const toDrawRanges2Point = (
  lines: Line[],
  startPointer: number,
  endPointer: number,
) => {
  const drawRanges: DrawRange[] = lines.map<DrawRange>((line) => {
    if (endPointer <= line.startIndex || startPointer >= line.endIndex) {
      return {
        start: 0,
        count: 0,
      };
    }

    if (
      line.endIndex <= endPointer &&
      startPointer <= line.endIndex &&
      startPointer >= line.startIndex
    ) {
      return {
        start: startPointer - line.startIndex,
        count: line.position.length / 3,
      };
    }

    return {
      start: 0,
      count: endPointer - line.startIndex + 1,
    };
  });
  return drawRanges;
};
