import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { EquipmentGroup, EquipmentWithOrg, Group, Org } from '../../../types';

import { getProjectDaslocks } from '../../../apis/DaslockApi';
import {
  createEquipmentGroup,
  getEquipmentGroups,
  getGroupOfEquipment,
  getProjectDaspowers,
} from '../../../apis/DaspowerApi';
import { getProjectDastemps } from '../../../apis/DastempApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setDaslocksOnEquipment,
  setDaspowersOnEquipment,
  setDastempsOnEquipment,
  setSelectedEquipmentGroup,
} from '../../../slices/pages/project-setting/equipmentManagementPageSlice';

import { groupCompareFn } from '../../../utils/Sort';
import ManagementGroupList from '../management/ManagementGroupList';

import EquipmentGroupEquipmentList from './EquipmentGroupEquipmentList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  height: calc(100vh - 87px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const EmptyOrg: Org = {
  id: '',
  ownerId: '',
  name: '',
  country: '',
  taxId: '',
  color: '',
  displayName: '',
  type: 'customer',
  imageURL: null,
  status: 'ACTIVE',
};

const EquipmentManagementPage = () => {
  const { projectId } = useParams();
  const { i18n } = useTranslation();
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { role, policies } = useAppSelector((store) => store.projects);
  const [searchParam, setSearchParam] = useSearchParams();
  const equipmentGroupIdSelected = searchParam.get('groupId');

  const {
    data: equipmentGroups,
    refetch: refetchEquipmentGroup,
    isFetching: isLoadingEquipmentGroup,
  } = useQuery(
    ['get-equipment-groups', projectId, i18n.language],
    async () => {
      const res = await getEquipmentGroups(projectId as string);
      return res.data.data.sort(
        groupCompareFn.bind({ locales: i18n.language }),
      );
    },
    { enabled: !!projectId, initialData: [], refetchOnWindowFocus: false },
  );

  const selectedEquipmentGroup: EquipmentGroup | undefined = useMemo(() => {
    return equipmentGroups.find(
      (group) => group.id === equipmentGroupIdSelected,
    );
  }, [equipmentGroupIdSelected, equipmentGroups]);

  const { data: groupOfEquipments, isFetching: isLoadingEquipmentOfAProject } =
    useQuery(
      ['get-equipment-of-a-group', selectedEquipmentGroup?.id],
      async () => {
        const res = await getGroupOfEquipment({
          projectId: projectId as string,
          groupId: (selectedEquipmentGroup as Group).id,
        });
        return res?.data.data
          .map<EquipmentWithOrg>((equipment) => ({
            ...equipment,
            orgDetail: orgMap[equipment.orgId] ?? EmptyOrg,
          }))
          .sort((a, b) => a.name.localeCompare(b.name, i18n.language));
      },
      {
        enabled: !!selectedEquipmentGroup,
        initialData: [],
        refetchOnWindowFocus: false,
      },
    );

  useQuery(
    ['get-daspower-project', projectId],
    async () => getProjectDaspowers({ projectId: projectId as string }),
    {
      enabled: !!projectId,
      onSuccess: (res) => {
        dispatch(setDaspowersOnEquipment(res.data.data));
      },
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    ['get-dastemp-project', projectId],
    async () => getProjectDastemps({ projectId: projectId as string }),
    {
      enabled: !!projectId,
      onSuccess: (res) => {
        if (res.data.data.length > 0) {
          dispatch(setDastempsOnEquipment(res.data.data));
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  useQuery(
    ['get-daslock-project', projectId],
    async () => getProjectDaslocks({ projectId: projectId as string }),
    {
      enabled: !!projectId,
      onSuccess: (res) => {
        if (res.data.data.length > 0) {
          dispatch(setDaslocksOnEquipment(res.data.data));
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (selectedEquipmentGroup) {
      dispatch(setSelectedEquipmentGroup(selectedEquipmentGroup));
    }
  }, [selectedEquipmentGroup]);

  useEffect(() => {
    if (!equipmentGroupIdSelected && equipmentGroups.length > 0) {
      navigate(
        `/project-setting/${projectId}/equipment-group-management?groupId=${equipmentGroups[0].id}`,
        { replace: true },
      );
    }
  }, [equipmentGroupIdSelected, projectId, equipmentGroups]);

  const isEditable = () => {
    const isAuthorizedCreateEquipmentGroup = policies.some(
      (policy) => policy.name === 'CreateEquipmentGroup',
    );
    return (
      role === 'owner' || role === 'admin' || isAuthorizedCreateEquipmentGroup
    );
  };

  return (
    <Container>
      <ManagementGroupList
        data={equipmentGroups}
        selectedGroup={selectedEquipmentGroup}
        onSelect={(group) =>
          setSearchParam({
            groupId: group.id,
          })
        }
        onSuccessCreateGroup={refetchEquipmentGroup}
        isLoadingGroup={isLoadingEquipmentGroup}
        handleCreateGroup={createEquipmentGroup}
        isEditable={isEditable()}
        resource="equipment"
      />
      {selectedEquipmentGroup && (
        <EquipmentGroupEquipmentList
          equipmentGroupSelected={selectedEquipmentGroup}
          data={groupOfEquipments}
          refetchGroup={refetchEquipmentGroup}
          equipmentGroupProject={equipmentGroups}
          isLoadingEquipmentOfAProject={isLoadingEquipmentOfAProject}
        />
      )}
      <Outlet />
    </Container>
  );
};

export default EquipmentManagementPage;
