import * as React from 'react';

const DastempSvgColor = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8744 14.0334L11.3104 10L4.75146 14.0334L11.3155 18.0615L17.8744 14.0334ZM4 15.4127V18.9827L10.5435 23.0003V19.4303L4 15.4127ZM12.0869 19.4302V23.0003L16.1875 20.4806L17.8125 17.7188V15.912L12.0869 19.4302Z"
      fill="#A1A1A1"
    />
    <mask id="path-2-inside-1_874_33588" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 4C18.7909 4 17 5.79086 17 8V17.5278C15.7725 18.6264 15 20.223 15 22C15 25.3137 17.6863 28 21 28C24.3137 28 27 25.3137 27 22C27 20.223 26.2275 18.6264 25 17.5278V8C25 5.79086 23.2091 4 21 4Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4C18.7909 4 17 5.79086 17 8V17.5278C15.7725 18.6264 15 20.223 15 22C15 25.3137 17.6863 28 21 28C24.3137 28 27 25.3137 27 22C27 20.223 26.2275 18.6264 25 17.5278V8C25 5.79086 23.2091 4 21 4Z"
      fill="#F3F3F3"
    />
    <path
      d="M17 17.5278L18.0004 18.6455L18.5 18.1983V17.5278H17ZM25 17.5278H23.5V18.1983L23.9996 18.6455L25 17.5278ZM18.5 8C18.5 6.61929 19.6193 5.5 21 5.5V2.5C17.9624 2.5 15.5 4.96243 15.5 8H18.5ZM18.5 17.5278V8H15.5V17.5278H18.5ZM16.5 22C16.5 20.6674 17.0774 19.4716 18.0004 18.6455L15.9996 16.4101C14.4677 17.7812 13.5 19.7786 13.5 22H16.5ZM21 26.5C18.5147 26.5 16.5 24.4853 16.5 22H13.5C13.5 26.1421 16.8579 29.5 21 29.5V26.5ZM25.5 22C25.5 24.4853 23.4853 26.5 21 26.5V29.5C25.1421 29.5 28.5 26.1421 28.5 22H25.5ZM23.9996 18.6455C24.9227 19.4716 25.5 20.6674 25.5 22H28.5C28.5 19.7786 27.5323 17.7812 26.0004 16.4101L23.9996 18.6455ZM23.5 8V17.5278H26.5V8H23.5ZM21 5.5C22.3807 5.5 23.5 6.61929 23.5 8H26.5C26.5 4.96243 24.0376 2.5 21 2.5V5.5Z"
      fill="#A1A1A1"
      mask="url(#path-2-inside-1_874_33588)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 10C20.4477 10 20 10.4477 20 11V19.1706C18.8348 19.5825 18 20.6937 18 21.9999C18 23.6568 19.3431 24.9999 21 24.9999C22.6569 24.9999 24 23.6568 24 21.9999C24 20.6937 23.1652 19.5825 22 19.1706V11C22 10.4477 21.5523 10 21 10Z"
      fill="#FF6B00"
    />
  </svg>
);

export default DastempSvgColor;
