import { useMemo } from 'react';

import { Policy, PolicyType, ProjectRole } from '../types';

const useHasPolicy = (
  policies: Policy[],
  policyName: PolicyType,
  userProjectRole?: ProjectRole | null,
) => {
  if (userProjectRole === 'owner' || userProjectRole === 'admin') return true;

  return useMemo(() => {
    for (let i = 0; i < policies.length; i++) {
      if (policies[i].name === policyName) {
        return true;
      }
    }
    return false;
  }, [policies, policyName]);
};

export default useHasPolicy;
