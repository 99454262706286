import * as THREE from 'three';

import { globalTracker } from './ResourceTracker';

export class DaswaterCreator {
  create(config: { name: string; material: THREE.Material }) {
    const cylinderGeometry = new THREE.CylinderGeometry(0.1, 0.1, 0.3, 24);

    const material = new THREE.MeshBasicMaterial({ color: '#262626' });
    const sensor1: THREE.InstancedMesh = new THREE.InstancedMesh(
      cylinderGeometry,
      material,
      1000,
    );

    sensor1.position.set(0.2, -0.1, 0);
    const sensor2: THREE.InstancedMesh = new THREE.InstancedMesh(
      cylinderGeometry,
      material,
      1000,
    );
    sensor2.position.set(-0.2, -0.1, 0);
    const geometry = globalTracker.track(new THREE.BoxGeometry(0.7, 0.4, 0.7));
    geometry.name = config.name;

    const box: THREE.InstancedMesh = new THREE.InstancedMesh(
      geometry,
      config.material,
      1000,
    );

    const group: THREE.Group = globalTracker.track(new THREE.Group());
    group.add(box);
    group.add(sensor1);
    group.add(sensor2);
    group.name = `${config.name}Mesh`;
    return group;
  }

  createMesh(config: { name: string; material: THREE.Material }) {
    const cylinderGeometry = new THREE.CylinderGeometry(0.1, 0.1, 0.3, 24);

    const material = new THREE.MeshBasicMaterial({ color: '#262626' });
    const sensor1 = new THREE.Mesh(cylinderGeometry, material);
    sensor1.position.set(0.2, -0.1, 0);
    const sensor2 = new THREE.Mesh(cylinderGeometry, material);
    sensor2.position.set(-0.2, -0.1, 0);
    const geometry = globalTracker.track(new THREE.BoxGeometry(0.7, 0.4, 0.7));
    geometry.name = config.name;
    const box: THREE.Mesh = new THREE.Mesh(geometry, config.material);

    const group: THREE.Group = globalTracker.track(new THREE.Group());
    group.add(box);
    group.add(sensor1);
    group.add(sensor2);
    group.name = `${config.name}Mesh`;

    return group;
  }
}
