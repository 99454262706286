import * as React from 'react';

const DastrackVSvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 10H26H24H21H19V12V15V17H5C4.44772 17 4 17.4477 4 18V20C4 20.5523 4.44772 21 5 21H6.17071C6.58254 19.8348 7.69378 19 9 19C10.3062 19 11.4175 19.8348 11.8293 21H18.1707C18.5825 19.8348 19.6938 19 21 19C22.3062 19 23.4175 19.8348 23.8293 21H27C27.5523 21 28 20.5523 28 20V16C28 15.4477 27.5523 15 27 15H26V12H27V10ZM21 15V12H24V15H21Z"
      fill="#A1A1A1"
    />
    <circle cx="21" cy="22" r="2" fill="#A1A1A1" />
    <circle cx="9" cy="22" r="2" fill="#A1A1A1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7H20V9H18V10V11V16H5C4.44772 16 4 15.5523 4 15V10H16V9V7ZM6 15L8.5 11H10L7.5 15H6ZM9 15L11.5 11H13L10.5 15H9ZM14.5 11L12 15H13.5L16 11H14.5Z"
      fill="#E6A600"
    />
  </svg>
);

export default DastrackVSvgColor;
