import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button as MuiButton,
  ClickAwayListener,
  Popper,
  styled,
  Typography,
} from '@mui/material';

import { Org, PlantGroup } from '../../../types';
import { Plant } from '../../../types/Resource';

import { updatePlantGroup } from '../../../apis/DastrackApi';
import { useAppSelector } from '../../../hooks';

import EmptyDataWarning from '../../../components/EmptyDataWarning';
import Loading from '../../../components/Loading';
import ProjectPanel from '../../../components/Panel/ProjectPanel';
import Search from '../../../components/Search';

import AddPlantSvgIcon from '../../../assets/SvgIcon/AddPlantSvgIcon';
import MoreSvgIcon from '../../../assets/SvgIcon/MoreSvgIcon';
import PlantSvgIcon from '../../../assets/SvgIcon/PlantSvgIcon';
import RenameSvgIcon from '../../../assets/SvgIcon/RenameSvgIcon';
import MuiTheme from '../../../theme';
import { navigatorPlant as navigator } from '../../../utils/common';
import RenameDialog from '../management/group-dialog/RenameDialog';

import EditPlant from './EditPlant';
import PlantItem from './PlantItem';
import TargetOperationDialog from './TargetOperationDialog';
import ViewPlant from './ViewPlant';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

const MoreContainer = styled('div')`
  cursor: pointer;
`;

const ContainerPopper = styled(Box)`
  width: 10.5rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  overflow: hidden;
`;

interface ContainerButtonPopperProps {
  disabled?: boolean;
}

const ContainerButtonPopper = styled('div')<ContainerButtonPopperProps>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.externalColor.dark.$20};
    color: ${({ theme }) => theme.color.secondary.$40};
  }
`;

const TextPopper = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: 'inherit',
  marginLeft: '2px',
  transition: '0.2s',
}));

const Button = styled(MuiButton)`
  &.Mui-disabled {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.secondary.$60};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  }
`;

interface PlantGroupPlantListProps {
  plantGroupSelected: PlantGroup | undefined;
  data: Array<Plant & { orgDetail: Org | undefined }>;
  plantGroupProject: Array<PlantGroup> | undefined;
  refetchGroup: () => void;
  onSuccessUpdate: () => void;
  onSuccessBind: () => void;
  isLoadingPlantOfAGroup: boolean;
}

const PlantGroupPlantList: React.FC<PlantGroupPlantListProps> = ({
  plantGroupSelected,
  plantGroupProject,
  refetchGroup,
  onSuccessUpdate,
  onSuccessBind,
  data,
  isLoadingPlantOfAGroup,
}) => {
  const { t } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedPlant, setSelectedPlant] = useState<
    Plant & { orgDetail: Org | undefined }
  >();

  const [showUpdatePlantGroup, setShowUpdatePlantGroup] = useState(false);
  const [showAddPlantDialog, setShowAddPlantDialog] = useState(false);
  const [showViewPlantDialog, setShowViewPlantDialog] = useState(false);
  const [showEditPlant, setShowEditPlant] = useState(false);
  const [navigatorIndex, setNavigatorIndex] = useState(1);
  const { role, policies, projectContact } = useAppSelector(
    (store) => store.projects,
  );
  const { dastracks } = useAppSelector((store) => store.plantManagement);
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  const groupIdSelected = searchParams.get('groupId');
  const plantIdSelected = searchParams.get('plantId');

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsOpen(false);
  };

  const plantContacts = useMemo(() => {
    if (projectContact.length > 0 && selectedPlant) {
      return projectContact.filter((contact) =>
        contact.bindingProjectReference.some(
          (ref) => ref.type === 'plant' && ref.refId === selectedPlant.id,
        ),
      );
    }
    return [];
  }, [projectContact, selectedPlant]);

  const handleRenameGroup = () => {
    setShowUpdatePlantGroup(true);
  };

  const handleEditGroup = () => {
    navigate(`edit-plant-group?groupId=${groupIdSelected}`);
  };

  const connectedDevice = useMemo(() => {
    if (dastracks.length > 0) {
      return dastracks.filter((dastrack) =>
        selectedPlant?.bindingDastracks.includes(dastrack.dasId),
      );
    }
    return [];
  }, [selectedPlant, dastracks]);

  const groupPlantSelected = useMemo(() => {
    if (plantGroupProject) {
      return plantGroupProject.filter((group) =>
        selectedPlant ? selectedPlant.groupIds.includes(group.id) : [],
      );
    }
    return [];
  }, [selectedPlant]);

  const editablePlant = () => {
    const isAuthorizedWriteOnePlantGroup = policies.some(
      (policy) => policy.name === 'WriteOnePlantGroup',
    );
    const isGroupAuthorized = policies
      .filter((policy) => policy.name === 'WriteOnePlantGroup')
      .some((policy) => policy.groupId === plantGroupSelected?.id);

    if (role === 'owner' || role === 'admin') {
      return true;
    } else if (isAuthorizedWriteOnePlantGroup && isGroupAuthorized) {
      return true;
    } else {
      return false;
    }
  };

  const items = data
    .filter((d) => d.name.indexOf(filterText) !== -1)
    .map((d) => (
      <PlantItem
        key={`Plant-${d.id}`}
        name={d.name}
        orgName={d.orgDetail?.name}
        orgColor={d.orgDetail?.color}
        imageURL={d.imageURL}
        onClick={() => {
          setSelectedPlant(d);
          setShowViewPlantDialog(true);
          if (groupIdSelected) {
            setSearchParams({
              groupId: groupIdSelected,
              plantId: d.id,
            });
          }
        }}
        dasIds={d.bindingDastracks}
        disabled={!editablePlant()}
      />
    ));

  useEffect(() => {
    if (
      groupIdSelected &&
      plantIdSelected &&
      selectedPlant?.id !== plantIdSelected &&
      data.length > 0
    ) {
      const selected = data.find((item) => item.id === plantIdSelected);
      setSelectedPlant(selected);
      setShowViewPlantDialog(true);
    }
  }, [groupIdSelected, data, plantIdSelected]);

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 87px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={plantGroupSelected?.name}
      subtitle={t('project-setting:page.devices.subtitle.plant', {
        count: items.length,
      })}
      rightNode={
        <>
          <MoreContainer
            onClick={handleToggle}
            ref={anchorRef}
            data-cy="btn-more-edit-plant-list"
          >
            <MoreSvgIcon />
          </MoreContainer>
          <ClickAwayListener onClickAway={handleClose}>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="right-end"
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [45, 0],
                  },
                },
              ]}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <ContainerPopper>
                {/* <ContainerButtonPopper onClick={handleDeleteGroup}>
                  <DeleteSvgIcon />
                  <TextPopper>{t('delete-group')}</TextPopper>
                </ContainerButtonPopper> */}
                <ContainerButtonPopper
                  onClick={handleRenameGroup}
                  disabled={!editablePlant()}
                  data-cy="btn-rename-group-plant"
                >
                  <RenameSvgIcon />
                  <TextPopper>{t('rename-group')}</TextPopper>
                </ContainerButtonPopper>
                <ContainerButtonPopper
                  onClick={handleEditGroup}
                  disabled={!editablePlant()}
                  data-cy="btn-edit-plant-list"
                >
                  <PlantSvgIcon />
                  <TextPopper>{t('edit-plant-list')}</TextPopper>
                </ContainerButtonPopper>
              </ContainerPopper>
            </Popper>
          </ClickAwayListener>
        </>
      }
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
        <Button
          sx={{
            width: '100%',
            color: MuiTheme.color.secondary.$60,
            marginBottom: '10px',
            borderColor: MuiTheme.color.secondary.$60,
            '&:hover': {
              color: 'white',
              borderColor: 'white',
            },
          }}
          variant="outlined"
          startIcon={<AddPlantSvgIcon sx={{ width: '32px', height: '32px' }} />}
          onClick={() => setShowAddPlantDialog(true)}
          disabled={!editablePlant()}
          data-cy="btn-add-plant-in-group"
        >
          {t('page.plant-management.plant-group-plant-list.add-plant')}
        </Button>
      </div>
      <ListContainer>
        {isLoadingPlantOfAGroup ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
      <TargetOperationDialog
        open={showAddPlantDialog}
        title={t('page.plant-management.button-more.add-new-plant-to-group')}
        onCloseDialog={() => setShowAddPlantDialog(false)}
        plantGroupProject={plantGroupProject}
        plantGroupSelected={plantGroupSelected}
        onSuccessCreatePlant={onSuccessUpdate}
        onSuccessBind={onSuccessBind}
      />
      {showEditPlant && (
        <EditPlant
          open={showEditPlant}
          title={t('page.plant-management.button-more.edit-plant-information')}
          navigator={navigator}
          navigatorIndex={navigatorIndex}
          plantGroupProject={plantGroupProject}
          plantGroupSelected={groupPlantSelected}
          plantSelected={selectedPlant}
          connectedDevice={connectedDevice}
          plantContacts={plantContacts}
          onSuccessEditPlant={onSuccessUpdate}
          onSuccessBind={onSuccessBind}
          onCloseDialog={() => {
            if (groupIdSelected) {
              navigate(
                `/project-setting/${projectId}/plant-group-management?groupId=${groupIdSelected}`,
              );
            } else {
              navigate(`/project-setting/${projectId}/plant-group-management`);
            }
            setShowEditPlant(false);
          }}
          onSelectNavigatorIndex={(index: number) => setNavigatorIndex(index)}
        />
      )}
      {showViewPlantDialog && (
        <ViewPlant
          open={showViewPlantDialog}
          title={t('page.plant-management.button-more.plant-information')}
          navigator={navigator}
          navigatorIndex={navigatorIndex}
          plantSelected={selectedPlant}
          plantGroupSelected={groupPlantSelected}
          connectedDevice={connectedDevice}
          plantContacts={plantContacts}
          onCloseDialog={() => {
            setShowViewPlantDialog(false);
            if (groupIdSelected) {
              navigate(
                `/project-setting/${projectId}/plant-group-management?groupId=${groupIdSelected}`,
              );
            } else {
              navigate(`/project-setting/${projectId}/plant-group-management`);
            }
          }}
          setDialogEditPlant={(state) => {
            if (groupIdSelected && plantIdSelected) {
              setSearchParams({
                groupId: groupIdSelected,
                plantId: plantIdSelected,
              });
            }
            setShowEditPlant(state);
          }}
          onSelectNavigatorIndex={(index: number) => setNavigatorIndex(index)}
        />
      )}
      <RenameDialog
        open={showUpdatePlantGroup}
        title={t('page.plant-management.button-more.rename-plant-group')}
        submitText={t('OK')}
        name={plantGroupSelected?.name ?? ''}
        remark={plantGroupSelected?.remark ?? ''}
        groupId={plantGroupSelected?.id ?? ''}
        operationApi={updatePlantGroup}
        onClose={() => setShowUpdatePlantGroup(false)}
        onSuccess={() => {
          setShowUpdatePlantGroup(false);
          refetchGroup();
        }}
      />
    </ProjectPanel>
  );
};

export default PlantGroupPlantList;
