import * as THREE from 'three';

import { OrbitControls } from './threejs/OrbitControls';

interface Config {
  width: number;
  height: number;
}

class Video360Renderer {
  videoEl: HTMLVideoElement;

  canvasEl: HTMLCanvasElement;

  scene: THREE.Scene;

  renderer: THREE.WebGLRenderer;

  geometry: THREE.SphereGeometry;

  texture: THREE.VideoTexture;

  material: THREE.MeshBasicMaterial;

  controls: OrbitControls;

  camera: THREE.PerspectiveCamera;

  mesh: THREE.Mesh;

  config: Config;

  rafId: number | undefined;

  constructor(
    videoEl: HTMLVideoElement,
    canvasEl: HTMLCanvasElement,
    config: Config,
  ) {
    this.videoEl = videoEl;
    this.canvasEl = canvasEl;
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGL1Renderer({
      canvas: canvasEl,
      antialias: true,
    });
    this.config = config;
    this.scene.scale.set(-1, 1, 1);
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.config.width / this.config.height,
      1,
      100,
    );
    this.geometry = new THREE.SphereGeometry(15, 32, 32);
    this.texture = new THREE.VideoTexture(videoEl);
    this.material = new THREE.MeshBasicMaterial({ map: this.texture });
    this.material.side = THREE.BackSide;

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement, {
      isInvertRotation: true,
    });
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.05;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 7.5;
    this.controls.target.set(0, 0, -1);

    const animate = () => {
      this.rafId = requestAnimationFrame(animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    };

    animate();
  }

  dispose() {
    if (typeof this.rafId !== 'undefined') {
      cancelAnimationFrame(this.rafId);
    }
    this.controls.dispose();
    this.mesh.remove();
    this.geometry.dispose();
    this.texture.dispose();
    this.material.dispose();
    this.scene.remove();
    this.camera.remove();
    this.renderer.dispose();
  }
}

export default Video360Renderer;
