import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import { styled, Typography } from '@mui/material';
import maplibregl from 'maplibre-gl';

import { ConnectedDeviceInfo, DeviceType, SettingMap } from '../../../types';

import { useLocalStorageObject } from '../../../hooks/useLocalStorageObject';

import { handleIconMedium } from '../../../assets/SvgIcon/HandleIcon';
import i18n from '../../../i18n';
import { getMapStyle } from '../../../utils/MapboxUtils';

import 'maplibre-gl/dist/maplibre-gl.css';

const Container = styled('div')`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
`;

const Content = styled('div')`
  border-radius: 4px;
  padding: 10px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  height: 32px;
  color: white;
  padding-left: 10px;
`;

const BindToDevice = styled('div')`
  background: rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 12px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.box_bbg};
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const ContainerMap = styled('div')`
  height: 140px;
  margin-top: 10px;
  border-radius: 4px;
`;

interface ConnectedDeviceCardViewProps {
  data: ConnectedDeviceInfo;
  index: number;
  deviceType: DeviceType | undefined;
}

const ConnectedDeviceCardView: React.FC<ConnectedDeviceCardViewProps> = ({
  index,
  data,
  deviceType,
}) => {
  const { projectId } = useParams();
  const { t } = useTranslation('project-setting');

  const [mapRef, setMapRef] = useState<HTMLDivElement | null>(null);
  const mapLibreRef = useRef<maplibregl.Map | null>(null);
  const markerRef = useRef<maplibregl.Marker | null>(null);
  const [mapLocal] = useLocalStorageObject<SettingMap>(
    'mapStyleSetting_',
    projectId,
  );

  useEffect(() => {
    const initial = async () => {
      const map = new maplibregl.Map({
        container: `project-setting-structure-view-${index}`,
        interactive: false,
        zoom: 15,
        style: getMapStyle(mapLocal?.mapStyle),
        center: [data?.location?.lon ?? 0, data?.location?.lat ?? 0],
        attributionControl: false,
      });

      mapLibreRef.current = map;

      map.on('load', async () => {
        const language = new MapboxLanguage({
          defaultLanguage: i18n.language,
        });

        map.addControl(language as unknown as maplibregl.IControl);
      });

      markerRef.current = new maplibregl.Marker()
        .setLngLat([data?.location?.lon ?? 0, data?.location?.lat ?? 0])
        .addTo(map);
    };

    if (mapRef) {
      if (!mapLibreRef.current) {
        initial();
      }
    }

    return () => {
      mapLibreRef.current?.remove();
      mapLibreRef.current = null;
    };
  }, [mapRef, data]);

  return (
    <Container>
      <Header>
        <Typography variant="body2">
          {`${t(
            'page.plant-management.dialog.create-plant.connected-device.title',
          )} ${index + 1}`}
        </Typography>
      </Header>
      <Content>
        <BindToDevice>
          {handleIconMedium(deviceType)}
          <Typography variant="body2" sx={{ ml: '9px' }}>
            {data.name}
          </Typography>
        </BindToDevice>
        {deviceType === 'dasconcrete' && (
          <div>
            <Title>{t('location')}</Title>
            <Text
              title={
                data?.location
                  ? `${data.location.lon}, ${data.location.lat}, ${
                      data.location.alt ? data.location.alt : 'N/A'
                    }`
                  : 'N/A'
              }
            >
              {data?.location
                ? `${data.location.lon}, ${data.location.lat}, ${
                    data.location.alt ? data.location.alt : 'N/A'
                  }`
                : 'N/A'}
            </Text>
            <ContainerMap
              ref={(ref) => setMapRef(ref)}
              id={`project-setting-structure-view-${index}`}
            />
          </div>
        )}
      </Content>
    </Container>
  );
};

export default ConnectedDeviceCardView;
