import i18n from '../i18n';

export const getBatteryLevelEquipment = (value: number) => {
  if (value >= 2.9) {
    return 100;
  } else if (value > 2.7 && value < 2.9) {
    return 50;
  } else {
    return 5;
  }
};

/* Reference : 
   - voltage > 2.9 -> 2 (full charged)
   - voltage > 2.7 -> 1 (half charged)
   - voltage < 2.7 -> 0 (nearly empty)
*/

export const getBatteryStatus = (value: number) => {
  if (value >= 70) {
    return i18n.t(
      'project-setting:page.equipment-management.battery-status.normal-power-supply',
    );
  } else if (value > 50 && value < 70) {
    return i18n.t(
      'project-setting:page.equipment-management.battery-status.half-charged-power-supply',
    );
  } else {
    return i18n.t(
      'project-setting:page.equipment-management.battery-status.empty-power-supply',
    );
  }
};
