import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../../components/Loading';

const SafetyTrainingVrDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "safety-training-vr-dashboard-page" */ '../../../pages/safety-training-vr-dashboard'
    ),
);

const safetyTrainingVrDashboardRouter = (
  <Route path="safety-training-vr-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <SafetyTrainingVrDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default safetyTrainingVrDashboardRouter;
