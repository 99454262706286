import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CleanDateSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2761 12.2189C12.9842 11.927 12.5109 11.927 12.2189 12.2189C11.927 12.5109 11.927 12.9842 12.2189 13.2761L14.9428 16L12.2189 18.7239C11.927 19.0158 11.927 19.4891 12.2189 19.7811C12.5109 20.073 12.9842 20.073 13.2761 19.7811L16 17.0572L18.7239 19.7811C19.0158 20.073 19.4891 20.073 19.7811 19.7811C20.073 19.4891 20.073 19.0158 19.7811 18.7239L17.0572 16L19.7811 13.2761C20.073 12.9842 20.073 12.5109 19.7811 12.2189C19.4891 11.927 19.0158 11.927 18.7239 12.2189L16 14.9428L13.2761 12.2189Z"
    />
  </SvgIcon>
);

export default CleanDateSvgIcon;
