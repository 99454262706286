import React, { useRef } from 'react';
import { styled, SxProps, Theme } from '@mui/material';

import CheckBoxSvgIcon from '../../assets/SvgIcon/CheckboxSvgIcon';

interface ContainerProps {
  disabled: boolean;
  isSelected: boolean;
  selectType: 'none' | 'one' | 'partial' | 'all';
}

const Container = styled('div', {
  shouldForwardProp: (prop) => {
    switch (prop) {
      case 'isSelected':
      case 'selectType':
      case 'sx':
        return false;
      default:
        return true;
    }
  },
})<ContainerProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  user-select: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    & > .checkbox {
      border-color: ${({ theme }) => theme.externalColor.primary.$60};
    }
  }

  & > .checkbox {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid
      ${({ disabled, theme }) =>
        disabled ? theme.color.secondary.$60 : theme.color.secondary.$40};
    border-radius: 4px;
    margin: 8px;
    background: ${({ disabled, theme }) =>
      disabled ? theme.color.secondary.$60 : '#fff'};
    color: ${({ theme }) => theme.color.primary.$100};

    ${({ isSelected, selectType }) => {
      if (isSelected && selectType !== 'partial') {
        return {
          border: 'none',
        };
      }
    }}

    ${({ theme, isSelected, selectType }) => {
      if (isSelected) {
        if (selectType === 'partial') {
          return {
            padding: '4px',
            '&:before': {
              display: 'block',
              content: '""',
              width: '100%',
              height: '100%',
              background: theme.color.primary.$100,
              borderRadius: '1px',
            },
          };
        }
      }
    }};
  }

  & > label {
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
  }
`;

interface CheckboxDarkProps {
  value: any;
  name?: string;
  label?: string;
  sx?: SxProps<Theme>;
  checked?: boolean;
  disabled?: boolean;
  selectType?: 'none' | 'one' | 'partial' | 'all';
  onChange?: (value: any, checked: boolean) => void;
}

const CheckboxDark: React.VFC<CheckboxDarkProps> = ({
  value,
  name,
  label,
  sx,
  checked = false,
  disabled = false,
  selectType = 'none',
  onChange,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleOnClick = () => {
    onChange?.(value, !checked);
  };

  return (
    <Container
      ref={checkboxRef}
      sx={sx}
      isSelected={checked}
      disabled={disabled}
      selectType={selectType}
      onClick={handleOnClick}
      data-cy={`checkbox-dark-list-device-item${disabled ? '-disabled' : ''}`}
    >
      <div className="checkbox">
        {checked && selectType !== 'partial' && (
          <CheckBoxSvgIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
        )}
      </div>
      {label && <label htmlFor={name}>{label}</label>}
    </Container>
  );
};

export default CheckboxDark;
