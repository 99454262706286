import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton, styled } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { Group, ResourceType } from '../../../types';

import { useAppSelector } from '../../../hooks';

import EmptyDataWarning from '../../../components/EmptyDataWarning';
import Loading from '../../../components/Loading';
import ProjectPanel from '../../../components/Panel/ProjectPanel';
import Search from '../../../components/Search';

import AddGroupSvgIcon from '../../../assets/SvgIcon/AddGroupSvgIcon';
import Muitheme from '../../../theme';

import CreateGroupDialog from './group-dialog';
import GroupItem from './GroupItem';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

const Button = styled(MuiButton)`
  &.Mui-disabled {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.secondary.$60};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  }
`;

interface ManagementGroupListProps {
  data: Array<Group> | undefined;
  selectedGroup?: Group;
  onSelect: (group: Group) => void;
  onSuccessCreateGroup: () => void;
  handleCreateGroup: (params: {
    projectId: string;
    name: string;
    remark?: string;
  }) => Promise<any>;
  isLoadingGroup: boolean;
  resource: ResourceType;
  isEditable: boolean;
}

const ManagementGroupList: React.FC<ManagementGroupListProps> = ({
  data,
  selectedGroup,
  isLoadingGroup,
  onSelect,
  onSuccessCreateGroup,
  handleCreateGroup,
  resource,
  isEditable,
}) => {
  const { t } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);
  const selectedProjectId = useAppSelector(
    (store) => store.system.selectedProjectId,
  );

  const queryClient = useQueryClient();

  const items =
    data
      ?.filter((d) => d.name.indexOf(filterText) !== -1)
      .map((d) => (
        <GroupItem
          key={`${resource.toUpperCase()}-${d.id}`}
          data={d}
          onClick={() => onSelect(d)}
          selected={d.id === selectedGroup?.id}
        />
      )) ?? [];

  const handleSuccess = () => {
    setShowCreateGroupDialog(false);
    onSuccessCreateGroup();
    queryClient.invalidateQueries(['project-policies', selectedProjectId]);
  };

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 87px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={t(`page.${resource}-management.${resource}-group-list.title`)}
      subtitle={t('total-n-groups_one', {
        count: items.length,
      })}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: Muitheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
        <Button
          sx={{
            width: '100%',
            color: Muitheme.color.secondary.$60,
            marginBottom: '10px',
            borderColor: Muitheme.color.secondary.$60,
            '&:hover': {
              color: 'white',
              borderColor: 'white',
            },
          }}
          variant="outlined"
          startIcon={<AddGroupSvgIcon sx={{ width: '32px', height: '32px' }} />}
          onClick={() => {
            setShowCreateGroupDialog(true);
          }}
          disabled={!isEditable}
          data-cy={`btn-add-new-${resource}-group`}
        >
          {t(`page.${resource}-management.create-group`)}
        </Button>
      </div>
      <ListContainer>
        {isLoadingGroup ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
      <CreateGroupDialog
        open={showCreateGroupDialog}
        submitText={t('create')}
        title={t(`page.${resource}-management.dialog.create-group.title`)}
        operationApi={handleCreateGroup}
        onClose={() => setShowCreateGroupDialog(false)}
        onSuccess={handleSuccess}
      />
    </ProjectPanel>
  );
};

export default ManagementGroupList;
