import React, { ReactNode } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';

const Container = styled(Box, { label: 'ProjectPanel-Container' })`
  display: flex;
  flex-direction: column;
  width: 375px;
  background: ${({ theme }) => theme.color.secondary.$100};
  border-radius: 10px;
`;

const Header = styled(Box, { label: 'ProjectPanel-Header' })`
  color: white;
  padding: 8px;
  padding-left: 16px;
  height: 48px;
  display: flex;
  align-items: center;
`;

const RightContainer = styled(Box, { label: 'ProjectPanel-RightContainer' })`
  display: inline-block;
  margin-left: auto;
`;

const Content = styled(Box, { label: 'ProjectPanel-Content' })``;

interface ProjectPanelProps extends BoxProps {
  title?: string;
  subtitle?: string;
  rightNode?: ReactNode;
}

const ProjectPanel: React.FC<ProjectPanelProps> = ({
  title,
  subtitle,
  rightNode,
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <Header className="header">
        <Typography variant="h3" data-cy="project-setting-title">
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 'regular',
            marginLeft: '0.25rem',
          }}
          variant="h4"
        >
          {subtitle ? ` | ${subtitle}` : null}
        </Typography>
        <RightContainer>{rightNode}</RightContainer>
      </Header>
      <Content className="content">{children}</Content>
    </Container>
  );
};

export default ProjectPanel;
