import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { ConnectedDeviceInfo, ResourceType } from '../../../../types';

import EmptyDeviceConnected from '../../../../components/EmptyDeviceConnected';

import ConnectedDeviceCardView from './ConnectedDeviceCardView';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

interface ConnectedDeviceViewProps {
  data: Array<ConnectedDeviceInfo>;
  resource: ResourceType;
}

const ConnectedDeviceView: React.FC<ConnectedDeviceViewProps> = ({
  data,
  resource,
}) => {
  const { t } = useTranslation('project-setting');

  return (
    <Container>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.connected-device.title')}
      </ContentTitle>
      <ContainerContent>
        {data.length !== 0 ? (
          data.map((device, i) => (
            <ConnectedDeviceCardView
              key={i}
              index={i}
              data={device}
              resource={resource}
            />
          ))
        ) : (
          <EmptyDeviceConnected />
        )}
      </ContainerContent>
    </Container>
  );
};

export default ConnectedDeviceView;
