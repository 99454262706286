import * as React from 'react';

const DasAoASvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8125 20.125C20.8125 20.125 27 16.6875 27 11.1875C27 7.77024 24.2298 5 20.8125 5C17.3952 5 14.625 7.77024 14.625 11.1875C14.625 16.6875 20.8125 20.125 20.8125 20.125ZM20.8125 13.9375C22.3313 13.9375 23.5625 12.7063 23.5625 11.1875C23.5625 9.66872 22.3313 8.4375 20.8125 8.4375C19.2937 8.4375 18.0625 9.66872 18.0625 11.1875C18.0625 12.7063 19.2937 13.9375 20.8125 13.9375Z"
      fill="#FF6B00"
    />
    <path d="M10.5 21.5L10.0328 27L5 21.9672L10.5 21.5Z" fill="#A1A1A1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5783 14.9147C13.3524 16.9252 14.638 18.5271 15.7742 19.6633C16.6105 20.4996 17.4392 21.1587 18.0902 21.6278C18.5179 22.8817 18.75 24.2263 18.75 25.625H16C16 20.3093 11.6907 16 6.375 16V13.25C8.63556 13.25 10.7546 13.8561 12.5783 14.9147ZM22.5391 22.2965C22.7593 23.3716 22.875 24.4848 22.875 25.625H25.625C25.625 23.8554 25.3862 22.1415 24.9391 20.5139C24.3553 21.0229 23.8082 21.4369 23.3619 21.7509C23.0299 21.9846 22.7476 22.1672 22.5391 22.2965ZM12.6975 7.43735C12.3163 8.2607 12.0568 9.15184 11.942 10.0877C10.2028 9.4645 8.32855 9.125 6.375 9.125V6.375C8.58975 6.375 10.7171 6.74902 12.6975 7.43735Z"
      fill="#A1A1A1"
    />
  </svg>
);

export default DasAoASvgColor;
