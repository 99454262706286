import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmptyIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="161"
      height="160"
      viewBox="0 0 161 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.5 86V84C37.3954 84 36.5 84.8954 36.5 86H38.5ZM66.5 86L68.215 84.971C67.8535 84.3686 67.2025 84 66.5 84V86ZM122.5 86H124.5C124.5 84.8954 123.605 84 122.5 84V86ZM94.5 86V84C93.7975 84 93.1465 84.3686 92.785 84.971L94.5 86ZM88.5 96V98C89.2025 98 89.8535 97.6314 90.215 97.029L88.5 96ZM72.5 96L70.785 97.029C71.1465 97.6314 71.7975 98 72.5 98V96ZM38.5 88H66.5V84H38.5V88ZM40.5 119V86H36.5V119H40.5ZM41.5 120C40.9477 120 40.5 119.552 40.5 119H36.5C36.5 121.761 38.7386 124 41.5 124V120ZM119.5 120H41.5V124H119.5V120ZM120.5 119C120.5 119.552 120.052 120 119.5 120V124C122.261 124 124.5 121.761 124.5 119H120.5ZM120.5 86V119H124.5V86H120.5ZM94.5 88H122.5V84H94.5V88ZM92.785 84.971L86.785 94.971L90.215 97.029L96.215 87.029L92.785 84.971ZM88.5 94H72.5V98H88.5V94ZM74.215 94.971L68.215 84.971L64.785 87.029L70.785 97.029L74.215 94.971Z"
        fill="currentColor"
      />
      <path
        d="M54.5 70L38.5 86H54.5V70Z"
        fill="currentColor"
        fillOpacity="0.3"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M106.5 70L122.5 86H106.5V70Z"
        fill="currentColor"
        fillOpacity="0.3"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M106.5 70H54.5V86H66.5L72.5 96H88.5L94.5 86H106.5V70Z"
        fill="currentColor"
        fillOpacity="0.2"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="102.5" y="39" width="6" height="14" rx="3" fill="currentColor" />
      <path
        d="M104.5 41L101.5 38"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M106.5 41L109.5 38"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="99.5"
        cy="45"
        r="4"
        fill="currentColor"
        fillOpacity="0.1"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="111.5"
        cy="45"
        r="4"
        fill="currentColor"
        fillOpacity="0.1"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        opacity="0.5"
        d="M106.5 57C106.5 56.4477 106.052 56 105.5 56C104.948 56 104.5 56.4477 104.5 57H106.5ZM79.5 88C79.5 88.5523 79.9477 89 80.5 89C81.0523 89 81.5 88.5523 81.5 88H79.5ZM81.5933 85.9029C81.6449 85.353 81.2408 84.8655 80.691 84.814C80.1411 84.7625 79.6536 85.1665 79.6021 85.7164L81.5933 85.9029ZM81.6946 79.0896C81.3976 79.5552 81.5344 80.1735 82 80.4704C82.4657 80.7674 83.0839 80.6306 83.3808 80.165L81.6946 79.0896ZM86.1256 77.3326C86.5765 77.0136 86.6833 76.3895 86.3643 75.9387C86.0452 75.4879 85.4211 75.3811 84.9703 75.7001L86.1256 77.3326ZM91.0842 72.7505C90.5655 72.9401 90.2988 73.5143 90.4884 74.033C90.678 74.5517 91.2522 74.8185 91.7709 74.6289L91.0842 72.7505ZM95.8935 73.1088C96.4061 72.9034 96.6552 72.3213 96.4498 71.8086C96.2443 71.2959 95.6622 71.0469 95.1496 71.2523L95.8935 73.1088ZM100.487 68.1777C100.076 68.5464 100.042 69.1786 100.41 69.5898C100.779 70.0009 101.411 70.0353 101.822 69.6666L100.487 68.1777ZM104.687 65.9344C104.938 65.4421 104.741 64.8401 104.249 64.59C103.756 64.3398 103.154 64.5362 102.904 65.0286L104.687 65.9344ZM104.42 59.1068C104.377 59.6573 104.788 60.139 105.338 60.1825C105.889 60.226 106.371 59.815 106.414 59.2644L104.42 59.1068ZM81.5 88C81.5 87.2539 81.5321 86.5562 81.5933 85.9029L79.6021 85.7164C79.5346 86.4364 79.5 87.1966 79.5 88H81.5ZM83.3808 80.165C84.1202 79.0057 85.0511 78.0931 86.1256 77.3326L84.9703 75.7001C83.7183 76.5861 82.5939 77.6794 81.6946 79.0896L83.3808 80.165ZM91.7709 74.6289C93.1036 74.1417 94.5291 73.6555 95.8935 73.1088L95.1496 71.2523C93.8221 71.7842 92.4743 72.2423 91.0842 72.7505L91.7709 74.6289ZM101.822 69.6666C102.94 68.6639 103.919 67.4474 104.687 65.9344L102.904 65.0286C102.254 66.3082 101.432 67.33 100.487 68.1777L101.822 69.6666ZM106.414 59.2644C106.471 58.5467 106.5 57.7926 106.5 57H104.5C104.5 57.7438 104.473 58.4451 104.42 59.1068L106.414 59.2644Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default EmptyIconSvg;
