import * as React from 'react';

const DasGasSvgColor = () => (
  <svg
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 6C5.89543 6 5 6.89543 5 8V24C5 25.1046 5.89543 26 7 26H25C26.1046 26 27 25.1046 27 24V8C27 6.89543 26.1046 6 25 6H7ZM10 24C11.1046 24 12 23.1046 12 22C12 20.8954 11.1046 20 10 20C8.89543 20 8 20.8954 8 22C8 23.1046 8.89543 24 10 24ZM24 22C24 23.1046 23.1046 24 22 24C20.8954 24 20 23.1046 20 22C20 20.8954 20.8954 20 22 20C23.1046 20 24 20.8954 24 22ZM24 9H7.99999V18H24V9Z"
      fill="#A1A1A1"
    />
    <rect x="9" y="10" width="14" height="7" fill="white" />
  </svg>
);

export default DasGasSvgColor;
