import {
  Camera,
  Group,
  InstancedMesh,
  Material,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Raycaster,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

import { AOA, AOAUwb, Beacon, LocatorType3D } from '../../../../types';

import { WorkerConfig } from './ThreeManager';

export class PickControl {
  raycaster: Raycaster;

  pickedObject: Object3D | Group | Mesh | InstancedMesh | null;

  tempPickedObject: Object3D | Group | null;

  pickedObjectMaterial: Material[] | Material | null;

  pickPosition: Vector2;

  scene: Scene;

  camera: Camera;

  transformControl: TransformControls;

  renderer: WebGLRenderer;

  pickedObjectPosition: { x: number; y: number; z: number } | undefined;

  pickedObjectRotation: { x: number; y: number; z: number } | undefined;

  listObjectLoaded: Array<Object3D>;

  orbitControl: OrbitControls;

  rect: DOMRect;

  onUpdatePosition?: (
    pos: { x: number; y: number; z: number },
    name?: string,
    rotation?: number,
    locator?: WorkerConfig & {
      deviceType: LocatorType3D;
      aoa?: AOA | undefined;
      beacon?: Beacon | undefined;
      aoaUwb?: AOAUwb | undefined;
    },
  ) => void;

  onUpdateRotation?: (rotation: number, name?: string) => void;

  onUpdateLabel?: (
    key: string,
    data: WorkerConfig,
    position: THREE.Vector3,
    isVisible: boolean,
  ) => void;

  constructor(
    scene: Scene,
    camera: Camera,
    renderer: WebGLRenderer,
    orbitControl: OrbitControls,
    activeDrag?: boolean,
    onUpdatePosition?: (
      pos: { x: number; y: number; z: number },
      name?: string,
      rotation?: number,
      locator?: WorkerConfig & {
        deviceType: LocatorType3D;
        aoa?: AOA | undefined;
        beacon?: Beacon | undefined;
        aoaUwb?: AOAUwb | undefined;
      },
    ) => void,
    onUpdateRotation?: (rotation: number) => void,
    onUpdateLabel?: (
      key: string,
      data: WorkerConfig,
      position: THREE.Vector3,
      isVisible: boolean,
    ) => void,
  ) {
    this.scene = scene;
    this.camera = camera;
    this.orbitControl = orbitControl;
    this.raycaster = new Raycaster();
    this.raycaster.layers.set(1);
    this.pickPosition = new Vector2();
    this.renderer = renderer;
    this.pickedObject = null;
    this.tempPickedObject = null;
    this.pickedObjectMaterial = null;
    this.pickedObjectPosition = undefined;
    this.pickedObjectRotation = undefined;
    this.transformControl = new TransformControls(
      this.camera,
      this.renderer.domElement,
    );
    this.listObjectLoaded = [];
    this.onUpdatePosition = onUpdatePosition;
    this.onUpdateRotation = onUpdateRotation;
    this.onUpdateLabel = onUpdateLabel;
    this.rect = renderer.domElement.getBoundingClientRect();
    this.transformControlMode();
    this.transformControl.addEventListener('dragging-changed', this.onDragging);
    if (activeDrag) {
      document.addEventListener('mousemove', this.onHoverObject);
      document.addEventListener('click', this.onClickObject);
    }
  }

  private onHoverObject = (event: MouseEvent) => {
    this.pickPosition.x =
      ((event.clientX - this.rect.left) / (this.rect.right - this.rect.left)) *
        2 -
      1;

    this.pickPosition.y =
      -((event.clientY - this.rect.top) / (this.rect.bottom - this.rect.top)) *
        2 +
      1;

    this.onSelected(this.pickPosition);
  };

  private onClickObject = (event: MouseEvent) => {
    this.pickPosition.x =
      ((event.clientX - this.rect.left) / (this.rect.right - this.rect.left)) *
        2 -
      1;

    this.pickPosition.y =
      -((event.clientY - this.rect.top) / (this.rect.bottom - this.rect.top)) *
        2 +
      1;

    this.onSelected(this.pickPosition);
    this.tempPickedObject = this.pickedObject;
    this.addTransformControl();
  };

  private onSelected = (pickedPosition: Vector2) => {
    this.unSubscribe();
    this.onSubScribe(pickedPosition);
  };

  private unSubscribe = () => {
    if (this.pickedObject) {
      if (this.pickedObject instanceof Mesh) {
        if (this.pickedObjectMaterial) {
          this.pickedObject.material = this.pickedObjectMaterial;
        }
      }
      this.pickedObject = null;
    }
  };

  private onSubScribe = (pickedPosition: Vector2) => {
    this.raycaster.setFromCamera(pickedPosition, this.camera);
    const intersectedObjects = this.raycaster.intersectObjects(
      this.scene.children,
    );

    if (intersectedObjects.length > 0) {
      const object = intersectedObjects[0].object;
      if (
        object.name === 'dasbeaconEditMesh' ||
        object.name === 'dasaoaEditMesh' ||
        object.name === 'cctvEditMesh' ||
        object.name === 'daswaterEditMesh'
      ) {
        this.pickedObject = object;
        this.pickedObjectMaterial = (this.pickedObject as Mesh).material;
        (this.pickedObject as Mesh).material = new MeshBasicMaterial({
          color: 0xff0000,
        });
      }
    }
  };

  setTransformControl = () => {
    const editMeshNames = [
      'dasbeacon',
      'dasaoa',
      'cctv',
      'daswater',
      'dasconcrete',
    ];

    this.scene.children.forEach((object) => {
      if (editMeshNames.some((name) => object.name === `${name}EditMesh`)) {
        this.tempPickedObject = object;
        this.transformControl.attach(object);
        this.scene.add(this.transformControl);
      }
    });
  };

  private addTransformControl = () => {
    if (this.pickedObject) {
      this.transformControl.attach(this.pickedObject);
      this.scene.add(this.transformControl);
    } else {
      this.transformControl.detach();
    }
  };

  radToDeg = (val: number) => {
    return val * (180 / Math.PI);
  };

  onDragging = (event) => {
    if (this.tempPickedObject) {
      this.tempPickedObject.rotation.order = 'YXZ';
      const rot = this.tempPickedObject?.rotation;
      const rotY = rot?.y ?? 0;
      const rotX = rot?.x ?? 0;
      const rotZ = rot?.z ?? 0;
      const newRotation = {
        x: this.radToDeg(rotX > 0 ? rotX : 2 * Math.PI + rotX),
        y: this.radToDeg(rotY > 0 ? rotY : 2 * Math.PI + rotY),
        z: this.radToDeg(rotZ > 0 ? rotZ : 2 * Math.PI + rotZ),
      };

      const worldPosition = new Vector3();
      this.tempPickedObject.getWorldPosition(worldPosition);
      const newPosition = {
        x: worldPosition.x,
        y: worldPosition.y,
        z: worldPosition.z,
      };
      this.pickedObjectRotation = newRotation;
      this.pickedObjectPosition = newPosition;

      this.onUpdatePosition?.(
        newPosition,
        this.tempPickedObject.userData.dasId,
        newRotation.y,
        this.tempPickedObject.userData.locator,
      );
      this.onUpdateLabel?.(
        this.tempPickedObject.userData.key,
        this.tempPickedObject.userData.locator,
        worldPosition,
        true,
      );
      this.onUpdateRotation?.(newRotation.y);
    }

    this.orbitControl.enabled = !event.value;
  };

  getPickedObjectRotation = () => {
    return this.pickedObjectRotation;
  };

  getPickedObjectPosition = () => {
    return this.pickedObjectPosition;
  };

  private transformControlMode = () => {
    window.addEventListener('keydown', (event) => {
      switch (event.code) {
        case 'KeyG':
          this.transformControl.setMode('translate');
          this.transformControl.showX = true;
          this.transformControl.showY = true;
          this.transformControl.showZ = true;
          break;
        case 'KeyR':
          this.transformControl.setMode('rotate');
          this.transformControl.showX = false;
          this.transformControl.showZ = false;
          break;
      }
    });
  };

  dispose = () => {
    document.removeEventListener('mousemove', this.onHoverObject);
    document.removeEventListener('click', this.onClickObject);
    this.transformControl.removeEventListener(
      'dragging-changed',
      this.onDragging,
    );
    this.transformControl.dispose();
  };
}
