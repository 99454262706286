import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';
import { format } from 'date-fns';

import { WorkerCertificate } from '../../../types';

import CertificateDefault from '../../../assets/images/default-image/certificate_default.png';
import EmptyData from '../../EmptyData';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const ContainerCard = styled('div')`
  margin-top: 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 8px;
  height: 42px;
`;

const ContainerDetail = styled('div')``;

const ContainerImage = styled('div')`
  margin: 10px auto 0 auto;
  width: 300px;
  height: 210px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 300px;
  height: 210px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

interface CertificateContentProps {
  data: Array<WorkerCertificate> | undefined;
}

const CertificateContentView: React.FC<CertificateContentProps> = ({
  data,
}) => {
  const { t } = useTranslation('project-setting');

  const item =
    data?.length !== 0 ? (
      data?.map((certificate, index) => (
        <ContainerCard key={certificate.id}>
          <Text>{`${t('certificate.title')} ${index + 1}`}</Text>
          <ContainerImage>
            <Avatar
              src={certificate.imageURL ?? ''}
              alt="worker photo"
              sx={{ marginBottom: '10px' }}
              variant="square"
            >
              <Avatar src={CertificateDefault} alt="worker beeinventor" />
            </Avatar>
          </ContainerImage>
          <ContainerDetail>
            <Item>
              <Title>{t('certificate.name')}</Title>
              <Text>{certificate.name ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('certificate.number')}</Title>
              <Text>{certificate.number ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('expiry-date')}</Title>
              <Text>
                {certificate.expiryDate
                  ? format(new Date(certificate.expiryDate), 'yyyy-MM-dd')
                  : 'N/A'}
              </Text>
            </Item>
          </ContainerDetail>
        </ContainerCard>
      ))
    ) : (
      <EmptyData />
    );

  return (
    <Container>
      <ContentTitle>
        {t('page.worker-management.dialog.create-worker.certificate.title')}
      </ContentTitle>
      <ContainerContent>{item}</ContainerContent>
    </Container>
  );
};

export default CertificateContentView;
