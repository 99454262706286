import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Popper, styled, Typography } from '@mui/material';
import { addDays, format, startOfWeek } from 'date-fns';

import { Area } from '../../../types';

const Container = styled('div')`
  & .content {
    position: relative;
    display: grid;
    grid-template-columns: 40px 40px 40px 40px 40px 40px 40px;
    grid-template-rows: 20px 40px 40px 40px 40px 40px 40px;
    gap: 16px;

    & .week-name {
      color: white;
      text-align: center;
    }

    & .date {
      ${({ theme }) => ({
        color: theme.color.secondary.$100,
        backgroundColor: theme.color.secondary.$60,
        '&.has-attendance': {
          borderRadius: '4px',
          backgroundColor: theme.externalColor.primary.$60,
          '&:hover': {
            border: '2px solid #FFFFFF',
          },
        },
      })}
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      user-select: none;
      &.not-current-month {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    & .tooltip-content {
      color: ${({ theme }) => theme.color.secondary.$40};
      background-color: ${({ theme }) => theme.externalColor.secondary.$140};
      border-radius: 4px;
      padding: 13px 10px;
      max-width: 200px;
      max-height: 300px;
      overflow: auto;

      & li {
        list-style-type: none;
      }
    }
  }
`;

interface DetailTimeReportDaysTableProps {
  title?: string;
  refDate: Date;
  countTable:
    | { [date: string]: { [areaId: string]: number } | undefined }
    | undefined;
  areaMap: { [id: string]: Area | undefined } | undefined;
}

const DetailTimeReportDaysTable: React.FC<DetailTimeReportDaysTableProps> = ({
  title,
  countTable,
  refDate,
  areaMap,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const dayDomRef = useRef<Array<HTMLDivElement | null>>([]);
  const { t } = useTranslation('report-dashboard');
  const currentMonth = refDate.getMonth();
  const [tooltipConfigs, setTooltipConfigs] = useState<{
    show: boolean;
    domIndex: number;
    content: any[];
  }>({
    show: false,
    domIndex: 0,
    content: [],
  });

  const handleOpenTooltip = useCallback(
    (domIndex: number, dateText: string) => {
      const dateElement = dayDomRef.current[domIndex];
      if (!dateElement) return;

      const area = countTable && countTable[dateText];
      if (!area) return;

      const tooltipContent = Object.entries(area).map(([areaId, value]) => (
        <li key={`area-${areaId}-hours`}>
          {areaMap?.[areaId]?.name ?? t('unknown')}:{' '}
          {`${parseInt((value / 60).toString())
            .toString()
            .padStart(2, '0')}:${parseInt((value % 60).toString())
            .toString()
            .padStart(2, '0')}
         (${t('Hour')}:${t('minute')})`}
        </li>
      ));

      setTooltipConfigs({
        show: true,
        domIndex,
        content: tooltipContent,
      });
    },
    [countTable, areaMap, t],
  );

  const handleCloseTooltip = useCallback(() => {
    setTooltipConfigs({
      show: false,
      domIndex: 0,
      content: [],
    });
  }, []);

  const { items } = useMemo(() => {
    const startOfWeekDate = startOfWeek(refDate);
    const localItems: React.ReactNode[] = [];

    for (let i = 0; i < 42; i += 1) {
      const currentDate = addDays(startOfWeekDate, i);
      const month = currentDate.getMonth();
      const date = currentDate.getDate();
      const dateString = format(currentDate, 'yyyy-MM-dd');

      if (countTable) {
        dayDomRef.current = [];
        let hasAttendance = false;
        if (
          countTable[dateString] &&
          Object.keys(countTable[dateString] ?? {}).length > 0
        ) {
          hasAttendance = true;
        }
        localItems.push(
          <div
            key={`DetailTimeReportDaysTable-item-${month}-${date}`}
            ref={(ref) => dayDomRef.current.push(ref)}
            className={`date${
              month !== currentMonth ? ' not-current-month' : ''
            }${hasAttendance ? ' has-attendance' : ''}`}
            onClick={() => handleOpenTooltip(i, dateString)}
          >
            {date}
          </div>,
        );
      }
    }

    return {
      items: localItems,
    };
  }, [refDate, countTable, areaMap]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const dateElements = contentRef.current?.querySelectorAll('.date');
      if (dateElements) {
        dayDomRef.current = Array.from(dateElements) as HTMLDivElement[];
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Container>
      <Typography
        variant="subtitle2"
        textAlign="center"
        color="white"
        fontWeight="bold"
        margin="0 0 10px"
      >
        {title}
      </Typography>
      <div ref={contentRef} className="content">
        <span className="week-name">{t('week.sun')}</span>
        <span className="week-name">{t('week.mon')}</span>
        <span className="week-name">{t('week.tue')}</span>
        <span className="week-name">{t('week.wed')}</span>
        <span className="week-name">{t('week.thu')}</span>
        <span className="week-name">{t('week.fri')}</span>
        <span className="week-name">{t('week.sat')}</span>
        {items}
      </div>
      {!!dayDomRef.current[tooltipConfigs.domIndex] && (
        <Popper
          open={tooltipConfigs.show}
          anchorEl={dayDomRef.current[tooltipConfigs.domIndex]}
          placement="right-start"
          container={contentRef.current}
        >
          <ClickAwayListener onClickAway={handleCloseTooltip}>
            <div className="tooltip-content">{tooltipConfigs.content}</div>
          </ClickAwayListener>
        </Popper>
      )}
    </Container>
  );
};

export default DetailTimeReportDaysTable;
