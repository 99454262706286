import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Group } from '../../../types';
import { DastrackV } from '../../../types/Device';

type VehicleManagementPage = {
  selectedGroup: Group | undefined;
  dastrackV: Array<DastrackV>;
};

const initialState: VehicleManagementPage = {
  selectedGroup: undefined,
  dastrackV: [],
};

export const vehicleManagementPageSlice = createSlice({
  name: 'vehicleManagement',
  initialState,
  reducers: {
    setSelectedVehicleGroup: (
      state,
      action: PayloadAction<VehicleManagementPage['selectedGroup']>,
    ) => {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    },
    setDastrackV: (
      state,
      action: PayloadAction<VehicleManagementPage['dastrackV']>,
    ) => {
      return { ...state, dastrackV: action.payload };
    },
  },
});

export const { setSelectedVehicleGroup, setDastrackV } =
  vehicleManagementPageSlice.actions;
export default vehicleManagementPageSlice.reducer;
