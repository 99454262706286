import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';

import { Org, PlantGroup } from '../../../../types';
import { Plant } from '../../../../types/Resource';

import { useAppSelector } from '../../../../hooks';

import EmptyDataWarning from '../../../../components/EmptyDataWarning';
import Loading from '../../../../components/Loading';
import ProjectPanel from '../../../../components/Panel/ProjectPanel';
import Search from '../../../../components/Search';

import MuiTheme from '../../../../theme';
import { navigatorPlant } from '../../../../utils/common';
import EditPlant from '../EditPlant';
import ViewPlant from '../ViewPlant';

import PlantItemPermission from './PlantItemPermission';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type PlantPermissionListProps = {
  selectedId: (plantIds: Array<string>) => void;
  data: Array<Plant & { orgDetail: Org }>;
  onSuccessEdit: () => void;
  onSuccessBind: () => void;
  plantGroupProject: Array<PlantGroup>;
  isLoadingSetPlant: boolean;
  isLoadingPlantsOfProject: boolean;
};

const PlantPermissionList: React.VFC<PlantPermissionListProps> = ({
  selectedId,
  data,
  onSuccessBind,
  onSuccessEdit,
  plantGroupProject,
  isLoadingSetPlant,
  isLoadingPlantsOfProject,
}) => {
  const { t, i18n } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const [plantIdWillAssign, setPlantIdWillAssign] = useState<Array<string>>([]);
  const [showEditPlant, setShowEditPlant] = useState<boolean>(false);
  const [showViewPlantDialog, setShowViewPlantDialog] = useState(false);
  const [navigatorIndex, setNavigatorIndex] = useState(1);
  const [selectedPlant, setSelectedPlant] = useState<
    Plant & { orgDetail: Org }
  >();
  const { dastracks } = useAppSelector((store) => store.plantManagement);
  const { projectContact } = useAppSelector((store) => store.projects);
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId } = useParams();
  const groupIdSelected = searchParams.get('groupId');
  const plantIdSelected = searchParams.get('plantId');
  const navigate = useNavigate();

  const plantContacts = useMemo(() => {
    if (projectContact.length > 0 && selectedPlant) {
      return projectContact.filter((contact) =>
        contact.bindingProjectReference.some(
          (ref) => ref.type === 'plant' && ref.refId === selectedPlant.id,
        ),
      );
    }
    return [];
  }, [projectContact, selectedPlant]);

  const handleOnChange = (value: any) => {
    const newValue = value;
    const updateValue = [...plantIdWillAssign];
    if (plantIdWillAssign.includes(newValue)) {
      updateValue.splice(updateValue.indexOf(newValue), 1);
    } else {
      updateValue.push(newValue);
    }
    setPlantIdWillAssign(updateValue);
  };

  useEffect(() => {
    if (isLoadingSetPlant) {
      setPlantIdWillAssign([]);
    }
  }, [isLoadingSetPlant]);

  useEffect(() => {
    selectedId(plantIdWillAssign);
  }, [plantIdWillAssign]);

  const groupPlantSelected = useMemo(() => {
    return plantGroupProject.filter((group) =>
      selectedPlant ? selectedPlant.groupIds.includes(group.id) : [],
    );
  }, [selectedPlant]);

  const connectedDevice = useMemo(() => {
    if (dastracks.length > 0) {
      return dastracks.filter((dastrack) =>
        selectedPlant?.bindingDastracks.includes(dastrack.dasId),
      );
    }
    return [];
  }, [selectedPlant, dastracks]);

  const items = data
    .filter((plant) => plant.name.indexOf(filterText) !== -1)
    .sort((a, b) => a.name.localeCompare(b.name, i18n.language))
    .map((plant) => (
      <PlantItemPermission
        key={`Plant ${plant.id}`}
        name={plant.name}
        imageURL={plant.imageURL}
        dasIds={plant.bindingDastracks}
        id={plant.id}
        orgName={plant.orgDetail.name}
        orgColor={plant.orgDetail.color}
        onChange={handleOnChange}
        onClick={() => {
          setShowViewPlantDialog(true);
          setSelectedPlant(plant);
          if (groupIdSelected) {
            setSearchParams({
              groupId: groupIdSelected,
              plantId: plant.id,
            });
          }
        }}
      />
    ));

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginLeft: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={t('page.plant-management.title-plant-list-permission')}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer data-cy="container-plant-list-permission">
        {isLoadingPlantsOfProject ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
      {showEditPlant && (
        <EditPlant
          open={showEditPlant}
          title={t('page.plant-management.button-more.edit-plant-information')}
          navigator={navigatorPlant}
          navigatorIndex={navigatorIndex}
          plantGroupProject={plantGroupProject}
          plantGroupSelected={groupPlantSelected}
          plantSelected={selectedPlant}
          connectedDevice={connectedDevice}
          plantContacts={plantContacts}
          onSuccessEditPlant={onSuccessEdit}
          onSuccessBind={onSuccessBind}
          onCloseDialog={() => {
            if (groupIdSelected) {
              navigate(
                `/project-setting/${projectId}/plant-group-management/edit-plant-group?groupId=${groupIdSelected}`,
              );
            } else {
              navigate(
                `/project-setting/${projectId}/plant-group-management/edit-plant-group`,
              );
            }
            setShowEditPlant(false);
          }}
          onSelectNavigatorIndex={(index: number) => setNavigatorIndex(index)}
        />
      )}
      {showViewPlantDialog && (
        <ViewPlant
          open={showViewPlantDialog}
          title={t('page.plant-management.button-more.plant-information')}
          navigator={navigatorPlant}
          navigatorIndex={navigatorIndex}
          plantSelected={selectedPlant}
          plantGroupSelected={groupPlantSelected}
          connectedDevice={connectedDevice}
          plantContacts={plantContacts}
          onCloseDialog={() => {
            setShowViewPlantDialog(false);
            if (groupIdSelected) {
              navigate(
                `/project-setting/${projectId}/plant-group-management/edit-plant-group?groupId=${groupIdSelected}`,
              );
            } else {
              navigate(
                `/project-setting/${projectId}/plant-group-management/edit-plant-group`,
              );
            }
          }}
          setDialogEditPlant={(state) => {
            if (groupIdSelected && plantIdSelected) {
              setSearchParams({
                groupId: groupIdSelected,
                plantId: plantIdSelected,
              });
            }
            setShowEditPlant(state);
          }}
          onSelectNavigatorIndex={(index: number) => setNavigatorIndex(index)}
        />
      )}
    </ProjectPanel>
  );
};

export default PlantPermissionList;
