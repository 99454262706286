import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { PolicyType } from '../types';
import { AiAlert, Alert, DeviationAlert } from '../types/Alert';
import { Dasair } from '../types/Device';
import { WebSocketAlert, WebSocketAlertReceived } from '../types/Websocket';

import { getAreasOfProject } from '../apis/AreaApi';
import { getProjectDasairs } from '../apis/DasAirApi';
import { getProjectWorkers } from '../apis/DasloopApi';
import { getEquipmentById } from '../apis/DaspowerApi';
import { getProjectPlants } from '../apis/DastrackApi';
import { getProjectVehicles } from '../apis/DastrackVApi';
import { getProjectOfResources } from '../apis/ResourceApi';
import { useAppSelector } from '../hooks';

import CloseMediumSvgIcon from '../assets/SvgIcon/CloseMediumSvgIcon';
import getAlertIcon, {
  AlertType,
  getDasConcreteAlertType,
} from '../utils/getAlertIcon';
import { getDasairAlertType } from '../utils/getDasairType';
import { findTheClosestPipe } from '../utils/getWaterLevel';
import toAlertDescription from '../utils/toAlertDescription';

const ContainerSnackbar = styled(Box)`
  width: 450px;
  height: 80px;
  background-color: ${({ theme }) => theme.color.red.$100};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > .info {
    ${({ theme }) => ({ ...theme.externalTypography.body3 })}
    flex: 1;
    min-width: 0;
    & > .title {
      ${({ theme }) => theme.typography.body2}
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    }
    & > .description {
      font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    & > .date {
      & > span {
        margin: 0 0.625rem;
      }
    }
  }

  & .icon {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .alert-icon {
    width: 40px;
    height: 40px;
  }

  & .btn-close {
    width: 32px;
    height: 32px;
    align-self: flex-start;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

const useAlertSnackBar = (projectId: string, websocket: WebSocketAlert) => {
  const projectRole = useAppSelector((store) => store.projects.role);
  const policies = useAppSelector((store) => store.projects.policies);
  const policyMap = new Map<PolicyType, boolean>();
  policies.forEach((policy) => {
    policyMap.set(policy.name, true);
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation('alert-dashboard');

  const workerReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadOneWorkerGroup');

  const plantReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadOnePlantGroup');

  const equipmentReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadOneEquipmentGroup');

  const vehicleReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadOneVehicleGroup');

  const areaReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadArea');

  const dasairReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadDasair');

  const dasConcreteReadable =
    projectRole === 'owner' || projectRole === 'admin';

  const aiReadable =
    projectRole === 'owner' ||
    projectRole === 'admin' ||
    policyMap.has('ReadOneAiGroup');

  useEffect(() => {
    const handleClose = (key) => {
      closeSnackbar(key);
    };

    const showSnackbar = async (ws: WebSocketAlertReceived) => {
      let resourceName: string | undefined;
      let areaName: string | undefined;
      let areaType: string;
      let dasair: Dasair | undefined;
      switch (ws.type) {
        case 'dasloop':
        case 'dasgas':
          if (workerReadable && 'workerId' in ws.data) {
            resourceName = await getProjectWorkers({
              projectId,
              params: {
                id: ws.data.workerId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          break;
        case 'dastrack':
          if (plantReadable && 'plantId' in ws.data) {
            resourceName = await getProjectPlants({
              projectId,
              params: {
                id: ws.data.plantId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          break;
        case 'dascas_g':
          if (plantReadable) {
            resourceName = await getProjectPlants({
              projectId,
              params: {
                id: (ws.data as Alert<'dascas'>).plantId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          break;
        case 'dastemp':
        case 'daspower':
        case 'daslock':
          if (equipmentReadable) {
            resourceName = await getEquipmentById(
              projectId,
              (ws.data as Alert<'dastemp' | 'daspower' | 'daslock'>).equipment
                ?.id ?? '',
            ).then((res) => res.data.data.name);
          }
          break;
        case 'deviation':
          if (vehicleReadable && 'vehicleId' in ws.data) {
            resourceName = await getProjectVehicles({
              projectId,
              params: {
                id: ws.data.vehicleId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          if (areaReadable && 'areaId' in ws.data) {
            const res = await getAreasOfProject(projectId, {
              id: ws.data.areaId ?? undefined,
              selectVisible: false,
            });
            areaName = res.data.data?.[0]?.name;
            areaType = res.data.data?.[0]?.type;
          }
          break;
        case 'dasair':
          if (dasairReadable && 'endpointId' in ws.data) {
            dasair = await getProjectDasairs({
              projectId,
              params: {
                id: ws.data.endpointId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]);
          }
          break;
        case 'area':
          if (workerReadable && 'workerId' in ws.data) {
            resourceName = await getProjectWorkers({
              projectId,
              params: {
                id: ws.data.workerId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          if (areaReadable && 'areaId' in ws.data) {
            const res = await getAreasOfProject(projectId, {
              id: ws.data.areaId ?? undefined,
              selectVisible: false,
            });
            areaName = res.data.data?.[0]?.name;
            areaType = res.data.data?.[0]?.type;
          }
          break;
        case 'dasconcrete':
          if (dasConcreteReadable && 'resourceId' in ws.data) {
            resourceName = await getProjectOfResources({
              projectId,
              resourceType: 'field',
              params: {
                id: ws.data.resourceId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          break;
        case 'ai':
          if (aiReadable && 'resourceId' in ws.data) {
            resourceName = await getProjectOfResources({
              projectId,
              resourceType: 'ai',
              params: {
                id: ws.data.resourceId ?? undefined,
              },
            }).then((res) => res.data.data?.[0]?.name);
          }
          break;
      }

      enqueueSnackbar('', {
        content: (key) => {
          let title: string = '';
          let alertType: AlertType = AlertType.AREA_ALERT;
          let timestamp: string = new Date().toISOString();

          if (ws.type === 'deviation') {
            timestamp = (ws.data as Alert<'deviation'>).exitedAt;
          } else {
            timestamp = (
              ws.data as Exclude<WebSocketAlertReceived['data'], DeviationAlert>
            ).timestamp;
          }

          const { description } = toAlertDescription(ws.type, ws.data, t, {
            areaName:
              ws.type === 'area'
                ? areaName ?? `${t('no-permission')} ${t('or')} ${t('deleted')}`
                : undefined,
            areaType,
            pipes: ws.pipes,
          });

          if (ws.type === 'dasloop') {
            const dasAlert = ws.data as Alert<'dasloop'>;
            const workerName = resourceName;

            if (dasAlert.alert.type === AlertType.CAP_OFF) {
              title = workerName
                ? `${workerName} (${dasAlert.dasId}) ${t(
                    `alert-type.${dasAlert.alert.type}`,
                  )}`
                : `${t('no-permission')} ${t('or')} ${t(
                    'no-assigned-worker',
                  )} (${dasAlert.dasId})`;
            } else {
              title = workerName
                ? `${workerName} (${dasAlert.dasId})`
                : `${t('no-permission')} ${t('or')} ${t(
                    'no-assigned-worker',
                  )} (${dasAlert.dasId})`;
            }

            alertType = dasAlert.alert.type;
          } else if (ws.type === 'dastrack') {
            const dasAlert = ws.data as Alert<'dastrack'>;
            const plantName = resourceName;

            title = plantName
              ? `${plantName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t('no-assigned-plant')} (${
                  dasAlert.dasId
                })`;
            alertType = dasAlert.alertType;
          } else if (ws.type === 'daspower') {
            const dasAlert = ws.data as Alert<'daspower'>;
            const equipmentName = resourceName;
            title = equipmentName
              ? `${equipmentName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t(
                  'no-assigned-equipment',
                )} (${dasAlert.dasId})`;
            alertType = AlertType.DASPOWER_ALERT;
          } else if (ws.type === 'area') {
            const dasAlert = ws.data as Alert<'area'>;
            const workerName = resourceName;
            title = workerName
              ? `${workerName} (${dasAlert.dasId})`
              : `${t('no-permission')} (${dasAlert.dasId})`;
            alertType = AlertType.AREA_ALERT;
          } else if (ws.type === 'dastemp') {
            const dasAlert = ws.data as Alert<'dastemp'>;
            const equipmentName = resourceName;
            title = equipmentName
              ? `${equipmentName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t('deleted')} (${
                  dasAlert.dasId
                })`;
            alertType = AlertType.AREA_ALERT;
          } else if (ws.type === 'dasair') {
            const dasAlert = ws.data as Alert<'dasair'>;
            title =
              dasair?.dasId ??
              `${t('no-permission')} ${t('or')} ${t('deleted')}`;
            alertType = getDasairAlertType(dasAlert);
          } else if (ws.type === 'dascart') {
            const dasAlert = ws.data as Alert<'dascart'>;
            if (dasAlert.efsAlert && dasAlert.efsAlert > 0) {
              title = `超出電子圍籬: ${dasAlert.efsAlert} 人`;
            } else if (dasAlert.ppeAlert && dasAlert.ppeAlert > 0) {
              title = `發現工安違規: ${dasAlert.ppeAlert} 人`;
            }
            alertType = AlertType.DASCART_ALERT;
          } else if (ws.type === 'deviation') {
            const deviationAlert = ws.data as Alert<'deviation'>;
            title = `${resourceName} ${t('leave')} ${
              areaName
                ? `${areaName} (${deviationAlert.dasId})`
                : `${t('no-permission')} (${deviationAlert.dasId})`
            }`;
            alertType = AlertType.DASTRACK_V_LEAVE_ALERT;
          } else if (ws.type === 'daswater') {
            const dasAlert = ws.data as Alert<'daswater'>;
            const pipeName = findTheClosestPipe(ws.pipes, dasAlert)?.name;
            title = pipeName
              ? `${pipeName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t('deleted')} (${
                  dasAlert.dasId
                })`;

            if (dasAlert.field === 'battery') {
              alertType = AlertType.DASWATER_L_LOWBATTERYALARM;
            } else if (dasAlert.field === 'distance') {
              alertType = AlertType.DASWATER_L_DISTANCE;
            } else if (dasAlert.field === 'isTilt') {
              alertType = AlertType.DASWATER_L_TILT;
            } else {
              alertType = AlertType.DASWATER_L;
            }
          } else if (ws.type === 'dasgas') {
            const dasAlert = ws.data as Alert<'dasgas'>;
            const workerName = resourceName;
            title = workerName
              ? `${workerName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t('no-assigned-worker')} (${
                  dasAlert.dasId
                })`;
            alertType = dasAlert.alert.type;
          } else if (ws.type === 'daslock') {
            const dasAlert = ws.data as Alert<'daslock'>;
            const equipmentName = resourceName;
            title = equipmentName
              ? `${equipmentName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t('deleted')} (${
                  dasAlert.dasId
                })`;
            alertType = dasAlert.alert.type;
          } else if (ws.type === 'dascas_g') {
            const dasAlert = ws.data as Alert<'dascas'>;
            const plantName = resourceName;

            title = plantName
              ? `${plantName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t('no-assigned-plant')} (${
                  dasAlert.dasId
                })`;
            alertType = dasAlert.alertType;
          } else if (ws.type === 'dasconcrete') {
            const dasAlert = ws.data as Alert<'dasconcrete'>;
            const structureName = resourceName;
            title = structureName
              ? `${structureName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t(
                  'no-assigned-structure',
                )} (${dasAlert.dasId})`;
            alertType = getDasConcreteAlertType(dasAlert);
          } else if (ws.type === 'ai') {
            const dasAlert = ws.data as AiAlert;
            const structureName = resourceName;
            title = structureName
              ? `${structureName} (${dasAlert.dasId})`
              : `${t('no-permission')} ${t('or')} ${t(
                  'no-assigned-structure',
                )} (${dasAlert.dasId})`;
            alertType = dasAlert.field;
          }

          return (
            <ContainerSnackbar key={key}>
              <div className="icon">
                {getAlertIcon(alertType, true, true, {
                  width: '40px',
                  height: '40px',
                })}
              </div>
              <div className="info">
                <div className="title ellipsis" title={title}>
                  {title}
                </div>
                {description && (
                  <div className="description ellipsis" title={description}>
                    {description}
                  </div>
                )}
                <div className="date">
                  {`${format(new Date(timestamp), 'HH : mm : ss')}`}
                  <span>|</span>
                  {`${format(new Date(timestamp), 'yyyy - MM - dd')}`}
                </div>
              </div>
              <div className="btn-close" onClick={() => handleClose(key)}>
                <CloseMediumSvgIcon sx={{ width: '32px', height: '32px' }} />
              </div>
            </ContainerSnackbar>
          );
        },
      });
    };
    if (websocket) {
      showSnackbar(websocket);
    }
  }, [projectId, websocket, t]);
};

export default useAlertSnackBar;
