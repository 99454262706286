import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface FreeFallSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const FreeFallSvgIcon = ({ ...props }: FreeFallSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      d="M26.2527 17.209H20.7512C20.7146 17.2088 20.6785 17.2015 20.6452 17.1875C20.612 17.1736 20.5822 17.1533 20.558 17.1281C20.5338 17.1028 20.5157 17.0731 20.5048 17.041C20.4938 17.0088 20.4904 16.9749 20.4947 16.9414L21.3486 10.8176C21.3711 10.6498 21.3296 10.4799 21.2311 10.3372C21.1326 10.1945 20.9833 10.0879 20.8089 10.0356C20.6344 9.98337 20.4456 9.98873 20.275 10.0508C20.1043 10.1129 19.9624 10.2278 19.8736 10.3758L13.0929 21.7521C13.0293 21.8585 12.9973 21.9786 13.0002 22.1001C13.003 22.2216 13.0405 22.3402 13.109 22.4441C13.1775 22.5479 13.2745 22.6333 13.3902 22.6915C13.5059 22.7498 13.6362 22.7788 13.7679 22.7758H19.2729C19.3094 22.776 19.3455 22.7833 19.3788 22.7973C19.4121 22.8112 19.4418 22.8315 19.466 22.8567C19.4902 22.882 19.5084 22.9117 19.5193 22.9438C19.5302 22.976 19.5336 23.0099 19.5294 23.0434L18.6484 29.1672C18.6215 29.3379 18.6613 29.5119 18.7606 29.6582C18.8599 29.8044 19.0122 29.9134 19.1906 29.9657C19.3689 30.018 19.5616 30.0103 19.7343 29.9438C19.907 29.8773 20.0485 29.7565 20.1335 29.6028L26.9109 18.2265C26.9721 18.1214 27.0027 18.0035 26.9998 17.8842C26.9969 17.765 26.9605 17.6484 26.8943 17.546C26.828 17.4435 26.7341 17.3586 26.6216 17.2996C26.5092 17.2405 26.3821 17.2093 26.2527 17.209Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default FreeFallSvgIcon;
