import * as React from 'react';

const DasconcreteSvgColor = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9965 19L28.0002 12.0038L22.0002 8L11.0039 15.0039L16.9965 19Z"
      fill="#E5E5E5"
    />
    <path d="M16 21L10 17V28L16 32V21Z" fill="#E5E5E5" />
    <path d="M18 21L29 14V25L18 32V21Z" fill="#A1A1A1" />
  </svg>
);

export default DasconcreteSvgColor;
