import type { SVGProps } from 'react';

const CO2SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle cx={11} cy={13} r={8.25} stroke="currentColor" strokeWidth={1.5} />
    <path
      fill="currentColor"
      d="M7.353 16c-.438 0-.837-.109-1.196-.327a2.44 2.44 0 0 1-.848-.904A2.654 2.654 0 0 1 5 13.496c0-.472.1-.899.302-1.279.202-.38.478-.677.828-.89.35-.218.745-.327 1.183-.327.237 0 .47.03.697.09.228.06.423.146.585.258l-.178 1.078a2.345 2.345 0 0 0-.854-.188c-.35 0-.62.109-.808.327-.189.213-.283.521-.283.925 0 .394.099.702.296.925.201.217.48.326.835.326.135 0 .262-.014.38-.041a2.66 2.66 0 0 0 .434-.153l.184 1.098A2.323 2.323 0 0 1 7.353 16ZM11.301 16a2.47 2.47 0 0 1-1.261-.32 2.267 2.267 0 0 1-.855-.883 2.718 2.718 0 0 1-.302-1.293c0-.487.1-.92.302-1.3.206-.381.49-.676.855-.884A2.47 2.47 0 0 1 11.3 11c.474 0 .892.107 1.256.32.368.208.652.503.854.883.206.376.309.81.309 1.3 0 .483-.103.914-.309 1.294-.201.375-.486.67-.854.883-.368.213-.787.32-1.256.32Zm0-1.266a.818.818 0 0 0 .677-.34c.18-.228.27-.527.27-.898 0-.245-.042-.463-.125-.653a1.02 1.02 0 0 0-.335-.452.787.787 0 0 0-.493-.167.778.778 0 0 0-.486.167 1.02 1.02 0 0 0-.336.452c-.079.19-.118.408-.118.653 0 .371.088.67.263.898.175.227.403.34.683.34ZM14.108 14.908c.517-.491.907-.909 1.17-1.252.263-.343.394-.628.394-.855 0-.148-.043-.262-.131-.34a.477.477 0 0 0-.342-.119c-.271 0-.554.09-.848.271l-.322-1.036c.241-.13.478-.227.71-.292.237-.065.484-.097.743-.097.307 0 .574.065.802.194.232.126.41.3.532.522.123.218.184.468.184.751 0 .422-.134.816-.4 1.182-.268.362-.671.707-1.21 1.036h1.584v1.016h-2.866v-.98Z"
    />
    <circle cx={16.5} cy={2.5} r={1.5} fill="currentColor" />
    <circle cx={20.5} cy={5.5} r={2.5} fill="currentColor" />
  </svg>
);
export default CO2SvgIcon;
