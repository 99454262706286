import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DasconcreteColorSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.5C12 6.52802 11.9992 6.55586 11.9977 6.5835L13.139 7.72475C13.9894 7.26255 14.964 7 16 7C17.036 7 18.0106 7.26255 18.861 7.72475L20.0023 6.5835C20.0008 6.55586 20 6.52802 20 6.5C20 5.67157 20.6716 5 21.5 5C22.3284 5 23 5.67157 23 6.5C23 7.32843 22.3284 8 21.5 8C21.472 8 21.4441 7.99923 21.4165 7.99772L20.4447 8.96949C21.4111 10.0346 22 11.4485 22 13C22 16.3137 19.3137 19 16 19C12.6863 19 10 16.3137 10 13C10 11.4485 10.5889 10.0346 11.5553 8.96949L10.5835 7.99771C10.5559 7.99923 10.528 8 10.5 8C9.67157 8 9 7.32843 9 6.5C9 5.67157 9.67157 5 10.5 5C11.3284 5 12 5.67157 12 6.5ZM10 13.5C10 14.3284 9.32843 15 8.5 15C7.67157 15 7 14.3284 7 13.5C7 12.6716 7.67157 12 8.5 12C9.32843 12 10 12.6716 10 13.5ZM23.5 15C24.3284 15 25 14.3284 25 13.5C25 12.6716 24.3284 12 23.5 12C22.6716 12 22 12.6716 22 13.5C22 14.3284 22.6716 15 23.5 15ZM8 18.5C8 19.3284 7.32843 20 6.5 20C5.67157 20 5 19.3284 5 18.5C5 17.6716 5.67157 17 6.5 17C7.32843 17 8 17.6716 8 18.5ZM25.5 20C26.3284 20 27 19.3284 27 18.5C27 17.6716 26.3284 17 25.5 17C24.6716 17 24 17.6716 24 18.5C24 19.3284 24.6716 20 25.5 20ZM10 23.5C10 24.3284 9.32843 25 8.5 25C7.67157 25 7 24.3284 7 23.5C7 22.6716 7.67157 22 8.5 22C9.32843 22 10 22.6716 10 23.5ZM23.5 25C24.3284 25 25 24.3284 25 23.5C25 22.6716 24.3284 22 23.5 22C22.6716 22 22 22.6716 22 23.5C22 24.3284 22.6716 25 23.5 25Z"
        fill="#A1A1A1"
      />
      <path
        d="M25 18.5C25 23.1944 20.9706 27 16 27C11.0294 27 7 23.1944 7 18.5C7 15.8925 8.12493 13.5592 10.082 12H21.918C23.8751 13.5592 25 15.8925 25 18.5Z"
        fill="#FF6B00"
      />
      <circle cx="20.5" cy="16.5" r="1.5" fill="#656565" />
      <circle cx="11.5" cy="16.5" r="1.5" fill="#656565" />
      <circle cx="20.5" cy="21.5" r="1.5" fill="#656565" />
      <circle cx="11.5" cy="21.5" r="1.5" fill="#656565" />
      <circle cx="16" cy="19" r="2" fill="#656565" />
    </SvgIcon>
  );
};

export default DasconcreteColorSvgIcon;
