import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  LineString,
  Point,
  Polygon,
} from 'geojson';

import { Area, AreaType, AreaTypeColor, Building } from '../../../types';

type AreaManagementState = {
  selectedAreaType: AreaType;
  centerProject: [number, number];
  areaProjectFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties>;
  destinationFeatureCollection: FeatureCollection<Point, GeoJsonProperties>;
  linePipePathFeatureCollection: FeatureCollection<
    LineString,
    GeoJsonProperties
  >;
  currentProjectOf3dAssets: Building[];
  selectedArea: Area | undefined;
  areaMode: 'edit' | 'add-new';
  isFinishEditArea: boolean;
  isFilterPanelOpen: boolean;
  pathColor: AreaTypeColor;
  routeFeatureCollection: Feature<LineString, GeoJsonProperties>;
  routeAreaFeatureCollection: Feature<Polygon>;
};

const initialState: AreaManagementState = {
  selectedAreaType: 'normal',
  centerProject: [121.36074324749816, 24.983996867689783],
  areaProjectFeatureCollection: {
    type: 'FeatureCollection',
    features: [],
  },
  destinationFeatureCollection: {
    type: 'FeatureCollection',
    features: [],
  },
  linePipePathFeatureCollection: {
    type: 'FeatureCollection',
    features: [],
  },
  selectedArea: undefined,
  areaMode: 'add-new',
  isFinishEditArea: false,
  isFilterPanelOpen: false,
  currentProjectOf3dAssets: [],
  pathColor: 'path-red',
  routeFeatureCollection: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
  },
  routeAreaFeatureCollection: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [],
    },
  },
};

export const areaManagementSlice = createSlice({
  name: 'areaManagementPage',
  initialState,
  reducers: {
    setSelectedAreaType: (
      state,
      action: PayloadAction<AreaManagementState['selectedAreaType']>,
    ) => {
      return {
        ...state,
        selectedAreaType: action.payload,
      };
    },
    setCenterProject: (
      state,
      action: PayloadAction<AreaManagementState['centerProject']>,
    ) => {
      return {
        ...state,
        centerProject: action.payload,
      };
    },
    setAreaProjectFeatureCollection: (
      state,
      action: PayloadAction<
        AreaManagementState['areaProjectFeatureCollection']
      >,
    ) => {
      return { ...state, areaProjectFeatureCollection: action.payload };
    },
    setDestinationFeatureCollection: (
      state,
      action: PayloadAction<
        AreaManagementState['destinationFeatureCollection']
      >,
    ) => {
      return { ...state, destinationFeatureCollection: action.payload };
    },
    setLinePipePathFeatureCollection: (
      state,
      action: PayloadAction<
        AreaManagementState['linePipePathFeatureCollection']
      >,
    ) => {
      return { ...state, linePipePathFeatureCollection: action.payload };
    },
    setRouteFeatureCollection: (
      state,
      action: PayloadAction<AreaManagementState['routeFeatureCollection']>,
    ) => {
      return { ...state, routeFeatureCollection: action.payload };
    },
    setRouteAreaFeatureCollection: (
      state,
      action: PayloadAction<AreaManagementState['routeAreaFeatureCollection']>,
    ) => {
      return { ...state, routeAreaFeatureCollection: action.payload };
    },
    setPathColor: (
      state,
      action: PayloadAction<AreaManagementState['pathColor']>,
    ) => {
      return { ...state, pathColor: action.payload };
    },
    setSelectedAreaData: (
      state,
      action: PayloadAction<AreaManagementState['selectedArea']>,
    ) => {
      return { ...state, selectedArea: action.payload };
    },
    setAreaEditMode: (
      state,
      action: PayloadAction<AreaManagementState['areaMode']>,
    ) => {
      return { ...state, areaMode: action.payload };
    },
    setIsFinsihEditArea: (
      state,
      action: PayloadAction<AreaManagementState['isFinishEditArea']>,
    ) => {
      return {
        ...state,
        isFinishEditArea: action.payload,
      };
    },
    setIsFilterPanelOpen: (
      state,
      action: PayloadAction<AreaManagementState['isFilterPanelOpen']>,
    ) => {
      return {
        ...state,
        isFilterPanelOpen: action.payload,
      };
    },
    setCurrentProjectOf3dAssets: (
      state,
      action: PayloadAction<AreaManagementState['currentProjectOf3dAssets']>,
    ) => {
      return { ...state, currentProjectOf3dAssets: action.payload };
    },
  },
});

export const {
  setSelectedAreaType,
  setAreaProjectFeatureCollection,
  setDestinationFeatureCollection,
  setLinePipePathFeatureCollection,
  setCurrentProjectOf3dAssets,
  setCenterProject,
  setSelectedAreaData,
  setAreaEditMode,
  setIsFinsihEditArea,
  setIsFilterPanelOpen,
  setPathColor,
  setRouteFeatureCollection,
  setRouteAreaFeatureCollection,
} = areaManagementSlice.actions;
export default areaManagementSlice.reducer;
