import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoginOageState = {
  username: string;
};

const initialState: LoginOageState = {
  username: '',
};

export const loginPageSlice = createSlice({
  name: 'loginPage',
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        username: action.payload,
      };
    },
  },
});

export const { setUsername } = loginPageSlice.actions;
export default loginPageSlice.reducer;
