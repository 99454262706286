import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectInvitaionData } from '../../../types';

type NotificationListState = {
  indecisionCount: number;
};

const initialState: NotificationListState = {
  indecisionCount: 0,
};

export const notificationListPageSlice = createSlice({
  name: 'projectPage',
  initialState,
  reducers: {
    updateIndecisionCount: (
      state,
      action: PayloadAction<ProjectInvitaionData[]>,
    ) => {
      return {
        ...state,
        indecisionCount: action.payload.filter(
          (invitation) => invitation.status === 'pending',
        ).length,
      };
    },
  },
});

export const { updateIndecisionCount } = notificationListPageSlice.actions;
export default notificationListPageSlice.reducer;
