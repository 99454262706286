import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const AssetManagementDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasfind-dashboard-page" */ '../../pages/asset-management-dashboard'
    ),
);

const assetManagementDashboardRouter = (
  <Route path="asset-management-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <AssetManagementDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default assetManagementDashboardRouter;
