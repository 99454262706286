import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const RegisterComponent = lazy(
  () => import(/* webpackChunkName: "register" */ '../../pages/register'),
);

const CreateNewAccountFormComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "register" */ '../../pages/register/CreateNewAccountForm'
    ),
);

const VerificationCodeFormComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "register" */ '../../pages/register/VerificationCodeForm'
    ),
);

const CreateAccountSuccessComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "register" */ '../../pages/register/CreateAccountSuccess'
    ),
);

const registerRouter = (
  <Route
    path="/register"
    element={
      <Suspense fallback={<Loading />}>
        <RegisterComponent />
      </Suspense>
    }
  >
    <Route
      path=""
      element={
        <Suspense fallback={<Loading />}>
          <CreateNewAccountFormComponent />
        </Suspense>
      }
    />
    <Route
      path="verification-code"
      element={
        <Suspense fallback={<Loading />}>
          <VerificationCodeFormComponent />
        </Suspense>
      }
    />
    <Route
      path="create-account-success"
      element={
        <Suspense fallback={<Loading />}>
          <CreateAccountSuccessComponent />
        </Suspense>
      }
    />
  </Route>
);

export default registerRouter;
