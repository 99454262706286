import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddBuildingSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0055 17H23.9944C23.8434 17.0008 23.6922 17.0358 23.5528 17.1056L16 20.882L8.44721 17.1056C8.13723 16.9506 7.76909 16.9671 7.47427 17.1493C7.17945 17.3315 7 17.6534 7 18V23C7 23.3788 7.214 23.725 7.55279 23.8944L15.5528 27.8944C15.8343 28.0352 16.1657 28.0352 16.4472 27.8944L24.4472 23.8944C24.786 23.725 25 23.3788 25 23V18C25 17.6534 24.8205 17.3315 24.5257 17.1493C24.3668 17.0511 24.1865 17.001 24.0055 17ZM16.4472 18.8944L21 16.618V13H17V11.382L16 11.882L10.2361 9L16 6.11803L17.7639 7H21V6.38197L16.4472 4.10557C16.1657 3.96481 15.8343 3.96481 15.5528 4.10557L7.55279 8.10557C7.214 8.27496 7 8.62123 7 9V14C7 14.3788 7.214 14.725 7.55279 14.8944L15.5528 18.8944C15.8343 19.0352 16.1657 19.0352 16.4472 18.8944ZM9 13.382V10.618L15 13.618V16.382L9 13.382ZM9 19.618V22.382L15 25.382V22.618L9 19.618ZM25 5V9H29V11H25V15H23V11H19V9H23V5H25Z"
      fill="#A1A1A1"
    />
  </SvgIcon>
);

export default AddBuildingSvgIcon;
