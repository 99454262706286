import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DastempSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_2555_65653" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 8C22.7909 8 21 9.79086 21 12V21.5278C19.7725 22.6264 19 24.223 19 26C19 29.3137 21.6863 32 25 32C28.3137 32 31 29.3137 31 26C31 24.223 30.2275 22.6264 29 21.5278V12C29 9.79086 27.2091 8 25 8Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 8C22.7909 8 21 9.79086 21 12V21.5278C19.7725 22.6264 19 24.223 19 26C19 29.3137 21.6863 32 25 32C28.3137 32 31 29.3137 31 26C31 24.223 30.2275 22.6264 29 21.5278V12C29 9.79086 27.2091 8 25 8Z"
        fill="#F3F3F3"
      />
      <path
        d="M21 21.5278L22.0004 22.6455L22.5 22.1983V21.5278H21ZM29 21.5278H27.5V22.1983L27.9996 22.6455L29 21.5278ZM22.5 12C22.5 10.6193 23.6193 9.5 25 9.5V6.5C21.9624 6.5 19.5 8.96243 19.5 12H22.5ZM22.5 21.5278V12H19.5V21.5278H22.5ZM20.5 26C20.5 24.6674 21.0774 23.4716 22.0004 22.6455L19.9996 20.4101C18.4677 21.7812 17.5 23.7786 17.5 26H20.5ZM25 30.5C22.5147 30.5 20.5 28.4853 20.5 26H17.5C17.5 30.1421 20.8579 33.5 25 33.5V30.5ZM29.5 26C29.5 28.4853 27.4853 30.5 25 30.5V33.5C29.1421 33.5 32.5 30.1421 32.5 26H29.5ZM27.9996 22.6455C28.9227 23.4716 29.5 24.6674 29.5 26H32.5C32.5 23.7786 31.5323 21.7812 30.0004 20.4101L27.9996 22.6455ZM27.5 12V21.5278H30.5V12H27.5ZM25 9.5C26.3807 9.5 27.5 10.6193 27.5 12H30.5C30.5 8.96243 28.0376 6.5 25 6.5V9.5Z"
        fill="#A1A1A1"
        mask="url(#path-1-inside-1_2555_65653)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 14C24.4477 14 24 14.4477 24 15V23.1706C22.8348 23.5825 22 24.6937 22 25.9999C22 27.6568 23.3431 28.9999 25 28.9999C26.6569 28.9999 28 27.6568 28 25.9999C28 24.6937 27.1652 23.5825 26 23.1706V15C26 14.4477 25.5523 14 25 14Z"
        fill="#FF6B00"
      />
      <path d="M21.5 17L15.5 13.5L9.5 17L15.5 20.5L21.5 17Z" fill="#A1A1A1" />
      <path d="M9 18V21.5L15 25V21.5L9 18Z" fill="#A1A1A1" />
      <path d="M22 18V21.5L16 25V21.5L22 18Z" fill="#A1A1A1" />
    </SvgIcon>
  );
};

export default DastempSvgColorMedium;
