import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseMediumSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29327 8.29236C8.90281 8.68283 8.90225 9.31645 9.29203 9.7076L15.0824 15.5183L9.31332 21.2975C8.9232 21.6883 8.9232 22.3219 9.31333 22.7127C9.70345 23.1035 10.336 23.1035 10.7261 22.7127L16.4939 16.9348L22.2564 22.7175C22.6461 23.1087 23.2787 23.1092 23.6691 22.7188C24.0596 22.3283 24.0601 21.6947 23.6704 21.3035L17.9066 15.5195L23.7018 9.71415C24.0919 9.32334 24.0919 8.68972 23.7018 8.29891C23.3117 7.9081 22.6792 7.9081 22.2891 8.29891L16.4951 14.1031L10.706 8.2936C10.3162 7.90245 9.68374 7.9019 9.29327 8.29236Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CloseMediumSvgIcon;
