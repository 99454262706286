import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const InfititLoaderComp = lazy(
  () =>
    import(
      /* webpackChunkName: "test" */ '../../pages/lab/infinit-loader/InfititLoaderComp'
    ),
);

const InfiniteScrollTest = lazy(
  () =>
    import(
      /* webpackChunkName: "test" */ '../../pages/lab/infinit-loader/InfiniteScrollTest'
    ),
);

const SvgCharts = lazy(
  () => import(/* webpackChunkName: "test" */ '../../pages/lab/SvgChatrs'),
);

const TestingReact18 = lazy(
  () => import(/* webpackChunkName: "test" */ '../../pages/lab/TestingReact18'),
);

const DrawAreaExperiment = lazy(
  () =>
    import(/* webpackChunkName: "test" */ '../../pages/lab/DrawAreaExperiment'),
);

const DrawTruckPathExperiment = lazy(
  () =>
    import(
      /* webpackChunkName: "test" */ '../../pages/lab/DrawTruckPathExperiment'
    ),
);
const WebrtcLiveCall = lazy(
  () => import(/* webpackChunkName: "test" */ '../../pages/lab/WebrtcLiveCall'),
);

const StrengthChartDemoPage = lazy(
  () =>
    import(
      /* webpackChunkName: "test" */ '../../pages/lab/StrengthChartDemoPage'
    ),
);

export const labRouter = (
  <Route
    path="/lab"
    element={
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    }
  >
    <Route path="infinite-loader" element={<InfititLoaderComp />} />
    <Route path="infinite-scroll-test" element={<InfiniteScrollTest />} />
    <Route path="svg-charts" element={<SvgCharts />} />
    <Route path="test-react-18" element={<TestingReact18 />} />
    <Route path="draw-path-truck" element={<DrawTruckPathExperiment />} />
    <Route path="draw-area" element={<DrawAreaExperiment />} />
    <Route path="webrtc-live-call" element={<WebrtcLiveCall />} />
    <Route path="strength-chart-demo" element={<StrengthChartDemoPage />} />
  </Route>
);
