import {
  Coordinates,
  DeviceType,
  WorkerCertificateWillAssign,
} from '../../../types';
import {
  CreateAssetBasicInformation,
  CreateEnvironmentBasicInformation,
  CreatePlantBasicInformation,
  CreateStructureBasicInformation,
  CreateWorkerBasicInformation,
} from '../../../types/Resource';

import { bindDascasToPlant, createCraneApi } from '../../../apis/DascasApi';
import {
  bindDasloopToWorker,
  createWorker as createWorkerApi,
  createWorkerCertificate,
  uploadAvatarCertificate,
  uploadWorkerAvatar,
} from '../../../apis/DasloopApi';
import { uploadPlantAvatar } from '../../../apis/DastrackApi';
import { bindProjectContact } from '../../../apis/ProjectApi';
import {
  bindDeviceToResource,
  createResource,
  createResourceBase,
  uploadResourceAvatar,
} from '../../../apis/ResourceApi';

export interface CreateResoure<TBasicInformation> {
  projectId: string;
  basicInformation: TBasicInformation;
  couldBindDevice: boolean;
  devices: Array<{
    id: string;
    type: DeviceType;
    dasId: string;
    location?: Coordinates | null;
  }>;
  contactIds: string[];
  certificateData?: WorkerCertificateWillAssign[];
  avatar?: File;
}

export const createWorker = async (
  data: CreateResoure<CreateWorkerBasicInformation>,
) => {
  const worker = await createWorkerApi({
    projectId: data.projectId,
    payload: data.basicInformation,
  }).then((res) => res.data.data);

  if (data.avatar && worker) {
    await uploadWorkerAvatar({
      projectId: data.projectId,
      imageFile: data.avatar,
      workerId: worker.id,
    });
  }

  const devices = data.devices.filter((device) => device.type === 'dasloop');

  if (data.couldBindDevice) {
    for (let i = 0; i < devices.length; i++) {
      await bindDasloopToWorker({
        projectId: data.projectId,
        dasId: devices[i].dasId,
        workerId: worker.id,
      });
    }
  }

  for (let i = 0; i < data.contactIds.length; i++) {
    await bindProjectContact({
      projectId: data.projectId,
      contactId: data.contactIds[i],
      type: 'worker',
      referenceId: worker.id,
    });
  }

  if (data.certificateData) {
    for (let i = 0; i < data.certificateData.length; i++) {
      const oneOfCertificateData = data.certificateData[i];
      const certificate = await createWorkerCertificate({
        projectId: data.projectId,
        name: oneOfCertificateData.name,
        workerId: worker.id,
        expiryDate: oneOfCertificateData.expiryDate,
        number: oneOfCertificateData.number,
      }).then((res) => res.data.data);

      if (oneOfCertificateData.imageFile) {
        await uploadAvatarCertificate({
          projectId: data.projectId,
          certificateId: certificate.id,
          imageFile: oneOfCertificateData.imageFile,
          workerId: worker.id,
        });
      }
    }
  }
};

export const createEnvironment = async (
  data: CreateResoure<CreateEnvironmentBasicInformation>,
) => {
  const environment = await createResource({
    projectId: data.projectId,
    resourceType: 'environment',
    data: data.basicInformation,
  }).then((res) => res.data.data);

  if (data.avatar && environment) {
    await uploadResourceAvatar({
      projectId: data.projectId,
      resourceType: 'environment',
      imageFile: data.avatar,
      resourceId: environment.id,
    });
  }

  for (let i = 0; i < data.contactIds.length; i++) {
    await bindProjectContact({
      projectId: data.projectId,
      contactId: data.contactIds[i],
      type: 'environment',
      referenceId: environment.id,
    });
  }
};

export const createCrane = async (
  data: CreateResoure<CreatePlantBasicInformation>,
) => {
  const plant = await createCraneApi({
    projectId: data.projectId,
    payload: data.basicInformation,
  }).then((res) => res.data.data);

  if (data.avatar && plant) {
    await uploadPlantAvatar({
      projectId: data.projectId,
      imageFile: data.avatar,
      plantId: plant.id,
    });
  }

  const devices = data.devices.filter((device) => device.type === 'dascas');

  if (data.couldBindDevice) {
    for (let i = 0; i < devices.length; i++) {
      await bindDascasToPlant({
        projectId: data.projectId,
        dasId: devices[i].dasId,
        data: {
          plantId: plant.id,
        },
      });
    }
  }

  for (let i = 0; i < data.contactIds.length; i++) {
    await bindProjectContact({
      projectId: data.projectId,
      contactId: data.contactIds[i],
      type: 'plant',
      referenceId: plant.id,
    });
  }
};

export const createStructure = async (
  data: CreateResoure<CreateStructureBasicInformation>,
) => {
  const structure = await createResourceBase({
    projectId: data.projectId,
    resourceType: 'field',
    payload: data.basicInformation,
  }).then((res) => res.data.data);

  const devices = data.devices.filter(
    (device) => device.type === 'dasconcrete',
  );

  if (data.couldBindDevice) {
    for (let i = 0; i < devices.length; i++) {
      await bindDeviceToResource({
        projectId: data.projectId,
        dasId: devices[i].dasId,
        resourceType: 'field',
        data: {
          resourceId: structure.id,
          location: devices[i].location,
        },
      });
    }
  }

  for (let i = 0; i < data.contactIds.length; i++) {
    await bindProjectContact({
      projectId: data.projectId,
      contactId: data.contactIds[i],
      type: 'field',
      referenceId: structure.id,
    });
  }
};

export const createAsset = async (
  data: CreateResoure<CreateAssetBasicInformation>,
) => {
  const asset = await createResourceBase({
    projectId: data.projectId,
    resourceType: 'asset',
    payload: data.basicInformation,
  }).then((res) => res.data.data);

  if (data.avatar && asset) {
    await uploadResourceAvatar({
      projectId: data.projectId,
      resourceType: 'asset',
      imageFile: data.avatar,
      resourceId: asset.id,
    });
  }

  for (let i = 0; i < data.contactIds.length; i++) {
    await bindProjectContact({
      projectId: data.projectId,
      contactId: data.contactIds[i],
      type: 'asset',
      referenceId: asset.id,
    });
  }
};
