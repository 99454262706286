import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

import WorkerAttendanceInformation from '../../pages/report-dashboard/WorkerAttendanceInformation';

const ReportComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "area-dashboard-page-edit-area" */ '../../pages/report-dashboard'
    ),
);

const reportRouter = (
  <Route path="report-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <ReportComponent />
        </Suspense>
      }
    >
      <Route
        path="worker/:resourceId"
        element={
          <div>
            <WorkerAttendanceInformation />
          </div>
        }
      />
      <Route
        path="plant/:resourceId"
        element={
          <div>
            <WorkerAttendanceInformation />
          </div>
        }
      />
    </Route>
  </Route>
);

export default reportRouter;
