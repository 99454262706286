import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

const Container = styled('div')`
  height: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 11px;
  color: ${({ theme }) => theme.color.box_bbg};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
const EmptyDeviceConnected: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Typography variant="body2">{t('no-device-connected')}</Typography>
    </Container>
  );
};

export default EmptyDeviceConnected;
