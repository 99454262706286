import { useTranslation } from 'react-i18next';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  styled,
  Typography,
} from '@mui/material';

import { AssignDeviceItemData } from '../../../types/Device';

import ConfirmButtonDark from '../../../components/ConfirmButtonDark';
import ConfirmDarkDialog from '../../../components/Dialog/ConfirmDarkDialog';

import exchangeImage from '../../../assets/images/png/img_exchange.png';
import MuiTheme from '../../../theme';

const TextConfirm = styled(Typography)`
  ${({ theme }) => ({ ...theme.typography.h2 })};
  max-width: 350px;
  color: '#fff';
  margin: 10px auto 20px auto;
  word-break: break-word;
  text-align: center;
  text-align-last: center;
`;

const TextInner = styled(Typography)`
  ${({ theme }) => ({ ...theme.typography.body2 })};
  color: ${({ theme }) => theme.color.secondary.$60};
  margin: 0 auto 20px auto;
  max-width: 270px;
  word-break: break-word;
  text-align: center;
  text-align-last: center;
`;

const DialogActions = styled(MuiDialogActions)`
  height: 80px;
  padding: 24px;
  justify-content: space-between;
`;

const DialogContent = styled(MuiDialogContent)`
  & > img {
    display: block;
    margin: 0 auto;
  }
`;

interface RebindConfirmProps {
  open: boolean;
  onClose: () => void;
  handleOnSelect: (d: AssignDeviceItemData | undefined) => void;
}

const RebindConfirm: React.FC<RebindConfirmProps> = ({
  open,
  onClose,
  handleOnSelect,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    handleOnSelect(undefined);
    onClose();
  };

  return (
    <ConfirmDarkDialog open={open} onClose={handleClose}>
      <DialogContent>
        <TextConfirm>{t('reassign-setting-title')}</TextConfirm>
        <TextInner>{t('reassign-setting-text-inner')}</TextInner>
        <img src={exchangeImage} alt="bee-exchange-image" />
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            height: '32px',
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': { background: MuiTheme.color.secondary.$60 },
          }}
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButtonDark
          status={undefined}
          sx={{
            ml: 'auto',
            width: '120px',
            height: '32px',
          }}
          onClick={onClose}
        >
          {t('OK')}
        </ConfirmButtonDark>
      </DialogActions>
    </ConfirmDarkDialog>
  );
};

export default RebindConfirm;
