import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const PublicAnnouncementPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "public-announcement-page" */ '../../pages/public-announcement'
    ),
);

const PublicAnnouncementRecordComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "public-announcement-page" */ '../../pages/public-announcement/record/AnnouncemnetRecordDialog'
    ),
);

const PublicAnnouncementManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "public-announcement-page" */ '../../pages/public-announcement/management/PublicAnnouncementManagement'
    ),
);

const publicAnnouncementRouter = (
  <Route path="public-announcement">
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <PublicAnnouncementPageComponent />
        </Suspense>
      }
    >
      <Route
        path="record/:recordId"
        element={
          <Suspense fallback={<Loading />}>
            <PublicAnnouncementRecordComponent />
          </Suspense>
        }
      />
    </Route>
    <Route
      path=":projectId/management"
      element={
        <Suspense fallback={<Loading />}>
          <PublicAnnouncementManagementComponent />
        </Suspense>
      }
    />
  </Route>
);

export default publicAnnouncementRouter;
