import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PinSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14C9 10.4055 12.1402 7.00006 16 7.00006C19.8598 7.00006 22.9999 10.4055 23 14C23 18.2641 17.2762 23.9965 16.5382 24.7356C16.5042 24.7697 16.4807 24.7931 16.469 24.8054C16.2189 25.0647 15.7815 25.0652 15.531 24.8054C15.5193 24.7931 15.4958 24.7697 15.4618 24.7356C14.7238 23.9965 9 18.2641 9 14ZM16 17C17.6569 17 19 15.6568 19 14C19 12.3431 17.6569 11 16 11C14.3432 11 13 12.3431 13 14C13 15.6568 14.3432 17 16 17Z"
      />
    </SvgIcon>
  );
};

export default PinSvgIcon;
