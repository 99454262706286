import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="9" y="15" width="14" height="2" rx="1" fill="currentColor" />
    <rect
      x="15"
      y="23"
      width="14"
      height="2"
      rx="1"
      transform="rotate(-90 15 23)"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AddSvgIcon;
