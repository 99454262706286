import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';

import { FloorPlan } from '../../../types';

type FloorPlanManagementState = {
  floorPlanMode: 'edit' | 'add-new' | 'none';
  isFinishEdit: boolean;
  isFloorPlanSelected: boolean;
  selectedFloorPlan: FloorPlan | undefined;
  floorPlanFeatureCollection: FeatureCollection;
};

const initialState: FloorPlanManagementState = {
  floorPlanMode: 'none',
  isFinishEdit: false,
  isFloorPlanSelected: false,
  selectedFloorPlan: {
    id: '',
    name: '',
    imageURL: null,
    rectangle: [
      [0, 0],
      [0, 0],
      [0, 0],
      [0, 0],
    ],
    altitude: 0,
    transparency: null,
    createdAt: '',
    updatedAt: '',
  },
  floorPlanFeatureCollection: {
    type: 'FeatureCollection',
    features: [],
  },
};

export const floorPlanManagementSlice = createSlice({
  name: 'floorPlanManagementPage',
  initialState,
  reducers: {
    setSelectedFloorPlan: (
      state,
      action: PayloadAction<FloorPlanManagementState['selectedFloorPlan']>,
    ) => {
      return {
        ...state,
        selectedFloorPlan: action.payload,
      };
    },
    setIsFloorPlanSelected: (
      state,
      action: PayloadAction<FloorPlanManagementState['isFloorPlanSelected']>,
    ) => {
      return {
        ...state,
        isFloorPlanSelected: action.payload,
      };
    },
    setFloorPlanFeatureCollection: (
      state,
      action: PayloadAction<
        FloorPlanManagementState['floorPlanFeatureCollection']
      >,
    ) => {
      return { ...state, floorPlanFeatureCollection: action.payload };
    },
    setFloorPlanMode: (
      state,
      action: PayloadAction<FloorPlanManagementState['floorPlanMode']>,
    ) => {
      return { ...state, floorPlanMode: action.payload };
    },
  },
});

export const {
  setSelectedFloorPlan,
  setIsFloorPlanSelected,
  setFloorPlanFeatureCollection,
  setFloorPlanMode,
} = floorPlanManagementSlice.actions;
export default floorPlanManagementSlice.reducer;
