import { LevelHazardThermal } from '../types';
import { DasairType, DasGasType, HazardRank } from '../types/Dasair';
import { Dasair } from '../types/Device';

const rankLevel = {
  hazardous: 3,
  attention: 2,
  good: 1,
  zero: 0,
};

export const checkValue = (value: number | null | undefined) => {
  return value === undefined || value === null ? false : true;
};

export const getAirQualityHazardRank: (
  airType: DasairType | DasGasType,
  value: number,
) => HazardRank | undefined = (airType, value) => {
  const type = airType.toLowerCase();

  if (type === 'o2') {
    if (value < 19.5) return 'hazardous';
    if (value >= 19.5 && value <= 23.5) return 'good';
    if (value > 23.5) return 'hazardous';
  } else if (type === 'co') {
    if (value >= 35) return 'hazardous';
    if (value >= 9 && value < 35) return 'attention';
    if (value < 9) return 'good';
  } else if (type === 'ch2o') {
    if (value > 0.5) return 'hazardous';
    if (value >= 0.1 && value <= 0.5) return 'attention';
    if (value < 0.1) return 'good';
  } else if (type === 'nh3') {
    if (value > 50) return 'hazardous';
    if (value <= 50 && value >= 35) return 'attention';
    if (value < 35) return 'good';
  } else if (type === 'pm2_5') {
    if (value >= 65) return 'hazardous';
    if (value >= 35) return 'attention';
    if (value < 35) return 'good';
  } else if (type === 'pm10') {
    if (value >= 100) return 'hazardous';
    if (value >= 75) return 'attention';
    if (value < 75) return 'good';
  } else if (type === 'co2') {
    if (value >= 2500) return 'hazardous';
    if (value >= 1000) return 'attention';
    if (value < 1000) return 'good';
  } else if (type === 'voc') {
    if (value >= 400) return 'hazardous';
    if (value >= 100) return 'attention';
    if (value < 100) return 'good';
  } else if (type === 'so2') {
    if (value >= 2) return 'hazardous';
    if (value >= 1) return 'attention';
    if (value < 1) return 'good';
  } else if (type === 'h2s') {
    if (value >= 10) return 'hazardous';
    if (value < 10) return 'good';
  } else if (type === 'ch4') {
    if (value >= 4) return 'hazardous';
    if (value >= 1) return 'attention';
    if (value < 1) return 'good';
  }

  return undefined;
};

export const getTotalRank: (data: {
  [key: string]: HazardRank | undefined;
}) => HazardRank | undefined = (data) => {
  let curr: HazardRank | 'zero' = 'zero';
  for (const key in data) {
    const rank = data[key] ? rankLevel[data[key] ?? 'zero'] : rankLevel.zero;

    if (rankLevel[curr] < rank) {
      curr = data[key] ?? 'zero';
    }
  }
  if (curr === 'zero') return undefined;
  return curr as HazardRank | undefined;
};

export const getTotalHazardRank = (dasair: Dasair | undefined) => {
  const dataPoint = dasair?.shadow.dataPoint;
  const airQualityRank: { [key: string]: HazardRank | undefined } = {};
  if (dataPoint) {
    Object.entries(dataPoint).map(([key, value]) => {
      const property = key.toUpperCase() as DasairType | DasGasType;
      if (value.value !== null) {
        const rank = getAirQualityHazardRank(property, value.value);
        airQualityRank[property] = rank;
      } else {
        airQualityRank[property] = undefined;
      }
    });

    const rank = getTotalRank(airQualityRank);
    return rank;
  }
};

export const getlevelTermalHazard: (value: number) => LevelHazardThermal = (
  value,
) => {
  if (value >= 0 && value <= 26.8) {
    return 'safe-level';
  } else if (value > 26.8 && value <= 32.2) {
    return 'level-1';
  } else if (value > 32.2 && value <= 40) {
    return 'level-2';
  } else if (value > 40.6 && value <= 54.4) {
    return 'level-3';
  } else if (value > 54.4) {
    return 'level-4';
  } else {
    return 'level-4';
  }
};

export const getPositionPixelTerminalHazard = (level: LevelHazardThermal) => {
  if (level === 'safe-level') {
    return 1;
  } else if (level === 'level-1') {
    return 30;
  } else if (level === 'level-2') {
    return 90;
  } else if (level === 'level-3') {
    return 180;
  } else if (level === 'level-4') {
    return 270;
  }
  return 0;
};

export function getTextRange(value: number) {
  if (value >= 0 && value <= 26.8) {
    return '(< 26.8)';
  } else if (value > 26.8 && value <= 32.2) {
    return '(26.8 - 32.2)';
  } else if (value > 32.2 && value <= 40.6) {
    return '(32.2 - 40.6)';
  } else if (value > 40.6 && value <= 54.4) {
    return '(40.6 - 54.4)';
  } else if (value > 54.4) {
    return '(> 54)';
  } else {
    return 'Value is out of range';
  }
}
