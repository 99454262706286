import { useContext, useEffect, useState } from 'react';

import { WebSocketAlert, WebsocketAlert } from '../types/Websocket';

import { useAppDispatch } from '../hooks';
import { setAlertWebsocket } from '../slices/projectSlice';

import { PubSubContext } from '../utils/PubSub';

import { alertHandler } from './useWebSocket';

export const useAlertQueueTimeOut = (
  projectId: string | undefined,
  duration: number = 3000,
) => {
  const pubSub = useContext(PubSubContext);
  const [alertWebsockets, setLocalAlertWebsockets] = useState<
    Array<WebsocketAlert>
  >([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pubSub) {
      const websocketAlertHandler = async (data) => {
        if (data.type === 'dascas_g') {
          const alert = (await alertHandler(
            projectId,
            data.type,
            data,
          )) as WebSocketAlert | null;

          const dasId = alert?.data.dasId ?? null;
          const id = alert?.data.id ?? null;
          const timestamp = Date.now();

          setLocalAlertWebsockets((prevAlerts) => [
            ...prevAlerts,
            { state: true, dasId, id, timestamp },
          ]);
        }
      };

      const removeExpiredAlerts = () => {
        const currentTimestamp = Date.now();
        setLocalAlertWebsockets((prevAlerts) =>
          prevAlerts.filter(
            (alert) => currentTimestamp - alert.timestamp <= duration,
          ),
        );
      };

      pubSub.subscribe('alert', websocketAlertHandler);

      const timeoutRef = setInterval(removeExpiredAlerts, duration);

      return () => {
        pubSub.unsubscribe('alert', websocketAlertHandler);

        clearInterval(timeoutRef);
      };
    }
  }, [pubSub, projectId, duration]);

  useEffect(() => {
    dispatch(setAlertWebsocket(alertWebsockets));
  }, [alertWebsockets]);
};
