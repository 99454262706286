import * as THREE from 'three';

import { globalTracker } from './ResourceTracker';

class WorkerCreator {
  create(config: { name: string; material: THREE.Material }) {
    const headGeometry: THREE.SphereGeometry = globalTracker.track(
      new THREE.SphereGeometry(0.2, 32, 36),
    );
    headGeometry.name = 'workerHead';
    headGeometry.translate(0, 1.85, 0);
    const bodyGeomertry: THREE.CylinderGeometry = globalTracker.track(
      new THREE.CylinderGeometry(0.2, 0.05, 1.6, 12),
    );
    bodyGeomertry.name = 'workerBody';
    bodyGeomertry.translate(0, 0.8, 0);
    const headMesh: THREE.InstancedMesh = new THREE.InstancedMesh(
      headGeometry,
      config.material,
      1000,
    );

    headMesh.name = `${config.name}HeadMesh`;
    const bodyMesh: THREE.InstancedMesh = new THREE.InstancedMesh(
      bodyGeomertry,
      config.material,
      1000,
    );

    bodyMesh.name = `${config.name}BodyMesh`;

    const group: THREE.Group = globalTracker.track(new THREE.Group());
    group.add(headMesh);
    group.add(bodyMesh);

    group.name = config.name;
    return group;
  }
}

export default WorkerCreator;
