import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeviceSquareSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9963 20L30 14.0038L19.9963 8L10 14.0038L19.9963 20Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M19 21.5L9 15.5V26.5L19 32.5V21.5Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M21 21.5L31 15.5V26.5L21 32.5V21.5Z"
        fill="black"
        fillOpacity="0.4"
      />
    </SvgIcon>
  );
};

export default DeviceSquareSvgIcon;
