import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import {
  Box,
  Button as MuiButton,
  ClickAwayListener,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import { useQueries } from '@tanstack/react-query';

import {
  BuildingField,
  BuildingGroup,
  FieldGroup,
  ResourceType,
} from '../../types';
import { Structure } from '../../types/Resource';

import {
  createResourceGroup,
  getGroupOfResources,
  updateResourceGroup,
} from '../../apis/ResourceApi';
import { useAppSelector } from '../../hooks';

import { handleAddIcon } from '../../assets/SvgIcon/HandleIcon';
import MoreSvgIcon from '../../assets/SvgIcon/MoreSvgIcon';
import RenameSvgIcon from '../../assets/SvgIcon/RenameSvgIcon';
import MuiTheme from '../../theme';
import EmptyDataWarning from '../EmptyDataWarning';
import ProjectPanel from '../Panel/ProjectPanel';
import Search from '../Search';

import RenameDialog from './group-dialog/RenameDialog';
import TargetOperationField from './TargetOperationDialog/TargetOperationField';
import FieldItem from './FieldItem';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

const MoreContainer = styled('div')`
  cursor: pointer;
`;

const ContainerPopper = styled(Box)`
  width: 10.5rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  overflow: hidden;
`;

interface ContainerButtonPopperProps {
  disabled?: boolean;
}

const ContainerButtonPopper = styled('div')<ContainerButtonPopperProps>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.externalColor.dark.$20};
    color: ${({ theme }) => theme.color.secondary.$40};
  }
`;

const TextPopper = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: 'inherit',
  marginLeft: '2px',
  transition: '0.2s',
}));

const Button = styled(MuiButton)`
  &.Mui-disabled {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.secondary.$60};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  }
`;

interface GroupOfFieldListProps {
  resourceType: ResourceType;
  selectedGroup: BuildingGroup | undefined;
  selectedFieldGroup?: FieldGroup;
  onSelectFieldGroup?: (fieldId: string) => void;
  refetchGroup: () => void;
  mode: 'edit' | 'none';
}

const GroupOfFieldList: FC<GroupOfFieldListProps> = ({
  resourceType,
  selectedGroup,
  selectedFieldGroup,
  refetchGroup,
  onSelectFieldGroup,
  mode,
}) => {
  const { t } = useTranslation('project-setting');
  const anchorRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedBuildingId = searchParams.get('buildingId');
  const [filterText, setFilterText] = useState('');
  const [showAddFieldDialog, setShowAddFieldDialog] = useState(false);
  const [showEditFieldDialog, setShowEditFieldDialog] = useState(false);
  const [showUpdateFieldGroup, setShowUpdateFieldGroup] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [selectedField, setSelectedField] = useState<BuildingField>();
  const { role, policies } = useAppSelector((store) => store.projects);
  const [resourcelistHeight, setResourcelistHeight] = useState(0);

  const items = useMemo(() => {
    return (
      selectedGroup?.roomList.filter(
        (resource) => resource.name.indexOf(filterText) !== -1,
      ) ?? []
    );
  }, [selectedGroup, filterText]);

  const editableField = () => {
    let isAuthorizedOneGroup: boolean = false;
    let isGroupAuthorized: boolean = false;
    if (resourceType === 'worker') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOneWorkerGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOneWorkerGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    } else if (resourceType === 'plant') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOnePlantGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOnePlantGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    }

    if (role === 'owner' || role === 'admin') {
      return true;
    } else if (isAuthorizedOneGroup && isGroupAuthorized) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setResourcelistHeight(entry.contentBoxSize[0].blockSize);
      }
    });
    if (listRef.current) {
      resizeObserver.observe(listRef.current);
    }

    return () => {
      if (listRef.current) {
        resizeObserver.unobserve(listRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const allResources = useQueries({
    queries: items.map((building) => {
      return {
        queryKey: ['get-group-resources', projectId, building.id],
        queryFn: async () => {
          let localStructures: Structure[] = [];
          const load = async (nextCursor?: string) => {
            const res = await getGroupOfResources({
              projectId: projectId as string,
              resourceType: 'field',
              groupId: building.id as string,
              params: {
                nextCursor,
              },
            });
            localStructures = localStructures.concat(
              res.data.data as Structure[],
            );

            if (res.data.paging.nextCursor) {
              await load(res.data.paging.nextCursor);
            }
          };

          await load();

          return localStructures;
        },
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!building.id && !!projectId,
      };
    }),
  });

  const operationApi = updateResourceGroup;
  const operationFieldApi = createResourceGroup;

  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const field = items[index] as BuildingField;
      const structure = allResources[index];

      return (
        <FieldItem
          key={`field-${resourceType}-item-${field.id}`}
          style={style}
          name={field.name}
          onClickEdit={() => {
            setSelectedField(field);
            setShowEditFieldDialog(true);
          }}
          disabled={!editableField()}
          isInList={false}
          onClick={() => {
            if (mode === 'edit') {
              onSelectFieldGroup?.(field.id);
            } else {
              navigate(
                `edit-structure-list?buildingId=${selectedBuildingId}&fieldId=${field.id}`,
              );
            }
          }}
          selected={field.id === selectedFieldGroup?.id}
          deviceTotal={t('count-structure', {
            count: structure.data?.length,
          })}
        />
      );
    },
    [items, resourceType, mode, selectedFieldGroup, allResources],
  );

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsOpen(false);
  };

  const handleRenameGroup = () => {
    setShowUpdateFieldGroup(true);
  };

  const editableResource = () => {
    let isAuthorizedOneGroup: boolean = false;
    let isGroupAuthorized: boolean = false;
    if (resourceType === 'worker') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOneWorkerGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOneWorkerGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    } else if (resourceType === 'plant') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOnePlantGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOnePlantGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    } else if (resourceType === 'field' || resourceType === 'structure') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOneFieldGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOneFieldGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    }

    if (role === 'owner' || role === 'admin') {
      return true;
    } else if (isAuthorizedOneGroup && isGroupAuthorized) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 87px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={selectedGroup?.name}
      subtitle={t('project-setting:pouring-area-management')}
      rightNode={
        mode === 'none' && (
          <>
            <MoreContainer
              onClick={handleToggle}
              ref={anchorRef}
              data-cy={`btn-more-edit-${resourceType}-list`}
            >
              <MoreSvgIcon />
            </MoreContainer>
            <ClickAwayListener onClickAway={handleClose}>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="right-end"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0],
                    },
                  },
                ]}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                <ContainerPopper>
                  <ContainerButtonPopper
                    onClick={handleRenameGroup}
                    disabled={!editableResource()}
                    data-cy={`btn-rename-group-${resourceType}`}
                  >
                    <RenameSvgIcon />
                    <TextPopper>{t('rename-building')}</TextPopper>
                  </ContainerButtonPopper>
                </ContainerPopper>
              </Popper>
            </ClickAwayListener>
          </>
        )
      }
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
        <Button
          sx={{
            width: '100%',
            color: MuiTheme.color.secondary.$60,
            marginBottom: '10px',
            borderColor: MuiTheme.color.secondary.$60,
            '&:hover': {
              color: 'white',
              borderColor: 'white',
            },
          }}
          variant="outlined"
          startIcon={handleAddIcon('pouring-area')}
          onClick={() => {
            setShowAddFieldDialog(true);
          }}
          disabled={!editableField()}
          data-cy={`btn-add-${resourceType}-in-group`}
        >
          {t(`page.${resourceType}-management.add-resource`)}
        </Button>
      </div>
      <ListContainer ref={listRef}>
        {items.length > 0 ? (
          <FixedSizeList
            className="List"
            width="100%"
            height={resourcelistHeight}
            itemCount={items.length}
            itemSize={64}
          >
            {Row}
          </FixedSizeList>
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
      {showAddFieldDialog && (
        <TargetOperationField
          open={showAddFieldDialog}
          onClose={() => setShowAddFieldDialog(false)}
          // @ts-ignore
          operationApi={operationFieldApi}
          onSuccess={() => {
            setShowAddFieldDialog(false);
            refetchGroup();
          }}
          buildingGroupId={selectedGroup?.id}
          mode="create"
        />
      )}

      {showEditFieldDialog && selectedField && (
        <TargetOperationField
          open={showEditFieldDialog}
          onClose={() => setShowEditFieldDialog(false)}
          // @ts-ignore
          operationApi={operationApi}
          onSuccess={() => {
            setShowEditFieldDialog(false);
            refetchGroup();
          }}
          buildingGroupId={selectedGroup?.id}
          name={selectedField.name}
          remark={selectedField.remark}
          groupId={selectedField?.id}
          imageURL={selectedField.imageUrl}
          mode="edit"
        />
      )}
      {showUpdateFieldGroup && (
        <RenameDialog
          open={showUpdateFieldGroup}
          resourceType={resourceType}
          title={t(`rename-group`)}
          submitText={t('OK')}
          name={selectedGroup?.name ?? ''}
          remark={selectedGroup?.remark ?? ''}
          groupId={selectedGroup?.id ?? ''}
          // @ts-ignore
          operationApi={operationApi}
          onClose={() => setShowUpdateFieldGroup(false)}
          onSuccess={() => {
            setShowUpdateFieldGroup(false);
            refetchGroup();
          }}
          threeDimensionalAssetsId={selectedGroup?.threeDimensionalAssetsId}
        />
      )}

      <Outlet />
    </ProjectPanel>
  );
};

export default GroupOfFieldList;
