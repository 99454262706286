import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Org, PlantGroup, PlantWithOrg } from '../../../types';
import { Dastrack } from '../../../types/Device';

import {
  createPlantGroup,
  getGroupOfPlant,
  getPlantGroups,
  getProjectDastracks,
} from '../../../apis/DastrackApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setDastracks,
  setSelectedPlantGroup,
} from '../../../slices/pages/project-setting/plantManagementPageSlice';

import { groupCompareFn } from '../../../utils/Sort';
import ManagementGroupList from '../management/ManagementGroupList';

import PlantGroupPlantList from './PlantGroupPlantList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  height: calc(100vh - 87px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const EmptyOrg: Org = {
  id: '',
  ownerId: '',
  name: '',
  country: '',
  taxId: '',
  color: '',
  displayName: '',
  type: 'customer',
  imageURL: null,
  status: 'ACTIVE',
};

const PlantManagementPage = () => {
  const { projectId } = useParams();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const { role, policies } = useAppSelector((store) => store.projects);
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const plantGroupIdSelected = searchParam.get('groupId');

  const { refetch: refetchDastrack } = useQuery(
    ['get-project-dastracks'],
    async () => {
      let dastracks: Dastrack[] = [];

      const run = async (nextCursor?: string | null) => {
        const res = await getProjectDastracks({
          projectId: projectId as string,
          params: {
            nextCursor: nextCursor ?? undefined,
          },
        });

        dastracks = dastracks.concat(res.data.data);

        if (res.data.paging.nextCursor) {
          await run(res.data.paging.nextCursor);
        }
      };

      await run();

      return dastracks;
    },
    {
      enabled: !!projectId,
      onSuccess: (dastracks) => {
        dispatch(setDastracks(dastracks));
      },
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: plantGroups,
    refetch: refetchPlantGroup,
    isFetching: isLoadingPlantGroup,
  } = useQuery(
    ['get-plant-groups', projectId, i18n.language],
    async () => {
      const res = await getPlantGroups(projectId as string);
      return res.data.data.sort(
        groupCompareFn.bind({ locales: i18n.language }),
      );
    },
    {
      enabled: !!projectId,
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );

  const selectedPlantGroup: PlantGroup | undefined = useMemo(() => {
    return plantGroups.find((group) => group.id === plantGroupIdSelected);
  }, [plantGroupIdSelected, plantGroups]);

  const {
    data: groupOfPlants,
    refetch: refetchPlantsOfAGroup,
    isFetching: isLoadingPlantOfAGroup,
  } = useQuery(
    ['group-of-plants', projectId, selectedPlantGroup?.id, orgMap],
    async () => {
      const res = await getGroupOfPlant({
        projectId: projectId as string,
        groupId: selectedPlantGroup?.id ?? '',
        params: {
          plantType: 'plant',
        },
      });
      return res.data.data
        .map<PlantWithOrg>((plant) => {
          return {
            ...plant,
            orgDetail: orgMap[plant.orgId] ?? EmptyOrg,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name, i18n.language));
    },
    {
      enabled: !!selectedPlantGroup,
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (selectedPlantGroup) {
      dispatch(setSelectedPlantGroup(selectedPlantGroup));
    }
  }, [selectedPlantGroup]);

  useEffect(() => {
    if (!plantGroupIdSelected && plantGroups.length > 0) {
      navigate(
        `/project-setting/${projectId}/plant-group-management?groupId=${plantGroups[0].id}`,
        { replace: true },
      );
    }
  }, [projectId, plantGroupIdSelected, plantGroups]);

  const isEditable = () => {
    const isAuthorizedCreatePlantGroup = policies.some(
      (policy) => policy.name === 'CreatePlantGroup',
    );
    return role === 'owner' || role === 'admin' || isAuthorizedCreatePlantGroup;
  };

  return (
    <Container>
      <ManagementGroupList
        data={plantGroups}
        selectedGroup={selectedPlantGroup}
        onSelect={(group) => setSearchParam({ groupId: group.id })}
        onSuccessCreateGroup={refetchPlantGroup}
        isLoadingGroup={isLoadingPlantGroup}
        isEditable={isEditable()}
        handleCreateGroup={createPlantGroup}
        resource="plant"
      />
      {selectedPlantGroup && (
        <PlantGroupPlantList
          plantGroupSelected={selectedPlantGroup}
          data={groupOfPlants}
          refetchGroup={refetchPlantGroup}
          plantGroupProject={plantGroups}
          onSuccessUpdate={refetchPlantsOfAGroup}
          onSuccessBind={refetchDastrack}
          isLoadingPlantOfAGroup={isLoadingPlantOfAGroup}
        />
      )}
    </Container>
  );
};

export default PlantManagementPage;
