import * as React from 'react';

const DaspowerSvgColor = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2527 12.9299H16.7512C16.7146 12.9297 16.6785 12.9216 16.6452 12.9063C16.612 12.891 16.5822 12.8687 16.558 12.8409C16.5338 12.8131 16.5157 12.7805 16.5048 12.7451C16.4938 12.7097 16.4904 12.6724 16.4947 12.6355L17.3486 5.89938C17.3711 5.71475 17.3296 5.52792 17.2311 5.37096C17.1326 5.214 16.9833 5.0967 16.8089 5.03921C16.6344 4.98171 16.4456 4.98761 16.275 5.05588C16.1043 5.12414 15.9624 5.25053 15.8736 5.41334L9.0929 17.9273C9.02933 18.0444 8.99734 18.1764 9.00017 18.3101C9.003 18.4437 9.04055 18.5742 9.10902 18.6885C9.17749 18.8027 9.27447 18.8966 9.39016 18.9607C9.50585 19.0247 9.63616 19.0567 9.76794 19.0534H15.2729C15.3094 19.0536 15.3455 19.0617 15.3788 19.077C15.4121 19.0923 15.4418 19.1146 15.466 19.1424C15.4902 19.1702 15.5084 19.2028 15.5193 19.2382C15.5302 19.2736 15.5336 19.3109 15.5294 19.3477L14.6484 26.0839C14.6215 26.2717 14.6613 26.4631 14.7606 26.624C14.8599 26.7849 15.0122 26.9048 15.1906 26.9623C15.3689 27.0198 15.5616 27.0113 15.7343 26.9382C15.907 26.8651 16.0485 26.7322 16.1335 26.5631L22.9109 14.0492C22.9721 13.9336 23.0027 13.8039 22.9998 13.6727C22.9969 13.5415 22.9605 13.4133 22.8943 13.3006C22.828 13.1879 22.7341 13.0945 22.6216 13.0296C22.5092 12.9646 22.3821 12.9303 22.2527 12.9299Z"
      fill="#FFCE4F"
    />
  </svg>
);

export default DaspowerSvgColor;
