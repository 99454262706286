import { PagedResponse } from '../types';
import { DasConcreteAlert, RequestAlertsParams } from '../types/Alert';
import { DasConcrete } from '../types/Device';

import DsmAxios from './DsmAxios';

export const getDasConcreteAlerts = (
  projectId: string,
  params?: RequestAlertsParams & {
    resourceId?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<DasConcreteAlert>>(
    `v1/projects/${projectId}/dasconcrete-alerts`,
    { params },
  );
};

export const getUserDasconcrete = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<DasConcrete>>('v1/dasconcretes', {
    params,
  });
};
