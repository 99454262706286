import { Popper, PopperProps } from '@mui/material';

const MenuItemPopper: React.FC<PopperProps> = ({ children, ...props }) => {
  return (
    <Popper
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
      {...props}
    >
      {children}
    </Popper>
  );
};

export default MenuItemPopper;
