import i18n from '../../../../i18n';

export const checkNameFormat = (name) => {
  if (name === '') {
    return {
      isError: true,
      message: i18n.t('error:inputIsEmpty'),
    };
  }
  return { isError: false, message: '' };
};

export const checkEmtpy = (data) => {
  if (data === '') {
    return {
      isError: true,
      message: i18n.t('error:inputIsEmpty'),
    };
  }
  return { isError: false, message: '' };
};

export const checkYear = (data) => {
  if (!/^\d{4}$/.test(data)) {
    return {
      isError: true,
      message: i18n.t('error:notYear'),
    };
  }
  return { isError: false, message: '' };
};
