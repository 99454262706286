import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DascartDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dascart-dashboard"*/ '../../pages/dascart-dashboard'
    ),
);

const jc6DashboardRouter = (
  <Route path="dascart-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DascartDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default jc6DashboardRouter;
