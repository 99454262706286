import { useLocation } from 'react-router-dom';

import PageNotFound from '../../pages/PageNotFound';

const Root = () => {
  const location = useLocation();
  if (location.pathname === '/alert-reaction-form') {
    return <PageNotFound />;
  }
  return null;
};

export default Root;
