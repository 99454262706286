import type { SVGProps } from 'react';

const CH4SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 1a3 3 0 0 1 2.708 4.293L8.523 7.11A5.973 5.973 0 0 1 12 6c1.358 0 2.61.451 3.616 1.212l1.759-1.759a3 3 0 1 1 1.5 1.328L16.99 8.668C17.628 9.62 18 10.767 18 12c0 1.296-.41 2.496-1.11 3.476l1.817 1.816a3 3 0 1 1-1.414 1.414l-1.816-1.815A5.973 5.973 0 0 1 12 18a5.972 5.972 0 0 1-3.333-1.01l-1.885 1.885a3 3 0 1 1-1.328-1.5l1.758-1.759A5.974 5.974 0 0 1 6 12c0-1.296.41-2.496 1.11-3.477L5.292 6.708A3 3 0 1 1 4 1Zm12 11a4 4 0 1 0-8 0 4 4 0 0 0 8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CH4SvgIcon;
