import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    width={33}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.242 10.388 1.064-1.065a1.104 1.104 0 0 1 1.562 0l1.309 1.309c.43.431.43 1.13 0 1.562l-1.065 1.064-2.87-2.87Zm-1.458 1.458L9.5 20.13V23h2.87l8.284-8.284-2.87-2.87Z"
    />
  </SvgIcon>
);

export default EditSvgIcon;
