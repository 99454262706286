import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FeatureListItem, FeatureType, Org } from '../types';

export interface SystemState {
  selectedProjectId: string | null;
  orgMap: { [index: string]: Org | undefined };
  featureListMap: { [key in FeatureType]?: FeatureListItem };
}

const initialState: SystemState = {
  selectedProjectId: localStorage.getItem('selectedProjectId'),
  orgMap: {},
  featureListMap: {},
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setSelectedProjectId: (
      state,
      action: PayloadAction<SystemState['selectedProjectId']>,
    ) => {
      if (action.payload)
        localStorage.setItem('selectedProjectId', action.payload);
      else localStorage.removeItem('selectedProjectId');
      return {
        ...state,
        selectedProjectId: action.payload,
      };
    },
    setOrgMap: (state, action: PayloadAction<Org[]>) => {
      return {
        ...state,
        orgMap: action.payload.reduce((prev, curr) => {
          prev[curr.id] = curr;
          return prev;
        }, {}),
      };
    },
    setFeatureListMap: (
      state,
      action: PayloadAction<SystemState['featureListMap']>,
    ) => {
      return { ...state, featureListMap: action.payload };
    },

    resetAll: () => ({ ...initialState }),
  },
});

export const { setSelectedProjectId, setOrgMap, resetAll, setFeatureListMap } =
  systemSlice.actions;
export default systemSlice.reducer;
