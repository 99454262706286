import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  ArrowBackIos,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Button as MuiButton, styled } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Org } from '../../../../types';
import { Plant } from '../../../../types/Resource';

import {
  getDastracksProject,
  getGroupOfPlant,
  getPlantGroups,
  getProjectPlants,
  setPlantToAGroup,
} from '../../../../apis/DastrackApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setDastracks } from '../../../../slices/pages/project-setting/plantManagementPageSlice';

import PlantListOfGroup from './PlantListOfGroup';
import PlantPermissionList from './PlantPermissionList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  flex-direction: column;
`;
const Header = styled('div')`
  height: 32px;
  margin-bottom: 10px;
`;

const ContainerMain = styled('div')`
  display: flex;
  height: calc(100vh - 129px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ContainerButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(MuiButton)`
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 0%;
  margin: 0;
  min-width: 32px;
  height: 32px;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.Mui-disabled {
    color: rgba(255, 255, 255, 0.1);
  }
`;

const EditPlantManagement: React.VFC = () => {
  const { mutate: mutateSetPlantToAGroup, isLoading: isLoadingSetPlant } =
    useMutation(setPlantToAGroup);
  const { projectId } = useParams();
  const { selectedGroup } = useAppSelector((store) => store.plantManagement);
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEnableRemovePlants, setIsEnableRemovePlants] = useState(true);
  const [isEnableSetPlants, setIsEnableSetPlants] = useState(true);
  const [listPlantGroupWillAssign, setListPlantGroupWillAssign] = useState<
    Array<string>
  >([]);
  const [listPlantProjectWillAssign, setListPlantProjectWillAssign] = useState<
    Array<string>
  >([]);

  const dispatch = useAppDispatch();

  const {
    data: plantsOfAGroup,
    refetch: refetchPlantsOfAGroup,
    isLoading: isLoadingPlantOfAGroup,
  } = useQuery(
    [`get-plants-of-a-group-${selectedGroup?.id}`],
    async () =>
      getGroupOfPlant({
        projectId: projectId as string,
        groupId: selectedGroup?.id ?? '',
      }),
    {
      enabled: !!selectedGroup,
    },
  );

  const plants: Array<Plant & { orgDetail: Org }> = useMemo(() => {
    const EmptyOrg: Org = {
      id: '',
      ownerId: '',
      name: '',
      country: '',
      taxId: '',
      color: '',
      displayName: '',
      type: 'customer',
      imageURL: null,
      status: 'ACTIVE',
    };
    if (plantsOfAGroup && plantsOfAGroup.data.data.length > 0)
      return plantsOfAGroup?.data.data.map((plant) => ({
        ...plant,
        orgDetail: orgMap[plant.orgId] ?? EmptyOrg,
      }));
    return [];
  }, [plantsOfAGroup, orgMap]);

  const { data: plantGroup } = useQuery(
    ['get-plant-group', projectId],
    async () => {
      const res = await getPlantGroups(projectId as string);
      return res.data.data;
    },
    { enabled: !!projectId, initialData: [], refetchOnWindowFocus: false },
  );

  const {
    data: plantsOfProject,
    refetch: refetchPlantsOfProject,
    isLoading: isLoadingPlantsOfProject,
  } = useQuery(
    ['get-plant-of-project'],
    async () => getProjectPlants({ projectId: projectId ?? '' }),
    {
      enabled: !!projectId,
    },
  );

  const { data: dastracks, refetch: refetchDastrack } = useQuery(
    ['get-dastracks-project'],
    async () => getDastracksProject(projectId ?? ''),
  );

  const plantsProject: Array<Plant & { orgDetail: Org }> = useMemo(() => {
    const EmptyOrg: Org = {
      id: '',
      ownerId: '',
      name: '',
      country: '',
      taxId: '',
      color: '',
      displayName: '',
      type: 'superAdmin',
      imageURL: null,
      status: 'ACTIVE',
    };
    if (plantsOfProject && plantsOfProject.data.data.length > 0) {
      return plantsOfProject.data.data
        .filter((wp) =>
          plantsOfAGroup?.data.data.every((wg) => wg.id !== wp.id),
        )
        .map((plant) => ({
          ...plant,
          orgDetail: orgMap[plant.orgId] ?? EmptyOrg,
        }));
    }
    return [];
  }, [plantsOfProject, plantsOfAGroup, orgMap]);

  const handleRemoveWorker = async () => {
    try {
      await mutateSetPlantToAGroup(
        {
          projectId: projectId as string,
          groupId: selectedGroup.id,
          plantIds: listPlantGroupWillAssign.concat(listPlantProjectWillAssign),
        },
        {
          onSuccess: () => {
            refetchPlantsOfAGroup();
            refetchPlantsOfProject();
            setIsEnableRemovePlants(true);
            setIsEnableSetPlants(true);
            setListPlantGroupWillAssign([]);
            setListPlantProjectWillAssign([]);
          },
        },
      );
    } catch (err) {
      const error = err as Error;
      alert(error.message);
    }
  };

  useEffect(() => {
    if (dastracks) {
      dispatch(setDastracks(dastracks?.data.data));
    }
  }, [dastracks]);

  useEffect(() => {
    if (listPlantGroupWillAssign.length < plants.length) {
      setIsEnableRemovePlants(false);
    } else {
      setIsEnableRemovePlants(true);
    }
  }, [listPlantGroupWillAssign, plants]);

  useEffect(() => {
    if (listPlantProjectWillAssign.length > 0) {
      setIsEnableSetPlants(false);
    } else {
      setIsEnableSetPlants(true);
    }
  }, [listPlantProjectWillAssign]);

  return (
    <Container>
      <Header>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: 'transparent',
            },
          }}
          color="secondary"
          onClick={() =>
            navigate(`/project-setting/${projectId}/plant-group-management`, {
              replace: true,
            })
          }
          startIcon={<ArrowBackIos />}
          data-cy="btn-end-edit-plant-list"
        >
          {t('end-editing')}
        </DialogButton>
      </Header>
      <ContainerMain>
        <PlantListOfGroup
          selectedId={(wg) => setListPlantGroupWillAssign(wg)}
          data={plants}
          isLoadingPlantOfAGroup={isLoadingPlantOfAGroup}
        />
        <ContainerButton>
          <Button
            onClick={handleRemoveWorker}
            disabled={isEnableRemovePlants}
            data-cy="btn-remove-plant-to-group"
          >
            <KeyboardDoubleArrowRight />
          </Button>
          <Button
            onClick={handleRemoveWorker}
            disabled={isEnableSetPlants}
            data-cy="btn-add-plant-to-group"
          >
            <KeyboardDoubleArrowLeft />
          </Button>
        </ContainerButton>
        <PlantPermissionList
          data={plantsProject}
          onSuccessBind={refetchDastrack}
          onSuccessEdit={refetchPlantsOfProject}
          selectedId={(wp) => setListPlantProjectWillAssign(wp)}
          plantGroupProject={plantGroup}
          isLoadingSetPlant={isLoadingSetPlant}
          isLoadingPlantsOfProject={isLoadingPlantsOfProject}
        />
      </ContainerMain>
    </Container>
  );
};

export default EditPlantManagement;
