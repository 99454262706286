import React, { CSSProperties, useMemo } from 'react';
import { Avatar, BoxProps, styled } from '@mui/material';

import { EquipmentWithOrg } from '../../../types';

import DefaultEquipment from '../../../assets/images/default-image/equipment_default.png';

interface ContainerProps {
  orgColor?: CSSProperties['color'];
  disabled: boolean;
  isInList: boolean;
}

//prettier-ignore
const Container = styled('div', { shouldForwardProp: prop => prop !== 'orgColor' && prop!== "isInList" })<ContainerProps>`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 10px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  & .info {
    flex: 1;
    min-width: 0;
    div {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-of-type(2) {
        ${({ theme }) => ({ ...theme.externalTypography.body3 })}
        color: ${({ theme }) => theme.color.secondary.$60};
        &:before {
          display: inline-block;
          content: '';
          width: 6px;
          height: 14px;
          border-radius: 3px;
          background: ${({ orgColor }) =>
            orgColor ? orgColor : 'transparent'};
          margin-right: 6px;
        }
      }
      &.org{
        display: flex;
        align-items: center;
      }
      & > span{
        margin-left: 5px;
        color: ${({theme})=> theme.color.secondary.$60};
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        max-width:  100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

interface EquipmentItemProps extends BoxProps {
  onClick: () => void;
  equipment: EquipmentWithOrg;
  disabled: boolean;
  isInList: boolean;
}

const EquipmentItem: React.VFC<EquipmentItemProps> = ({
  onClick,
  equipment,
  disabled,
  isInList,
}) => {
  const dasIds = useMemo(() => {
    return equipment.bindingDaspowers.concat(
      equipment.bindingDastemps.concat(equipment.bindingDaslocks),
    );
  }, [equipment]);
  return (
    <>
      <Container
        orgColor={equipment.orgDetail?.color}
        onClick={onClick}
        disabled={disabled}
        isInList={isInList}
      >
        <Avatar
          variant="square"
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            objectFit: 'cover',
            marginRight: '10px',
          }}
          src={equipment.imageURL ?? ''}
        >
          <Avatar
            src={DefaultEquipment}
            alt="equipment beeinventor"
            variant="square"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
        </Avatar>
        <div className="info">
          <div
            title={`${equipment.name}${
              dasIds.length > 0 ? ` (${dasIds.join(', ')})` : ''
            }`}
          >
            {equipment.name}{' '}
            {dasIds.length > 0 && <span>{`(${dasIds.join(', ')})`}</span>}
          </div>
          <div className="org">{equipment.orgDetail?.name} </div>
        </div>
      </Container>
    </>
  );
};

export default EquipmentItem;
