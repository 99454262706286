import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { Group, ResourceType } from '../../../../types';

import CheckboxDefault from '../../../../components/Checkbox/CheckboxDefault';
import EmptyData from '../../../../components/EmptyData';

import { groupCompareFn } from '../../../../utils/Sort';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
`;

const GroupItemContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 12px 10px;
  background-color: ${({ theme }) => theme.color.secondary.$100};
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

interface GroupEditProps {
  groupIdsSelected: Array<string>;
  handleGroupAssign: (groupIdAssign: Array<string>) => void;
  listGroup: Group[];
  resource: ResourceType;
}

const GroupEdit: React.FC<GroupEditProps> = ({
  listGroup,
  groupIdsSelected,
  handleGroupAssign,
  resource,
}) => {
  const { t, i18n } = useTranslation('project-setting');

  const selectedGroupIdsMap = groupIdsSelected.reduce<{
    [id: string]: boolean;
  }>((prev, curr) => {
    prev[curr] = true;
    return prev;
  }, {});

  const handleOnchange = (groupId: string, checked: boolean) => {
    const newSelectedGroupIds = Object.entries(selectedGroupIdsMap)
      .filter(([key, value]) => {
        if (key !== groupId) return value;
      })
      .map(([key]) => key);
    if (checked) {
      newSelectedGroupIds.push(groupId);
    }
    handleGroupAssign(newSelectedGroupIds);
  };

  const item =
    listGroup
      .sort(
        groupCompareFn.bind({
          selectedGroupIdMap: selectedGroupIdsMap,
          locales: i18n.language,
        }),
      )
      .map((group, index) => (
        <GroupItemContainer key={`${resource}-group ${index}`}>
          <CheckboxDefault
            value={group.id}
            onChange={handleOnchange}
            checked={selectedGroupIdsMap[group.id] ?? false}
            disabled={group.isDefault}
            selectType="default"
            label={group.name}
          />
        </GroupItemContainer>
      )) ?? [];

  return (
    <Container>
      <ContentTitle>
        {t(
          `page.${resource}-management.dialog.create-${resource}.${resource}-group.title`,
        )}
      </ContentTitle>
      <ContainerContent>
        {item.length > 0 ? item : <EmptyData />}
      </ContainerContent>
    </Container>
  );
};

export default GroupEdit;
