export const getAge = (birthDay) => {
  const today = new Date();
  const birthDate = new Date(birthDay);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age !== 0 ? age : '-';
};
