import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

import CheckSvgIcon from '../assets/SvgIcon/CheckSvgIcon';
import mainTheme from '../theme';

interface ContainerProps {
  selected?: boolean;
}

const Container = styled(Box)<ContainerProps>`
  ${({ theme }) => theme.typography.body1}
  display: flex;
  color: ${({ theme, selected }) =>
    selected ? 'white' : theme.color.secondary.$60};
  padding: 8px 0;

  &:hover {
    cursor: pointer;
    color: #fff;
  }

  & .required-marker {
    color: ${({ theme }) => theme.color.highlight};
    margin-left: 4px;
  }
`;

const CheckWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-right: 12px;
`;

interface CheckListItemProps extends BoxProps {
  name: string;
  checked?: boolean;
  required?: boolean;
  selected?: boolean;
}

const CheckListItem: React.VFC<CheckListItemProps> = ({
  name,
  checked,
  required,
  selected,
  ...props
}) => {
  return (
    <Container selected={selected} {...props}>
      <CheckWrapper>
        {checked && (
          <CheckSvgIcon
            sx={{
              width: '18px',
              color: mainTheme.palette.success.main,
            }}
          />
        )}
      </CheckWrapper>
      {name}
      {required && <span className="required-marker">*</span>}
    </Container>
  );
};

export default CheckListItem;
