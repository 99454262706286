import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const MultiStoreyDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "multi-storey-dashboard" */ '../../pages/multi-storey-dashboard'
    ),
);

const attendanceDashboardRouter = (
  <Route path="multi-storey-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <MultiStoreyDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default attendanceDashboardRouter;
