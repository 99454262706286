import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const QRCodeRedirection = lazy(
  () =>
    import(
      /* webpackChunkName: "device-information" */ '../../pages/device-information'
    ),
);

export const qrCodeRedirectRouter = (
  <Route
    path="/device/information/"
    element={
      <Suspense fallback={<Loading />}>
        <QRCodeRedirection />
      </Suspense>
    }
  ></Route>
);
