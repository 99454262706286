import * as React from 'react';

const DasCTagSvgColor = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9142 4.91421C16.1332 4.13317 14.8668 4.13317 14.0858 4.91421L8.42893 10.5711C7.64788 11.3521 7.64788 12.6184 8.42893 13.3995L9.84315 14.8137L6.68464 17.9722C5.43878 17.9484 4.35485 18.2681 4.48424 19.4153C4.75682 21.832 5.56241 21.9229 6.27713 21.2081L6.30761 21.1777L9.13604 18.3492L11.2574 16.2279L12.6716 17.6421L9.13604 21.1777C8.74552 21.5682 8.74551 22.2014 9.13604 22.5919C9.52656 22.9824 10.1597 22.9824 10.5503 22.5919L14.0858 19.0563L15.5 20.4706L13.3787 22.5919L10.5503 25.4203L10.5198 25.4508C9.80505 26.1655 9.89591 26.9711 12.3127 27.2437C13.4598 27.3731 13.7795 26.2891 13.7557 25.0433L16.9142 21.8848L18.3284 23.299C19.1095 24.08 20.3758 24.08 21.1569 23.299L26.8137 17.6421C27.5948 16.8611 27.5948 15.5948 26.8137 14.8137L16.9142 4.91421Z"
      fill="#A1A1A1"
    />
  </svg>
);

export default DasCTagSvgColor;
