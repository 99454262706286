import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';

import { Contact } from '../types';

import { useAppSelector } from '../hooks';

import CheckboxDark from './Checkbox/CheckboxDark';

interface ContainerProps {
  disabled?: boolean;
}

const Container = styled('div')<ContainerProps>`
  display: flex;
  height: 64px;
  align-items: center;
  transition: 0.3s;
  padding: 0 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
`;

const ContainerCheckbox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerDetail = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const ContainerInfo = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ContainerIcon = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: auto;
  cursor: pointer;
  &.arrow {
    :hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;

const ContainerDetailContact = styled('div')`
  width: 100%;
  padding: 16px;

  & > .container-inner {
    padding: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    & > .container-detail {
      margin-bottom: 4px;
    }
  }
`;

const TextOuter = styled(Typography)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
`;

interface OrgNameProps {
  color?: React.CSSProperties['color'];
}

const Org = styled(Typography)<OrgNameProps>`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #a1a1a1;

  &:before {
    display: inline-block;
    content: '';
    width: 6px;
    height: 14px;
    border-radius: 3px;
    background: ${({ color = 'transparent' }) => color};
    margin-right: 4px;
    transform: translate(0, 2px);
  }
`;

const TextSmallTitle = styled(Typography)`
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #a1a1a1;
`;

interface ContactListCompProps {
  contact: Contact;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  checked: boolean;
  disabled: boolean;
}

const ContactListComp: React.FC<ContactListCompProps> = ({
  contact,
  onChange,
  checked,
  disabled,
}) => {
  const { t } = useTranslation('project-setting');
  const [openContact, setOpenContact] = useState<boolean>(false);
  const orgMap = useAppSelector((store) => store.system.orgMap);

  return (
    <>
      <Container data-cy="bind-item-device-management" disabled={disabled}>
        <ContainerDetail>
          <ContainerCheckbox>
            <CheckboxDark
              onChange={onChange}
              value={contact.id}
              checked={checked}
              disabled={disabled}
            />
          </ContainerCheckbox>
          <ContainerInfo>
            <Box sx={{ display: 'flex' }}>
              <TextOuter>{contact.name}</TextOuter>
              <TextOuter sx={{ color: '#a1a1a1', ml: '8px' }}>
                ({contact.role})
              </TextOuter>
            </Box>
            <Org color={orgMap[contact.orgId]?.color ?? ''}>
              {orgMap[contact.orgId]?.name
                ? orgMap[contact.orgId]?.name
                : 'N/A'}
            </Org>
          </ContainerInfo>
          <ContainerIcon
            className="arrow"
            onClick={() => setOpenContact((prev) => !prev)}
          >
            {openContact ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </ContainerIcon>
        </ContainerDetail>
      </Container>
      {openContact && (
        <ContainerDetailContact>
          <div className="container-inner">
            <div className="container-detail">
              <TextSmallTitle>{t('contact-form.name')}</TextSmallTitle>
              <TextOuter>{contact.name}</TextOuter>
            </div>
            <div className="container-detail">
              <TextSmallTitle>{t('contact-form.role')}</TextSmallTitle>
              <TextOuter>{contact.role ?? 'N/A'}</TextOuter>
            </div>
            <div className="container-detail">
              <TextSmallTitle>{t('language')}</TextSmallTitle>
              <TextOuter>
                {t(
                  `page.worker-management.dialog.create-worker.emergency-contact.${contact.language}`,
                ) ?? 'N/A'}
              </TextOuter>
            </div>
            <div className="container-detail">
              <TextSmallTitle>{t('phone')}</TextSmallTitle>
              <TextOuter>{contact.phone ?? 'N/A'}</TextOuter>
            </div>
            <div className="container-detail">
              <TextSmallTitle>{t('email')}</TextSmallTitle>
              <TextOuter>{contact.email ?? 'N/A'}</TextOuter>
            </div>
            <div className="container-detail">
              <TextSmallTitle>{t('telegram')}</TextSmallTitle>
              <TextOuter>{contact.telegram ?? 'N/A'}</TextOuter>
            </div>
          </div>
        </ContainerDetailContact>
      )}
    </>
  );
};

export default ContactListComp;
