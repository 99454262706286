import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';

import { ConnectedDeviceInfo, DeviceType, ResourceType } from '../../../types';

import { getDeviceTypeByDasId } from '../../../utils/common';
import EmptyDeviceConnected from '../../EmptyDeviceConnected';

import ConnectedDeviceCardView from './ConnectedDeviceCardView';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const ContentTitle = styled(Typography)`
  color: white;
`;

interface ConnectedDeviceViewProps {
  data: Array<ConnectedDeviceInfo>;
  resource: ResourceType;
}

const ConnectedDeviceView: React.FC<ConnectedDeviceViewProps> = ({
  data,
  resource,
}) => {
  const { t } = useTranslation('project-setting');

  return (
    <Container>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.connected-device.title')}
      </ContentTitle>
      <ContainerContent>
        {data.length !== 0 ? (
          data.map((device, i) => {
            let deviceType: DeviceType | undefined;
            if (resource === 'worker') {
              deviceType = getDeviceTypeByDasId(device.dasId);
            } else if (resource === 'plant') {
              deviceType = 'dastrack';
            } else if (resource === 'vehicle') {
              deviceType = 'dastrack-v';
            } else if (resource === 'pipe') {
              deviceType = 'daswater';
            } else if (resource === 'crane') {
              deviceType = 'dascas';
            } else if (resource === 'structure') {
              deviceType = 'dasconcrete';
            } else if (resource === 'ai') {
              deviceType = 'das_ai_box';
            }

            return (
              <ConnectedDeviceCardView
                key={i}
                index={i}
                data={device}
                deviceType={deviceType}
              />
            );
          })
        ) : (
          <EmptyDeviceConnected />
        )}
      </ContainerContent>
    </Container>
  );
};

export default ConnectedDeviceView;
