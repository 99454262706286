import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const ConfinedSpaceDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "confined-space-dashboard-page" */ '../../pages/confined-space-dashboard'
    ),
);

const confinedSpaceDashboardRouter = (
  <Route path="confined-space-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <ConfinedSpaceDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default confinedSpaceDashboardRouter;
