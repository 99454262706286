import { Dialog as MuiDialog, DialogProps, styled } from '@mui/material';

import Loading from '../../../components/Loading';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    min-height: 90px;
    color: white;
    background-color: #333333;
    padding: 10px 10px;
    & > p {
      margin: 0;
    }
  }
`;

interface LoadingDialogProps extends DialogProps {
  progress: number;
}

const LoadingDialog: React.FC<LoadingDialogProps> = (props) => {
  return (
    <Dialog {...props}>
      <Loading />
      <p data-cy="loading-dialog-3d-plus">
        Loading... {props.progress.toFixed(2)} %
      </p>
    </Dialog>
  );
};

export default LoadingDialog;
