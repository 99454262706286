import { formatInTimeZone } from 'date-fns-tz';

export const getLocalTimeZone: () => string | undefined = () => {
  const offset = new Date().getTimezoneOffset();
  let timeZone: string | undefined;

  if (offset !== 0) {
    timeZone = `${offset < 0 ? '+' : '-'}${Math.floor(Math.abs(offset) / 60)
      .toString()
      .padStart(2, '0')}:${Math.floor(Math.abs(offset) % 60)
      .toString()
      .padStart(2, '0')}`;
  }
  return timeZone;
};

export const getTimeOffset = (timezone: string = 'Universal') => {
  const date = new Date();
  return formatInTimeZone(date, timezone, 'XXX');
};

export const toISOStringWithOffset = (
  date: Date,
  timezone: string = 'Universal',
) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
    .getSeconds()
    .toString()
    .padStart(2, '0')}.${date
    .getMilliseconds()
    .toString()
    .padStart(2, '0')}${getTimeOffset(timezone)}`;
};
