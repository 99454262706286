import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeviceBoxSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.0001 11L16.0001 6L8.00012 11L16.0001 16L24.0001 11Z"
        fill="currentColor"
      />
      <path d="M7 12.5V21.5L15 26.5V17.5L7 12.5Z" fill="currentColor" />
      <path
        d="M25.0003 12.5L17 17.5L16.9996 26.5L24.9998 21.5L25.0003 12.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DeviceBoxSvgIcon;
