import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const CallbackPage = lazy(() => import('../../pages/oauth/callboack'));

const oauthRouter = (
  <Route path="oauth" element={<Outlet />}>
    <Route
      path="callback"
      element={
        <Suspense fallback={<Loading />}>
          <CallbackPage />
        </Suspense>
      }
    />
  </Route>
);

export default oauthRouter;
