import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';

import {
  Contact,
  DasIdWillAssign,
  Org,
  PlantGroup,
  PlantWillAssign,
} from '../../../../types';
import { Dastrack } from '../../../../types/Device';
import { Plant, ResourceNavigator } from '../../../../types/Resource';

import CheckListItem from '../../../../components/CheckListItem';

import CleanDateSvgIcon from '../../../../assets/SvgIcon/CleanDateSvgIcon';
import EditSvgIcon from '../../../../assets/SvgIcon/EditSvgIcon';
import ConnectedDeviceView from '../../management/content/ConnectedDeviceView';
import ContactView from '../../management/content/ContactView';
import GroupView from '../../management/content/GroupView';
import {
  checkResourceHasContact,
  hasAssignedDevice,
} from '../../management/validation/management-validation';
import { checkPlantBasicInformationAvalible } from '../../management/validation/plant-validation';

import BasicInformationContentView from './BasicInformationContentView';

const Dialog = styled(MuiDialog)`
  color: white;
  & .MuiDialog-paper {
    width: 676px;
    height: 686px;
    max-width: unset;
    background: ${({ theme }) => theme.color.secondary.$80};
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 8px 8px 16px;
  height: 48px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({ ...theme.typography.h3 })}
  font-family: 'Montserrat';
  flex: 1;
  color: white;
  padding: 0;
`;

const ContentWrapper = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  @media only screen and (max-width: 600px) {
    min-height: 240px;
  }
`;

const ContainerSelected = styled('div')`
  width: 380px;
  border-radius: 4px;
  padding: 10px 20px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const IconButtonStyled = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: 0.3s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

interface ViewPlantProps extends DialogProps {
  title: string;
  navigator: ResourceNavigator;
  navigatorIndex: number;
  plantSelected: (Plant & { orgDetail: Org | undefined }) | undefined;
  plantGroupSelected: Array<PlantGroup>;
  connectedDevice: Array<Dastrack>;
  plantContacts: Contact[];
  onCloseDialog: () => void;
  setDialogEditPlant: (isOpen: boolean) => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const ViewPlant: React.FC<ViewPlantProps> = ({
  open,
  title,
  navigator,
  navigatorIndex,
  plantSelected,
  plantGroupSelected,
  connectedDevice,
  plantContacts,
  onCloseDialog,
  setDialogEditPlant,
  onSelectNavigatorIndex,
}) => {
  const [plantWillAssign, setPlantWillAssign] = useState<PlantWillAssign>();
  const [dastrackWillAssign, setDastrackWillAssign] = useState<
    Array<DasIdWillAssign>
  >([]);
  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );

  const { t } = useTranslation('project-setting');

  useEffect(() => {
    setPlantWillAssign({
      id: plantSelected?.id ?? '',
      name: plantSelected?.name ?? '',
      model: plantSelected?.model ?? '',
      serialNumber: plantSelected?.serialNumber ?? '',
      permitNumber: plantSelected?.permitNumber ?? '',
      permitIssuer: plantSelected?.permitIssuer ?? '',
      permitExpiryDate: plantSelected?.permitExpiryDate ?? '',
      safetyLoad: plantSelected?.safetyLoad ?? 5,
      orgId: plantSelected?.orgId ?? '',
      projectId: plantSelected?.projectId ?? '',
      remark: plantSelected?.remark ?? '',
      imageURL: plantSelected?.imageURL ?? '',
      groupIds: plantSelected?.groupIds ?? [],
      bindingDastracks: plantSelected?.bindingDastracks ?? [],
      bindingDascases: plantSelected?.bindingDascases ?? [],
      brand: plantSelected?.brand ?? '',
      numberReading: plantSelected?.numberReading ?? 0,
      yearOfProduction: plantSelected?.yearOfProduction ?? 0,
      plantType: plantSelected?.plantType ?? 'plant',
    });
  }, [plantSelected]);

  useEffect(() => {
    if (connectedDevice.length > 0) {
      const init: Array<DasIdWillAssign> = connectedDevice.map((dastrack) => ({
        id: dastrack.id,
        dasId: dastrack.dasId,
        name: dastrack.dasId,
        batteryLevel: dastrack.shadow.dataPoint.batteryLevel.value
          ? dastrack.shadow.dataPoint.batteryLevel.value
          : 0,
      }));
      setDastrackWillAssign(init);
    }
  }, [connectedDevice]);

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [navigatorIndex]);

  return (
    <Dialog open={open}>
      <Header>
        <DialogTitle>{title}</DialogTitle>
        <IconButtonStyled
          onClick={() => {
            onCloseDialog();
            setDialogEditPlant(true);
          }}
          data-cy="dialog-edit-plant"
        >
          <EditSvgIcon
            sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          />
        </IconButtonStyled>
        <CleanDateSvgIcon
          sx={{ width: '32px', height: '32px', cursor: 'pointer' }}
          onClick={onCloseDialog}
        />
      </Header>
      <ContentWrapper>
        <DialogContent>
          <CheckList>
            {navigator.map((item, index) => {
              let checked: boolean = false;
              switch (item.name) {
                case 'group':
                  checked = true;
                  break;
                case 'plant-basic-information':
                  checked = checkPlantBasicInformationAvalible(plantWillAssign);
                  break;
                case 'emergency-contact':
                  checked = checkResourceHasContact(
                    plantContacts.map((cp) => cp.id),
                  );
                  break;
                case 'connected-device':
                  checked = hasAssignedDevice(dastrackWillAssign);
              }

              return (
                <CheckListItem
                  key={item.id}
                  name={t(`navigator.${item.name}`)}
                  required={item.required}
                  checked={checked}
                  onClick={() => onSelectNavigatorIndex(index)}
                  selected={checkedNavigator[index]}
                />
              );
            })}
          </CheckList>
        </DialogContent>
        <ContainerSelected>
          {navigator[navigatorIndex].name === 'group' && (
            <GroupView group={plantGroupSelected} resource="plant" />
          )}
          {navigator[navigatorIndex].name === 'plant-basic-information' && (
            <BasicInformationContentView data={plantSelected} />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactView data={plantContacts} />
          )}

          {navigator[navigatorIndex].name === 'connected-device' && (
            <ConnectedDeviceView
              data={connectedDevice.map((device) => ({
                ...device,
                name: device.dasId,
              }))}
              resource="plant"
            />
          )}
        </ContainerSelected>
      </ContentWrapper>
    </Dialog>
  );
};

export default ViewPlant;
