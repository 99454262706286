import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownColor } from '@beeinventor/dasiot-react-component-lib';
import { styled, Typography } from '@mui/material';

import { DropDownColorItem } from '../../../../types';

import { useAppSelector } from '../../../../hooks';

import { getHexColor } from '../../../../utils/getAreaColor';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  margin-bottom: 8px;
`;

const Content = styled('div')`
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  color: white;
`;

interface ContainerDropdownProps {
  isSelected?: boolean;
}

// prettier-ignore
const ContainerDropdown = styled('div', {shouldForwardProp: (prop)=> prop !== "isSelected"})<ContainerDropdownProps>`
  & > .Dropdown-root {
    color: ${({ isSelected, theme }) => isSelected ? "#fff" : theme.color.secondary.$60};
    font-weight: 400;
    font-size: 14px;
    padding: 0 8px;
    /* border: 1px solid ${({ theme }) => theme.color.secondary.$60}; */
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    & > .Dropdown-icon {
      width: 32px;
      height: 32px;
    }
  }
  & > .Dropdown--disabled {
    background: rgba(0, 0, 0, 0.806);
    color: ${({ theme }) => theme.color.secondary.$40};
  }
  & div[role='tooltip'] {
    & > .MuiBox-root {
      color: ${({ theme }) => theme.color.box_bbg};
      font-weight: 200;
      font-size: 12px;
      background: ${({ theme }) => theme.color.secondary.$100};
      max-height: 120px;
      overflow: auto;
    }
    z-index: 5;
  }
`;

interface ContactContentProps {
  onChange: (value: any) => void;
  selectedPath: string | undefined;
}

const PathEdit: React.FC<ContactContentProps> = ({
  onChange,
  selectedPath,
}) => {
  const { t } = useTranslation('project-setting');

  const projectArea = useAppSelector((store) => store.projects.projectArea);

  const PATH_LIST = useMemo(() => {
    if (projectArea.length > 0) {
      const items: DropDownColorItem[] = projectArea
        .filter((a) => a.type === 'path' && a.areaPath)
        .map((area) => ({
          id: area.id,
          value: area.id,
          name: area.name,
          color: getHexColor(area.areaPath?.color ?? 'red'),
        }));
      return items;
    }

    return [];
  }, [projectArea, getHexColor]);

  return (
    <Container>
      <ContainerContent>
        <ContentTitle>{t('assign-path')}</ContentTitle>
        <Content>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>
            {t('assign-path')}
          </Typography>
          <ContainerDropdown isSelected={!!selectedPath}>
            <DropdownColor
              list={PATH_LIST}
              onSelect={(v) => onChange(v as string)}
              selectedId={selectedPath}
              mode="dark"
              popperProps={{
                disablePortal: true,
                placement: 'bottom-start',
              }}
              placeholder={t('placeholder-path')}
            />
          </ContainerDropdown>
        </Content>
      </ContainerContent>
    </Container>
  );
};

export default PathEdit;
