import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const Main = lazy(() => import('../../pages/safie-camera'));
const LiveStreamPage = lazy(
  () => import('../../pages/safie-camera/LiveStreamPage'),
);
const MovieClipsPage = lazy(
  () => import('../../pages/safie-camera/MovieClipsPage'),
);

//ToDo: routering improvement for deviceId
const safieCameraRouter = (
  <Route path="safie-camera" element={<Outlet />}>
    <Route
      key="safie-camera"
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <Main />
        </Suspense>
      }
    >
      <Route
        key="live-stream"
        path="live-stream"
        element={
          <Suspense fallback={<Loading />}>
            <LiveStreamPage />
          </Suspense>
        }
      />
      <Route
        key="movie-clips"
        path="movie-clips"
        element={
          <Suspense fallback={<Loading />}>
            <MovieClipsPage />
          </Suspense>
        }
      />
    </Route>
    <Route
      key="safie-camera-project-device"
      path=":projectId/?:deviceId"
      element={
        <Suspense fallback={<Loading />}>
          <Main />
        </Suspense>
      }
    >
      <Route
        key="live-stream"
        path="live-stream"
        element={
          <Suspense fallback={<Loading />}>
            <LiveStreamPage />
          </Suspense>
        }
      />
      <Route
        key="movie-clips"
        path="movie-clips"
        element={
          <Suspense fallback={<Loading />}>
            <MovieClipsPage />
          </Suspense>
        }
      />
    </Route>
  </Route>
);

export default safieCameraRouter;
