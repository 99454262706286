import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { addDays, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';

import { GetAttendanceDetailOfWorkersRTO } from '../../types/index';

import { getAttendanceDetailOfWorkers } from '../../apis/AreaApi';

import { getTimeOffset, toISOStringWithOffset } from '../../utils/getTimeZone';

const useAttendanceOfWorkerData = (
  queryKey: QueryKey,
  projectId: string,
  workerId: string,
  areaId: string,
  params: {
    refDate: Date;
    timeZone?: string;
    interval: 'hour' | 'day';
  },
  options?: Omit<
    UseQueryOptions<
      GetAttendanceDetailOfWorkersRTO['workers'][string]['intervals']
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<
    GetAttendanceDetailOfWorkersRTO['workers'][string]['intervals']
  >(
    queryKey,
    async () => {
      let intervals: GetAttendanceDetailOfWorkersRTO['workers'][string]['intervals'] =
        {};
      for (let i = 0; i < 6; i += 1) {
        const startOfWeekDate = startOfWeek(
          addDays(startOfMonth(params.refDate), 7 * i),
        );
        const endOfWeekDate = endOfWeek(startOfWeekDate);

        const res = await getAttendanceDetailOfWorkers(projectId, {
          fromDate: toISOStringWithOffset(startOfWeekDate, params.timeZone),
          toDate: toISOStringWithOffset(endOfWeekDate, params.timeZone),
          timeZone: getTimeOffset(params.timeZone),
          interval: params.interval,
        });

        intervals = {
          ...intervals,
          ...res.data.data.workers[workerId]?.intervals,
        };
      }
      const result = Object.entries(intervals).reduce((prev, [key, value]) => {
        prev[key] = {
          areas: Object.entries(value.areas)
            .filter(([areaKey]) => {
              if (areaId === 'all') return true;
              return areaKey === areaId;
            })
            .reduce((areaPrev, [areaKey, areaValue]) => {
              areaPrev[areaKey] = areaValue;
              return areaPrev;
            }, {}),
        };
        return prev;
      }, {});
      return result;
    },
    options,
  );
};

export default useAttendanceOfWorkerData;
