import type { SVGProps } from 'react';

const AiDefaultAlertSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 2h14v14H2zm4 3h2l3 8H9l-.375-1h-3.25L5 13H3zm.125 5L7 7.666 7.875 10zM14 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 2v5h-2V8zM9 21v-3h5.833L19 20.5V27zm10.996-2L18 17.802V8.2l1.996-1.2L30 13.005zM9 23l10 6v6.5l-10-6zm22-8.5-10 6V27l10-6zm-1 2.328-3 1.83v3.17L30 20zM21 29l10-6v6.5l-10 6zm6-1.843 3-1.829V28.5l-3 1.828z"
      clipRule="evenodd"
    />
  </svg>
);
export default AiDefaultAlertSvgIcon;
