import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface DaslockAlertSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const DaslockAlertSvgIcon = ({ ...props }: DaslockAlertSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 15V17H16V15C16 12.7909 17.7909 11 20 11C22.2091 11 24 12.7909 24 15ZM14 17V15C14 11.6863 16.6863 9 20 9C23.3137 9 26 11.6863 26 15V17H28C28.5523 17 29 17.4477 29 18V30C29 30.5523 28.5523 31 28 31H12C11.4477 31 11 30.5523 11 30V18C11 17.4477 11.4477 17 12 17H14Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DaslockAlertSvgIcon;
