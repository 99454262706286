import React, { useEffect, useRef, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  ClickAwayListener,
  Popper as MuiPopper,
  styled,
} from '@mui/material';

import { ErrorProps } from '../types';

import errorSvg from '../assets/images/svg/ic_error_ifo.svg';
import SearchSvgIcon from '../assets/SvgIcon/SearchSvgIcon';

interface ExternalContainerProps {
  disabled?: boolean;
}

const ExternalContainer = styled(Box)<ExternalContainerProps>`
  opacity: ${({ disabled }) => (disabled ? 0.9 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

interface ContainerProps {
  selected: boolean;
  popperOpen: boolean;
  error?: boolean;
  disabled?: boolean;
}

// prettier-ignore
const Container = styled(Box, { shouldForwardProp: (prop) => prop !== 'popperOpen' && prop !== 'error' && prop !== 'selected', })<ContainerProps>`
  ${({ theme }) => theme.typography.body2};
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 40px;
  opacity: ${({ disabled }) => (disabled ? "0.3" : "1")};
  color: ${({ theme }) => theme.color.secondary.$60};
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ popperOpen, error, theme }) => {
    if (error) return theme.color.highlight;
    if (popperOpen) return 'rgba(0, 0, 0, 0.5)';
    return 'transparent';
  }};
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  span {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .btn-clear {
    cursor: pointer;
  }

  svg {
    display: block;
    margin: 4px;
    margin-right: 8px;
  }
`;

interface ItemProps {
  itemColor?: string;
  selected?: boolean;
}

const shouldForwardProp = (prop: PropertyKey) =>
  prop !== 'itemColor' && prop !== 'selected';

const SelectedItem = styled('span', { shouldForwardProp })<ItemProps>`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: ${({ theme, selected }) =>
    selected ? '#fff' : theme.color.secondary.$60};
  &:before {
    display: ${({ itemColor }) => (itemColor ? 'block' : 'none')};
    content: '';
    width: 6px;
    height: 14px;
    margin-right: 8px;
    background: ${({ itemColor }) => (itemColor ? itemColor : 'unset')};
    border-radius: 3px;
  }
`;

const ErrorContainer = styled(Box)`
  ${({ theme }) => theme.typography.caption};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.highlight};
`;

const Popper = styled(MuiPopper)`
  z-index: 2000;

  & .container {
    max-height: 160px;
    background: ${({ theme }) => theme.color.secondary.$100};
    border-radius: 4px;
  }

  & .header {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.secondary.$60};
    padding: 4px 0;

    input {
      ${({ theme }) => theme.typography.body2};
      flex: 1;
      display: inline-block;
      background: unset;
      padding: 0;
      border: none;
      outline: none;
      color: white;
    }
  }

  & .menu {
    color: white;
    padding: 0;
    max-height: 120px;
    overflow-y: auto;
    margin: 0;
  }
`;

// prettier-ignore
const Item = styled('li', { shouldForwardProp: (prop) => prop !== 'itemColor'} )<ItemProps>`
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 9.5px 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &:before {
    display: ${({ itemColor }) => (itemColor ? 'block' : 'none')};
    content: '';
    width: 6px;
    height: 14px;
    margin-right: 8px;
    background: ${({ itemColor }) => (itemColor ? itemColor : 'unset')};
    border-radius: 3px;
  }
`;

interface Data {
  id: string;
  name: string;
  color?: string;
}

interface OrgAutocompleteProps extends Omit<BoxProps, 'onSelect' | 'onBlur'> {
  selectedId?: string;
  placeholder?: string;
  popperPlaceholder?: string;
  data: Array<Data>;
  keyPrefix?: string;
  error?: ErrorProps;
  disabled?: boolean;
  reset?: boolean;
  onSelect: (d: Data) => void;
  onBlur: () => void;
}

const OrgAutocompleteBasic: React.VFC<OrgAutocompleteProps> = ({
  selectedId,
  placeholder,
  popperPlaceholder,
  data,
  error,
  keyPrefix,
  disabled,
  onSelect,
  onBlur,
  ...props
}) => {
  const externalContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedData, setSelectedData] = useState<Data>();
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === selectedId) {
        setSelectedData(data[i]);
        break;
      }
    }
  }, [selectedId, data]);

  const handleOnSelect = (d: Data) => {
    setSelectedData(d);
    onSelect(d);
    setOpen(false);
  };

  return (
    <>
      <ExternalContainer
        ref={externalContainerRef}
        disabled={disabled}
        {...props}
      >
        <Container
          selected={!!selectedData}
          popperOpen={open}
          disabled={disabled}
          error={error?.isError}
          onClick={() => setOpen(!open)}
        >
          <SelectedItem
            itemColor={selectedData?.color}
            selected={!!selectedData}
          >
            {selectedData ? selectedData.name : placeholder}
          </SelectedItem>
          {open ? (
            <KeyboardArrowUp
              sx={{
                color: selectedData ? '#fff' : '##A1A1A1',
                width: '24px',
                height: '24px',
                marginRight: '10px !important',
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                color: selectedData ? '#fff' : '##A1A1A1',
                width: '24px',
                height: '24px',
                marginRight: '10px !important',
              }}
            />
          )}
        </Container>
        {error?.isError && (
          <ErrorContainer>
            <img src={errorSvg} alt="" /> {error.message}
          </ErrorContainer>
        )}
      </ExternalContainer>
      <Popper
        open={open}
        anchorEl={externalContainerRef.current}
        placement="bottom"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
            onBlur();
          }}
        >
          <div
            className="container"
            style={{ width: `${externalContainerRef.current?.clientWidth}px` }}
          >
            <div className="header">
              <SearchSvgIcon sx={{ width: '32px', height: '32px', mr: 'e' }} />
              <input
                placeholder={popperPlaceholder}
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.currentTarget.value);
                }}
              />
            </div>
            <ul className="menu">
              {data
                .filter((d) => d.name.indexOf(filterText) > -1)
                .map((d) => (
                  <Item
                    key={`${keyPrefix}-OrgAutocomplete-item-${d.id}`}
                    className="item"
                    itemColor={d.color}
                    onClick={() => handleOnSelect(d)}
                  >
                    {d.name}
                  </Item>
                ))}
            </ul>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default OrgAutocompleteBasic;
