import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DaspowerDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "daspower-dashboard-page" */ '../../pages/daspower-dashboard'
    ),
);

const daspowerDashboardRouter = (
  <Route path="daspower-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DaspowerDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default daspowerDashboardRouter;
