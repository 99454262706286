import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AssetSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="33"
    height="32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 10.5L16.0002 5L6.99998 10.5L16.0002 16.5L25 10.5ZM6 12V22L15 28V18L6 12ZM17 18L26.0003 12V22L17 28V18Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AssetSvgIcon;
