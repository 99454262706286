import React from 'react';
import { InputBase, InputBaseProps, styled } from '@mui/material';

import SearchSvg from '../assets/images/svg/btn_ic_search_placeholder.svg';

import IconContainer from './IconContainer';

type SreachProps = InputBaseProps & {};

const StyledInputBase = styled(InputBase, { label: 'Search' })<SreachProps>`
  border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  border-radius: 4px;
`;

const Search: React.VFC<SreachProps> = (props) => {
  return (
    <StyledInputBase
      placeholder="Search"
      startAdornment={
        <IconContainer>
          <img src={SearchSvg} alt="" />
        </IconContainer>
      }
      {...props}
    />
  );
};

export default Search;
