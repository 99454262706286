import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SaveSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14286 8C8.51167 8 8 8.51167 8 9.14286V22.8571C8 23.4883 8.51167 24 9.14286 24H22.8571C23.4883 24 24 23.4883 24 22.8571V12.5714C24 12.2683 23.8796 11.9776 23.6653 11.7633L20.2367 8.33474C20.0224 8.12041 19.7317 8 19.4286 8H19V12C19 12.5523 18.5523 13 18 13H12C11.4477 13 11 12.5523 11 12V8H9.14286ZM21 22L11 22V17.5C11 16.6716 11.6716 16 12.5 16L19.5 16C20.3284 16 21 16.6716 21 17.5V22Z"
    />
    <rect x="13" y="8" width="4" height="3" />
  </SvgIcon>
);

export default SaveSvgIcon;
