import { useEffect, useState } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { DasAiBox } from '../../types/Device';

import { getProjectDasAiBoxes } from '../../apis/devices/DasAiBoxApi';

type UseProjectDasAiBoxMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  indexBy?: 'id' | 'dasId';
  options?: UseQueryOptions<DasAiBoxMap>;
};

export type DasAiBoxMap = { [id: string]: DasAiBox | undefined };

export const useProjectDasAiBoxMap = ({
  queryKey,
  projectId,
  indexBy = 'id',
  options,
}: UseProjectDasAiBoxMapParams) => {
  const [deviceMap, setDeviceMap] = useState<DasAiBoxMap>({});
  const { data, ...others } = useQuery<DasAiBoxMap>(
    queryKey.concat(indexBy),
    async () => {
      let localDevices: DasAiBox[] = [];

      const load = async (nextCursor?: string | undefined) => {
        const res = await getProjectDasAiBoxes({
          projectId: projectId as string,
          params: {
            nextCursor,
          },
        });
        localDevices = localDevices.concat(res.data.data);
        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      const map = localDevices.reduce<DasAiBoxMap>((prev, curr) => {
        if (indexBy === 'id') {
          prev[curr.id] = curr;
        } else {
          prev[curr.dasId] = curr;
        }
        return prev;
      }, {});

      return map;
    },
    {
      ...options,
    },
  );

  useEffect(() => {
    setDeviceMap(data ?? {});
  }, [data]);

  return {
    data: deviceMap,
    ...others,
  };
};
