import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton, styled, Typography } from '@mui/material';

import { WorkerCertificateWillAssign } from '../../../types';

import AddSvgIcon from '../../../assets/SvgIcon/AddSvgIcon';

import CertificateCard from './CertificateCard';

const Container = styled('div')`
  height: calc(100% - 80px);
  width: 100%;
`;

const ContainerContent = styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  margin-bottom: 8px;
`;

const Content = styled('div')`
  border-radius: 4px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  margin-top: 10px;
`;

const Button = styled(MuiButton)`
  text-transform: none;
  height: 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

interface CertificateContentProps {
  handleCertificateWillAssign: (
    certificates: Array<WorkerCertificateWillAssign>,
  ) => void;
  data: Array<WorkerCertificateWillAssign>;
}

const CertificateContent: React.VFC<CertificateContentProps> = ({
  handleCertificateWillAssign,
  data,
}) => {
  const { t } = useTranslation('project-setting');
  const cards = data;

  const handleAddCard = () => {
    const initWorkerCard: WorkerCertificateWillAssign = {
      name: '',
      number: '',
      expiryDate: '',
      imageURL: '',
    };
    handleCertificateWillAssign([...cards, initWorkerCard]);
  };

  const onChangeCard = (index: number, datas: WorkerCertificateWillAssign) => {
    const newDatas = [...cards];
    newDatas[index] = datas;
    handleCertificateWillAssign(newDatas);
  };

  const onDeleteCard = (index: number) => {
    const newcards = [...cards];
    newcards.splice(index, 1);
    handleCertificateWillAssign(newcards);
  };

  const items = cards.map((item, i) => (
    <CertificateCard
      key={i}
      onChange={onChangeCard}
      onDeleteCard={onDeleteCard}
      data={item}
      index={i}
    />
  ));

  return (
    <Container>
      <ContainerContent>
        <ContentTitle>
          {t('page.worker-management.dialog.create-worker.certificate.title')}
        </ContentTitle>
        <Button
          startIcon={<AddSvgIcon sx={{ width: '32px', height: '32px' }} />}
          onClick={handleAddCard}
          data-cy="btn-add-certificate"
        >
          {t(
            'page.worker-management.dialog.create-worker.certificate.title-button',
          )}
        </Button>
        <Content>{items}</Content>
      </ContainerContent>
    </Container>
  );
};

export default CertificateContent;
