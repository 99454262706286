import { DasairAlert } from '../types/Alert';
import { Limit } from '../types/Dasair';

import { AlertType } from './getAlertIcon';

export const getDasairAlertType = (alert: DasairAlert) => {
  let dasairAlertType: AlertType = AlertType.DASAIR_O2_ALERT;

  switch (alert.field) {
    case 'o2':
      dasairAlertType = AlertType.DASAIR_O2_ALERT;
      break;
    case 'ch2o':
      dasairAlertType = AlertType.DASAIR_CH2O_ALERT;
      break;
    case 'co':
      dasairAlertType = AlertType.DASAIR_CO_ALERT;
      break;
    case 'co2':
      dasairAlertType = AlertType.DASAIR_CO2_ALERT;
      break;
    case 'nh3':
      dasairAlertType = AlertType.DASAIR_NH3_ALERT;
      break;
    case 'pm10':
      dasairAlertType = AlertType.DASAIR_PM10_ALERT;
      break;
    case 'pm2_5':
      dasairAlertType = AlertType.DASAIR_PM2_5_ALERT;
      break;
    case 'voc':
      dasairAlertType = AlertType.DASAIR_VOC_ALERT;
      break;
    case 'h2s':
      dasairAlertType = AlertType.DASAIR_H2S_ALERT;
      break;
    case 'humidity':
      dasairAlertType = AlertType.DASAIR_HUMIDITY_ALERT;
      break;
    case 'temperature':
      dasairAlertType = AlertType.DASAIR_TEMPERATURE_ALERT;
      break;
    default:
      dasairAlertType = AlertType.DASAIR_O2_ALERT;
      break;
  }

  return dasairAlertType;
};

export const getColorType = (
  value: number,
  limitSets: Limit[],
): 'warn' | 'danger' | undefined => {
  let colorType: 'warn' | 'danger' | undefined;
  limitSets.forEach((set) => {
    if (set.lowerBound <= value && value <= set.upperBound) {
      if (set.type !== 'safe') {
        colorType = set.type;
      }
    }
  });
  if (colorType === undefined) {
    if (value < limitSets[0].lowerBound) {
      colorType = 'danger';
    } else if (value > limitSets[limitSets.length - 1].upperBound) {
      colorType = 'danger';
    }
  }
  return colorType;
};
