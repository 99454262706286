import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DastrackDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dasloop-dashboard-page" */ '../../pages/dastrack-dashboard'
    ),
);

const dastrackDashboardRouter = (
  <Route path="dastrack-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DastrackDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dastrackDashboardRouter;
