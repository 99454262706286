import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChangeDeviceSvgIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 11H19C21.2091 11 23 12.7909 23 15V15"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22 21L14 21C11.7909 21 10 19.2091 10 17V17"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M9 11L12 8L12 14L9 11Z" fill="white" />
    <path d="M24 21L21 24L21 18L24 21Z" fill="white" />
  </SvgIcon>
);

export default ChangeDeviceSvgIcon;
