class SrsWebrtc {
  rtmpUrl: string;

  videoEl: HTMLVideoElement;

  pc: RTCPeerConnection | undefined;

  constructor(rtmpUrl: string, videoEl: HTMLVideoElement) {
    this.rtmpUrl = rtmpUrl;
    this.videoEl = videoEl;
  }

  async play() {
    this.pc = new RTCPeerConnection();

    const api = this.rtmpUrl
      .replace(/^rtmp/, 'https')
      .replace(':1935', '')
      .replace(/\/live\/.*$/, '/rtc/v1/play/');

    const streamurl = this.rtmpUrl
      .replace(/^rtmp/, 'webrtc')
      .replace(':1935', '');

    const gotVideoStream = (e: RTCTrackEvent) => {
      this.videoEl.srcObject = e.streams[0];
    };

    this.pc.addEventListener('track', gotVideoStream);

    try {
      const offer = await this.pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });

      const response = await fetch(api, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api,
          streamurl,
          sdp: offer.sdp,
        }),
      }).then((res) => res.json());

      if (!response.sdp) {
        throw new Error('Response not has SDP');
      }

      await this.pc.setLocalDescription(offer);
      await this.pc.setRemoteDescription(
        new RTCSessionDescription({
          type: 'answer',
          sdp: response.sdp,
        }),
      );
    } catch (error) {
      console.error(error);
      this.close();
    }
  }

  close() {
    this.pc?.close();
    this.pc = undefined;
  }
}

export default SrsWebrtc;
