import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../../components/Loading';

const SafetyMonitoringDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "safety-monitoring-dashboard-page" */ '../../../pages/safety-monitoring-dashboard'
    ),
);

const safetyMonitoringDashboardRouter = (
  <Route path="safety-monitoring-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <SafetyMonitoringDashboardPageComponent />
        </Suspense>
      }
    />
  </Route>
);

export default safetyMonitoringDashboardRouter;
