import type { SVGProps } from 'react';

const VOCSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.961 8.44a4 4 0 1 0-4.52 4.521 7.463 7.463 0 0 0-.42 1.96A6.002 6.002 0 0 1 9 3a6.002 6.002 0 0 1 5.92 5.022 7.463 7.463 0 0 0-1.959.419Z"
      clipRule="evenodd"
    />
    <circle cx={15.5} cy={15.5} r={4.5} stroke="currentColor" strokeWidth={2} />
    <circle cx={11} cy={3} r={2} fill="currentColor" />
    <circle cx={21} cy={13} r={2} fill="currentColor" />
    <circle cx={17} cy={21} r={2} fill="currentColor" />
    <circle cx={3} cy={10} r={2} fill="currentColor" />
  </svg>
);
export default VOCSvgIcon;
