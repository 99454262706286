import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../types';

const initialState: User = {
  id: '',
  createdAt: '',
  email: '',
  enabled: false,
  firstName: '',
  imageURL: null,
  lastName: '',
  org: {
    id: '',
    displayName: '',
    imageURL: null,
    name: '',
    type: 'customer',
    country: '',
    ownerId: '',
    color: '',
    status: 'ACTIVE',
    taxId: '',
  },
  passwordChangeRequired: false,
  username: '',
};

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    resetUser: () => ({ ...initialState }),
    resetAll: () => initialState,
  },
});

export const { setUser, resetUser, resetAll } = userSlice.actions;
export default userSlice.reducer;
