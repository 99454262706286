import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Dasloop } from '../../types/Device';

type DeviceSliceState = {
  dasloops: Dasloop[];
};

const initialState: DeviceSliceState = {
  dasloops: [
    {
      id: '',
      owner: null,
      ownerUserId: '',
      dasId: '',
      projectId: '',
      workerId: '',
      shadow: {
        dataPoint: {
          geoPoint: {
            timestamp: '',
            value: {
              lat: 0,
              lon: 0,
            },
            stale: false,
          },
          batteryLevel: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          heartRate: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          fix: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          standstillTokenExpiredDate: {
            timestamp: null,
            value: null,
            stale: true,
          },
          standstillTokenExpiredRemainingCount: {
            timestamp: null,
            value: null,
            stale: true,
          },
          speed: {
            timestamp: '',
            value: 255,
            stale: false,
          },
          lpwan: {
            timestamp: '',
            value: 20,
            stale: false,
          },
          debugData: {
            timestamp: '',
            value: {
              wearingTime: 0,
              batteryCurrent: 0,
              stationaryTime: 0,
              networkDisconnectCount: 0,
              moduleRecoverCount: 0,
              serverDisconnectCount: 0,
            },
            stale: false,
          },
          firmwareVersion: {
            timestamp: '',
            value: '',
            stale: true,
          },
          altitude: {
            timestamp: '',
            value: null,
            stale: false,
          },
          ambientTemperature: {
            timestamp: null,
            value: null,
            stale: true,
          },
          strength: {
            timestamp: '',
            value: 17,
            stale: false,
          },
          wifi: {
            timestamp: '',
            value: null,
            stale: false,
          },
          reportInterval: {
            timestamp: '',
            value: 10,
            stale: true,
          },
          standstillTokenActivatedDuration: {
            timestamp: null,
            value: null,
            stale: true,
          },
          satellites: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          bootCount: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          bodyTemperature: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          timestamp: {
            timestamp: '',
            value: '',
            stale: false,
          },
          beacons: {
            timestamp: '',
            value: [
              {
                id: 0,
                rssi: 0,
              },
            ],
            stale: true,
          },
          standstillTokenActivatedDate: {
            timestamp: null,
            value: null,
            stale: true,
          },
          deviceStatus: {
            timestamp: '',
            value: 0,
            stale: false,
          },
          standstillTokenActivatedRemainingCount: {
            timestamp: null,
            value: null,
            stale: true,
          },
          heartRateQuility: {
            timestamp: '',
            value: 29,
            stale: true,
          },
          bootResult: {
            timestamp: '',
            value: 20482,
            stale: true,
          },
          shutdown: {
            timestamp: '',
            value: 0,
            stale: true,
          },
          humidity: {
            timestamp: null,
            value: null,
            stale: true,
          },
          pressure: {
            timestamp: null,
            value: null,
            stale: true,
          },
        },
      },
      coordinates: {
        alt: 0,
        lat: 0,
        lon: 0,
      },
      coordinatesSource: 'gps',
      lastCoordinatesUpdatedAt: '',
      createdAt: '',
      dasgasIds: [],
      isOnline: false,
      isWearing: false,
    },
  ],
};

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDasloops: (
      state,
      action: PayloadAction<DeviceSliceState['dasloops']>,
    ) => {
      return { ...state, dasloops: action.payload };
    },
    resetAll: () => initialState,
  },
});

export const { setDasloops, resetAll } = deviceSlice.actions;
export default deviceSlice.reducer;
