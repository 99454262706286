import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface DaslockAlertSvgIconProps extends SvgIconProps {
  disabled?: boolean;
}

const LowBatterySvgIcon = ({ ...props }: DaslockAlertSvgIconProps) => (
  <SvgIcon
    viewBox="0 0 40 40"
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/SvgIcon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 15V25H27V15H10ZM9.5 13C8.67157 13 8 13.6716 8 14.5V25.5C8 26.3284 8.67157 27 9.5 27H27.5C28.3284 27 29 26.3284 29 25.5V23H31C31.5523 23 32 22.5523 32 22V18C32 17.4477 31.5523 17 31 17H29V14.5C29 13.6716 28.3284 13 27.5 13H9.5ZM15 17H12V23H15V17Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LowBatterySvgIcon;
