import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DastempDashboardComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "dastemp-dashboard" */ '../../pages/dastemp-dashboard'
    ),
);

const dastempDashboardRouter = (
  <Route path="dastemp-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DastempDashboardComponent />
        </Suspense>
      }
    />
  </Route>
);

export default dastempDashboardRouter;
