import { VehicleAssign, VehicleWithOrg } from '../../../../types';
import { Vehicle } from '../../../../types/Resource';

export const checkIsVehicleInformationValid = (
  basicInfomation?: VehicleAssign,
) => {
  const requiredFields = ['name', 'orgId', 'alertInterval'];
  return requiredFields.every((field) => basicInfomation?.[field]);
};

export const checkIsPathSelected = (path: string | undefined) => {
  if (path) return true;
  return false;
};

export const checkIsFormVehicleEdited = (
  vehicleSelected: VehicleWithOrg,
  vehicleAssign: VehicleAssign,
) => {
  const checkFieldValue = (field: keyof Vehicle) => {
    const selectedValue = vehicleSelected[field];
    const willAssignValue = vehicleAssign[field];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue !== willAssignValue) return true;
    }
    return false;
  };

  if (checkFieldValue('orgId')) return true;
  if (checkFieldValue('name')) return true;
  if (checkFieldValue('alertInterval')) return true;
  if (checkFieldValue('plateNumber')) return true;
  if (checkFieldValue('remark')) return true;
  return false;
};
