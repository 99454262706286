import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CompleteButtonSvgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.83333 16.8333C9.37309 16.3731 9.3731 15.6269 9.83333 15.1667C10.2936 14.7064 11.0398 14.7064 11.5 15.1667L14 17.6667L19.8333 11.8333C20.2936 11.3731 21.0398 11.3731 21.5 11.8333C21.9602 12.2936 21.9602 13.0398 21.5 13.5L14 21L9.83333 16.8333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CompleteButtonSvgIcon;
