import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { styled } from '@mui/material';

import { ResourceType } from '../../../types';
import { Resource } from '../../../types/Resource';

import MuiTheme from '../../../theme';
import EmptyDataWarning from '../../EmptyDataWarning';
import Loading from '../../Loading';
import ProjectPanel from '../../Panel/ProjectPanel';
import Search from '../../Search';

import ResourceItem from './ResourceItem';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type ResourcePermissionListProps = {
  resourceType: ResourceType;
  resources: Array<Resource>;
  isLoadingProjectResource: boolean;
  onChange: (resoureIds: Array<string>) => void;
};

const ResourcePermissionList: React.FC<ResourcePermissionListProps> = ({
  resourceType,
  resources,
  isLoadingProjectResource,
  onChange,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const { t, i18n } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const [selectedResources, setSelectedResources] = useState<{
    [resourceId: string]: boolean | undefined;
  }>({});
  const [resourcelistHeight, setResourcelistHeight] = useState(0);

  const handleOnChange = (resourceId: string, checked: boolean) => {
    const newSelectedResources = { ...selectedResources };
    newSelectedResources[resourceId] = checked;
    setSelectedResources(newSelectedResources);
    onChange(
      Object.entries(newSelectedResources)
        .filter(([, bool]) => bool)
        .map(([id]) => id),
    );
  };

  const items = resources
    .filter((resource) => resource.name.indexOf(filterText) !== -1)
    .sort((a, b) => a.name.localeCompare(b.name, i18n.language));
  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const resource = items[index];
      return (
        <ResourceItem
          key={`resource-item-${resource.id}`}
          style={style}
          resourceType={resourceType}
          resource={resource}
          checked={!!selectedResources[resource.id]}
          onChange={handleOnChange}
        />
      );
    },
    [items, resourceType, selectedResources],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setResourcelistHeight(entry.contentBoxSize[0].blockSize);
      }
    });
    if (listRef.current) {
      resizeObserver.observe(listRef.current);
    }

    return () => {
      if (listRef.current) {
        resizeObserver.unobserve(listRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginLeft: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={t(
        `page.${resourceType}-management.title-${resourceType}-list-permission`,
      )}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer ref={listRef} data-cy="container-worker-list-permission">
        {isLoadingProjectResource ? (
          <Loading />
        ) : items.length > 0 ? (
          <FixedSizeList
            className="List"
            width="100%"
            height={resourcelistHeight}
            itemCount={items.length}
            itemSize={64}
          >
            {Row}
          </FixedSizeList>
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
    </ProjectPanel>
  );
};

export default ResourcePermissionList;
