import { i18n } from 'i18next';

const translateName = (I18n: i18n, firstName?: string, lastName?: string) => {
  switch (I18n.language) {
    case 'zh-Hant':
    case 'ja':
      return `${lastName}${firstName}`;
    default:
      return `${firstName} ${lastName}`;
  }
};

export default translateName;
