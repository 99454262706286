import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DascasTagSvgColorMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.272 7.03508C20.6845 6.09508 19.3155 6.09508 18.728 7.03508L16.875 9.99988H14C12.8954 9.99988 12 10.8953 12 11.9999V27.9999C12 29.1044 12.8954 29.9999 14 29.9999H16.875L18.728 32.9647C19.3155 33.9047 20.6845 33.9047 21.272 32.9647L23.125 29.9999H26C27.1046 29.9999 28 29.1044 28 27.9999V11.9999C28 10.8953 27.1046 9.99988 26 9.99988H23.125L21.272 7.03508ZM20 8.4999C20.2761 8.4999 20.5 8.27604 20.5 7.9999C20.5 7.72376 20.2761 7.4999 20 7.4999C19.7239 7.4999 19.5 7.72376 19.5 7.9999C19.5 8.27604 19.7239 8.4999 20 8.4999ZM19.5 31.9999C19.5 31.7237 19.7239 31.4999 20 31.4999C20.2761 31.4999 20.5 31.7237 20.5 31.9999C20.5 32.276 20.2761 32.4999 20 32.4999C19.7239 32.4999 19.5 32.276 19.5 31.9999Z"
        fill="#D9D9D9"
      />
      <rect x="14" y="12" width="12" height="16" rx="1" fill="white" />
      <rect x="17" y="14" width="1" height="1" fill="#F0F0F0" />
      <rect x="17" y="16" width="1" height="1" fill="#F0F0F0" />
      <rect x="17" y="18" width="1" height="1" fill="#F0F0F0" />
      <rect x="17" y="22" width="1" height="1" fill="#F0F0F0" />
      <rect x="17" y="20" width="1" height="1" fill="#F0F0F0" />
      <rect x="17" y="24" width="1" height="1" fill="#F0F0F0" />
      <rect x="22" y="14" width="1" height="1" fill="#F0F0F0" />
      <rect x="22" y="16" width="1" height="1" fill="#F0F0F0" />
      <rect x="22" y="18" width="1" height="1" fill="#F0F0F0" />
      <rect x="22" y="22" width="1" height="1" fill="#F0F0F0" />
      <rect x="22" y="20" width="1" height="1" fill="#F0F0F0" />
      <rect x="22" y="24" width="1" height="1" fill="#F0F0F0" />
      <rect x="19" y="14" width="1" height="1" fill="#F0F0F0" />
      <rect x="20" y="16" width="1" height="1" fill="#F0F0F0" />
      <rect x="19" y="18" width="1" height="1" fill="#F0F0F0" />
      <rect x="19" y="22" width="1" height="1" fill="#F0F0F0" />
      <rect x="18" y="26" width="4" height="1" fill="#656565" />
      <rect x="20" y="20" width="1" height="1" fill="#F0F0F0" />
      <rect x="20" y="24" width="1" height="1" fill="#F0F0F0" />
    </SvgIcon>
  );
};

export default DascasTagSvgColorMedium;
