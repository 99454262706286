import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Popper, styled, Typography } from '@mui/material';
import { addDays, format, startOfWeek } from 'date-fns';

import { Area } from '../../../types';

const Container = styled('div')`
  & .content {
    position: relative;
    display: grid;
    grid-template-columns: 40px 40px 40px 40px 40px 40px 40px;
    grid-template-rows: 20px 40px 40px 40px 40px 40px 40px;
    gap: 16px;

    & .week-name {
      color: white;
      text-align: center;
    }

    & .date {
      ${({ theme }) => ({
        color: theme.color.secondary.$100,
        backgroundColor: theme.color.secondary.$60,
        '&.has-attendance': {
          borderRadius: '4px',
          backgroundColor: theme.externalColor.primary.$60,
          '&:hover': {
            border: '2px solid #FFFFFF',
          },
        },
      })}
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      user-select: none;
      &.not-current-month {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    & .tooltip-content {
      color: ${({ theme }) => theme.color.secondary.$40};
      background-color: ${({ theme }) => theme.externalColor.secondary.$140};
      border-radius: 4px;
      padding: 13px 10px;
      max-width: 200px;
      max-height: 300px;
      overflow: auto;

      & li {
        list-style-type: none;
      }
    }
  }
`;

interface AttendanceReportDaysTableProps {
  title?: string;
  refDate: Date;
  countTable: { [date: string]: { [areaId: string]: number } } | undefined;
  areaMap: { [id: string]: Area | undefined } | undefined;
}

const AttendanceReportDaysTable: React.FC<AttendanceReportDaysTableProps> = ({
  title,
  countTable,
  refDate,
  areaMap,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const dayDomRef = useRef<Array<HTMLDivElement | null>>([]);
  const { t } = useTranslation('report-dashboard');
  const [tooltipConfigs, setTooltipConfigs] = useState<{
    show: boolean;
    domIndex: number;
    content: any[];
  }>({
    show: false,
    domIndex: 0,
    content: [],
  });

  const handleOpenTooltip = useCallback(
    (domIndex, dateText: string) => {
      if (countTable && countTable[dateText]) {
        setTooltipConfigs({
          show: true,
          domIndex,
          content: Object.entries(countTable[dateText] ?? {}).map(
            ([areaId, value]) => {
              return (
                <li key={`area-${areaId}-hours`}>
                  {areaMap?.[areaId]?.name ?? 'unknown'}:{' '}
                  {t('number-of-hour', {
                    count: value,
                  })}
                </li>
              );
            },
          ),
        });
      }
    },
    [countTable, areaMap],
  );

  const handleCloseTooltip = useCallback(() => {
    setTooltipConfigs({
      show: false,
      domIndex: 0,
      content: [],
    });
  }, []);

  const { items } = useMemo(() => {
    const localItems: Array<ReactNode> = [];
    const currentMonth = refDate.getMonth();
    const startOfWeekDate = startOfWeek(refDate);

    if (countTable) {
      dayDomRef.current = [];
      for (let i = 0; i < 42; i += 1) {
        const currentDate = addDays(startOfWeekDate, i);
        const month = currentDate.getMonth();
        const date = currentDate.getDate();
        const dateString = format(currentDate, 'yyyy-MM-dd');

        localItems.push(
          <div
            ref={(ref) => dayDomRef.current.push(ref)}
            key={`AttendanceReportDaysTable-item-${month}-${date}`}
            className={`date${
              month !== currentMonth ? ' not-current-month' : ''
            }${
              Object.keys(countTable?.[dateString] ?? {}).length > 0
                ? ' has-attendance'
                : ''
            }`}
            onClick={() => handleOpenTooltip(i, dateString)}
          >
            {date}
          </div>,
        );
      }
    }

    return {
      items: localItems,
    };
  }, [refDate, countTable, handleOpenTooltip, handleCloseTooltip]);

  useEffect(() => {
    document
      .querySelectorAll('div.date')
      .forEach((dom) => dayDomRef.current.push(dom as HTMLDivElement));
    return () => {
      dayDomRef.current = [];
    };
  }, []);

  return (
    <Container className="report-contianer">
      <Typography
        variant="subtitle2"
        textAlign="center"
        color="white"
        fontWeight="bold"
        margin="0 0 10px"
      >
        {title}
      </Typography>
      <div ref={contentRef} className="content">
        <span className="week-name">{t('week.sun')}</span>
        <span className="week-name">{t('week.mon')}</span>
        <span className="week-name">{t('week.tue')}</span>
        <span className="week-name">{t('week.wed')}</span>
        <span className="week-name">{t('week.thu')}</span>
        <span className="week-name">{t('week.fri')}</span>
        <span className="week-name">{t('week.sat')}</span>
        {items}
      </div>
      {!!dayDomRef.current[tooltipConfigs.domIndex] && (
        <Popper
          open={tooltipConfigs.show}
          anchorEl={dayDomRef.current[tooltipConfigs.domIndex]}
          placement="right-start"
          container={contentRef.current}
        >
          <ClickAwayListener onClickAway={handleCloseTooltip}>
            <div className="tooltip-content">{tooltipConfigs.content}</div>
          </ClickAwayListener>
        </Popper>
      )}
    </Container>
  );
};

export default AttendanceReportDaysTable;
