import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import { Org } from '../../../../types';
import { Equipment } from '../../../../types/Resource';

import { useAppSelector } from '../../../../hooks';

import EmptyDataWarning from '../../../../components/EmptyDataWarning';
import Loading from '../../../../components/Loading';
import ProjectPanel from '../../../../components/Panel/ProjectPanel';
import Search from '../../../../components/Search';

import MuiTheme from '../../../../theme';

import EquipmentItem from './EquipmentItem';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type EquipmentListOfGroupProps = {
  selectedId: (equipmentIds: Array<string>) => void;
  data: Array<Equipment & { orgDetail: Org }>;
  isLoadingEquipmentOfAGroup: boolean;
};

const EquipmentListOfGroup: React.VFC<EquipmentListOfGroupProps> = ({
  selectedId,
  data,
  isLoadingEquipmentOfAGroup,
}) => {
  const { i18n } = useTranslation();
  const { selectedGroup } = useAppSelector(
    (store) => store.equipmentManagement,
  );
  const [filterText, setFilterText] = useState('');

  const [equipmentIDWillAssign, setEquipmentIdWillAssign] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    const initEquipmentIds: Array<string> = data.map(
      (equipment) => equipment.id,
    );
    setEquipmentIdWillAssign(initEquipmentIds);
  }, [data]);

  const handleOnchange = (value: any, checked: boolean) => {
    const updateValue = value;
    if (checked) {
      setEquipmentIdWillAssign((prev) =>
        prev.filter((equipmentId) => equipmentId !== updateValue),
      );
    } else {
      setEquipmentIdWillAssign([...equipmentIDWillAssign, updateValue]);
    }
  };

  useEffect(() => {
    selectedId(equipmentIDWillAssign);
  }, [equipmentIDWillAssign]);

  const items = data
    .filter((equipment) => equipment.name.indexOf(filterText) !== -1)
    .sort((a, b) => {
      if (a.groupIds.length === 1 && b.groupIds.length > 1) {
        return 1;
      } else if (a.groupIds.length > 1 && b.groupIds.length === 1) {
        return -1;
      }
      return a.name.localeCompare(b.name, i18n.language);
    })
    .map((equipment) => (
      <EquipmentItem
        key={`EquipmentItem-item${equipment.id}`}
        onChange={handleOnchange}
        equipment={equipment}
        disabledEquipment={equipment.groupIds.length === 1 ? true : false}
      />
    ));

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={selectedGroup.name}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer data-cy="container-equipment-list-group">
        {isLoadingEquipmentOfAGroup ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
    </ProjectPanel>
  );
};

export default EquipmentListOfGroup;
