import * as dateFnsLocale from 'date-fns/locale';

export const getDateFnsLocale = (languageCode: string) => {
  switch (languageCode) {
    case 'zh-Hant':
      return dateFnsLocale.zhTW;
    case 'ja':
      return dateFnsLocale.ja;
    default:
      return dateFnsLocale.enUS;
  }
};
