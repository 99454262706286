import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@beeinventor/dasiot-react-component-lib';
import {
  Avatar as MuiAvatar,
  Box,
  styled,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { InputValidationError, PlantWillAssign } from '../../../../types';

import { useAppSelector } from '../../../../hooks';

import OrgAutocompleteBasic from '../../../../components/OrgAutocompleteBasic';
import Input from '../../../../components/TextField/Input';

import PlantDefault from '../../../../assets/images/default-image/plant_default.png';
import UploadSvg from '../../../../assets/images/svg/btn_upload_image.svg';
import ErrorSvg from '../../../../assets/images/svg/ic_error_ifo.svg';

import { checkEmtpy, checkNameFormat, checkYear } from './lib';

import './TargetOperationDialog.css';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  flex: 1;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 10px 10px 10px;
  border-radius: 4px;
`;

const ContentTitle = styled(Typography)`
  color: white;
  line-height: 2.125rem;
`;

const SpanRequire = styled('span')`
  color: ${({ theme }) => theme.color.highlight};
`;

const Avatar = styled(MuiAvatar)`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetails = styled('div')`
  margin: 10px 0;
  height: 58px;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.box_bbg,
}));

const ContainerImage = styled('div')`
  margin: 10px auto;
  width: 200px;
  position: relative;
`;

const ContainerUploadImage = styled('div')`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
`;

const Error = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > span {
    color: #ff6b00;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const ContainerTextArea = styled('div')`
  & textarea {
    ${({ theme }) => ({ ...theme.typography.body2 })}
    width: 100%;
    font-family: 'Noto Sans TC', 'Noto Sans';
    background: ${({ theme }) => theme.color.secondary.$100};
    border-radius: 4px;
    outline: none;
    color: white;
    padding: 10px;
    border-color: transparent;
    resize: none;
    &:hover {
      background: ${({ theme }) => theme.color.secondary.$100};
    }
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
`;

const ContainerDatePicker = styled('div', { label: 'Date-Picker-Container' })`
  & .DatePicker {
    display: flex;
    color: ${({ theme }) => theme.color.secondary.$60};
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    background: rgba(0, 0, 0, 0.2);
    padding-left: 8px;
    z-index: 10000;
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    & .MuiSvgIcon-root path {
      stroke: ${({ theme }) => theme.color.secondary.$60};
    }
  }
  & .DatePicker span {
    ${({ theme }) => ({ ...theme.typography.body2 })}
  }
`;

const InputYear = styled(Input)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface BasicInformationContentProps {
  handlePlantDataWIllAssign: (data: PlantWillAssign) => void;
  data: PlantWillAssign | undefined;
}

const BasicInformationContent: React.VFC<BasicInformationContentProps> = ({
  handlePlantDataWIllAssign,
  data,
}) => {
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const [imageFormatError, setImageFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });
  const [nameFormatError, setNameFormatError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });
  const [modelFormatError, setModelFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [serialNumberFormatError, setSerialNumberFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [permitNumberFormatError, setPermitNumberFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [permitIssuedByFormatError, setPermitIssuedByFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [safetyLoadFormatError, setSafetyLoadFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [yearProductionFormatError, setYearProductionFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [brandFormatError, setBrandFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [numberReadingFormatError, setNumberReadingFormatError] =
    useState<InputValidationError>({ isError: false, message: '' });
  const [expiryDateFormatError, setExpiryDateFormatError] =
    useState<InputValidationError>({
      isError: false,
      message: '',
    });

  const [orgIdError, setOrgIdError] = useState<InputValidationError>({
    isError: false,
    message: '',
  });
  const { t } = useTranslation('project-setting');
  const fileRef = useRef<HTMLInputElement | null>(null);

  const dialogRef = useRef<HTMLDivElement>(null);

  const handleOnClickUploadImage = () => {
    fileRef.current?.click();
  };

  const handleOnChange = (
    propertyObjs: Array<{ propertyName: keyof PlantWillAssign; value: any }>,
  ) => {
    const currentValue = {
      ...data,
    };
    propertyObjs.forEach((obj) => {
      // @ts-ignore
      currentValue[obj.propertyName] = obj.value;
    });

    handlePlantDataWIllAssign({
      id: currentValue?.id ?? '',
      orgId: currentValue?.orgId ?? '',
      name: currentValue?.name ?? '',
      model: currentValue?.model ?? '',
      serialNumber: currentValue?.serialNumber ?? '',
      permitNumber: currentValue?.permitNumber ?? '',
      permitIssuer: currentValue?.permitIssuer ?? '',
      permitExpiryDate: currentValue?.permitExpiryDate ?? '',
      safetyLoad: currentValue?.safetyLoad ?? undefined,
      projectId: currentValue?.projectId ?? '',
      remark: currentValue?.remark ?? undefined,
      imageURL: currentValue?.imageURL ?? '',
      groupIds: currentValue?.groupIds ?? [],
      bindingDastracks: currentValue?.bindingDastracks ?? [],
      bindingDascases: currentValue?.bindingDascases ?? [],
      brand: currentValue?.brand ?? undefined,
      numberReading: currentValue?.numberReading ?? undefined,
      yearOfProduction: currentValue?.yearOfProduction ?? undefined,
      avatarFile: currentValue.avatarFile,
      plantType: 'plant',
    });
  };

  const handleOnSelectFile = () => {
    setImageFormatError({ isError: false, message: '' });
    if (fileRef.current && fileRef.current?.files) {
      const fileImge = fileRef.current?.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const newSrc = reader.result as string;
        if (fileRef.current && fileRef.current.files) {
          handleOnChange([
            {
              propertyName: 'avatarFile',
              value: fileRef.current.files[0],
            },
            {
              propertyName: 'imageURL',
              value: newSrc,
            },
          ]);
        }
      });
      reader.readAsDataURL(fileImge);
    }
  };

  const LIST_ORG = useMemo(() => {
    const orgs = Object.values(orgMap);
    if (orgs.length > 0) {
      return orgs.map((organization) => ({
        id: organization?.id ?? '',
        name: organization?.name ?? '',
        color: organization?.color ?? '',
      }));
    }
    return [
      {
        id: '',
        name: '',
        color: '',
      },
    ];
  }, [orgMap]);

  const handleDateFormat = (date) => {
    if (!date) {
      setExpiryDateFormatError({
        isError: true,
        message: t('error:inputIsEmpty'),
      });
    } else {
      setExpiryDateFormatError({
        isError: false,
        message: '',
      });
    }
  };

  return (
    <Container ref={dialogRef}>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.basic-information.title')}
        <SpanRequire>*</SpanRequire>
      </ContentTitle>
      <Content>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? ''}
            alt="plant photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={PlantDefault} alt="worker beeinventor" />
          </Avatar>
          <ContainerUploadImage onClick={handleOnClickUploadImage}>
            <img src={UploadSvg} alt="upload icon" />
            <input
              ref={fileRef}
              type="file"
              hidden
              accept=".jpg, .png, .jpeg, .svg"
              onChange={handleOnSelectFile}
              data-cy="input-image-plant"
            />
          </ContainerUploadImage>
        </ContainerImage>
        {imageFormatError.message && imageFormatError.message !== '' && (
          <Error>
            {<img src={ErrorSvg} alt="error" />}
            <span>{imageFormatError.message}</span>
          </Error>
        )}
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.organization',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <OrgAutocompleteBasic
            data={LIST_ORG}
            onSelect={(d) => {
              handleOnChange([{ propertyName: 'orgId', value: d.id }]);
            }}
            onBlur={() => {
              if (!data?.orgId) {
                setOrgIdError({ isError: true, message: t('error') });
              } else {
                setOrgIdError({ isError: false, message: t('') });
              }
            }}
            error={orgIdError}
            selectedId={data?.orgId ?? ''}
            data-cy="dropdown-org-plant"
            placeholder={t('page.select-organization')}
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.plant-name',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.plant-name',
            )}
            value={data?.name ?? ''}
            onChange={(e) => {
              handleOnChange([
                { propertyName: 'name', value: e.currentTarget.value },
              ]);
            }}
            onBlur={() => setNameFormatError(checkNameFormat(data?.name ?? ''))}
            error={nameFormatError.isError}
            errorMessage={nameFormatError.message}
            autoComplete="off"
            data-cy="input-name-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.model',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.model',
            )}
            value={data?.model ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'model',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={() => setModelFormatError(checkEmtpy(data?.model ?? ''))}
            error={modelFormatError.isError}
            errorMessage={modelFormatError.message}
            autoComplete="off"
            data-cy="input-model-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.serial-number',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.serial-number',
            )}
            value={data?.serialNumber ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'serialNumber',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={() =>
              setSerialNumberFormatError(checkEmtpy(data?.serialNumber ?? ''))
            }
            error={serialNumberFormatError.isError}
            errorMessage={serialNumberFormatError.message}
            autoComplete="off"
            data-cy="input-serial-number-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.permit-number',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.permit-number',
            )}
            value={data?.permitNumber ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'permitNumber',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={() =>
              setPermitNumberFormatError(checkEmtpy(data?.permitNumber ?? ''))
            }
            error={permitNumberFormatError.isError}
            errorMessage={permitNumberFormatError.message}
            autoComplete="off"
            data-cy="input-permit-number-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.permit-issued',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.permit-issued',
            )}
            value={data?.permitIssuer ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'permitIssuer',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={() =>
              setPermitIssuedByFormatError(checkEmtpy(data?.permitIssuer ?? ''))
            }
            error={permitIssuedByFormatError.isError}
            errorMessage={permitIssuedByFormatError.message}
            autoComplete="off"
            data-cy="input-permit-issuer-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.certificate.expiry-date',
            )}
            <SpanRequire>*</SpanRequire>
          </Title>
          <ContainerDatePicker>
            <ContainerDatePicker>
              <DatePicker
                className="DatePicker"
                startDate={
                  data?.permitExpiryDate
                    ? new Date(data.permitExpiryDate)
                    : new Date()
                }
                onSelect={(date) => {
                  handleDateFormat(date);
                  if (date instanceof Date) {
                    handleOnChange([
                      {
                        propertyName: 'permitExpiryDate',
                        value: date.toISOString(),
                      },
                    ]);
                  }
                }}
                dateFormat="yyyy-MM-dd"
                popperProps={{
                  container: dialogRef.current,
                  className: 'dropdown-date-picker',
                }}
                mode="dark"
                data-cy="datepicker-expiryDate-permit-issuer"
              />
            </ContainerDatePicker>
            {expiryDateFormatError.message &&
              expiryDateFormatError.message !== '' && (
                <Error>
                  <img src={ErrorSvg} alt="error" />
                  <span>{expiryDateFormatError.message}</span>
                </Error>
              )}
          </ContainerDatePicker>
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.safety-load',
            )}
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.safety-load',
            )}
            value={data?.safetyLoad ?? ''}
            type="number"
            inputProps={{ min: 0, inputMode: 'numeric' }}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'safetyLoad',
                  value: parseInt(e.currentTarget.value, 10),
                },
              ]);
            }}
            onBlur={() =>
              setSafetyLoadFormatError(checkEmtpy(data?.safetyLoad ?? ''))
            }
            error={safetyLoadFormatError.isError}
            errorMessage={safetyLoadFormatError.message}
            autoComplete="off"
            data-cy="input-safety-load-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.year-of-production',
            )}
          </Title>
          <InputYear
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.year-of-production',
            )}
            value={data?.yearOfProduction ?? ''}
            inputProps={{ inputMode: 'numeric' }}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'yearOfProduction',
                  value: parseInt(e.currentTarget.value, 10),
                },
              ]);
            }}
            onBlur={() =>
              setYearProductionFormatError(
                checkYear(data?.yearOfProduction ?? ''),
              )
            }
            placeholder="yyyy"
            error={yearProductionFormatError.isError}
            errorMessage={yearProductionFormatError.message}
            autoComplete="off"
            type="number"
            data-cy="input-year-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.brand',
            )}
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.brand',
            )}
            value={data?.brand ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'brand',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            onBlur={() => setBrandFormatError(checkEmtpy(data?.brand ?? ''))}
            error={brandFormatError.isError}
            errorMessage={brandFormatError.message}
            autoComplete="off"
            data-cy="input-brand-plant"
          />
        </ContainerDetails>
        <ContainerDetails>
          <Title>
            {t(
              'page.plant-management.dialog.create-plant.basic-information.number-reading',
            )}
          </Title>
          <Input
            name={t(
              'page.plant-management.dialog.create-plant.basic-information.number-reading',
            )}
            value={data?.numberReading ?? ''}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'numberReading',
                  value: parseInt(e.currentTarget.value, 10),
                },
              ]);
            }}
            type="number"
            inputProps={{ min: 0, inputMode: 'numeric' }}
            onBlur={() =>
              setNumberReadingFormatError(checkEmtpy(data?.numberReading ?? ''))
            }
            error={numberReadingFormatError.isError}
            errorMessage={numberReadingFormatError.message}
            autoComplete="off"
            data-cy="input-number-reading-plant"
          />
        </ContainerDetails>
        <ContainerTextArea>
          <Title>
            {t(
              'page.worker-management.dialog.create-worker.basic-information.remark',
            )}
          </Title>
          <TextareaAutosize
            id="group-remark-input"
            name="group-remark"
            value={data?.remark ?? ''}
            maxRows={4}
            minRows={4}
            onChange={(e) => {
              handleOnChange([
                {
                  propertyName: 'remark',
                  value: e.currentTarget.value,
                },
              ]);
            }}
            autoComplete="off"
            data-cy="input-remark-plant"
          />
        </ContainerTextArea>
      </Content>
    </Container>
  );
};

export default BasicInformationContent;
