import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';

const DaswaterDashboardPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "daswater-l-dashboard-page" */ '../../pages/daswater-dashboard'
    ),
);

const EditPathPipeComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "daswater-l-dashboard-page" */ '../../components/EditPathPipeDialogMap'
    ),
);

const HistoricalRecordComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "daswater-l-dashboard-page" */ '../../pages/daswater-dashboard/hostorical-record'
    ),
);

const daswaterDashboardRouter = (
  <Route path="daswater-dashboard" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <DaswaterDashboardPageComponent />
        </Suspense>
      }
    >
      <Route path="pipe-path-setting" element={<EditPathPipeComponent />} />
      <Route path="historical-record" element={<HistoricalRecordComponent />} />
    </Route>
  </Route>
);

export default daswaterDashboardRouter;
